// PATH

import { images } from '../assets/images';

export const Routes = {
  DEFAULT: '/',
  PROJECT: '/projects/:projectId',
  SUPERVISORS_LIST: '/supervisors',
  MENTORS_LIST: '/mentors',
  FACILITATORS_LIST: '/facilitators',
  FREEMIUMS_LIST: '/freemiums',
  SCHOOLS_LIST: '/schools',
  PROJECTS_LIST: '/projects',
  INSCRIPTIONS: '/inscriptions',
  PROJECTS: '/projects',
  ADMIN: '/admin',
  SUCCESS: '/success',
  FACILITATOR: '/facilitators/:facilitatorId',
  FREEMIUM: '/freemiums/:freemiumId',
  MENTOR: '/mentors/:mentorId',
  SUPERVISOR: '/supervisors/:supervisorId',
  SCHOOL_CREATION: '/schools/create',
  SCHOOL: '/schools/:schoolId',
  SCHOOL_REINSCRIPTION: '/schools/:schoolId/reinscription',
  INSCRIPTIONS_SCHOOL: '/inscriptions/school/:schoolId',
  REINSCRIPTIONS_SCHOOL: '/reinscriptions/school/:schoolId',
  PROJECT_CREATION: '/projects/create',
  INSCRIPTIONS_MENTOR: '/inscriptions/mentor/:mentorId',
  FACILITATOR_CREATION: '/facilitators/create',
  FREEMIUM_CREATION: '/freemiums/create',
  MENTOR_CREATION: '/mentors/create',
  SUPERVISOR_CREATION: '/supervisors/create',
  LEGAL_MENTIONS: '/mentions-legales',
  NOTIFICATION_CREATION: '/notifications/create',
  NOTIFICATION_LIST: '/notifications',
  MINI_CREATION: '/projects/minis/create',
  MINI_DETAIL: '/projects/minis/:miniId',
  MINI_LIST: '/projects/minis',
};

export const url = {
  LIST_SUPERVISORS: adminId => `/admin/${adminId}/supervisors`,
  LIST_SCHOOLS: adminId => `/admin/${adminId}/schools`,
  LIST_PROJECTS: adminId => `/admin/${adminId}/projects`,
  LIST_MENTORS: adminId => `/admin/${adminId}/mentors`,
  LIST_FACILITATORS: adminId => `/admin/${adminId}/facilitators`,
  LIST_FREEMIUMS: adminId => `/admin/${adminId}/freemiums`,
  LIST_USERS: adminId => `/admin/${adminId}/users`,
  PROJECT_DETAIL: (adminId, id) => `/admin/${adminId}/projects/${id}`,
  PROJECT_LINK_USERS: (adminId, projectId) =>
    `/admin/${adminId}/projects/${projectId}/linkUser`,
  PROJECT_UNLINK_USERS: (adminId, projectId) =>
    `/admin/${adminId}/projects/${projectId}/unlinkUser`,
  SUPERVISOR_LINK_PROJECT: (adminId, supervisorId) =>
    `/admin/${adminId}/supervisors/${supervisorId}/linkProject`,
  SUPERVISOR_UNLINK_PROJECT: (adminId, supervisorId) =>
    `/admin/${adminId}/supervisors/${supervisorId}/unlinkProject`,
  MENTOR_LINK_PROJECT: (adminId, mentorId) =>
    `/admin/${adminId}/mentors/${mentorId}/linkProject`,
  MENTOR_UNLINK_PROJECT: (adminId, mentorId) =>
    `/admin/${adminId}/mentors/${mentorId}/unlinkProject`,
  FACILITATOR_LINK_PROJECT: (adminId, facilitatorId) =>
    `/admin/${adminId}/facilitators/${facilitatorId}/linkProject`,
  FACILITATOR_UNLINK_PROJECT: (adminId, facilitatorId) =>
    `/admin/${adminId}/facilitators/${facilitatorId}/unlinkProject`,
  GET_REGIONS: '/inscription/regions',
  SCHOOL_DETAIL: (adminId, id) => `/admin/${adminId}/schools/${id}`,
  LIST_SCHOOLS_INSCRIPTIONS: (adminId, isReinscription) =>
    `/admin/${adminId}/inscriptions/schools?isReinscription=${isReinscription}`,
  LIST_MENTORS_INSCRIPTIONS: adminId =>
    `/admin/${adminId}/inscriptions/mentors`,
  SCHOOL_DETAIL_INSCRIPTIONS: (adminId, id, isReinscription) =>
    `/admin/${adminId}/inscriptions/schools/${id}?isReinscription=${isReinscription}`,
  FACILITATOR_DETAIL: (adminId, facilitatorId) =>
    `/admin/${adminId}/facilitators/${facilitatorId}`,
  MENTOR_DETAIL: (adminId, mentorId) => `/admin/${adminId}/mentors/${mentorId}`,
  FREEMIUM_DETAIL: (adminId, freemiumId) =>
    `/admin/${adminId}/freemiums/${freemiumId}`,
  FREEMIUM_UPGRADE: (adminId, freemiumId) =>
    `/admin/${adminId}/freemiums/${freemiumId}/upgrade`,
  DELETE_SCHOOL: (adminId, schoolId) => `/admin/${adminId}/schools/${schoolId}`,
  DELETE_PROJECT: (adminId, projectId) =>
    `/admin/${adminId}/projects/${projectId}`,
  ARCHIVE_PROJECT: (adminId, projectId) =>
    `/admin/${adminId}/projects/${projectId}`,
  ARCHIVE_PROJECTS: adminId => `/admin/${adminId}/projects/archive`,
  CHANGE_TO_FINISH_PROJECTS: adminId => `/admin/${adminId}/projects/finish`,
  SAVE_PROJECT_INFO: (adminId, projectId) =>
    `/admin/${adminId}/projects/${projectId}`,
  CREATE_PROJECT: adminId => `/admin/${adminId}/projects`,
  MENTOR_DETAIL_INSCRIPTIONS: (adminId, id) =>
    `/admin/${adminId}/inscriptions/mentors/${id}`,
  EXPORT_SCHOOLS: adminId => `/admin/${adminId}/schools/export`,
  EXPORT_SUPERVISORS: adminId => `/admin/${adminId}/supervisors/export`,
  EXPORT_MENTORS: adminId => `/admin/${adminId}/mentors/export`,
  EXPORT_FACILITATORS: adminId => `/admin/${adminId}/facilitators/export`,
  EXPORT_FREEMIUMS: adminId => `/admin/${adminId}/freemiums/export`,
  EXPORT_PROJECTS: adminId => `/admin/${adminId}/projects/export`,
  EXPORT_ARCHIVES_PROJECTS: adminId =>
    `/admin/${adminId}/projects/export/archives`,
  REINSCRIPTION: (adminId, schoolId) =>
    `/admin/${adminId}/schools/${schoolId}/reinscription`,
  NOTIFICATIONS: adminId => `/admin/${adminId}/notifications`,
  MINI: adminId => `/admin/${adminId}/minis`,
  MINI_DETAIL: (adminId, miniId) => `/admin/${adminId}/minis/${miniId}`,
  MINI_DUPLICATE: (adminId, miniId) =>
    `/admin/${adminId}/minis/${miniId}/duplicate`,
  EXPORT_MINI_PROJECTS: adminId => `/admin/${adminId}/minis/export`,
  EXPORT_ARCHIVE_MINI_PROJECTS: adminId =>
    `/admin/${adminId}/minis/export/archives`,
};

//

export const origins = {
  AUTHENT: 'A',
  KUMULUS: 'K',
  DASHBOARD: 'D',
  FACILITATOR: 'F',
};

export const errorCodes = {
  FORBIDDEN: {
    ACCESS_DENIED: 'AccessDenied',
    INVALID_REDIRECTION: 'InvalidRedirection',
  },
};

// Filters

export const userInscriptionStatus = {
  ALL: { label: 'all' },
  PENDING: { label: 'pending' },
  ACCEPTED: { label: 'accepted' },
};

export const schoolInscriptionStatus = {
  ALL: { label: 'all' },
  DRAFT: { label: 'draft' },
  PENDING: { label: 'pending' },
  WAITING: { label: 'waiting' },
};

export const userStatus = {
  ALL: { label: 'all' },
  WARNING: { label: 'warning' },
  OK: { label: 'ok' },
};

export const userRights = {
  PENDING: 'PENDING',
  FREEMIUM: 'FREEMIUM',
  PREMIUM: 'PREMIUM',
};

export const pourcentFilter = {
  ALL: {
    label: 'all',
    low: 0,
    high: 100,
  },
  XSMALL: {
    label: 'xsmall',
    low: 0,
    high: 20,
  },
  SMALL: {
    label: 'small',
    low: 21,
    high: 40,
  },
  MEDIUM: {
    label: 'medium',
    low: 41,
    high: 60,
  },
  LARGE: {
    label: 'large',
    low: 61,
    high: 80,
  },
  XLARGE: {
    label: 'xlarge',
    low: 81,
    high: 100,
  },
};

//

export const userRole = {
  ADMIN: 'ADMIN',
  FACILITATOR: 'FACILITATOR',
  SUPERVISOR: 'SUPERVISOR',
  MENTOR: 'MENTOR',
  STUDENT: 'STUDENT',
  FREEMIUM: 'FREEMIUM',
};

export const userRealStatus = {
  PENDING: 'PENDING',
};

export const schoolStatus = {
  DRAFT: 'DRAFT',
  WAITING: 'WAITING',
  VALIDATED: 'VALIDATED',
};

export const projectStatus = {
  VALIDATED: 'VALIDATED',
  ARCHIVED: 'ARCHIVED',
  COMMING: 'COMMING',
  FINISHED: 'FINISHED'
};

export const projectStatusFilter = {
  ALL: {
    label: 'all',
    value: "ALL"
  },
  COMMING: {
    label: 'comming',
    value: 'COMMING'
  },
  VALIDATED: {
    label: 'validated',
    value: 'VALIDATED'
  },
  FINISHED: {
    label: 'finished',
    value: 'FINISHED'
  },
}

export const projectDuration = {
  ONE_YEAR: 1,
  TWO_YEARS: 2,
};

export const userValidationStatus = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
};

export const linkUserTypes = {
  FACILITATOR: 'facilitators',
  SUPERVISOR: 'supervisors',
  MENTOR: 'mentors',
};

export const actions = {
  LOGOUT: 'logout',
};

export const projectTypes = [
  {
    value: "M9",
    image: images.epaLogoMiniM,
  },
  {
    value: "M13",
    image: images.epaLogoMiniM,
  },
  {
    value: "L",
    image: images.epaLogoMiniL,
  },
  {
    value: "XL",
    image: images.epaLogoMiniXL,
  },
  {
    value: "10X",
    image: images.epaLogoChallenge10X,
  },
];

export const reinscriptionProjectTypes = [
  {
    value: "M13",
    image: images.epaLogoMiniM,
  },
  {
    value: "L",
    image: images.epaLogoMiniL,
  },
  {
    value: "XL",
    image: images.epaLogoMiniXL,
  },
];

export const projectTypeKeys = {
  M13: "M13",
  M9: "M9",
  L: "L",
  S: "S",
  X10: "10X",
  XL: "XL",
};

export const projecTypeFilter = {
  ALL: {
    label: "all",
    value: "ALL",
  },
  M9: {
    label: "m9",
    value: "M9",
  },
  M13: {
    label: "m13",
    value: "M13",
  },
  L: {
    label: "l",
    value: "L",
  },
  XL: {
    label: "xl",
    value: "XL",
  },
  X10: {
    label: "challenge",
    value: "10X",
  },
};

export const operations = {
  WITHDRAW: {
    key: 'WITHDRAW',
    label: 'withdraw',
  },
  DEPOSIT: {
    key: 'DEPOSIT',
    label: 'deposit',
  },
};

export const menu = {
  SUPERVISORS: Routes.SUPERVISORS_LIST,
  SCHOOLS: Routes.SCHOOLS_LIST,
  PROJECTS: Routes.PROJECTS_LIST,
};

export const footerLink = {
  LEGAL_MENTIONS: {
    label: 'legal_mentions',
    link: Routes.LEGAL_MENTIONS,
  },
};

export const navigationMenu = [
  {
    label: 'inscriptions',
    route: Routes.INSCRIPTIONS,
  },
  {
    label: 'schools',
    route: Routes.SCHOOLS_LIST,
  },
  {
    label: 'supervisors',
    route: Routes.SUPERVISORS_LIST,
  },
  {
    label: 'mentors',
    route: Routes.MENTORS_LIST,
  },
  {
    label: 'facilitators',
    route: Routes.FACILITATORS_LIST,
  },
  {
    label: 'freemiums',
    route: Routes.FREEMIUMS_LIST,
  },
  {
    label: 'projects',
    route: Routes.PROJECTS_LIST,
  },
  {
    label: 'notifications',
    route: Routes.NOTIFICATION_LIST,
  },
];

export const InscriptionsTabs = {
  SCHOOLS: 'schools',
  MENTORS: 'mentors',
};

export const SchoolInscriptionsTabs = {
  INSCRIPTIONS: 'inscriptions',
  REINSCRIPTIONS: 'reinscriptions',
};

export const InputTypes = {
  PASSWORD: 'password',
  TEXT: 'text',
  NUMBER: 'number',
};

export const Gender = {
  MR: { label: 'mr' },
  MME: { label: 'mrs' },
};

export const civilityValues = {
  MR: 'MR',
  MME: 'MME',
};

export const recipientType = {
  REGION: 'R',
  ACADEMY: 'A',
};

export const presenceType = {
  DISTANCE: 'DISTANCE',
  PRESENCE: 'PRESENCE',
};

export const miniStatus = {
  DRAFT: 'DRAFT',
  RUNNING: 'RUNNING',
  FINISHED: 'FINISHED',
  ARCHIVED: 'ARCHIVED',
};

export const miniStatusDropDown = {
  ALL: { label: 'all' },
  DRAFT: { label: 'mini_draft' },
  RUNNING: { label: 'mini_running' },
  FINISHED: { label: 'mini_finished' },
};

export const toolType = {
  VIDEO: 'VIDEO',
  COMMON_LINK: 'COMMON_LINK',
  ROOM_LINK: 'ROOM_LINK',
};

export const iconList = {
  TRELLO: 'TRELLO',
  TWITTER: 'TWITTER',
  FACEBOOK: 'FACEBOOK',
  SLACK: 'SLACK',
  MAIL: 'MAIL',
  CALENDAR: 'CALENDAR',
  G_DRIVE: 'G_DRIVE',
  DROPBOX: 'DROPBOX',
  ONE_DRIVE: 'ONE_DRIVE',
  FOLDER: 'FOLDER',
  SNAPCHAT: 'SNAPCHAT',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  PINTEREST: 'PINTEREST',
  G_PLUS: 'G_PLUS',
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO',
  TUMBLR: 'TUMBLR',
  WWEEDDOO: 'WWEEDDOO',
  MAILCHIMP: 'MAILCHIMP',
  ASANA: 'ASANA',
  WRIKE: 'WRIKE',
  TUZZIT: 'TUZZIT',
  ACHIEVEMENT: 'ACHIEVEMENT',
  EPA: 'EPA',
  AT: 'AT',
  LINK: 'LINK',
  FRIEND: 'FRIEND',
  ARROW: 'ARROW',
  GLOBAL: 'GLOBAL',
  HOME: 'HOME',
  VIDEO: 'VIDEO',
};
