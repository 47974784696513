//import nom_image from "./mon_image"
import epaLogo from "./logo-epa.svg";
import epaBackground from "./epa-background.png";
import epaLogoMiniM from "./logo-epa-mini-M.svg";
import epaLogoMiniL from "./logo-epa-mini-L.svg";
import epaLogoMiniS from "./logo-epa-mini-S.svg";
import epaLogoMiniXL from "./Logo_MEXL_RVB_Orange.svg";
import epaLogoChallenge10X from "./logo-epa-challenge-10X.png";
import commercial from "./commercial.svg";
import equal from "./equal.svg";
import mentions from "./mentions.svg";
import boys from "./boys.svg";
import girls from "./girls.svg";
import asanaIcon from "./asana.png";
import onedriveIcon from "./one-drive.png";
import tuzzitIcon from "./tuzzit.png";
import wweeddooIcon from "./wweeddoo.png";
import wrikeIcon from "./wrike.png";
import jaLogo from "./logo-junior.svg";
import epaLogoTurquoise from "./logo_epa_rvb_bleu_turquoise.png";

export const images = {
  epaLogo,
  epaBackground,
  epaLogoMiniM,
  epaLogoMiniL,
  epaLogoMiniS,
  epaLogoChallenge10X,
  epaLogoMiniXL,
  commercial,
  equal,
  mentions,
  girls,
  boys,
  asanaIcon,
  onedriveIcon,
  tuzzitIcon,
  wweeddooIcon,
  wrikeIcon,
  jaLogo,
  epaLogoTurquoise,
  epaLogoMiniXL,
};
