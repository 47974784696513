import React from 'react';
import { icons } from '../../../assets';
import './side-bar.scss';
import { isIeOrEdge } from '../../../utils';

export const SideBar = ({strings, goToPrevious = null, pop, menu}) => {

  const scrollTo = (id) => {
    const root = document.querySelector(`#root`);
    const element = document.querySelector(`.${id}`);
    const menuHeight = document.getElementById('fixed-wrapper').getBoundingClientRect().height;

    const offsetTop = element.offsetTop - menuHeight - 25;
    if (isIeOrEdge(window, document)) {
      root.scrollTop = offsetTop;
    } else {
      root.scrollTo({
        top: offsetTop,
        // top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div id={'sideBar'}>
      <div className="back-link" onClick={goToPrevious ? goToPrevious : pop}>
        <i className="material-icons">{icons.ARROW_BACK}</i>
        <p className={'link'}>{strings('back')}</p>
      </div>
      {menu && <ul className={'menu'}>
        {Object.values(menu).map((value, index) =>
          <li
            key={index}
            id={value.id}
            onClick={() => scrollTo(value.id)}
          >{value.label}</li>,
        )}
      </ul>
      }
    </div>
  );
};
