import { push } from 'react-router-redux';
import { SnackActions } from '../../../redux/snackBar';
import {Inscriptions} from './Inscriptions'
import {getTranslate} from "react-localize-redux";
import {connect} from 'react-redux'
import {InscriptionsActions, InscriptionsSelectors} from "../../../redux/inscriptions";
import {formatEnumForSelector, formatRegionsSelector} from "../../../utils";
import {schoolInscriptionStatus} from "../../../utils/enum";
import {ConfirmPopupActions} from "../../../redux/confirmPopup";
import { AuthSelectors } from '../../../redux/auth';


const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  inscriptions: InscriptionsSelectors.getInscriptions()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  statusFilter: formatEnumForSelector(
    getTranslate(state.locale),
    schoolInscriptionStatus
  ),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message, popupAction)=> dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  getSchoolInscriptions: (inBackground) => dispatch(InscriptionsActions.getSchoolInscriptionsRequested(inBackground)),
  getSchoolReinscriptions: (inBackground) => dispatch(InscriptionsActions.getSchoolReinscriptionsRequested(inBackground)),
  getMentorInscriptions: (inBackground) => dispatch(InscriptionsActions.getMentorInscriptionsRequested(inBackground)),
  deleteSchoolInscription: (id) => dispatch(InscriptionsActions.deleteSchoolInscriptionsRequested(id)),
  deleteSchoolReinscription: (id) => dispatch(InscriptionsActions.deleteSchoolReinscriptionsRequested(id)),
  editSchoolInscriptionConvention: (id) => dispatch(InscriptionsActions.editSchoolInscriptionConventionRequested(id)),
  editSchoolReinscriptionConvention: (id) => dispatch(InscriptionsActions.editSchoolReinscriptionConventionRequested(id)),
  validateSchoolInscription: (id) => dispatch(InscriptionsActions.validateSchoolInscriptionsRequested(id)),
  validateSchoolReinscription: (id) => dispatch(InscriptionsActions.validateSchoolReinscriptionsRequested(id)),
  deleteMentorInscription: (id) => dispatch(InscriptionsActions.deleteMentorInscriptionsRequested(id)),
  validateMentorInscription: (id) => dispatch(InscriptionsActions.validateMentorInscriptionsRequested(id)),
  setActionSnackBar: (snackAction, actionMessage) => dispatch(SnackActions.setAction(snackAction, actionMessage)),
  showSnackBar: (message) => dispatch(SnackActions.displayInfo(message)),
  hideSnackBar: () => dispatch(SnackActions.hideSnackBar()),
  setCurrentTab: (tab, page, filter, subTab = null) => dispatch(InscriptionsActions.setInscriptionCurrentTab(tab, page, filter, subTab)),
  resetCurrentTab: () => dispatch(InscriptionsActions.resetInscriptionCurrentTab()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inscriptions)
