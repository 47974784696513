import {call, put, select, takeLatest} from 'redux-saga/effects';
import { MentorsServices } from '../../services';
import LoaderActions from '../loader/actions';
import SnackActions from '../snackBar/actions';
import {default as MentorsActions, types} from './actions';
import {history} from "../../core/history";
import { downloadAttachment, Routes } from '../../utils';
import errorService from "../../services/ErrorService";

function* getMentorsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MentorsServices.getMentors, adminId);
  if (!error) {
    yield put(MentorsActions.getMentorsSucceeded(response.data));
  } else {
    alert("Waiting for action")
    yield put(SnackActions.displayError('mentors_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getMentorDetailRequested({mentorId}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(MentorsServices.getMentorDetail, id, mentorId);
  if (!error) {
    yield put(MentorsActions.getMentorDetailSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('mentor_detail_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* linkProjectMentorRequested({userId, projectsId}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  let responseCall = [];
  for (let i = 0; i < projectsId.length; i++) {
    const [error, response] = yield call(MentorsServices.linkProject, adminId, userId, projectsId[i]);
    responseCall = responseCall.concat({error, response})
  }

  const error = responseCall.some(item => item.error);

  if (!error) {
    const lastResponse = responseCall[responseCall.length - 1].response;
    yield put(MentorsActions.linkProjectMentorSucceeded(lastResponse.data));
  } else {
    yield put(SnackActions.displayError('one_or_more_link_project_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* unlinkProjectMentorRequested({userId, projectId}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(MentorsServices.unlinkProject, adminId, userId, projectId);

  if (!error) {
    yield put(MentorsActions.unlinkProjectMentorSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('unlink_project_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteMentorRequested({id}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(MentorsServices.deleteMentor, adminId, id);

  if (!error) {
    yield put(MentorsActions.deleteMentorSucceeded(id));
  } else {
    yield put(SnackActions.displayError('mentor_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateMentorRequested({mentor}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MentorsServices.updateMentor, adminId, mentor.id, mentor);

  if (!error) {
    yield put(MentorsActions.updateMentorSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('mentor_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* createMentorRequested({mentor}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MentorsServices.createMentor, adminId, mentor);

  if (!error) {
    yield put(MentorsActions.createMentorSucceeded(response.data));
    yield call(history.replace, Routes.MENTORS_LIST);
  } else {
    yield errorService.displayConflictError(error, 'mentor_creation_failed')
  }

  yield put(LoaderActions.loaded());
}

function* exportMentorsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MentorsServices.exportMentors, adminId);

  if (response && response.data && !error) {
    downloadAttachment(response)
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}


export default [
  takeLatest(types.DELETE_MENTOR_REQUESTED, deleteMentorRequested),
  takeLatest(types.GET_MENTORS_REQUESTED, getMentorsRequested),
  takeLatest(types.GET_MENTOR_DETAIL_REQUESTED, getMentorDetailRequested),
  takeLatest(types.LINK_PROJECT_MENTOR_REQUESTED, linkProjectMentorRequested),
  takeLatest(types.UNLINK_PROJECT_MENTOR_REQUESTED, unlinkProjectMentorRequested),
  takeLatest(types.UPDATE_MENTOR_REQUESTED, updateMentorRequested),
  takeLatest(types.CREATE_MENTOR_REQUESTED, createMentorRequested),
  takeLatest(types.EXPORT_MENTORS_REQUESTED, exportMentorsRequested),
];
