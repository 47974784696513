import {createSelector} from 'reselect';

const regions = state => state.commons.regions || [];

const getRegions = createSelector(regions, (regions) => regions.map(region => region.name));
const getAcademies = createSelector(regions, (regions) => regions.map(region => region.academies).reduce((acc, val) => [...acc, ...val], []).sort());

export default {
  getRegions,
  getAcademies,
};
