import React, { useState } from 'react';
import { InputTypes, REGEX } from '../../../../../utils';
import { Input } from '../../../../components';
import { FreemiumUpgradePopup } from './FreemiumUpgradePopup';

export const UpgradeToSupervisorPopup = ({strings, showForm, onCancel, onValidate}) => {

  const [phone, setPhone] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const handleValueChanged = (tag, value) => {
    setPhone(value);
    setPhoneError(null);
  };

  const validateForm = () => {
    const isInError = !phone || !REGEX.PHONE.test(phone);
    if (isInError && showForm) {
      setPhoneError(!phone ? 'input_require' : 'wrong_phone');
    } else {
      onValidate(phone);
    }
  };

  return <FreemiumUpgradePopup
    strings={strings}
    label={strings('upgrade_supervisor')}
    message={strings(showForm ? 'upgrade_supervisor_description' : 'upgrade_warning')}
    onCancel={onCancel}
    onValidate={validateForm}>
    {showForm &&
    <Input
      type={InputTypes.TEXT}
      label={strings('phone')}
      tag={'phone'}
      defaultValue={phone}
      inputClassName={`${phoneError ? 'invalid' : ''}`}
      error={phoneError ? strings(phoneError) : null}
      isHighlighted={true}
      handleValueChanged={handleValueChanged}
    />
    }
  </FreemiumUpgradePopup>;
};
