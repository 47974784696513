import React from 'react';
import { CheckBox } from '../../checkbox';
import './adminRights.scss';

export const AdminRights = ({strings, isAdmin, editable, isEditing, handleValueChanged}) => {
  return (
    <div id={'adminRights'}>
      <p className={'subtitle'}>{strings('admin_rights')}</p>
      <CheckBox
        disabled={!editable || !isEditing}
        onCheck={() => handleValueChanged('isAdmin', !isAdmin)}
        isChecked={isAdmin}/>
    </div>
  );
};
