import React, { Fragment, PureComponent } from "react";
import { Button, FormDropDown, Input } from "..";
import { userRights, userRole } from "../../../utils";
import {
  formatEnumForSelector,
  Gender,
  InputTypes,
} from "../../../utils/index";
import { ButtonColors } from "../index";
import { AdminRights } from "./adminRights/AdminRights";
import { MentorProfile } from "./mentorProfile/MentorProfile.jsx";
import "./user-summary.scss";

export class UserSummary extends PureComponent {
  state = {
    user: this.props.user || {},
    mentorProfile: this.props.mentorProfile || {},
    isEditing: this.props.creationMode || false,
    errors: {
      civility: {},
      firstName: {},
      lastName: {},
      email: {},
      phone: {},
      region: {},
      role: {},
    },
    mentorErrors: {
      description: {},
      companyName: {},
      companyAddress: {},
      companyCity: {},
      companyPostalCode: {},
      companyCountry: {},
      ceoFirstName: {},
      ceoLastName: {},
      ceoEmail: {},
      ceoPhone: {},
    },
    civilities: formatEnumForSelector(this.props.strings, Gender),
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user, mentorProfile, checkForm } = this.props;
    //TODO: on get failed clear user from reducer
    if (
      JSON.stringify(user) !== JSON.stringify(prevProps.user) ||
      JSON.stringify(mentorProfile) !== JSON.stringify(prevProps.mentorProfile)
    ) {
      this.setState({
        user: user || {},
        mentorProfile: mentorProfile || {},
      });
    }

    if (checkForm === true) {
      this.updateUserInfo();
    }
  }

  handleChange = (tag, value) => {
    const { errors, user, mentorErrors } = this.state;
    //Reset on error state
    Object.values(errors).map((val) => (val.onError = false));
    if (mentorErrors && user.role === userRole.MENTOR) {
      Object.values(mentorErrors).map((val) => (val.onError = false));
    }

    if (tag === "isAdmin") {
      this.setState({
        user: {
          ...user,
          isAdmin: value,
          role: value ? userRole.ADMIN : userRole.FACILITATOR,
        },
        errors,
        mentorErrors,
      });
    } else {
      this.setState({ user: { ...user, [tag]: value }, errors, mentorErrors });
    }
  };

  handleMentorProfileChange = (tag, value) => {
    const { mentorErrors, errors } = this.state;
    //Reset on error state
    Object.values(mentorErrors).map((val) => (val.onError = false));
    Object.values(errors).map((val) => (val.onError = false));
    this.setState({
      mentorProfile: { ...this.state.mentorProfile, [tag]: value },
      mentorErrors,
      errors,
    });
  };

  handleDropDownChange = (tag, item) => {
    this.handleChange(tag, item.key);
  };

  updateUserInfo = () => {
    const { validateUserForm, updateUser, creationMode } = this.props;
    const { user, mentorProfile, isEditing, errors, mentorErrors } = this.state;

    //if editing mode
    if (isEditing) {
      // check form
      let userResult = null;
      let profileResult = null;

      if (user.role === userRole.MENTOR) {
        let {
          ceoEmail,
          ceoFirstName,
          ceoLastName,
          ceoPhone,
          ...mentorProfileOthersData
        } = mentorProfile;

        ceoEmail =
          (ceoEmail && ceoEmail !== null && ceoEmail.trim()) === "" ? null : ceoEmail;
        ceoFirstName =
        ceoFirstName && ceoFirstName !== null && ceoFirstName.trim() === ""
            ? null
            : ceoFirstName;
        ceoLastName =
        ceoLastName && ceoLastName !== null && ceoLastName.trim() === ""
            ? null
            : ceoLastName;
        ceoPhone =
        ceoPhone && ceoPhone !== null && ceoPhone.trim() === "" ? null : ceoPhone;
        const newMentorProfile = {
          ceoEmail,
          ceoFirstName,
          ceoLastName,
          ceoPhone,
          ...mentorProfileOthersData,
        };
        const result = validateUserForm(
          user,
          newMentorProfile,
          errors,
          mentorErrors
        );

        userResult = result.errors;
        profileResult = result.mentorErrors;
      } else {
        userResult = validateUserForm(user, errors);
      }

      //if form contains errors
      const fields = [];

      Object.keys(userResult).forEach((key) =>
        fields.push({ key: key, data: userResult[key] })
      );
      if (profileResult) {
        Object.keys(profileResult).forEach((key) =>
          fields.push({
            key: key,
            data: profileResult[key],
          })
        );
      }
      const hasError = fields.some((field) => field.data.onError);
      if (hasError) {
        // save errors in state to display them
        this.setState({
          errors: { ...errors, userResult },
          mentorErrors: { ...mentorErrors, profileResult },
        });

        // not update school
        updateUser(false, { ...user, ...mentorProfile });
      } else {
        if (!creationMode) {
          this.setState({
            isEditing: !this.state.isEditing,
          });
        }
        // update user
        let {
          ceoEmail,
          ceoFirstName,
          ceoLastName,
          ceoPhone,
          ...mentorProfileOthersData
        } = mentorProfile;

        ceoEmail =
        ceoEmail && ceoEmail !== null && ceoEmail.trim() === "" ? null : ceoEmail;
        ceoFirstName =
        ceoFirstName && ceoFirstName !== null && ceoFirstName.trim() === ""
            ? null
            : ceoFirstName;
        ceoLastName =
        ceoLastName && ceoLastName !== null && ceoLastName.trim() === ""
            ? null
            : ceoLastName;
        ceoPhone =
        ceoPhone && ceoPhone !== null && ceoPhone.trim() === "" ? null : ceoPhone;
        const newMentorProfile = {
          ceoEmail,
          ceoFirstName,
          ceoLastName,
          ceoPhone,
          ...mentorProfileOthersData,
        };
        updateUser(true, { ...user, ...newMentorProfile });
      }
    } else {
      this.setState({ isEditing: !this.state.isEditing });
    }
  };

  render() {
    const { strings, regions, userFound, isBoUserAdmin, creationMode } =
      this.props;

    const { user, mentorProfile, isEditing, errors, mentorErrors, civilities } =
      this.state;

    const {
      id,
      civility,
      firstName,
      lastName,
      email,
      phone,
      region,
      role,
      rights,
    } = user;

    const isAdmin = role === userRole.ADMIN;
    const civilityLabel =
      civility && civilities.find((c) => c.key === civility)
        ? civilities.find((c) => c.key === civility).value.label
        : "";

    let rightsLabel = "";
    switch (rights) {
      case userRights.PENDING:
        rightsLabel = strings("rights_pending");
        break;
      case userRights.FREEMIUM:
        rightsLabel = strings("rights_freemium");
        break;
      case userRights.PREMIUM:
        rightsLabel = strings("rights_premium");
        break;
    }

    return (
      <div id={"userSummary"}>
        <div className={"name-wrapper"}>
          {isEditing ? (
            <div className={"name edition"}>
              <FormDropDown
                tag={"civility"}
                className={"small"}
                label={strings("civility")}
                data={civilities}
                dropDownClassName={`civility ${
                  errors.civility.onError ? "invalid" : ""
                }`}
                error={
                  errors.civility.onError
                    ? strings(errors.civility.errorMsg)
                    : null
                }
                current={civilityLabel}
                onValueChange={(tag, value) =>
                  this.handleDropDownChange(tag, value)
                }
                disabled={!isEditing}
              />
              <Input
                type={InputTypes.TEXT}
                defaultValue={firstName || ''}
                placeholder={creationMode && strings("firstName")}
                tag={"firstName"}
                className={"firstName"}
                disabled={!isEditing}
                inputClassName={`first-name ${
                  errors.firstName.onError ? "invalid" : ""
                }`}
                error={
                  errors.firstName.onError
                    ? strings(errors.firstName.errorMsg)
                    : null
                }
                isHighlighted={false}
                handleValueChanged={this.handleChange}
              />
              <Input
                type={InputTypes.TEXT}
                defaultValue={lastName || ''}
                placeholder={creationMode && strings("lastName")}
                tag={"lastName"}
                className={"lastName"}
                disabled={!isEditing}
                inputClassName={`last-name ${
                  errors.lastName.onError ? "invalid" : ""
                }`}
                error={
                  errors.lastName.onError
                    ? strings(errors.lastName.errorMsg)
                    : null
                }
                isHighlighted={false}
                handleValueChanged={this.handleChange}
              />
            </div>
          ) : (
            <div className={"name consultation"}>
              <p>{`${civilityLabel ? civilityLabel : ""} ${
                firstName ? firstName : ""
              } ${lastName ? lastName : ""}`}</p>
            </div>
          )}
          {userFound && (
            <Button
              color={ButtonColors.orange}
              label={
                isEditing
                  ? strings("button_text_save")
                  : strings("button_text_modify")
              }
              icon={"edit"}
              className={"edit-button"}
              enabled={id}
              action={this.updateUserInfo}
            />
          )}
        </div>
        <hr className={"separator"} />
        <div className={"user-summary"}>
          <div className={"form-container"}>
            <div className={"contact"}>
              <Input
                type={InputTypes.TEXT}
                label={strings("email")}
                defaultValue={email || ''}
                tag={"email"}
                className={"email"}
                disabled={!isEditing}
                inputClassName={`email ${
                  errors.email.onError ? "invalid" : ""
                }`}
                error={
                  errors.email.onError ? strings(errors.email.errorMsg) : null
                }
                isHighlighted={!isEditing}
                handleValueChanged={this.handleChange}
              />
              <Input
                type={InputTypes.TEXT}
                label={strings("phone")}
                defaultValue={phone || ''}
                tag={"phone"}
                disabled={!isEditing}
                inputClassName={`${errors.phone.onError ? "invalid" : ""}`}
                error={
                  errors.phone.onError ? strings(errors.phone.errorMsg) : null
                }
                isHighlighted={!isEditing}
                handleValueChanged={this.handleChange}
              />
            </div>
            <div className={"region"}>
              <FormDropDown
                tag={"region"}
                label={strings("region")}
                data={regions ? regions : []}
                dropDownClassName={`${errors.region.onError ? "invalid" : ""}`}
                error={
                  errors.region.onError ? strings(errors.region.errorMsg) : null
                }
                current={`${region ? region : ""}`}
                onValueChange={this.handleDropDownChange}
                disabled={!isEditing}
              />
            </div>
          </div>
          {role === userRole.MENTOR && (
            <Fragment>
              <hr className={"separator"} />
              <MentorProfile
                strings={strings}
                mentor={mentorProfile}
                errors={mentorErrors}
                isEditing={isEditing}
                handleValueChanged={this.handleMentorProfileChange}
              />
            </Fragment>
          )}
          <Fragment>
            <hr className={"separator"} />
            {role === userRole.FACILITATOR || role === userRole.ADMIN ? (
              <AdminRights
                strings={strings}
                isAdmin={isAdmin}
                editable={isBoUserAdmin}
                isEditing={isEditing}
                handleValueChanged={this.handleChange}
              />
            ) : !creationMode ? (
              <div className={"situation"}>
                <Input
                  type={InputTypes.TEXT}
                  label={strings("rights")}
                  defaultValue={rightsLabel}
                  className={"rights"}
                  disabled={true}
                  isHighlighted={false}
                />
              </div>
            ) : (
              <Fragment />
            )}
          </Fragment>
        </div>
      </div>
    );
  }
}
