import React from 'react';
import { InputTypes } from '../../../../utils/enum';
import { Input, TextArea } from '../../inputs';
import './mentorProfile.scss'

export const MentorProfile = ({strings, mentor, errors, isEditing, handleValueChanged}) => {

  const {description, companyName, companyAddress, companyPostalCode, companyCity, companyCountry, ceoFirstName, ceoLastName, ceoEmail, ceoPhone} = mentor;
  return (
    <div id={'mentorProfile'}>
      <p className={'subtitle'}>{strings('perimeter')}</p>
      <div className={'form-container'}>
        <TextArea
          tag={'description'}
          className={'description'}
          label={strings('description')}
          type={'text'}
          inputClassName={`${errors.description.onError ? 'invalid' : 'valid'}`}
          error={errors.description.onError ? strings(errors.description.errorMsg) : null}
          defaultValue={description}
          disabled={!isEditing}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <p className={'subtitle'}>{strings('company')}</p>
      <div className={'form-container'}>
        <div className={'line'}>
          <Input
            type={InputTypes.TEXT}
            defaultValue={companyName}
            tag={'companyName'}
            label={strings('company_name')}
            className={'full'}
            inputClassName={`${errors.companyName.onError ? 'invalid' : ''}`}
            error={errors.companyName.onError ? strings(errors.companyName.errorMsg) : null}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
          <Input
            type={InputTypes.TEXT}
            defaultValue={companyAddress}
            tag={'companyAddress'}
            label={strings('address')}
            inputClassName={`${errors.companyAddress.onError ? 'invalid' : ''}`}
            error={errors.companyAddress.onError ? strings(errors.companyAddress.errorMsg) : null}
            className={'full'}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
        </div>
        <div className={'line'}>
          <div className={'subcontainer full'}>
            <Input
              type={InputTypes.TEXT}
              defaultValue={companyPostalCode}
              tag={'companyPostalCode'}
              inputClassName={`${errors.companyPostalCode.onError ? 'invalid' : ''}`}
              error={errors.companyPostalCode.onError ? strings(errors.companyPostalCode.errorMsg) : null}
              disabled={!isEditing}
              label={strings('postalCode')}
              className={'small'}
              handleValueChanged={handleValueChanged}
            />
            <Input
              type={InputTypes.TEXT}
              defaultValue={companyCity}
              tag={'companyCity'}
              label={strings('city')}
              inputClassName={`${errors.companyCity.onError ? 'invalid' : ''}`}
              error={errors.companyCity.onError ? strings(errors.companyCity.errorMsg) : null}
              className={'medium'}
              disabled={!isEditing}
              handleValueChanged={handleValueChanged}
            />
          </div>
          <Input
            type={InputTypes.TEXT}
            defaultValue={companyCountry}
            tag={'companyCountry'}
            label={strings('country')}
            inputClassName={`${errors.companyCountry.onError ? 'invalid' : ''}`}
            error={errors.companyCountry.onError ? strings(errors.companyCountry.errorMsg) : null}
            className={'full'}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
        </div>
      </div>
      <p className={'subtitle'}>{strings('company_ceo')}</p>
      <div className={'form-container'}>
        <div className={'line'}>
          <Input
            type={InputTypes.TEXT}
            defaultValue={ceoFirstName}
            tag={'ceoFirstName'}
            label={strings('firstname')}
            className={'full'}
            inputClassName={`${errors.ceoFirstName.onError ? 'invalid' : ''}`}
            error={errors.ceoFirstName.onError ? strings(errors.ceoFirstName.errorMsg) : null}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
          <Input
            type={InputTypes.TEXT}
            defaultValue={ceoLastName}
            tag={'ceoLastName'}
            label={strings('lastname')}
            inputClassName={`${errors.ceoLastName.onError ? 'invalid' : ''}`}
            error={errors.ceoLastName.onError ? strings(errors.ceoLastName.errorMsg) : null}
            className={'medium'}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
        </div>
        <div className={'line'}>
          <Input
            type={InputTypes.EMAIL}
            defaultValue={ceoEmail}
            tag={'ceoEmail'}
            label={strings('email')}
            className={'full'}
            inputClassName={`${errors.ceoEmail.onError ? 'invalid' : ''}`}
            error={errors.ceoEmail.onError ? strings(errors.ceoEmail.errorMsg) : null}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
          <Input
            type={InputTypes.TEXT}
            defaultValue={ceoPhone}
            tag={'ceoPhone'}
            label={strings('phone')}
            inputClassName={`${errors.ceoPhone.onError ? 'invalid' : ''}`}
            error={errors.ceoPhone.onError ? strings(errors.ceoPhone.errorMsg) : null}
            className={'medium'}
            disabled={!isEditing}
            handleValueChanged={handleValueChanged}
          />
        </div>
      </div>
    </div>
  );
};
