import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import {SupervisorsActions, SupervisorsSelectors} from '../../../../redux/supervisors';
import { SupervisorDetail } from './SupervisorDetail';

const mapStateToProps = state => ({
  supervisor: state.supervisors.supervisor,
  strings: getTranslate(state.locale),
  projectsData: (page, search, region, academy) => SupervisorsSelectors.getProjects(page, search, region, academy)(state),
});

const mapDispatchToProps = dispatch => ({
  linkProjects: (userId, projectsId) => dispatch(SupervisorsActions.linkProjectSupervisorsRequested(userId, projectsId)),
  unlinkProject: (userId, projectId) => dispatch(SupervisorsActions.unlinkProjectSupervisorsRequested(userId, projectId)),
  getSupervisor: (supervisorId) => dispatch(SupervisorsActions.getSupervisorDetailRequested(supervisorId)),
  updateSupervisor: supervisor => dispatch(SupervisorsActions.updateSupervisorRequested(supervisor)),
  resetSupervisorDetail: () => dispatch(SupervisorsActions.resetSupervisorDetail()),
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorDetail);
