import {methods, url} from '../utils';
import {authenticatedService} from "./middleware";

const routes = {
  getMentors: (adminId) => url.LIST_MENTORS(adminId),
  getMentorDetail: (adminId, mentorId) => url.MENTOR_DETAIL(adminId, mentorId),
  linkProject: (adminId, userId) => url.MENTOR_LINK_PROJECT(adminId, userId),
  unlinkProject: (adminId, userId) => url.MENTOR_UNLINK_PROJECT(adminId, userId),
  exportMentors: (adminId) => url.EXPORT_MENTORS(adminId)
};

export default {
  getMentors: (adminId) => authenticatedService(methods.GET, routes.getMentors(adminId), {}, {}),
  getMentorDetail: (adminId, mentorId) => authenticatedService(methods.GET, routes.getMentorDetail(adminId, mentorId), {}, {}),
  linkProject: (adminId, userId, projectId) => authenticatedService(methods.PUT, routes.linkProject(adminId, userId), {projectId}, {}),
  unlinkProject: (adminId, userId, projectId) => authenticatedService(methods.PUT, routes.unlinkProject(adminId, userId), {projectId}, {}),
  deleteMentor: (adminId, mentorId) => authenticatedService(methods.DELETE, routes.getMentorDetail(adminId, mentorId), {}, {}),
  updateMentor: (adminId, mentorId, body) => authenticatedService(methods.PATCH, routes.getMentorDetail(adminId, mentorId), {...body}, {}),
  createMentor: (adminId, body) => authenticatedService(methods.PUT, routes.getMentors(adminId), {...body}, {}),
  exportMentors: (adminId, body) => authenticatedService(methods.GET, routes.exportMentors(adminId), {...body}, {}, {}, 'blob'),
};
