import * as Sentry from '@sentry/browser';
import 'moment/locale/fr';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import { Router, store } from './core';
import './index.scss';
import { unregister } from './registerServiceWorker';
import {
  ConfirmPopup,
  Footer,
  Loader,
  Navigation,
  ProjectNavigation,
  SnackBar,
} from './ui/components';
import { MaintenanceMode } from './ui/screens';
import { loadState, saveState } from './utils';
import { getMaintenance } from './utils/service';

if (loadState('epa_bo_last-version') !== process.env.REACT_APP_VERSION) {
  saveState('epa_bo_last-version', process.env.REACT_APP_VERSION);
  saveState('epa_bo_state', {});
  // if (caches) {
  //   caches.keys().then(keys => {
  //     for (let key of keys) caches.delete(key);
  //   });
  // }
  window.location.reload();
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://31bef264328f4a579b6f7940467d6a2a@sentry.io/1856155',
  });
}
function LoaderComponent() {
  return <Loader />;
}
const isProduction = process.env.NODE_ENV === 'production';
const ltsVersion = process.env.REACT_APP_LTS_VERSION;
ReactDOM.render(
  getMaintenance() ? (
    <MaintenanceMode />
  ) : (
    <CacheBuster
    currentVersion={ltsVersion}
    isEnabled={isProduction} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={LoaderComponent}//If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <Fragment>
        <Navigation />
        <ProjectNavigation />
        <Router />
        <SnackBar />
        <ConfirmPopup />
        <Loader />
        <Footer />
      </Fragment>
    </Provider>
    </CacheBuster>
  ),
  document.getElementById('root')
);

unregister();
