import React from "react";
import { icons } from "../../../../../assets";
import { Button, ButtonColors } from "../../../../components";
import { default as Group } from "./Group";

const School = ({
  strings,
  index,
  school,
  groups,
  isLast,
  categories,
  levels,
  sectors,
  handleDeleteSchool,
  handleAddGroup,
  handleGroupChange,
  handleDeleteGroup,
}) => {
  const totalStudents = groups
    ? groups
        .map(
          (group) =>
            parseInt(group.boysCount ? group.boysCount : 0) +
            parseInt(group.girlsCount ? group.girlsCount : 0)
        )
        .reduce((acc, val) => acc + val, 0)
    : 0;
  return (
    <div className={"school"}>
      <div className={"school-info"}>
        <div className={"line"}>
          <p className={"name"}>{school.name}</p>
          <i className={`material-icons`} onClick={handleDeleteSchool(index)}>
            {icons.CLOSE}
          </i>
        </div>
        <div className={"line"}>
          <p className={"address"}>{school.address}</p>
          <p className={"phone"}>{school.secretariatPhone}</p>
        </div>
      </div>
      <div className={"dashed-separator"} />
      {groups && groups.length > 0 && (
        <div className={"groups"}>
          {groups.map((group, groupIndex) => (
            <Group
              key={group.key || group.id}
              strings={strings}
              categories={categories}
              levels={levels}
              sectors={sectors}
              groupIndex={groupIndex}
              group={group}
              handleGroupChange={handleGroupChange(index, groupIndex)}
              handleDeleteGroup={handleDeleteGroup(index, groupIndex)}
            />
          ))}
        </div>
      )}
      <div className={"add-group"}>
        <div className={"button-wrapper"}>
          <Button
            color={ButtonColors.orange}
            label={strings("add_group")}
            icon={icons.ADD}
            className={"add"}
            action={handleAddGroup(index)}
          />
        </div>

        <div className={"students"}>
          <p className={"label"}>{strings("total_students_school")}</p>
          <p className={"value"}>{totalStudents}</p>
        </div>
      </div>
      {!isLast && <div className={"separator"} />}
    </div>
  );
};

export default School;
