import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { AuthSelectors } from "../../../../redux/auth";
import { ConfirmPopupActions } from "../../../../redux/confirmPopup";
import { NotifActions, NotifSelectors } from "../../../../redux/notifications";
import { SnackActions } from "../../../../redux/snackBar";
import { formatRegionsSelector } from '../../../../utils';
import { default as NotificationList } from "./NotificationList";

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
  notifications: NotifSelectors.getNotifications()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(NotifActions.getNotifListRequested()),
  setCurrentFilter: (page, filter) => dispatch(NotifActions.setNotifsCurrentFilter(page, filter)),
  showPopup: (message, popupAction) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  setActionSnackBar: (snackAction, actionMessage) => dispatch(SnackActions.setAction(snackAction, actionMessage)),
  duplicateNotif: (notif) => dispatch(NotifActions.setNotifDraft(notif)),
  deleteNotifs: (ids) => dispatch(NotifActions.deleteNotisfRequested(ids)),
  showSnackBar: (message) => dispatch(SnackActions.displayInfo(message)),
  hideSnackBar: () => dispatch(SnackActions.hideSnackBar()),
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationList);
