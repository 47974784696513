import { REGEX } from '../../../../utils';

export const validateSupervisorForm = (supervisor) => {
  const supervisorCopy = JSON.parse(JSON.stringify(supervisor));

  const {civility, lastName, firstName, phone, email} = supervisorCopy;
  civility.onError = !civility.value;
  civility.errorMsg = 'input_require';
  lastName.onError = !lastName.value || lastName.value.length < 2;
  lastName.errorMsg = 'input_require';
  firstName.onError = !firstName.value || firstName.value.length < 2;
  firstName.errorMsg = 'input_require';
  email.onError = !email.value || !REGEX.EMAIL.test(email.value);
  email.errorMsg = !email.value ? 'input_require' : 'wrong_email';
  phone.onError = !phone.value || !REGEX.PHONE.test(phone.value);
  phone.errorMsg = !phone.value ? 'input_require' : 'wrong_phone';

  return supervisorCopy;
};

export const validateNewProjectsForm = (projects) => {
  const projectsCopy = JSON.parse(JSON.stringify(projects));

  projectsCopy.forEach(project => {
    if (!project.type) {
      project.onError = true;
      project.errorMsg = 'project_error_no_type';
    }
    if (project.duration === null || project.duration === undefined) {
      project.onError = true;
      project.errorMsg = 'project_error_no_duration';
    }
    if (!project.users || project.users.length === 0) {
      project.onError = true;
      project.errorMsg = 'project_error_no_user';
      return;
    }

    const projectUsers = [];
    project.users.forEach(user => {
      if (!user.id) {
        const result = validateSupervisorForm(user);

        //Prepare a map of email/supervisors
        if (!result.email.onError) {
          if (projectUsers.includes(user.email.value)) {
            user.email.onError = true;
            user.email.errorMsg = 'duplicate_email';
            project.onError = true;
            project.errorMsg = 'project_error_users_duplicate'
          }
        }

        projectUsers.push(user.email.value);
      } else {
        if (projectUsers.includes(user.email)) {
          const previousUser = project.users.find(search => !search.id && search.email.value === user.email);

          if (previousUser) {
            previousUser.email.onError = true;
            previousUser.email.errorMsg = 'duplicate_email';
            project.onError = true;
            project.errorMsg = 'project_error_users_duplicate'
          }
        }

        projectUsers.push(user.email);
      }
    });
  });

  return projectsCopy;
};
