import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import { InscriptionsActions } from '../../../../redux/inscriptions/index';
import { SchoolsActions } from '../../../../redux/schools/index';
import { formatRegionsSelector } from '../../../../utils';
import { SchoolWrapperDetails } from './SchoolWrapperDetails';

const mapStateToProps = state => ({
  school: state.schools.school,
  schoolInscriptions: state.inscriptions.school,
  strings: getTranslate(state.locale),
  regions: formatRegionsSelector(getTranslate(state.locale), state.commons.regions).slice(1),
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  getSchoolDetails: id => dispatch(SchoolsActions.getSchoolDetailRequested(id)),
  getSchoolDetailInscriptions: id => dispatch(InscriptionsActions.getSchoolDetailInscriptionsRequested(id)),
  getSchoolDetailReinscriptions: id => dispatch(InscriptionsActions.getSchoolDetailReinscriptionsRequested(id)),
  updateSchool: school => dispatch(SchoolsActions.updateSchoolRequested(school)),
  updateSchoolInscriptions: school => dispatch(InscriptionsActions.updateSchoolInscriptionsRequested(school)),
  updateSchoolReinscriptions: school => dispatch(InscriptionsActions.updateSchoolReinscriptionsRequested(school)),
  resetSchoolDetail: () => dispatch(SchoolsActions.resetSchoolDetail()),
  resetSchoolDetailInscription: () => dispatch(InscriptionsActions.resetSchoolDetailInscription()),
  deleteSchool: id => dispatch(SchoolsActions.deleteSchoolRequested(id)),
  deleteSchoolInscription: id => dispatch(InscriptionsActions.deleteSchoolInscriptionsRequested(id)),
  deleteSchoolReinscription: id => dispatch(InscriptionsActions.deleteSchoolReinscriptionsRequested(id)),
  editSchoolInscriptionConvention: (id) => dispatch(InscriptionsActions.editSchoolInscriptionConventionRequested(id)),
  editSchoolReinscriptionConvention: (id) => dispatch(InscriptionsActions.editSchoolReinscriptionConventionRequested(id)),
  validateSchoolInscription: (id) => dispatch(InscriptionsActions.validateSchoolInscriptionsRequested(id)),
  validateSchoolReinscription: (id) => dispatch(InscriptionsActions.validateSchoolReinscriptionsRequested(id)),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolWrapperDetails);
