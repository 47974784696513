import {DropDown} from "./DropDown"
import {getTranslate} from "react-localize-redux";
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale)
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DropDown)
//TODO : mettre dans un index à la base du dossier parent une fois le component DropDown déplacé dans un dossier "/common" ou "/filter"
export {default as FormDropDown} from './form'
