import React from 'react';
import { icons } from '../../../assets';
import './searchbar.scss';

export const SearchBar = ({ strings, value, onValueChange }) => {
  return (
    <>
      <div id={'searchBar'}>
        <input
          placeholder={strings('search')}
          value={value}
          onChange={onValueChange}
        />
        <i className={'material-icons'}>{icons.SEARCH}</i>
      </div>
    </>
  );
};
