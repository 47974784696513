import {createAction} from '../../utils/index';

// Types
export const types = {
  GET_SUPERVISORS_REQUESTED: "GET_SUPERVISORS_REQUESTED",
  GET_SUPERVISORS_SUCCEEDED: "GET_SUPERVISORS_SUCCEEDED",
  SET_SUPERVISORS_CURRENT_FILTER: 'SET_SUPERVISORS_CURRENT_FILTER',
  GET_SUPERVISOR_DETAIL_REQUESTED: 'GET_SUPERVISOR_DETAIL_REQUESTED',
  GET_SUPERVISOR_DETAIL_SUCCEEDED: 'GET_SUPERVISOR_DETAIL_SUCCEEDED',
  LINK_PROJECT_SUPERVISOR_REQUESTED: 'LINK_PROJECT_SUPERVISOR_REQUESTED',
  LINK_PROJECT_SUPERVISOR_SUCCEEDED: 'LINK_PROJECT_SUPERVISOR_SUCCEEDED',
  UNLINK_PROJECT_SUPERVISOR_REQUESTED: 'UNLINK_PROJECT_SUPERVISOR_REQUESTED',
  UNLINK_PROJECT_SUPERVISOR_SUCCEEDED: 'UNLINK_PROJECT_SUPERVISOR_SUCCEEDED',
  DELETE_SUPERVISOR_REQUESTED: 'DELETE_SUPERVISOR_REQUESTED',
  DELETE_SUPERVISOR_SUCCEEDED: 'DELETE_SUPERVISOR_SUCCEEDED', 
  UPDATE_SUPERVISOR_REQUESTED: "UPDATE_SUPERVISOR_REQUESTED",
  UPDATE_SUPERVISOR_SUCCEEDED: "UPDATE_SUPERVISOR_SUCCEEDED",
  CREATE_SUPERVISOR_REQUESTED: "CREATE_SUPERVISOR_REQUESTED",
  CREATE_SUPERVISOR_SUCCEEDED: "CREATE_SUPERVISOR_SUCCEEDED",
  RESET_SUPERVISOR_DETAIL: 'RESET_SUPERVISOR_DETAIL',
  EXPORT_SUPERVISORS_REQUESTED: 'EXPORT_SUPERVISORS_REQUESTED'
};

// Actions
export default {
  getSupervisorsRequested : () => createAction(types.GET_SUPERVISORS_REQUESTED, {}),
  getSupervisorsSucceeded : (list) => createAction(types.GET_SUPERVISORS_SUCCEEDED, {list}),
  setSupervisorsCurrentFilter : (page, filter) => createAction(types.SET_SUPERVISORS_CURRENT_FILTER, {page, filter}),
  getSupervisorDetailRequested: supervisorId => createAction(types.GET_SUPERVISOR_DETAIL_REQUESTED, {supervisorId}),
  getSupervisorDetailSucceeded: supervisor => createAction(types.GET_SUPERVISOR_DETAIL_SUCCEEDED, {supervisor}),
  linkProjectSupervisorsRequested: (userId, projectsId) => createAction(types.LINK_PROJECT_SUPERVISOR_REQUESTED, {userId, projectsId}),
  linkProjectSupervisorsSucceeded: supervisor => createAction(types.LINK_PROJECT_SUPERVISOR_SUCCEEDED, {supervisor}),
  unlinkProjectSupervisorsRequested: (userId, projectId) => createAction(types.UNLINK_PROJECT_SUPERVISOR_REQUESTED, {userId, projectId}),
  unlinkProjectSupervisorsSucceeded: supervisor => createAction(types.UNLINK_PROJECT_SUPERVISOR_SUCCEEDED, {supervisor}),
  deleteSupervisorRequested: id => createAction(types.DELETE_SUPERVISOR_REQUESTED, {id}),
  deleteSupervisorSucceeded: id => createAction(types.DELETE_SUPERVISOR_SUCCEEDED, {id}),
  updateSupervisorRequested : supervisor => createAction(types.UPDATE_SUPERVISOR_REQUESTED, {supervisor}),
  updateSupervisorSucceeded : supervisor => createAction(types.UPDATE_SUPERVISOR_SUCCEEDED, {supervisor}),
  createSupervisorRequested : supervisor => createAction(types.CREATE_SUPERVISOR_REQUESTED, {supervisor}),
  createSupervisorSucceeded : supervisor => createAction(types.CREATE_SUPERVISOR_SUCCEEDED, {supervisor}),
  resetSupervisorDetail : () => createAction(types.RESET_SUPERVISOR_DETAIL, {}),
  exportSupervisorsRequested : () => createAction(types.EXPORT_SUPERVISORS_REQUESTED, {}),
};
