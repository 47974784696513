import {CONFLICT} from "http-status-codes";
import {ERROR_ALREADY_EXISTS} from "../utils";
import {SnackActions} from "../redux/snackBar";
import { put } from 'redux-saga/effects'
export default {
    *displayConflictError (error, substituteMessage) {
        if (error.response.status === CONFLICT && error.response.data.code === ERROR_ALREADY_EXISTS) {
            yield  put(SnackActions.displayError("account_already_exists"));
        } else {
            yield put(SnackActions.displayError(substituteMessage));
        }
    }
}
