import {createReducer} from 'reduxsauce';
import {types} from './actions';

const initialState = {
  list: [],
  currentFilter: null,
  draft: null,
};

const createNotifSucceeded = (state = initialState, action) => {
  const {notif} = action;
  const copyList = JSON.parse(JSON.stringify(state.list));
  copyList.push(notif);
  return {...state, list: copyList}
};

const getNotifListSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, list}
};

const setNotifsCurrentFilter = (state = initialState, action) => {
  const {page, filter} = action;
  return {...state, currentFilter: {page, filter}};
};

const deleteNotifsSucceeded = (state = initialState, action) => {
  const {ids} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const filteredList = copyList.filter(notif => !ids.includes(notif.id));
  return {...state, list: filteredList};
};

const setNotifDraft = (state = initialState, action) => {
  const {notif} = action;
  const {list} = state;

  let draft = null;
  if (notif) {
    draft = list.find(n => n.id === notif)
  }

  return {...state, draft}
}

export default createReducer(initialState, {
  [types.CREATE_NOTIF_SUCCEEDED]: createNotifSucceeded,
  [types.GET_NOTIF_LIST_SUCCEEDED]: getNotifListSucceeded,
  [types.SET_NOTIFS_CURRENT_FILTER]: setNotifsCurrentFilter,
  [types.DELETE_NOTIFS_SUCCEEDED]: deleteNotifsSucceeded,
  [types.SET_NOTIF_DRAFT]: setNotifDraft,
});
