import {createAction} from '../../utils/index';

// Types
export const types = {
  GET_MENTORS_REQUESTED: "GET_MENTORS_REQUESTED",
  GET_MENTORS_SUCCEEDED: "GET_MENTORS_SUCCEEDED",
  SET_MENTORS_CURRENT_FILTER: 'SET_MENTORS_CURRENT_FILTER',
  GET_MENTOR_DETAIL_REQUESTED: 'GET_MENTOR_DETAIL_REQUESTED',
  GET_MENTOR_DETAIL_SUCCEEDED: 'GET_MENTOR_DETAIL_SUCCEEDED',
  LINK_PROJECT_MENTOR_REQUESTED: 'LINK_PROJECT_MENTOR_REQUESTED',
  LINK_PROJECT_MENTOR_SUCCEEDED: 'LINK_PROJECT_MENTOR_SUCCEEDED',
  UNLINK_PROJECT_MENTOR_REQUESTED: 'UNLINK_PROJECT_MENTOR_REQUESTED',
  UNLINK_PROJECT_MENTOR_SUCCEEDED: 'UNLINK_PROJECT_MENTOR_SUCCEEDED',
  DELETE_MENTOR_REQUESTED: 'DELETE_MENTOR_REQUESTED',
  DELETE_MENTOR_SUCCEEDED: 'DELETE_MENTOR_SUCCEEDED',
  UPDATE_MENTOR_REQUESTED: "UPDATE_MENTOR_REQUESTED",
  UPDATE_MENTOR_SUCCEEDED: "UPDATE_MENTOR_SUCCEEDED",
  CREATE_MENTOR_REQUESTED: "CREATE_MENTOR_REQUESTED",
  CREATE_MENTOR_SUCCEEDED: "CREATE_MENTOR_SUCCEEDED",
  RESET_MENTOR_DETAIL: "RESET_MENTOR_DETAIL",
  EXPORT_MENTORS_REQUESTED: "EXPORT_MENTORS_REQUESTED",
};

// Actions
export default {
  getMentorsRequested : () => createAction(types.GET_MENTORS_REQUESTED, {}),
  getMentorsSucceeded : (list) => createAction(types.GET_MENTORS_SUCCEEDED, {list}),
  setMentorsCurrentFilter : (page, filter) => createAction(types.SET_MENTORS_CURRENT_FILTER, {page, filter}),
  getMentorDetailRequested: mentorId => createAction(types.GET_MENTOR_DETAIL_REQUESTED, {mentorId}),
  getMentorDetailSucceeded: mentor => createAction(types.GET_MENTOR_DETAIL_SUCCEEDED, {mentor}),
  linkProjectMentorRequested: (userId, projectsId) => createAction(types.LINK_PROJECT_MENTOR_REQUESTED, {userId, projectsId}),
  linkProjectMentorSucceeded: mentor => createAction(types.LINK_PROJECT_MENTOR_SUCCEEDED, {mentor}),
  unlinkProjectMentorRequested: (userId, projectId) => createAction(types.UNLINK_PROJECT_MENTOR_REQUESTED, {userId, projectId}),
  unlinkProjectMentorSucceeded: mentor => createAction(types.UNLINK_PROJECT_MENTOR_SUCCEEDED, {mentor}),
  deleteMentorRequested: id => createAction(types.DELETE_MENTOR_REQUESTED, {id}),
  deleteMentorSucceeded: id => createAction(types.DELETE_MENTOR_SUCCEEDED, {id}),
  updateMentorRequested : mentor => createAction(types.UPDATE_MENTOR_REQUESTED, {mentor}),
  updateMentorSucceeded : mentor => createAction(types.UPDATE_MENTOR_SUCCEEDED, {mentor}),
  createMentorRequested : mentor => createAction(types.CREATE_MENTOR_REQUESTED, {mentor}),
  createMentorSucceeded : mentor => createAction(types.CREATE_MENTOR_SUCCEEDED, {mentor}),
  resetMentorDetail : () => createAction(types.RESET_MENTOR_DETAIL, {}),
  exportMentorsRequested : () => createAction(types.EXPORT_MENTORS_REQUESTED, {}),
};
