import React, { useState } from "react";
import ShortId from "shortid";
import { icons } from "../../../../../assets";
import { iconList, toolType } from "../../../../../utils";
import { Button, ButtonColors, Panel } from "../../../../components";
import { validateDraft } from "./FormValidator";
import IconPicker from "./IconPicker";
import Tool from "./Tool";

const Tools = ({ strings, mini, handleMiniInfoChanged, saveMini }) => {
  const [showIconPicker, setShowIconPicker] = useState(false);
  const [editedToolIconIndex, setEditedToolIconIndex] = useState(null);
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const errors = validateDraft(mini);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveMini();
    }
  };

  const handleAddTool = (type) => () => {
    handleMiniInfoChanged("tools", [
      ...mini.tools,
      {
        key: ShortId.generate(),
        type,
        icon: type === toolType.VIDEO ? iconList.VIDEO : null,
      },
    ]);
  };

  const handleValueChange = (type, index) => (tag, value) => {
    const toolIndex = getTool(type, index);
    if (toolIndex !== null && toolIndex >= 0) {
      const list = mini.tools.slice();
      list[toolIndex][tag] = value;
      handleMiniInfoChanged("tools", list);
      setErrors({});
    }
  };

  const handleDeleteTool = (type, index) => () => {
    const toolIndex = getTool(type, index);
    if (toolIndex !== null && toolIndex >= 0) {
      const list = mini.tools.slice();
      list.splice(toolIndex, 1);
      handleMiniInfoChanged("tools", list);
      setErrors({});
    }
  };

  const handleCloseIconPicker = () => {
    setShowIconPicker(false);
  };

  const handleSelectIcon = (type, index) => () => {
    const toolIndex = getTool(type, index);
    if (toolIndex !== null && toolIndex >= 0) {
      setEditedToolIconIndex(toolIndex);
      setShowIconPicker(true);
    }
  };

  const handleIconSelected = (value) => () => {
    if (editedToolIconIndex !== null) {
      const list = mini.tools.slice();
      list[editedToolIconIndex].icon = value;
      handleMiniInfoChanged("tools", list);
      setEditedToolIconIndex(null);
      setShowIconPicker(false);
      setErrors({});
    }
  };

  const getTool = (type, index) => {
    let list = [];
    switch (type) {
      case toolType.COMMON_LINK:
        list = commonTools;
        break;
      case toolType.ROOM_LINK:
        list = roomTools;
        break;
      case toolType.VIDEO:
        list = videoTools;
        break;
      default:
        break;
    }

    if (list.length > 0) {
      const editedTool = list[index];
      return mini.tools.findIndex(
        (tool) =>
          (tool.id && tool.id === editedTool.id) ||
          (!tool.id && tool.key === editedTool.key)
      );
    }

    return null;
  };

  const commonTools = mini.tools.filter(
    (tool) => tool.type === toolType.COMMON_LINK
  );
  const videoTools = mini.tools.filter((tool) => tool.type === toolType.VIDEO);
  const roomTools = mini.tools.filter(
    (tool) => tool.type === toolType.ROOM_LINK
  );

  return (
    <Panel className={"tools"}>
      <div className={"header"}>
        <p className={"name"}>{mini.name}</p>
        <div className={"title-wrapper"}>
          <p>{strings("tools")}</p>
        </div>
      </div>
      <div className={"separator"} />
      <div className={"tool-type"}>
        <p className={"label"}>{strings("common_tools")}</p>
        <div className={"separator"} />
        {commonTools && commonTools.length > 0 && (
          <div className={"tools"}>
            {commonTools.map((tool, index) => (
              <Tool
                key={tool.id ? tool.id : tool.key}
                strings={strings}
                tool={tool}
                isVideo={false}
                enableLink={true}
                enableIcon={true}
                error={
                  errors.tools &&
                  errors.tools.find((err) =>
                    tool.id ? tool.id === err.id : err.key === tool.key
                  )
                }
                handleSelectIcon={handleSelectIcon(toolType.COMMON_LINK, index)}
                handleValueChange={handleValueChange(
                  toolType.COMMON_LINK,
                  index
                )}
                handleDeleteTool={handleDeleteTool(toolType.COMMON_LINK, index)}
              />
            ))}
          </div>
        )}
        <div className={"button-wrapper"}>
          <Button
            color={ButtonColors.orange}
            label={strings("button_text_add")}
            icon={icons.ADD}
            className={"add"}
            action={handleAddTool(toolType.COMMON_LINK)}
          />
        </div>
      </div>
      <div className={"separator"} />
      <div className={"tool-type"}>
        <p className={"label"}>{strings("video_tools")}</p>
        <div className={"separator"} />
        {videoTools && videoTools.length > 0 && (
          <div className={"tools"}>
            {videoTools.map((tool, index) => (
              <Tool
                key={tool.id ? tool.id : tool.key}
                strings={strings}
                tool={tool}
                isVideo={true}
                enableLink={true}
                enableIcon={false}
                error={
                  errors.tools &&
                  errors.tools.find((err) =>
                    tool.id ? tool.id === err.id : err.key === tool.key
                  )
                }
                handleValueChange={handleValueChange(toolType.VIDEO, index)}
                handleDeleteTool={handleDeleteTool(toolType.VIDEO, index)}
              />
            ))}
          </div>
        )}
        <div className={"button-wrapper"}>
          <Button
            color={ButtonColors.orange}
            label={strings("button_text_add")}
            icon={icons.ADD}
            className={"add"}
            action={handleAddTool(toolType.VIDEO)}
          />
        </div>
      </div>
      <div className={"separator"} />
      <div className={"tool-type"}>
        <p className={"label"}>{strings("room_tools")}</p>
        <div className={"separator"} />
        {roomTools && roomTools.length > 0 && (
          <div className={"tools"}>
            {roomTools.map((tool, index) => (
              <Tool
                key={tool.id ? tool.id : tool.key}
                strings={strings}
                tool={tool}
                isVideo={false}
                enableLink={false}
                enableIcon={true}
                error={
                  errors.tools &&
                  errors.tools.find((err) =>
                    tool.id ? tool.id === err.id : err.key === tool.key
                  )
                }
                handleSelectIcon={handleSelectIcon(toolType.ROOM_LINK, index)}
                handleValueChange={handleValueChange(toolType.ROOM_LINK, index)}
                handleDeleteTool={handleDeleteTool(toolType.ROOM_LINK, index)}
              />
            ))}
          </div>
        )}
        <div className={"button-wrapper"}>
          <Button
            color={ButtonColors.orange}
            label={strings("button_text_add")}
            icon={icons.ADD}
            className={"add"}
            action={handleAddTool(toolType.ROOM_LINK)}
          />
        </div>
      </div>
      <div className={"separator"} />
      {showIconPicker && (
        <IconPicker
          strings={strings}
          handleClose={handleCloseIconPicker}
          handleIconSelected={handleIconSelected}
        />
      )}
      <div className={"actions"}>
        <Button
          color={ButtonColors.green}
          label={strings("button_text_save")}
          className={"save"}
          action={handleSave}
        />
      </div>
    </Panel>
  );
};

export default Tools;
