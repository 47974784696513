import { call, put, select, takeLatest } from 'redux-saga/effects';
import { default as ProjectsActions, types } from './actions';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { ProjectsServices, SchoolsServices } from '../../services';
import { downloadAttachment, projectStatus, Routes, VALIDATION_ERROR } from '../../utils';
import { history } from '../../core/history';
import { UNPROCESSABLE_ENTITY } from 'http-status-codes';

function* projectDetailRequest({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    ProjectsServices.getProject,
    adminId,
    id
  );

  if (!error) {
    yield put(ProjectsActions.getProjectDetailSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('project_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getProjectsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    ProjectsServices.getListProjects,
    adminId
  );
  if (!error) {
    yield put(ProjectsActions.getProjectsSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('project_retrieval_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* updateInfoRequested({ projectInfo }) {
  const { id, type, name, duration, season, status } = projectInfo;

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    ProjectsServices.saveProjectInfo,
    adminId,
    { id, type, name, duration, season, status }
  );

  if (!error) {
    yield put(ProjectsActions.updateInfoSucceeded(response.data));
  } else {
    if (error.response.status == UNPROCESSABLE_ENTITY && error.response.data.code == VALIDATION_ERROR) {
      yield put(SnackActions.displayError('update_project_failed_has_success'));
    } else {
      yield put(SnackActions.displayError('project_update_failed'));
    }
  }

  yield put(LoaderActions.loaded());
}

function* linkUserRequested({ users, projectId }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  let responseCall = [];
  for (let i = 0; i < users.length; i++) {
    const [error, response] = yield call(
      ProjectsServices.linkUser,
      users[i],
      adminId,
      projectId
    );
    responseCall = responseCall.concat({ error, response });
  }

  const error = responseCall.some(item => item.error);

  if (!error) {
    const lastResponse = responseCall[responseCall.length - 1].response;
    yield put(ProjectsActions.unlinkUserSucceeded(lastResponse.data));
  } else {
    yield put(SnackActions.displayError('one_or_more_link_user_failed'));
  }

  // projectDetailRequest({id: projectId});

  yield put(LoaderActions.loaded());
}

function* unlinkUserRequested({ user, role, projectId }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    ProjectsServices.unlinkUser,
    { userId: user, role },
    adminId,
    projectId
  );

  if (!error) {
    yield put(ProjectsActions.unlinkUserSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('unlink_user_failed'));
  }

  projectDetailRequest({ id: projectId });

  yield put(LoaderActions.loaded());
}

function* deleteProjectRequested({ projectId }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(
    ProjectsServices.deleteProject,
    adminId,
    projectId
  );

  if (!error) {
    if (window.location.pathname !== Routes.PROJECTS_LIST) {
      window.location = Routes.PROJECTS_LIST;
    } else {
      yield call(getProjectsRequested);
    }
    yield put(SnackActions.displayInfo('delete_project_succeeded'));
  } else {
    yield put(SnackActions.displayError('delete_project_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* archiveProjectRequested({ projectId }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    ProjectsServices.archiveProject,
    adminId,
    projectId,
    { status: projectStatus.ARCHIVED }
  );

  if (!error) {
    if (window.location.pathname === Routes.PROJECTS_LIST) {
      yield call(getProjectsRequested);
    } else {
      yield put(ProjectsActions.archiveProjectSucceeded(response.data));
    }
    yield put(SnackActions.displayInfo('archive_project_succeeded'));
  } else {
    yield put(SnackActions.displayError('archive_project_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* archiveProjectsRequested({ projectsIds }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(ProjectsServices.archiveProjects, adminId, {
    projects: projectsIds,
    status: projectStatus.ARCHIVED,
  });

  if (!error) {
    yield call(getProjectsRequested);
    yield put(SnackActions.displayInfo('archive_projects_succeeded'));
  } else {
    yield put(SnackActions.displayError('archive_projects_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* changeToFinishProjectsRequested({ projectsIds }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(ProjectsServices.changeToFinishProjects, adminId, {
    projects: projectsIds,
    status: projectStatus.FINISHED,
  });

  if (!error) {
    yield call(getProjectsRequested);
    yield put(SnackActions.displayInfo('change_to_finished_projects_succeeded'));
  } else {
    yield put(SnackActions.displayError('change_to_finished_projects_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* createProjectRequested({ newProject }) {
  const users = newProject['users'] !== undefined ? newProject.users : [];

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    ProjectsServices.createProject,
    adminId,
    newProject
  );
  if (!error) {
    if (users.length > 0 && response.data.id > 0) {
      const listUsers = users.map(user => {
        return {
          ...user,
          userId: user.id,
        };
      });
      yield put(ProjectsActions.linkUserRequested(listUsers, response.data.id));
      yield put(ProjectsActions.getProjectsRequested());
    }
    yield put(ProjectsActions.createProjectSucceeded(response.data));

    yield call(history.replace, Routes.PROJECTS_LIST);
  } else {
    yield put(SnackActions.displayError('project_creation_failed'));
  }

  yield put(SnackActions.displayInfo('project_creation_success', 100000));
}

function* exportProjectsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    ProjectsServices.exportProjects,
    adminId
  );

  if (response && response.data && !error) {
    downloadAttachment(response);
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}
function* exportArchivesProjectsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    ProjectsServices.exportArchivesProjects,
    adminId
  );

  if (response && response.data && !error) {
    downloadAttachment(response);
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}
export default [
  takeLatest(types.GET_PROJECTS_REQUESTED, getProjectsRequested),
  takeLatest(types.GET_PROJECT_DETAIL_REQUESTED, projectDetailRequest),
  takeLatest(types.LINK_USER_REQUESTED, linkUserRequested),
  takeLatest(types.UNLINK_USER_REQUESTED, unlinkUserRequested),
  takeLatest(types.DELETE_PROJECT_REQUESTED, deleteProjectRequested),
  takeLatest(types.ARCHIVE_PROJECT_REQUESTED, archiveProjectRequested),
  takeLatest(types.ARCHIVE_PROJECTS_REQUESTED, archiveProjectsRequested),
  takeLatest(types.CHANGE_TO_FINISHED_PROJECTS_REQUESTED, changeToFinishProjectsRequested),
  takeLatest(types.UPDATE_INFO_REQUESTED, updateInfoRequested),
  takeLatest(types.CREATE_PROJECT_REQUESTED, createProjectRequested),
  takeLatest(types.EXPORT_PROJECTS_REQUESTED, exportProjectsRequested),
  takeLatest(
    types.EXPORT_ARCHIVES_PROJECTS_REQUESTED,
    exportArchivesProjectsRequested
  ),
];
