import React from "react";
import { icons } from "../../../../../assets";
import { getIcon, InputTypes, REGEX } from "../../../../../utils";
import { Button, ButtonColors, Input } from "../../../../components";

const Tool = ({
  strings,
  tool,
  isVideo,
  enableLink,
  enableIcon,
  error,
  handleSelectIcon,
  handleDeleteTool,
  handleValueChange,
}) => {
  const onTestLink = (link) => () =>
    window.open(link, "_blank", "noopener,noreferrer");

  return (
    <div className={"tool"}>
      <div className={"column"}>
        <div
          className={`icon ${enableIcon ? "enabled" : ""} ${
            error && error.errors.icon ? "invalid" : ""
          }`}
          onClick={handleSelectIcon}
        >
          <div className={"icon-wrapper"}>{getIcon(tool.icon)}</div>
          {enableIcon && (
            <i className={"material-icons arrow"}>{icons.ARROW_DROPDOWN}</i>
          )}
        </div>
      </div>
      <div className={"column content"}>
        <Input
          type={InputTypes.TEXT}
          tag={"label"}
          defaultValue={tool.label}
          placeholder={strings("tool_title")}
          className={`${error && error.errors.label ? "invalid" : ""}`}
          handleValueChanged={handleValueChange}
        />
        {enableLink &&
          (isVideo ? (
            <Input
              type={InputTypes.TEXT}
              tag={"video"}
              defaultValue={tool.video}
              placeholder={strings("tool_video")}
              className={`${error && error.errors.video ? "invalid" : ""}`}
              handleValueChanged={handleValueChange}
            />
          ) : (
            <Input
              type={InputTypes.TEXT}
              tag={"url"}
              defaultValue={tool.url}
              placeholder={strings("tool_url")}
              className={`${error && error.errors.url ? "invalid" : ""}`}
              handleValueChanged={handleValueChange}
            />
          ))}
      </div>
      <div className={"column"}>
        <i className={"material-icons delete"} onClick={handleDeleteTool}>
          {icons.CLOSE}
        </i>
        {enableLink && !isVideo && (
          <Button
            color={ButtonColors.orange}
            label={strings("test")}
            className={"test"}
            enabled={tool?.url?.length > 0 && REGEX.URL.test(tool?.url)}
            action={
              tool?.url?.length > 0 && REGEX.URL.test(tool?.url) ? onTestLink(tool.url) : null
            }
          />
        )}
      </div>
    </div>
  );
};

export default Tool;
