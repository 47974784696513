import React, { PureComponent } from 'react';
import { Button, FormDropDown, Input } from '..';
import { icons } from '../../../assets';
import { formatAcademiesSelector, formatEnumForSelector, Gender } from '../../../utils';
import { InputTypes } from '../../../utils/index';
import { ButtonColors } from '../index';
import { validateSchoolDraftForm, validateSchoolForm } from './FormValidator';
import './school-info.scss';

export class SchoolInfo extends PureComponent {

  state = {
    school: this.props.school ? this.props.school : {},
    isEditing: this.props.creationMode || false,
    errors: {
      rne: {},
      name: {},
      address: {},
      postalCode: {},
      city: {},
      region: {},
      academy: {},
      country: {},
      secretariatPhone: {},
      principalCivility: {},
      principalFirstName: {},
      principalLastName: {},
      principalEmail: {},
      principalPhone: {},
      referentCivility: {},
      referentFirstName: {},
      referentLastName: {},
      referentEmail: {},
      referentPhone: {},
    },
    academies: [],
    civilities: formatEnumForSelector(this.props.strings, Gender),
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {school, checkForm, regions} = this.props;
    if (prevProps.school !== school) {
      const currRegionItem = school.region && regions.find(r => r.key === school.region);

      if (currRegionItem) {
        this.setState({
          academies: formatAcademiesSelector(this.props.strings, school.region && regions.find(r => r.key === school.region).value.academies).slice(1),
          school: school,
        });
      } else {
        this.setState({school});
      }
    }

    if (checkForm === true) {
      this.updateSchoolInfo();
    }
  }

  handleChange = (tag, value) => {
    const {errors, school} = this.state;
    //Reset on error state
    Object.values(errors).map(val => val.onError = false);

    this.setState({
      school: {
        ...school,
        [tag]: value,
      },
      errors: errors,
    });
  };

  handleDropDownChange = (tag, item) => {
    if (tag === 'region') {
      const {school} = this.state;
      school.academy = null;
      this.setState({
        academies: formatAcademiesSelector(this.props.strings, item.value.academies).slice(1),
        school: {
          ...school,
        },
      });
    }
    this.handleChange(tag, item.key);
  };

  updateSchoolInfo = () => {
    const {school, isEditing, errors} = this.state;
    const {creationMode, isDraft} = this.props;

    //if editing mode
    if (isEditing) {
      //check form
      const result = isDraft ? validateSchoolDraftForm(school, errors) : validateSchoolForm(school, errors);
      //if form contains errors
      const fields = [];

      Object.keys(result).forEach(
        key => {
          fields.push({key: key, data: result[key]});
        },
      );

      const hasError = fields.some(field => field.data.onError);

      if (hasError) {
        // save errors in state to display them
        this.setState({
          errors: {...errors, result},
        });

        // not update school
        this.props.updateSchool(false, school);

      } else {

        if (!creationMode) {
          this.setState({
            isEditing: !this.state.isEditing,
          });
        }

        // update school
        this.props.updateSchool(true, school);
      }
    } else {
      this.setState({
        isEditing: !this.state.isEditing,
      });
    }
  };

  render() {
    const {strings, creationMode, regions} = this.props;
    const {school, isEditing, errors, academies, civilities} = this.state;
    const {
      id,
      rne,
      name,
      address,
      postalCode,
      city,
      region,
      academy,
      country,
      secretariatPhone,
      principalCivility,
      principalFirstName,
      principalLastName,
      principalEmail,
      principalPhone,
      referentCivility,
      referentFirstName,
      referentLastName,
      referentEmail,
      referentPhone,
    } = school;

    const principalCivilityLabel = principalCivility && civilities.find(c => c.key === principalCivility) ?
      civilities.find(c => c.key === principalCivility).value.label : '';
    const referentCivilityLabel = referentCivility && civilities.find(c => c.key === principalCivility) ?
      civilities.find(c => c.key === referentCivility).value.label : '';

    return (
      <div id={'schoolInfo'}>
        <div className="header">
          <Input
            type={InputTypes.TEXT}
            placeholder={creationMode && strings('school_default_name')}
            defaultValue={name}
            tag={'name'}
            disabled={!isEditing}
            inputClassName={`title ${errors.name.onError ? 'invalid' : ''}`}
            error={errors.name.onError ? strings(errors.name.errorMsg) : null}
            isHighlighted={false}
            handleValueChanged={(tag, value) => this.handleChange(tag, value)}
          />
          {!creationMode && <Button
            color={ButtonColors.orange}
            label={isEditing ? strings('button_text_save') : strings('button_text_modify')}
            icon={isEditing ? icons.DONE : icons.EDIT}
            enabled={id}
            className={'edit-button'}
            action={e => this.updateSchoolInfo(e)}
          />}
        </div>
        <hr className={'separator'}/>
        <div className={'form-container'}>
          <div className={'line'}>
            <Input
              type={InputTypes.TEXT}
              defaultValue={address}
              tag={'address'}
              label={strings('address')}
              className={'full'}
              inputClassName={`${errors.address.onError ? 'invalid' : ''}`}
              error={errors.address.onError ? strings(errors.address.errorMsg) : null}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
            <div className={'subcontainer full'}>
              <Input
                type={InputTypes.TEXT}
                defaultValue={postalCode}
                tag={'postalCode'}
                inputClassName={`${errors.postalCode.onError ? 'invalid' : ''}`}
                error={errors.postalCode.onError ? strings(errors.postalCode.errorMsg) : null}
                disabled={!isEditing}
                label={strings('postalCode')}
                className={'small'}
                handleValueChanged={(tag, value) => this.handleChange(tag, value)}
              />
              <Input
                type={InputTypes.TEXT}
                defaultValue={city}
                tag={'city'}
                label={strings('city')}
                inputClassName={`${errors.city.onError ? 'invalid' : ''}`}
                error={errors.city.onError ? strings(errors.city.errorMsg) : null}
                className={'medium'}
                disabled={!isEditing}
                handleValueChanged={(tag, value) => this.handleChange(tag, value)}
              />
            </div>
          </div>
          <div className={'line'}>
            <FormDropDown
              tag={'region'}
              className={'full'}
              label={strings('region')}
              data={regions}
              dropDownClassName={`${errors.region.onError ? 'invalid' : ''}`}
              error={errors.region.onError ? strings(errors.region.errorMsg) : null}
              current={region}
              onValueChange={(tag, item) => this.handleDropDownChange(tag, item)}
              disabled={!isEditing}
            />
            <FormDropDown
              tag={'academy'}
              className={'full'}
              label={strings('academy')}
              data={academies}
              dropDownClassName={`${errors.academy.onError ? 'invalid' : ''}`}
              error={errors.academy.onError ? strings(errors.academy.errorMsg) : null}
              current={academy}
              onValueChange={(tag, item) => this.handleDropDownChange(tag, item)}
              disabled={!isEditing}
            />
          </div>
          <div className={'line'}>
            <Input
              type={InputTypes.TEXT}
              defaultValue={country}
              tag={'country'}
              label={strings('country')}
              className={'full'}
              inputClassName={`${errors.country.onError ? 'invalid' : ''}`}
              error={errors.country.onError ? strings(errors.country.errorMsg) : null}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
            <Input
              type={InputTypes.TEXT}
              defaultValue={secretariatPhone}
              tag={'secretariatPhone'}
              inputClassName={`${errors.secretariatPhone.onError ? 'invalid' : ''}`}
              error={errors.secretariatPhone.onError ? strings(errors.secretariatPhone.errorMsg) : null}
              label={strings('secretariatPhone')}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
          </div>
          <div className={'line'}>
            <Input
              type={InputTypes.TEXT}
              defaultValue={rne}
              tag={'rne'}
              inputClassName={`${errors.rne.onError ? 'invalid' : ''}`}
              error={errors.rne.onError ? strings(errors.rne.errorMsg) : null}
              label={strings('rne')}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
            <div className={'full'}/>
          </div>
        </div>
        <hr className={'separator'}/>
        <p className={'subtitle'}>{strings('headmaster')}</p>
        <div className={'form-container'}>
          <div className={'line'}>
            <div className={'subcontainer full'}>
              <FormDropDown
                tag={'principalCivility'}
                className={'small'}
                label={strings('civility')}
                data={civilities}
                dropDownClassName={`${errors.principalCivility.onError ? 'invalid' : ''}`}
                error={errors.principalCivility.onError ? strings(errors.principalCivility.errorMsg) : null}
                current={principalCivilityLabel}
                onValueChange={(tag, value) => this.handleDropDownChange(tag, value)}
                disabled={!isEditing}
              />
              <Input
                type={InputTypes.TEXT}
                defaultValue={principalFirstName}
                tag={'principalFirstName'}
                label={strings('firstName')}
                className={'medium'}
                inputClassName={`${errors.principalFirstName.onError ? 'invalid' : ''}`}
                error={errors.principalFirstName.onError ? strings(errors.principalFirstName.errorMsg) : null}
                disabled={!isEditing}
                handleValueChanged={(tag, value) => this.handleChange(tag, value)}
              />
            </div>
            <Input
              type={InputTypes.TEXT}
              defaultValue={principalLastName}
              tag={'principalLastName'}
              label={strings('lastName')}
              inputClassName={`${errors.principalLastName.onError ? 'invalid' : ''}`}
              error={errors.principalLastName.onError ? strings(errors.principalLastName.errorMsg) : null}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
          </div>
          <div className={'line'}>
            <Input
              type={InputTypes.TEXT}
              defaultValue={principalEmail}
              tag={'principalEmail'}
              label={strings('email')}
              inputClassName={`${errors.principalEmail.onError ? 'invalid' : ''}`}
              error={errors.principalEmail.onError ? strings(errors.principalEmail.errorMsg) : null}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
            <Input
              type={InputTypes.TEXT}
              defaultValue={principalPhone}
              tag={'principalPhone'}
              label={strings('phone')}
              inputClassName={`${errors.principalPhone.onError ? 'invalid' : ''}`}
              error={errors.principalPhone.onError ? strings(errors.principalPhone.errorMsg) : null}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
          </div>
        </div>
        <hr className={'separator'}/>
        <p className={'subtitle'}>{strings('referent')}</p>
        <div className={'form-container'}>
          <div className={'line'}>
            <div className={'subcontainer full'}>
              <FormDropDown
                tag={'referentCivility'}
                className={'small'}
                label={strings('civility')}
                data={civilities}
                dropDownClassName={`${errors.referentCivility.onError ? 'invalid' : ''}`}
                error={errors.referentCivility.onError ? strings(errors.referentCivility.errorMsg) : null}
                current={referentCivilityLabel}
                onValueChange={(tag, value) => this.handleDropDownChange(tag, value)}
                disabled={!isEditing}
              />
              <Input
                type={InputTypes.TEXT}
                defaultValue={referentFirstName}
                tag={'referentFirstName'}
                label={strings('firstName')}
                className={'medium'}
                inputClassName={`${errors.referentFirstName.onError ? 'invalid' : ''}`}
                error={errors.referentFirstName.onError ? strings(errors.referentFirstName.errorMsg) : null}
                disabled={!isEditing}
                handleValueChanged={(tag, value) => this.handleChange(tag, value)}
              />
            </div>
            <Input
              type={InputTypes.TEXT}
              defaultValue={referentLastName}
              tag={'referentLastName'}
              label={strings('lastName')}
              inputClassName={`${errors.referentLastName.onError ? 'invalid' : ''}`}
              error={errors.referentLastName.onError ? strings(errors.referentLastName.errorMsg) : null}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
          </div>
          <div className={'line'}>
            <Input
              type={InputTypes.TEXT}
              defaultValue={referentEmail}
              tag={'referentEmail'}
              label={strings('email')}
              inputClassName={`${errors.referentEmail.onError ? 'invalid' : ''}`}
              error={errors.referentEmail.onError ? strings(errors.referentEmail.errorMsg) : null}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
            <Input
              type={InputTypes.TEXT}
              defaultValue={referentPhone}
              tag={'referentPhone'}
              label={strings('phone')}
              inputClassName={`${errors.referentPhone.onError ? 'invalid' : ''}`}
              error={errors.referentPhone.onError ? strings(errors.referentPhone.errorMsg) : null}
              className={'full'}
              disabled={!isEditing}
              handleValueChanged={(tag, value) => this.handleChange(tag, value)}
            />
          </div>
        </div>
      </div>
    );
  }
}
