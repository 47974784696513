import React, { Component } from 'react';
import { ButtonColors } from '../../buttons';
import './formDropDown.scss';

export class FormDropDown extends Component {
  state = {
    displayList: false,
  };

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      this.setState({
        displayList: false,
      });
      event.stopPropagation();
    }
  };

  handleClickBox = () => {
    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  handleClickItem = item => () => {
    const {tag, onValueChange} = this.props;

    onValueChange(tag, item);

    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  render() {
    const {className, current, data, strings, disabled, label, error, dropDownClassName} = this.props;
    const {displayList} = this.state;

    return (
      <div className={`form-drop-down-wrapper ${className}`}>
        <span className={'label'}>{label}</span>
        <div className={'form-drop-down'}>
          <div
            className={`box ${disabled ? 'disabled' : ''} ${
              dropDownClassName ? dropDownClassName : ''
            }`}
            onClick={() => (!disabled ? this.handleClickBox() : undefined)}
            ref={this.boxRef}
          >
            <p className={'current'}>
              {(!current || current === '') && !disabled
                ? strings('select')
                : current}
            </p>
            <div className={'arrow ' + (displayList ? 'up' : '')} />
          </div>
          {error && (
            <span className={`error ${ButtonColors.orange}`}>{error}</span>
          )}
          <div className={`list ${displayList && 'display'}`}>
            {data.map((d, i) => (
              <div key={i} className={`row`} onClick={this.handleClickItem(d)}>
                <p>{d.value.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
