import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { logger } from "../../../utils";
import "./input.scss";

export class Input extends PureComponent {
  state = {
    value: this.props.defaultValue !== null ? this.props.defaultValue : "",
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.defaultValue !== this.state.value) {
      this.setState({ value: this.props.defaultValue !== null ?  this.props.defaultValue : "" });
    }
  }

  valueChanged = (event) => {
    const { value } = event.target;
    const { tag, handleValueChanged } = this.props;

    this.setState({ value });

    handleValueChanged(tag, value);
  };

  getSpanClass = (valueLength, maxLength) => {
    if (valueLength < maxLength / 2) {
      return "";
    } else if (valueLength === maxLength) {
      return "full";
    } else {
      return "half";
    }
  };

  render() {
    const {
      label,
      placeholder,
      type,
      className,
      inputClassName,
      error,
      maxLength,
      disabled,
      isHighlighted = true,
      icon,
      image,
      alt
    } = this.props;
    const { value } = this.state;

    return (
      <div className={`input-wrapper ${className}`}>
        <p className={"label"}>
          {label}{" "}
          {maxLength && (
            <span
              className={this.getSpanClass(value.length, maxLength)}
            >{`(${value.length}/${maxLength} char)`}</span>
          )}
        </p>
        <div className={"container"}>
          <input
            className={`${isHighlighted ? "highlight" : ""} ${
              inputClassName ? inputClassName : ""
            }`}
            type={type}
            ref={this.input}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength ? maxLength : ""}
            onChange={this.valueChanged}
            disabled={disabled}
          />
          {image && <img src={image} alt={alt}/>}
          {icon && <i className={"material-icons"}>{icon}</i>}
        </div>
        {error && <span className={"error"}>{error}</span>}
      </div>
    );
  }
}

Input.propsType = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleValueChanged: PropTypes.func,
};

Input.defaultProps = {
  type: "text",
  handleValueChanged: () => logger.warn("handleValueChanged not implemented."),
};
