import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  user: {
    id: null,
    status: null,
    role: null,
  },
  list: [],
  currentFilter: null,
};

// region Reducers
const getUserSucceeded = (state = initialState, action) => {
  const { user } = action;
  return { ...state, user: { ...user } };
};
const getUsersSucceeded = (state = initialState, action) => {
  const { list } = action;
  return { ...state, list };
};

export default createReducer(initialState, {
  [types.GET_USER_SUCCEEDED]: getUserSucceeded,
  [types.GET_USERS_SUCCEEDED]: getUsersSucceeded,
});
