import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import ShortId from "shortid";
import { icons } from "../../../../../assets";
import { Button, ButtonColors, Panel } from "../../../../components";
import Success from "./Success";

const Successes = ({
  strings,
  mini,
  miniSuccesses,
  getSuccesses,
  handleMiniInfoChanged,
  saveMini,
}) => {
  useEffect(() => {
    getSuccesses();
  }, []);

  const handleSave = () => {
    saveMini();
  };

  const handleValueChange = (index) => (_, value) => {
    const list = mini.successes.slice();
    list[index].label = value;
    handleMiniInfoChanged("successes", list);
  };

  const handleAddSuccess = () => {
    handleMiniInfoChanged("successes", [
      ...mini.successes,
      { key: ShortId.generate() },
    ]);
  };

  const handleAddDefaultSuccess = () => {
    handleMiniInfoChanged("successes", [
      ...miniSuccesses.map((success) => ({
        label: success.label,
        key: ShortId.generate(),
      })),
      ...mini.successes,
    ]);
  };

  const handleDeleteSuccess = (index) => () => {
    const list = mini.successes.slice();
    list.splice(index, 1);
    handleMiniInfoChanged("successes", list);
  };

  const handleDragEnded = (result, provided) => {
    const list = mini.successes.slice();
    const oldIndex = result.source.index;
    const oldIndexSuccess = list[oldIndex];
    list.splice(oldIndex, 1);
    list.splice(result.destination.index, 0, oldIndexSuccess);
    handleMiniInfoChanged("successes", list);
  };

  return (
    <Panel className={"successes"}>
      <div className={"header"}>
        <p className={"name"}>{mini.name}</p>
        <div className={"title-wrapper"}>
          <p>{strings("successes")}</p>
        </div>
      </div>
      <div className={"separator"} />
      <div className={"list"}>
        {mini.successes && mini.successes.length > 0 && (
          <DragDropContext onDragEnd={handleDragEnded}>
            <Droppable droppableId={"success-list"}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {mini.successes.map((success, index) => (
                    <Success
                      key={success.id ? success.id : success.key}
                      success={success}
                      index={index}
                      handleValueChange={handleValueChange}
                      handleDeleteSuccess={handleDeleteSuccess}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <div className={"button-wrapper"}>
          <Button
            color={ButtonColors.orange}
            label={strings("button_text_add")}
            icon={icons.ADD}
            className={"add"}
            action={handleAddSuccess}
          />
          {miniSuccesses && miniSuccesses.length > 0 && (
            <Button
              color={ButtonColors.orange}
              label={strings("add_default_successes")}
              icon={icons.ADD}
              className={"add-default"}
              action={handleAddDefaultSuccess}
            />
          )}
        </div>
      </div>
      <div className={"separator"} />
      <div className={"actions"}>
        <Button
          color={ButtonColors.green}
          label={strings("button_text_save")}
          className={"save"}
          action={handleSave}
        />
      </div>
    </Panel>
  );
};

export default Successes;
