import React from 'react';
import ShortId from 'shortid';
import { InputTypes } from '../../../../utils';
import { Button, ButtonColors, Input, SchoolInfo } from '../../../components';
import { Project } from './Project';

export const Validation = React.memo(({strings, school, keptProjects, newProjects, handleValidation}) => {

  return (
    <>
      <div className={'school-summary'}>
        <p className={'title'}>{strings('school')}</p>
        <Input
          type={InputTypes.TEXT}
          defaultValue={school.name}
          label={strings('school_name')}
          tag={'name'}
          disabled={true}
        />
        <SchoolInfo
          school={school}
          isDraft={false}
          strings={strings}
          regions={[]}
        />
      </div>
      <div className={'horizontal-dot-separator'}/>
      <div className={'projects-summary'}>
        <div className={'header'}>
          <p className={'title'}>{strings('projects')}</p>
          <p className={'subtitle'}>{strings('new')}</p>
        </div>
        <div className={'horizontal-dot-separator'}/>
        <ul>
          {newProjects.map((project, index) =>
            <li key={ShortId.generate()}>
              <Project
                strings={strings}
                project={project}
                index={index}/>
            </li>,
          )}
        </ul>
      </div>
      <div className={'projects-summary'}>
        <div className={'header'}>
          <p className={'title'}>{strings('projects')}</p>
          <p className={'subtitle'}>{strings('previous_projects')}</p>
        </div>
        <div className={'horizontal-dot-separator'}/>
        <ul>
          {keptProjects.map(project =>
            <li key={project.id}>
              <Project
                strings={strings}
                project={project}/>
            </li>,
          )}
        </ul>
      </div>
      <div className={'actions validation'}>
        <Button
          color={ButtonColors.green}
          label={strings('confirm')}
          action={handleValidation(false)}
        />
        <Button
          color={ButtonColors.green}
          label={strings('confirm_and_edit')}
          action={handleValidation(true)}
        />
      </div>
    </>
  );
});
