import React from 'react';
import ReactPaginate from 'react-paginate';
import './paginate.scss';
import { logger } from '../../../utils';

export const Wrapper = ({strings, numberPages, onPageChange, currentPage}) =>
  (
    <div className={'paginate-wrapper'}>
      <ReactPaginate
        pageCount={Number(numberPages)}
        initialPage={currentPage ? currentPage : 0}
        forcePage={currentPage}
        onPageChange={({selected}) => onPageChange(selected)}
        previousLabel={strings('previous')}
        nextLabel={strings('next')}
        activeLinkClassName={'selected'}
      />
    </div>
  );

Wrapper.defaultProps = {
  handlePageRequest: () => logger.warn('handlePageRequest not implemented'),
};
