import { ConnectedRouter } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import qs from 'qs';
import React, { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { clearUrlParams, Routes, userRole } from '../utils';
import { history } from './history';
import screens from './screens';
import store from './store';
import AuthActions from '../redux/auth/actions';
import UserActions from '../redux/user/actions';
import CommonsActions from '../redux/commons/actions';
import ReactGa from 'react-ga4';
import TagManager from 'react-gtm-module'
// Google Analytics
const usePageViews = () => {
  let location = window.location.pathname + window.location.search;
   
  if (!window.GA_INITIALIZED) {
    ReactGa.initialize([
      {
        trackingId: 'G-REEVLFVM8V', //Auth.
      },
      {
        trackingId: 'G-B9LPFLVC5T', //BO
      },
      {
        trackingId: 'G-40TJWPG6M6', //DB
      },
      {
        trackingId: 'G-X5VY7QGWJS', //Inscription
      },
    ]);
    window.GA_INITIALIZED = true;
  }
  ReactGa.send({ hitType: 'pageview', page: location });
};

const AuthRoute = ({ component: Component, ...rest }) => {
  const { id, accessToken, refreshToken } = store.getState().auth;
  //usePageViews();
  const tokens = rest.location
    ? qs.parse(rest.location.search, { ignoreQueryPrefix: true })
    : {};
  if (tokens.accessToken && tokens.refreshToken) {
    const decoded = jwtDecode(tokens.accessToken);
    const userProps = {
      id: decoded.id,
      role: decoded.role,
      email: decoded.email,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
    };
    store.dispatch(
      AuthActions.refreshTokensSucceed(
        decoded.id,
        tokens.accessToken,
        tokens.refreshToken
      )
    );
    //Create a temporary user with the token information to be able to call the api (user id mandatory)
    store.dispatch(UserActions.getUserSucceeded(userProps));
  }

  // //Retrieve the user to get all its information (region for automatic filters)
  store.dispatch(UserActions.getUserRequested());
  store.dispatch(CommonsActions.getRegionsRequested());
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5F36KT7' });
  }, []);
  return (
    <Route
      {...rest}
      render={props => {
        if (tokens.accessToken && tokens.refreshToken) {
          window.location.href = clearUrlParams(window.location.href);
          return <Component {...props} />;
        }

        return id && accessToken && refreshToken ? (
          <Component {...props} />
        ) : (
          (window.location.href = `${
            process.env.REACT_APP_AUTH_URL
          }?origin=F&redirectUrl=${clearUrlParams(window.location.href)}`)
        );
      }}
    />
  );
};

const AdminRoute = ({ key, path, component: Component, ...rest }) => {
  const { user } = store.getState().user;
  return user.role === userRole.ADMIN ? (
    <AuthRoute
      exact
      key={key}
      path={path}
      component={Component}
      location={rest.location}
    />
  ) : (
    <Route
      render={props => (
        <Redirect
          to={{ pathname: Routes.INSCRIPTIONS, state: { ...rest.location } }}
        />
      )}
    />
  );
};

const simpleRoute = () =>
  Object.entries(screens).map(([key, value]) =>
    value.isAdmin ? (
      <AdminRoute
        exact
        key={key}
        path={value.path}
        component={value.component}
      />
    ) : (
      <AuthRoute
        exact
        key={key}
        path={value.path}
        component={value.component}
      />
    )
  );

export default function Router() {
  return (
    <ConnectedRouter history={history}>
      <Fragment>
        <Switch>
          {simpleRoute()}
          <Redirect
            from={Routes.DEFAULT}
            to={`${Routes.INSCRIPTIONS}${history.location.search}`}
          />
        </Switch>
      </Fragment>
    </ConnectedRouter>
  );
}
