import React, { useState, useEffect } from "react";
import { icons } from "../../../../assets";
import { recipientType } from "../../../../utils";
import { Button, CheckBox, ListWrapper } from "../../../components";
import { Filter } from "../../commons/filter";

const RecipientTabs = {
  ACADEMIES: "academies",
  REGIONS: "regions",
};

export const SelectRecipient = ({
  strings,
  visible,
  title,
  academies,
  regions,
  academiesSelected,
  regionsSelected,
  handleClose,
  handleAddRecipient,
}) => {
  const [tab, setTab] = useState(RecipientTabs.ACADEMIES);
  const [filter, setFilter] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [itemsSelected, setItemsSelected] = useState([]);

  useEffect(
    () =>
      setItemsSelected(
        tab === RecipientTabs.ACADEMIES ? academiesSelected : regionsSelected
      ),
    [academiesSelected, regionsSelected]
  );

  const handleClickTab = (newTab) => {
    setTab(newTab.active);
    setSelectAll(false);
    setItemsSelected(
      newTab.active === RecipientTabs.ACADEMIES
        ? academiesSelected
        : regionsSelected
    );
  };

  const handleFilterChanged = () => (value) => {
    setFilter(value);
  };

  const onSelectAllChange = () => {
    const selectState = !selectAll;
    setSelectAll(selectState);
    if (selectState) {
      setItemsSelected(
        tab === RecipientTabs.ACADEMIES ? [...academies] : [...regions]
      );
    } else {
      setItemsSelected([]);
    }
  };

  const handleValidation = () => {
    handleAddRecipient(
      tab === RecipientTabs.ACADEMIES
        ? recipientType.ACADEMY
        : recipientType.REGION,
      itemsSelected
    );
    handleClose();
  };

  const handleClosePanel = () => {
    handleClose();
    setTab(RecipientTabs.ACADEMIES);
    setItemsSelected(academiesSelected);
  };

  const handleCheckChanged = (id) => () => {
    const itemsSelectedCopy = itemsSelected.slice();
    const newItemsSelected = itemsSelectedCopy.some((item) => item === id)
      ? itemsSelectedCopy.filter((item) => !(item === id))
      : itemsSelectedCopy.concat(id);

    setItemsSelected(newItemsSelected);
  };

  let list = tab === RecipientTabs.ACADEMIES ? academies : regions;

  if (filter.length > 0) {
    list = list.filter((element) =>
      element.toLowerCase().includes(filter.toLowerCase())
    );
  }

  const tabs = [
    {
      label: `${strings("academies")}`,
      count: tab === RecipientTabs.ACADEMIES ? itemsSelected.length : 0,
      active: RecipientTabs.ACADEMIES,
    },
    {
      label: strings("regions"),
      count: tab === RecipientTabs.REGIONS ? itemsSelected.length : 0,
      active: RecipientTabs.REGIONS,
    },
  ];

  return (
    <div className={`lateral-panel ${visible ? "show" : "hide"}`}>
      <div className={`container ${visible ? "show" : "hide"}`}>
        <div className={"header"}>
          <p className={"title"}>{title}</p>
          <i className={`material-icons`} onClick={handleClosePanel}>
            {icons.CLOSE}
          </i>
        </div>
        <div className={"filter-bar"}>
          <Filter
            strings={strings}
            tabs={tabs}
            currentTab={tab}
            currentSearch={filter}
            onSearchChange={handleFilterChanged("search")}
            onTabChange={handleClickTab}
          />
        </div>
        <div className={"items"}>
          <div className={"select-all"}>
            <CheckBox
              onCheck={onSelectAllChange}
              isChecked={selectAll}
              label={strings("select_all")}
            />
          </div>
          <ListWrapper strings={strings} className={`list-wrapper`}>
            {list.map((element, index) => (
              <div className={"item"} key={index}>
                <div className={"check"}>
                  <CheckBox
                    onCheck={handleCheckChanged(element)}
                    isChecked={itemsSelected.some(
                      (search) => search === element
                    )}
                  />
                </div>
                <div
                  className={"content"}
                  onClick={handleCheckChanged(element)}
                >
                  <p>{element}</p>
                </div>
              </div>
            ))}
          </ListWrapper>
        </div>
        <div className={"add"}>
          <Button
            label={strings("add")}
            className={"add-button"}
            action={handleValidation}
            enabled={itemsSelected.length > 0}
          />
        </div>
      </div>
    </div>
  );
};
