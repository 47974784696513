import {methods, url} from '../utils';
import {authenticatedService} from "./middleware";

const routes = {
  notif: (adminId) => url.NOTIFICATIONS(adminId),
};

export default {
  createNotif: (adminId, body) => authenticatedService(methods.PUT, routes.notif(adminId), {...body}, {}),
  getNotifs: (adminId) => authenticatedService(methods.GET, routes.notif(adminId), {}, {}),
  deleteNotifs: (adminId, body) => authenticatedService(methods.DELETE, routes.notif(adminId), body, {}),
};
