import React, { useEffect, useState } from "react";
import { icons, images } from "../../../../../assets";
import { InputTypes } from "../../../../../utils";
import {
  Input,
  Button,
  ButtonColors,
  DropDown,
  Panel,
} from "../../../../components";

const Group = ({
  strings,
  group,
  groupIndex,
  categories,
  levels,
  sectors,
  handleGroupChange,
  handleDeleteGroup,
}) => {
  const handleDropDownChanged = (response) =>
    handleGroupChange(response.key, response.value.value.value);

  return (
    <div className={"group"}>
      <div className={"header"}>
        <p className={"label"}>{`${strings("group_number")}${
          groupIndex + 1
        }`}</p>
        <i className={`material-icons`} onClick={handleDeleteGroup}>
          {icons.CLOSE}
        </i>
      </div>
      <div className={"wrapper"}>
        <p className={"label"}>{strings("category")}</p>
        <DropDown
          label={"category"}
          current={
            group.category
              ? categories.find(
                  (category) => category.key === group.category.id
                )
              : null
          }
          data={categories}
          onValueChange={handleDropDownChanged}
        />
      </div>
      <div className={"wrapper"}>
        <p className={"label"}>{strings("level")}</p>
        <DropDown
          label={"level"}
          current={
            group.level
              ? levels.find((level) => level.key === group.level.id)
              : null
          }
          data={levels}
          onValueChange={handleDropDownChanged}
        />
      </div>
      <div className={"wrapper"}>
        <p className={"label"}>{strings("sector")}</p>
        <DropDown
          label={"sector"}
          current={
            group.sector
              ? sectors.find((sector) => sector.key === group.sector.id)
              : null
          }
          data={sectors}
          onValueChange={handleDropDownChanged}
        />
      </div>
      <div className={"wrapper wrapper-composition"}>
        <div className={"composition"}>
          <p className={"label"}>{strings("girls_count")}</p>
          <Input
            type={InputTypes.NUMBER}
            tag={"girlsCount"}
            defaultValue={group.girlsCount}
            handleValueChanged={handleGroupChange}
            image={images.girls}
            alt={"Icone fille"}
          />
        </div>
        <div className={"composition"}>
          <p className={"label"}>{strings("boys_count")}</p>
          <Input
            type={InputTypes.NUMBER}
            tag={"boysCount"}
            defaultValue={group.boysCount}
            handleValueChanged={handleGroupChange}
            image={images.boys}
            alt={"Icone garçon"}
          />
        </div>
      </div>
      <div className={"dashed-separator"} />
    </div>
  );
};

export default Group;
