import { methods, url } from '../utils';
import { authenticatedService } from './middleware';

const routes = {
  getFreemiums: adminId => url.LIST_FREEMIUMS(adminId),
  freemiumDetail: (adminId, freemiumId) =>
    url.FREEMIUM_DETAIL(adminId, freemiumId),
  upgradeFreemium: (adminId, freemiumId) =>
    url.FREEMIUM_UPGRADE(adminId, freemiumId),
  exportFreemiums: adminId => url.EXPORT_FREEMIUMS(adminId),
};

export default {
  getFreemiums: adminId =>
    authenticatedService(methods.GET, routes.getFreemiums(adminId), {}, {}),
  getUsresFreemiums: adminId =>
    authenticatedService(methods.GET, routes.getFreemiums(adminId), {}, {}),
  getFreemiumDetail: (adminId, freemiumId) =>
    authenticatedService(
      methods.GET,
      routes.freemiumDetail(adminId, freemiumId),
      {},
      {}
    ),
  deleFreemium: (adminId, freemiumId) =>
    authenticatedService(
      methods.DELETE,
      routes.freemiumDetail(adminId, freemiumId),
      {},
      {}
    ),
  updateFreemium: (adminId, freemiumId, body) =>
    authenticatedService(
      methods.PATCH,
      routes.freemiumDetail(adminId, freemiumId),
      { ...body },
      {}
    ),
  createFreemium: (adminId, body) =>
    authenticatedService(
      methods.PUT,
      routes.getFreemiums(adminId),
      { ...body },
      {}
    ),
  upgradeFreemium: (adminId, freemiumId, body) =>
    authenticatedService(
      methods.PATCH,
      routes.upgradeFreemium(adminId, freemiumId),
      { ...body },
      {}
    ),
  exportFreemiums: (adminId, freemiumId, body) =>
    authenticatedService(
      methods.GET,
      routes.exportFreemiums(adminId),
      { ...body },
      {},
      {},
      'blob'
    ),
};
