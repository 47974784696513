import React, { PureComponent } from 'react';
import { logger, Routes, setSelectorLabel } from '../../../utils';
import { ListWrapper, ScreenWrapper } from '../../components';
import { ActionsBar, Filter, ListItem } from '../commons';
import './schoolList.scss';

export class SchoolList extends PureComponent {
  componentDidMount() {
    const { getSchools } = this.props;

    getSchools();
    this.initializeFilter();
  }
  initializeFilter = () => {
    const { setCurrentFilter, isBoUserAdmin } = this.props;
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        academy: 'all',
      });
  };
  onPageChange = page => {
    const { schools, setCurrentFilter } = this.props;
    const { filter, page: oldPage } = schools;
    const { search, region, academy } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        academy: academy.key,
      });
    }
  };

  onOptions = id => () => {
    logger.info('onOptionSelected', id);
  };

  onFiltersChange = key => value => {
    const { schools, setCurrentFilter } = this.props;
    const { filter } = schools;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, academy } = filter;
      let newFilter = {
        search,
        region: region.key,
        academy: academy.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  onClickItem = id => () => {
    this.props.navigateTo(Routes.SCHOOL.replace(':schoolId', id));
  };

  onClickCreate = () => {
    this.props.navigateTo(Routes.SCHOOL_CREATION);
  };

  onClickDownload = () => {
    this.props.exportList();
  };

  onDeleteSchool = id => {
    const { schools, deleteSchool, setCurrentFilter } = this.props;
    const { page, filter, listed } = schools;
    const { search, region, academy, status } = filter;

    deleteSchool(id);

    if (listed.length === 1 && page > 0) {
      setCurrentFilter(page - 1, {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      });
    }
  };

  render() {
    const { strings, schools, regions, showPopup } = this.props;
    const { page, filter, regionAcademies, all, listed, pageCount } = schools;
    const { search, region, academy } = filter;

    const actions = [
      {
        label: 'school_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_school'), () =>
            this.onDeleteSchool(id)
          ),
      },
    ];

    return (
      <ScreenWrapper id={'schools'}>
        <Filter
          strings={strings}
          label={`${strings('schools')} (${all.length})`}
          currentSearch={search}
          currentRegion={region}
          currentAcademy={academy}
          academies={regionAcademies}
          regions={regions}
          onSearchChange={this.onFiltersChange('search')}
          onRegionChange={this.onFiltersChange('region')}
          onAcademyChange={this.onFiltersChange('academy')}
        />
        <ListWrapper
          strings={strings}
          className={`list-wrapper`}
          currentPage={page}
          pageCount={pageCount}
          onPageChange={this.onPageChange}
        >
          {all.length > 0 ? (
            listed.map((item, key) => (
              <ListItem
                key={key}
                strings={strings}
                item={item}
                onOptions={this.onOptions}
                onClick={this.onClickItem}
                actions={actions}
              />
            ))
          ) : (
            <p className={'no-list'}>{strings('no_schools')}</p>
          )}
        </ListWrapper>
        <ActionsBar
          strings={strings}
          onClickDownload={this.onClickDownload}
          onClickCreate={this.onClickCreate}
        />
      </ScreenWrapper>
    );
  }
}
