import React from 'react';
import { userRealStatus, userRole, userValidationStatus } from '../../../utils';
import {
  Panel,
  ProjectsList,
  SchoolsList,
  ScreenWrapper,
  SideBar,
  UserSummary,
} from '../../components';
import './user.scss';

export const User = ({
  user,
  strings,
  validateUserForm,
  back,
  pop,
  updateUser,
  projectsData,
  getProjects,
  linkProjects,
  unlinkProject,
  navigateTo,
  fromInscription = false,
  children,
}) => {
  if (!user) {
    return null;
  }

  const { projects, schools, profile, ...userProps } = user;
  const menu = {
    detailsProject: { id: 'user-summary-wrapper', label: strings('details') },
    detailsUsers: { id: 'projects-list-wrapper', label: strings('projects') },
    detailsSchool: { id: 'schools-list-wrapper', label: strings('school') },
  };

  return (
    <ScreenWrapper>
      <div id={'user-details-screen'}>
        <div className={'side-bar'}>
          <SideBar
            menu={
              userProps.role !== userRole.FREEMIUM &&
              userProps.status !== userRealStatus.PENDING
                ? menu
                : null
            }
          />
        </div>
        <div className={'user-details'}>
          <Panel className={'user-summary-wrapper'}>
            <UserSummary
              userFound={!!user}
              user={userProps}
              mentorProfile={profile}
              strings={strings}
              validateUserForm={validateUserForm}
              updateUser={updateUser}
            />
          </Panel>
          {userProps.role !== userRole.FREEMIUM &&
            userProps.status !== userRealStatus.PENDING && (
              <Panel className={'projects-list-wrapper'}>
                <ProjectsList
                  isEditable={!fromInscription}
                  enableAddProject={user.id}
                  projects={projects}
                  allowAddProject={user.status !== userValidationStatus.PENDING}
                  strings={strings}
                  lateralListData={projectsData}
                  getProjects={getProjects}
                  linkProjects={linkProjects}
                  unlinkProject={unlinkProject}
                />
              </Panel>
            )}
          {userProps.role !== userRole.FREEMIUM &&
            userProps.status !== userRealStatus.PENDING && (
              <Panel className={'schools-list-wrapper'}>
                <SchoolsList
                  schools={schools}
                  strings={strings}
                  navigateTo={navigateTo}
                />
              </Panel>
            )}
          {children}
        </div>
      </div>
    </ScreenWrapper>
  );
};
