import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import { Project } from './Project';
import { connect } from 'react-redux';
import {ProjectsActions} from '../../../../redux/projects';
import { getTranslate } from "react-localize-redux";
import { goBack } from 'react-router-redux';
import {FacilitatorsActions} from "../../../../redux/facilitators";
import {MentorsActions} from "../../../../redux/mentors";
import {SupervisorsActions} from "../../../../redux/supervisors";
import {push} from "react-router-redux";
import { AuthSelectors } from '../../../../redux/auth';

const mapStateToProps = state => ({
  project: state.projects.project,
  strings: getTranslate(state.locale),
  isCreation: true,
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  getProjectsDetails: id => dispatch(ProjectsActions.getProjectDetailRequested(id)),
  linkUserRequested: (users, projectId) => dispatch(ProjectsActions.linkUserRequested(users, projectId)),
  getFacilitators: () => dispatch(FacilitatorsActions.getFacilitatorsRequested()),
  getMentors: () => dispatch(MentorsActions.getMentorsRequested()),
  getSupervisors: () => dispatch(SupervisorsActions.getSupervisorsRequested()),
  resetDetailRequested: () => dispatch(ProjectsActions.resetDetailRequested()),
  deleteProject: id => dispatch(ProjectsActions.deleteProjectRequested(id)),
  archiveProject: id => dispatch(ProjectsActions.archiveProjectRequested(id)),
  showPopup: (message, popupAction, title = null) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction, title)),
  pop: () => dispatch(goBack()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Project);
