import {createAction} from '../../utils/index';

// Types
export const types = {
  GET_SCHOOLS_REQUESTED: "GET_SCHOOLS_REQUESTED",
  GET_SCHOOLS_SUCCEEDED: "GET_SCHOOLS_SUCCEEDED",
  SET_SCHOOLS_CURRENT_FILTER: 'SET_SCHOOLS_CURRENT_FILTER',
  GET_SCHOOL_DETAIL_REQUESTED: 'GET_SCHOOL_DETAIL_REQUESTED',
  GET_SCHOOL_DETAIL_SUCCEEDED: 'GET_SCHOOL_DETAIL_SUCCEEDED',
  UPDATE_SCHOOL_REQUESTED: "UPDATE_SCHOOL_REQUESTED",
  UPDATE_SCHOOL_SUCCEEDED: "UPDATE_SCHOOL_SUCCEEDED",
  CREATE_SCHOOL_REQUESTED: "CREATE_SCHOOL_REQUESTED",
  CREATE_SCHOOL_SUCCEEDED: "CREATE_SCHOOL_SUCCEEDED",
  DELETE_SCHOOL_REQUESTED: "DELETE_SCHOOL_REQUESTED",
  RESET_CREATION: "RESET_CREATION",
  RESET_SCHOOL_DETAIL: "RESET_SCHOOL_DETAIL",
  START_REINSCRIPTION_REQUESTED: "START_REINSCRIPTION_REQUESTED",
  EXPORT_SCHOOLS_REQUESTED: "EXPORT_SCHOOLS_REQUESTED",
};

// Actions
export default {
  getSchoolsRequested : () => createAction(types.GET_SCHOOLS_REQUESTED, {}),
  getSchoolsSucceeded : (list) => createAction(types.GET_SCHOOLS_SUCCEEDED, {list}),
  setSchoolsCurrentFilter : (page, filter) => createAction(types.SET_SCHOOLS_CURRENT_FILTER, {page, filter}),
  getSchoolDetailRequested : id => createAction(types.GET_SCHOOL_DETAIL_REQUESTED, {id}),
  getSchoolDetailSucceeded: school => createAction(types.GET_SCHOOL_DETAIL_SUCCEEDED, {school}),
  updateSchoolRequested : school => createAction(types.UPDATE_SCHOOL_REQUESTED, {school}),
  updateSchoolSucceeded : school => createAction(types.UPDATE_SCHOOL_SUCCEEDED, {school}),
  createSchoolRequested : school => createAction(types.CREATE_SCHOOL_REQUESTED, {school}),
  createSchoolSucceeded : (school) => createAction(types.CREATE_SCHOOL_SUCCEEDED, {school}),
  deleteSchoolRequested : schoolId => createAction(types.DELETE_SCHOOL_REQUESTED, {schoolId}),
  resetCreation : () => createAction(types.RESET_CREATION, {}),
  resetSchoolDetail : () => createAction(types.RESET_SCHOOL_DETAIL, {}),
  startReinscriptionRequested : (schoolId, oldProjects, newProjects, editConvention) => createAction(types.START_REINSCRIPTION_REQUESTED, {schoolId, oldProjects, newProjects, editConvention}),
  exportSchoolsRequested : () => createAction(types.EXPORT_SCHOOLS_REQUESTED, {}),
};
