import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import {
  ALL,
  formatAcademiesSelector,
  formatEnumForSelector,
  formatRegionsSelector,
  InscriptionsTabs,
} from '../../utils';
import { ITEM_BY_PAGE } from '../../utils/constants';
import {
  SchoolInscriptionsTabs,
  schoolInscriptionStatus,
} from '../../utils/enum';

const defaultFilter = {
  search: '',
  region: null,
  academy: null,
  status: null,
};
const getCurrentTab = state =>
  state.inscriptions.currentTab || {
    tab: InscriptionsTabs.SCHOOLS,
    page: 0,
    filter: defaultFilter,
  };
const schools = state => state.inscriptions.schools || [];
const reinscriptions = state => state.inscriptions.reinscriptions || [];
const mentors = state => state.inscriptions.mentors || [];
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const strings = state => getTranslate(state.locale);

const getInscriptions = () =>
  createSelector(
    strings,
    userRegion,
    regions,
    getCurrentTab,
    schools,
    reinscriptions,
    mentors,
    (
      strings,
      userRegion,
      regions,
      currentTab,
      schools,
      reinscriptions,
      mentors
    ) => {
      const {
        tab = InscriptionsTabs.SCHOOLS,
        filter = defaultFilter,
        page = 0,
        subTab = SchoolInscriptionsTabs.INSCRIPTIONS,
      } = currentTab;
      const { search, region, academy, status } = filter;
      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;

      const regionList = formatRegionsSelector(strings, regions);
      const regionObject = regionList.find(search =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );
      const regionAcademies = regionObject
        ? formatAcademiesSelector(strings, regionObject.value.academies)
        : [];
      const matchingAcademy = regionAcademies.find(
        search => search.key === academy
      );
      const academyObject = regionAcademies
        ? academy && matchingAcademy && regionFilter !== ALL
          ? matchingAcademy
          : regionAcademies.find(search => search.key === ALL)
        : null;

      const statusList = formatEnumForSelector(
        strings,
        schoolInscriptionStatus
      );
      const statusObject = statusList.find(search =>
        status
          ? search.key.toLocaleLowerCase() === status.toLocaleLowerCase()
          : search.key.toLocaleLowerCase() ===
            Object.keys(schoolInscriptionStatus)[
              Object.values(schoolInscriptionStatus).indexOf(
                schoolInscriptionStatus.ALL
              )
            ].toLocaleLowerCase()
      );
      const pageFilter = {
        tab,
        subTab: subTab ? subTab : SchoolInscriptionsTabs.INSCRIPTIONS,
        page: page,
        filter: {
          search: search,
          region: regionObject,
          academy: academyObject,
          status: statusObject,
        },
      };

      const filteredList =
        tab === InscriptionsTabs.SCHOOLS
          ? pageFilter.subTab === SchoolInscriptionsTabs.INSCRIPTIONS
            ? filterSchools(
                strings,
                schools,
                page,
                search,
                regionFilter,
                academyObject ? academyObject.key : academyObject,
                status,
                false
              )
            : filterSchools(
                strings,
                reinscriptions,
                page,
                search,
                regionFilter,
                academyObject ? academyObject.key : academyObject,
                status,
                true
              )
          : filterMentors(
              strings,
              mentors,
              page,
              search,
              regionFilter,
              null,
              true
            );

      return {
        ...pageFilter,
        regionAcademies,
        ...filteredList,
        schoolsCount: schools.length,
        mentorsCount: mentors.length,
      };
    }
  );

const filterSchools = (
  strings,
  schools,
  page,
  search,
  region,
  academy,
  status,
  isReinscription
) => {
  let filteredSchools = schools;

  if (search.length > 0) {
    filteredSchools = filteredSchools.filter(p =>
      `${p.record} ${p.name} ${p.postalCode} ${p.address} ${p.city}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredSchools = filteredSchools.filter(p => p.region === region);
  }
  if (academy && academy !== ALL) {
    filteredSchools = filteredSchools.filter(p => p.academy === academy);
  }
  if (
    status &&
    status !==
      Object.keys(schoolInscriptionStatus)[
        Object.values(schoolInscriptionStatus).indexOf(
          schoolInscriptionStatus.ALL
        )
      ]
  ) {
    filteredSchools = filteredSchools.filter(p => p.status === status);
  }

  filteredSchools = formatSchools(filteredSchools, isReinscription);

  return {
    all: filteredSchools,
    listed: filteredSchools.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredSchools.length / ITEM_BY_PAGE),
  };
};

const filterMentors = (strings, mentors, page, search, region) => {
  let filteredMentors = mentors;

  if (search.length > 0) {
    filteredMentors = filteredMentors.filter(p =>
      `${p.firstName} ${p.lastName}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredMentors = filteredMentors.filter(
      p =>
        p.regions &&
        p.regions.length > 0 &&
        p.regions.findIndex(r => r === region) !== -1
    );
  }

  filteredMentors = formatMentors(filteredMentors, strings);

  return {
    all: filteredMentors,
    listed: filteredMentors.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredMentors.length / ITEM_BY_PAGE),
  };
};

const formatSchools = (schools, isReinscription) => {
  return schools.map(item => ({
    id: item.id,
    title: isReinscription
      ? item.name
      : `${item.record} - ${item.name ? item.name : ''}`,
    details: {
      place: `${item.address ? `${item.address} ` : ''}${
        item.postalCode ? `${item.postalCode} ` : ''
      }${item.city ? `${item.city} ` : ''}`,
      phone: item.secretariatPhone,
      academy: item.academy,
    },
    members: {
      projects: item.projectsCount,
      supervisors: item.supervisorsCount,
      mentors: item.mentorsCount,
      facilitators: item.facilitatorsCount,
    },
    status: item.status ? item.status.toLowerCase() : null,
    region: item.region,
    academy: item.academy,
  }));
};

const formatMentors = mentors => {
  return mentors.map(item => ({
    id: item.id,
    title: `${item.firstName} ${item.lastName}`,
    place: `${item.firstName} ${item.lastName}`,
    details: {
      phone: item.phone,
      email: item.email,
    },
    members: {
      projects: item.projectsCount,
      schools: item.schoolsCount,
      facilitators: item.facilitatorsCount,
      supervisors: item.supervisorsCount,
    },
    region: item.region,
    academy: item.academy,
  }));
};

export default {
  getInscriptions,
};
