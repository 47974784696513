import { methods, url } from '../utils';
import { authenticatedService } from './middleware';

const routes = {
  mini: adminId => url.MINI(adminId),
  miniDetail: (adminId, miniId) => url.MINI_DETAIL(adminId, miniId),
  duplicateMini: (adminId, miniId) => url.MINI_DUPLICATE(adminId, miniId),
  exportMiniProjects: adminId => url.EXPORT_MINI_PROJECTS(adminId),
  exportArchivesMiniProjects: adminId => url.EXPORT_ARCHIVE_MINI_PROJECTS(adminId),
};

export default {
  getMinis: adminId =>
    authenticatedService(methods.GET, routes.mini(adminId), {}, {}),
  createMini: (adminId, mini) =>
    authenticatedService(methods.PUT, routes.mini(adminId), mini, {}),
  getMini: (adminId, miniId) =>
    authenticatedService(
      methods.GET,
      routes.miniDetail(adminId, miniId),
      {},
      {}
    ),
  updateMini: (adminId, miniId, mini) =>
    authenticatedService(
      methods.PATCH,
      routes.miniDetail(adminId, miniId),
      mini,
      {}
    ),
  duplicateMini: (adminId, miniId) =>
    authenticatedService(
      methods.POST,
      routes.duplicateMini(adminId, miniId),
      {},
      {}
    ),
  deleteMini: (adminId, miniId) =>
    authenticatedService(
      methods.DELETE,
      routes.miniDetail(adminId, miniId),
      {},
      {}
    ),
  exportMiniProjects: (adminId, newProject) =>
    authenticatedService(
      methods.GET,
      routes.exportMiniProjects(adminId),
      newProject,
      {},
      {},
      'blob'
    ),

  exportArchivesMiniProjects: (adminId, newProject) =>
    authenticatedService(
      methods.GET,
      routes.exportArchivesMiniProjects(adminId),
      newProject,
      {},
      {},
      'blob'
    ),
};
