import React, { useEffect, useState } from 'react';
import { icons } from '../../../../assets';
import { history } from '../../../../core/history';
import { miniStatus, projectTypeKeys, Routes } from '../../../../utils';
import { Button, ButtonColors } from '../../../components';
import { Details } from './details';
import { formatMini } from './Formatter';
import { validateLaunch } from './FormValidator';
import './mini.scss';
import { Rooms } from './rooms';
import { default as Schools } from './schools';
import { default as Sidebar } from './Sidebar';
import { default as Successes } from './successes';
import { default as Team } from './team';
import { Tools } from './tools';

const menuKeys = {
  DETAILS: 'DETAILS',
  SCHOOLS: 'SCHOOLS',
  TEAM: 'TEAM',
  SUCCESSES: 'SUCCESSES',
  TOOLS: 'TOOLS',
  ROOMS: 'ROOMS',
};

const sidebarMenu = [
  {
    key: menuKeys.DETAILS,
    label: 'details',
  },
  {
    key: menuKeys.SCHOOLS,
    label: 'schools',
  },
  {
    key: menuKeys.TEAM,
    label: 'teams',
  },
  {
    key: menuKeys.SUCCESSES,
    label: 'successes',
  },
  {
    key: menuKeys.TOOLS,
    label: 'tools',
  },
  {
    key: menuKeys.ROOMS,
    label: 'rooms',
  },
];
const Mini = ({ match, location, strings, existingMini, getMini, createMini, updateMini, duplicateMini, deleteMini, showPopup, pop, navigateTo }) => {
  const [popup, setPopup] = useState(null);
  const [step, setStep] = useState(menuKeys.DETAILS);
  const [mini, setMini] = useState({
    status: miniStatus.DRAFT,
    miniType: projectTypeKeys.S,
    distance: true,
    partners: [],
  });

  const isCreation = location.pathname === Routes.MINI_CREATION;

  useEffect(() => {
    if (!isCreation && (!location.state || !location.state.fromCreation)) {
      getMini(match.params.miniId);
    }
  }, []);

  useEffect(() => {
    if (existingMini && Object.keys(existingMini).length > 0 && !isCreation) {
      setMini(JSON.parse(JSON.stringify(existingMini)));
    }
  }, [existingMini]);

  const handleMiniChange = (mini) => setMini(mini);

  const handleMiniInfoChange = (tag, value) => setMini({ ...mini, [tag]: value });

  const saveMini = () => {
    if (isCreation) {
      createMini(formatMini(mini));
    } else {
      updateMini(formatMini(mini));
    }
  };

  const handleDuplicate = () => {
    if (JSON.stringify(mini) !== JSON.stringify(existingMini)) {
      setPopup({
        title: strings('warning'),
        mainMessage: strings('confirm_reset_mini'),
        actions: [
          { color: ButtonColors.orange, label: strings('cancel_save'), action: () => hidePopup() },
          {
            color: ButtonColors.green,
            label: strings('confirm_no_save'),
            action: () => {
              hidePopup();
              duplicateMini(mini.id)
            },
          },
        ],
      });
    } else {
      duplicateMini(mini.id);
    }
  };

  const handleDelete = () => {
    showPopup(strings('confirm_delete_mini'), () => deleteMini(mini.id));
  };

  const handleLaunch = () => {
    if (JSON.stringify(mini) !== JSON.stringify(existingMini)) {
      setPopup({
        title: strings('warning'),
        mainMessage: strings('confirm_reset_mini'),
        actions: [
          { color: ButtonColors.orange, label: strings('cancel_save'), action: () => hidePopup() },
          {
            color: ButtonColors.green,
            label: strings('confirm_no_save'),
            action: () => {
              hidePopup();
              performLanch();
            },
          },
        ],
      });
    } else {
      performLanch();
    }
  };

  const performLanch = () => {
    const errors = validateLaunch(mini);
    if (Object.keys(errors).length !== 0) {
      const messages = Object.values(errors).map((value) => strings(value));
      setPopup({
        title: strings('mini_launch_error_title'),
        mainMessage: strings('mini_launch_error'),
        messages,
        actions: [{ color: ButtonColors.green, label: strings('ok'), action: () => hidePopup() }],
      });
    } else {
      updateMini({ id: mini.id, status: miniStatus.RUNNING });
    }
  };

  const handleEnd = () => updateMini({ id: mini.id, status: miniStatus.FINISHED });

  const handleArchive = () => updateMini({ id: mini.id, status: miniStatus.ARCHIVED });

  const hidePopup = () => setPopup(null);

  const goToStep = (entry) => () => {
    if (JSON.stringify(mini) !== JSON.stringify(existingMini)) {
      setPopup({
        title: strings('warning'),
        mainMessage: strings('confirm_reset_mini'),
        actions: [
          { color: ButtonColors.orange, label: strings('cancel_save'), action: () => hidePopup() },
          {
            color: ButtonColors.green,
            label: strings('confirm_no_save'),
            action: () => {
              hidePopup();
              setMini(existingMini);
              setStep(entry);
            },
          },
        ],
      });
    } else {
      setStep(entry);
    }
  };

  const goBack = () => {
    if (history.oldLocation && history.oldLocation === Routes.MINI_LIST) {
      pop();
    } else {
      navigateTo(Routes.MINI_LIST);
    }
  };

  const renderMiniStep = () => {
    switch (step) {
      case menuKeys.DETAILS:
        return <Details strings={strings} mini={mini} handleMiniInfoChanged={handleMiniInfoChange} saveMini={saveMini} />;
      case menuKeys.SCHOOLS:
        return <Schools strings={strings} mini={mini} handleMiniInfoChanged={handleMiniInfoChange} saveMini={saveMini} />;
      case menuKeys.TEAM:
        return <Team strings={strings} mini={mini} handleMiniInfoChanged={handleMiniInfoChange} saveMini={saveMini} />;
      case menuKeys.SUCCESSES:
        return <Successes strings={strings} mini={mini} handleMiniInfoChanged={handleMiniInfoChange} saveMini={saveMini} />;
      case menuKeys.TOOLS:
        return <Tools strings={strings} mini={mini} handleMiniInfoChanged={handleMiniInfoChange} saveMini={saveMini} />;
      case menuKeys.ROOMS:
        return <Rooms strings={strings} mini={mini} handleMiniInfoChanged={handleMiniInfoChange} handleMiniChange={handleMiniChange} saveMini={saveMini} />;
      default:
        return <></>;
    }
  };

  return (
    <div id={'mini-detail'}>
      <div className={'side-bar'}>
        <div className={'sidebar-wrapper'}>
          <div className='back-link' onClick={goBack}>
            <i className='material-icons'>{icons.ARROW_BACK}</i>
            <p className={'link'}>{strings('all_project')}</p>
          </div>
          {location.pathname !== Routes.MINI_CREATION && (
            <>
              <Sidebar strings={strings} entries={sidebarMenu} currentEntry={step} handleEntrySelected={goToStep} />
              <div className={'actions'}>
                {!isCreation && (
                  <>
                    {mini.status === miniStatus.DRAFT && (
                      <Button color={ButtonColors.green} label={strings('button_launch')} className={'launch'} action={handleLaunch} />
                    )}
                    {mini.status === miniStatus.RUNNING && (
                      <Button color={ButtonColors.green} label={strings('button_end')} className={'end'} action={handleEnd} />
                    )}
                    {mini.status === miniStatus.FINISHED && (
                      <Button color={ButtonColors.green} label={strings('archive')} className={'archive'} action={handleArchive} />
                    )}
                    <Button color={ButtonColors.green} label={strings('button_duplicate')} className={'duplicate'} action={handleDuplicate} />
                    <Button color={ButtonColors.green} label={strings('delete')} className={'delete'} action={handleDelete} />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={'mini'}>{renderMiniStep()}</div>
      {popup && (
        <div className={'popup-wrapper'}>
          <div className={'popup'}>
            <p className={'title'}>{popup.title}</p>
            <div className={'messages'}>
              <p className={'main'}>{popup.mainMessage}</p>
              {popup.messages && popup.messages.map((message, index) => <p key={index}>{message}</p>)}
            </div>
            <div className={'actions'}>
              {popup.actions.map((action, index) => (
                <Button key={index} color={action.color} label={action.label} action={action.action} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Mini;
