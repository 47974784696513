import { getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthActions, AuthSelectors } from '../../../redux/auth';
import { Navigation } from './Navigation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(AuthActions.logoutRequested()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
