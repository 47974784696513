import React, { PureComponent } from "react";
import { icons } from "../../../assets";
import { logger } from "../../../utils";
import { Draggable } from "react-beautiful-dnd";

export class ListItem extends PureComponent {
  valueChanged = (event) => {
    const { value } = event.target;
    const { index, handleValueChanged } = this.props;

    handleValueChanged(index, value);
  };

  itemDeleted = () => {
    const { index, handleItemDeletion } = this.props;

    handleItemDeletion(index);
  };

  getListItem = () => {
    const { label, onError, reordonnable } = this.props;

    return (
      <li className={"cell"}>
        {reordonnable && <i className="material-icons drag">{icons.DRAG}</i>}
        <div>
          <input
            className={`${onError ? "invalid" : ""}`}
            type={"text"}
            defaultValue={label ? label : ""}
            onChange={this.valueChanged}
          />
          <i onClick={this.itemDeleted} className={"material-icons"}>
            {icons.CANCEL}
          </i>
        </div>
      </li>
    );
  };

  render() {
    const { itemKey, index, reordonnable } = this.props;

    return (
      <>
        {reordonnable ? (
          <Draggable draggableId={itemKey} index={index}>
            {(provided, snapshot) => (
              <div
                className={"item-wrapper"}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {this.getListItem()}
              </div>
            )}
          </Draggable>
        ) : (
          <div className={"item-wrapper"}>{this.getListItem()}</div>
        )}
      </>
    );
  }
}

ListItem.defaultProps = {
  handleValueChanged: () => logger.warn("handleValueChanged not implemented."),
};
