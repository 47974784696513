import moment from 'moment';
import PropTypes from "prop-types";
import React, { useState } from "react";
import { icons } from "../../../../assets";
import { Button, ButtonColors, CheckBox } from "../../../components";
import { ActionPopup } from "../../../components/popups";

const ListItem = ({
  strings,
  item,
  actions,
  isChecked,
  handleCheckChanged,
}) => {
  const [showActions, setShowActions] = useState(false);
  const [expandCell, setExpandCell] = useState(false);

  const onToggleActions = (e) => {
    e && e.stopPropagation();
    setShowActions(!showActions);
  };

  const onClickItem = () => setExpandCell(!expandCell);

  const openLink = (link) => () => {
    window.open(link, "_blank", "noopener,noreferrer");
  }

  return (
    <div className={"notif-item"}>
      {handleCheckChanged && (
        <div className={"check"}>
          <CheckBox
            onCheck={handleCheckChanged(item.id)}
            isChecked={isChecked}
          />
        </div>
      )}
      <div
        className={`content ${expandCell ? "expanded" : ""}`}
        onClick={onClickItem}
      >
        <div className={"header"}>
          <p className={`label bold`}>{item.title}</p>
          <p className={"date"}>{moment(item.createdAt).format("DD MMM YYYY")}</p>
          {actions && (
            <div className={"options"} onClick={onToggleActions}>
              <i className={"material-icons"}>{icons.THREE_DOT}</i>
            </div>
          )}
        </div>
        <div className={"info"}>
          <p>
            <span className={"creator"}>{`${strings("notif_from")} ${
              item.creator.firstName
            } ${item.creator.lastName}`}</span>
            <span className={"recipient"}>{`${strings("notif_to")} ${
              item.recipients.regions.length > 0
                ? item.recipients.regions.reduce((acc, val) => `${acc}, ${val}`)
                : ""
            } ${
              item.recipients.academies.length > 0
                ? item.recipients.academies.reduce(
                    (acc, val) => `${acc}, ${val}`
                  )
                : ""
            }`}</span>
          </p>
          <i className={"material-icons"}>{icons.CHEVRON_RIGHT}</i>
        </div>
        <div className={"content"}>
          <div className={"separator"} />
          <p>{item.description}</p>
          {item.links && item.links.length > 0 && (
            <div className={"links"}>
              {item.links.map((link, index) => (
                <Button
                  key={index}
                  color={ButtonColors.grey}
                  label={link.title}
                  icon={icons.ARROW_FORWARD}
                  action={openLink(link.link)}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {showActions && (
        <ActionPopup
          display={true}
          userConcerned={item.id}
          tag={""}
          onClose={onToggleActions}
          items={actions}
        />
      )}
    </div>
  );
};

ListItem.propTypes = {
  strings: PropTypes.func,
};

ListItem.defaultProps = {};

export default ListItem;
