import React, { PureComponent } from 'react';
import { Routes, setSelectorLabel } from '../../../../utils';
import { CheckBox, ListWrapper, ScreenWrapper } from '../../../components';
import { ActionsBar, Filter, ProjectListItem } from '../../commons';
import strings from '../../../../assets/localize/strings_fr';
import './projectsList.scss';
import { SnackActions } from '../../../../redux/snackBar';

export class ProjectsList extends PureComponent {
  state = {
    projectsSelected: [],
    popup: false,
    selectAll: false,
    createdProject: false,
  };
  initializeFilter = () => {
    const { setCurrentFilter, isBoUserAdmin } = this.props;
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        academy: 'all',
        percent: 'ALL',
        status: 'ALL',
        type: 'ALL',
        saison: 'ALL',
        duree: 'ALL',
      });
  };
  componentDidMount() {
    const { getProjects, projects, showSnackBar, hideSnackBar } = this.props;

    this.initializeFilter();

    getProjects();
    this.handleProjectDisappearing();
  }

  onClick = id => () => {
    const { navigateTo } = this.props;

    navigateTo(Routes.PROJECT.replace(':projectId', id));
  };

  goToCreateProject = () => {
    const { navigateTo } = this.props;
    navigateTo(Routes.PROJECT_CREATION);
  };

  onClickDownload = () => {
    this.props.exportList();
  };
  onClickDownloadArchive = () => {
    this.props.exportListArchive();
  };
  onPageChange = page => {
    const { projects, setCurrentFilter } = this.props;
    const { filter, page: oldPage } = projects;
    const { search, region, academy, percent, type, saison, status, duree } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        academy: academy.key,
        percent: percent.key,
        status: status.key,
        type: type.key,
        saison: saison.key,
        duree: duree.key,
      });
    }
  };

  onFiltersChange = key => value => {
    const { projects, setCurrentFilter } = this.props;
    const { filter } = projects;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, academy, percent, type, saison, status, duree } = filter;

      let newFilter = {
        search,
        region: region.key,
        academy: academy.key,
        percent: percent.key,
        type: type.key,
        saison: saison.key,
        status: status.key,
        duree: duree.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  handleProjectDisappearing = () => {
    const { setCurrentFilter, projects } = this.props;
    const { page, filter, listed } = projects;
    const { search, region, academy, percent, type, saison, status, duree } = filter;

    if (listed.length === 1 && page > 0) {
      setCurrentFilter(page - 1, {
        search,
        region: region.key,
        academy: academy.key,
        percent: percent.key,
        type: type.key,
        saison: saison.key,
        status: status.key,
        duree: duree.key,
      });
    }
  };

  onDeleteProject = id => {
    const { deleteProject } = this.props;

    deleteProject(id);
    this.handleProjectDisappearing();
  };

  onArchiveProject = id => {
    const { archiveProject } = this.props;

    archiveProject(id);
    this.handleProjectDisappearing();
  };

  onArchiveProjects = () => {
    const { strings, showPopup, archiveProjects } = this.props;
    const { projectsSelected } = this.state;

    showPopup(strings('confirm_archive_projects'), () =>
      archiveProjects(projectsSelected)
    );
  };

  onChangeToFinishedProjects = () => {
    const { strings, showPopup, changeToFinishProjects } = this.props;
    const { projectsSelected } = this.state;

    showPopup(strings('confirm_finished_projects'), () => {
      changeToFinishProjects(projectsSelected)
      this.setState({
        projectsSelected: []
      });
    });
  };

  onSelectAllChange = () => {
    const { projects, showSnackBar, setActionSnackBar, hideSnackBar } =
      this.props;
    const { selectAll } = this.state;
    const { all } = projects;

    if (selectAll) {
      hideSnackBar();
      this.setState({ selectAll: false, projectsSelected: [] });
    } else {
      // setActionSnackBar(this.onArchiveProjects, 'archive');
      // showSnackBar('want_archive_projects');
      setActionSnackBar(this.onChangeToFinishedProjects, "button_end")
      showSnackBar('want_finish_projects');
      this.setState({
        selectAll: true,
        projectsSelected: all.map(item => item.id),
      });
    }
  };

  onMyProjectsChange = () => {
    const { projects, setCurrentFilter } = this.props;
    const { filter } = projects;

    const {
      search,
      region,
      academy,
      percent,
      status,
      type,
      saison,
      duree,
      userProjects,
    } = filter;
    let newFilter = {
      search,
      region: region.key,
      academy: academy.key,
      percent: percent.key,
      type: type.key,
      saison: saison.key,
      status: status.key,
      duration: duree.key,
      userProjects: !userProjects,
    };

    setCurrentFilter(0, newFilter);
  };

  onCheckChange = id => () => {
    const { showSnackBar, setActionSnackBar, hideSnackBar } = this.props;
    const { projectsSelected } = this.state;

    const newProjectsSelected = projectsSelected.some(item => item === id)
      ? projectsSelected.filter(item => !(item === id))
      : projectsSelected.concat(id);

    this.setState({
      projectsSelected: newProjectsSelected,
    });

    // setActionSnackBar(this.onArchiveProjects, 'archive');
    // newProjectsSelected.length > 0
    //   ? showSnackBar(
    //       newProjectsSelected.length === 1
    //         ? 'want_archive_project'
    //         : 'want_archive_projects'
    //     )
    //   : hideSnackBar();
    setActionSnackBar(this.onChangeToFinishedProjects, 'button_end');
    newProjectsSelected.length > 0
      ? showSnackBar(
          newProjectsSelected.length === 1
            ? 'want_finish_project'
            : 'want_finish_projects'
        )
      : hideSnackBar();
  };

  //Change
  render() {
    const {
      strings,
      projects,
      regions,
      types,
      percentFilters,
      projetStatusFilters,
      showPopup,
      isBoUserAdmin,
    } = this.props;
    const { projectsSelected, selectAll } = this.state;
    const {
      page,
      filter,
      regionAcademies,
      all,
      listed,
      pageCount,
      saisons,
      durees,
    } = projects;
    const {
      search,
      region,
      academy,
      percent,
      type,
      saison,
      status,
      duree,
      userProjects,
    } = filter;

    const actions = [
      {
        label: 'project_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_project'), () =>
            this.onDeleteProject(id)
          ),
      },
      {
        label: 'project_archive_action',
        action: id =>
          showPopup(
            strings('confirm_archive_projects'),
            () => this.onArchiveProject(id),
            strings('confirm_warning')
          ),
      },
      {
        label: 'project_access_action',
        action: id =>
          window.open(
            `${process.env.REACT_APP_DASHBOARD_URL}/projects/${id}`,
            '_blank'
          ),
      },
    ];
    return (
      <ScreenWrapper id={'project'}>
        <Filter
          strings={strings}
          label={`${strings('projects')} (${all.length})`}
          pourcent={percentFilters}
          status={projetStatusFilters}
          academies={regionAcademies}
          regions={regions}
          types={types}
          saisons={saisons}
          durations={durees}
          currentSearch={search}
          currentRegion={region}
          currentAcademy={academy}
          currentPourcent={percent}
          currentType={type}
          currentSaison={saison}
          currentStatus={status}
          currentDuration={duree}
          onSearchChange={this.onFiltersChange('search')}
          onStatusChange={this.onFiltersChange('status')}
          onRegionChange={this.onFiltersChange('region')}
          onAcademyChange={this.onFiltersChange('academy')}
          onPourcentChange={this.onFiltersChange('percent')}
          onTypeChange={this.onFiltersChange('type')}
          onSaisonChange={this.onFiltersChange('saison')}
          onDurationChange={this.onFiltersChange('duree')}
        />
        <div className={'checkboxes'}>
          <CheckBox
            onCheck={this.onSelectAllChange}
            isChecked={selectAll}
            label={strings('select_all')}
          />
          <CheckBox
            onCheck={this.onMyProjectsChange}
            isChecked={userProjects}
            label={strings('my_projects')}
          />
        </div>
        <ListWrapper
          strings={strings}
          className={`list-wrapper`}
          currentPage={page}
          pageCount={pageCount}
          onPageChange={this.onPageChange}
        >
          {all.length > 0 ? (
            listed.map((item, key) => (
              <ProjectListItem
                key={key}
                strings={strings}
                item={item}
                isChecked={projectsSelected.some(p => p === item.id)}
                onCheck={this.onCheckChange}
                onClick={this.onClick}
                actions={actions}
              />
            ))
          ) : (
            <p className={'no-list'}>{strings('no_projects')}</p>
          )}
        </ListWrapper>
        <ActionsBar
          isBoUserAdmin={isBoUserAdmin}
          strings={strings}
          onClickDownloadArchive={this.onClickDownloadArchive}
          onClickDownload={this.onClickDownload}
          onClickCreate={this.goToCreateProject}
        />
      </ScreenWrapper>
    );
  }
}
