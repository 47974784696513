import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { MentorsActions } from '../../../../redux/mentors';
import { MentorCreation } from './MentorCreation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  createMentor: mentor => dispatch(MentorsActions.createMentorRequested(mentor)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorCreation);
