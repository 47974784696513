import { createAction } from '../../utils';

// Types
export const types = {
  GET_SUCCESSES_REQUESTED: 'GET_SUCCESSES_REQUESTED',
  GET_SUCCESSES_SUCCEEDED: 'GET_SUCCESSES_SUCCEEDED',
  CREATE_SUCCESSES_REQUESTED: 'CREATE_SUCCESSES_REQUESTED',
  GET_OPERATIONS_REQUESTED: 'GET_OPERATIONS_REQUESTED',
  GET_OPERATIONS_SUCCEEDED: 'GET_OPERATIONS_SUCCEEDED',
  CREATE_OPERATIONS_REQUESTED: 'CREATE_OPERATIONS_REQUESTED',
  CREATE_OPERATIONS_SUCCEEDED: 'CREATE_OPERATIONS_SUCCEEDED',
  GET_CATEGORIES_REQUESTED: 'GET_CATEGORIES_REQUESTED',
  GET_CATEGORIES_SUCCEEDED: 'GET_CATEGORIES_SUCCEEDED',
  CREATE_CATEGORIES_REQUESTED: 'CREATE_CATEGORIES_REQUESTED',
  CREATE_CATEGORIES_SUCCEEDED: 'CREATE_CATEGORIES_SUCCEEDED',
  GET_LEVELS_REQUESTED: 'GET_LEVELS_REQUESTED',
  GET_LEVELS_SUCCEEDED: 'GET_LEVELS_SUCCEEDED',
  CREATE_LEVELS_REQUESTED: 'CREATE_LEVELS_REQUESTED',
  CREATE_LEVELS_SUCCEEDED: 'CREATE_LEVELS_SUCCEEDED',
  GET_SECTORS_REQUESTED: 'GET_SECTORS_REQUESTED',
  GET_SECTORS_SUCCEEDED: 'GET_SECTORS_SUCCEEDED',
  CREATE_SECTORS_REQUESTED: 'CREATE_SECTORS_REQUESTED',
  CREATE_SECTORS_SUCCEEDED: 'CREATE_SECTORS_SUCCEEDED',
  GET_TIMES_REQUESTED: 'GET_TIMES_REQUESTED',
  GET_TIMES_SUCCEEDED: 'GET_TIMES_SUCCEEDED',
  CREATE_TIMES_REQUESTED: 'CREATE_TIMES_REQUESTED',
  CREATE_TIMES_SUCCEEDED: 'CREATE_TIMES_SUCCEEDED',
  GET_LOGS_REQUESTED: 'GET_LOGS_REQUESTED',
  GET_LOGS_SUCCEEDED: 'GET_LOGS_SUCCEEDED',
  CREATE_LOGS_REQUESTED: 'CREATE_LOGS_REQUESTED',

};

// Actions
export default {
  getSuccessesRequested: () => createAction(types.GET_SUCCESSES_REQUESTED),
  getSuccessesSucceeded: (list) => createAction(types.GET_SUCCESSES_SUCCEEDED, {list}),
  createSuccessesRequested: (successType, list) => createAction(types.CREATE_SUCCESSES_REQUESTED, {successType, list}),
  getOperationsRequested: () => createAction(types.GET_OPERATIONS_REQUESTED),
  getOperationsSucceeded: (list) => createAction(types.GET_OPERATIONS_SUCCEEDED, {list}),
  createOperationsRequested: (list) => createAction(types.CREATE_OPERATIONS_REQUESTED, {list}),
  createOperationsSucceeded: (list) => createAction(types.CREATE_OPERATIONS_SUCCEEDED, {list}),
  getCategoriesRequested: () => createAction(types.GET_CATEGORIES_REQUESTED),
  getCategoriesSucceeded: (list) => createAction(types.GET_CATEGORIES_SUCCEEDED, {list}),
  createCategoriesRequested: (list) => createAction(types.CREATE_CATEGORIES_REQUESTED, {list}),
  createCategoriesSucceeded: (list) => createAction(types.CREATE_CATEGORIES_SUCCEEDED, {list}),
  getLevelsRequested: () => createAction(types.GET_LEVELS_REQUESTED),
  getLevelsSucceeded: (list) => createAction(types.GET_LEVELS_SUCCEEDED, {list}),
  createLevelsRequested: (list) => createAction(types.CREATE_LEVELS_REQUESTED, {list}),
  createLevelsSucceeded: (list) => createAction(types.CREATE_LEVELS_SUCCEEDED, {list}),
  getSectorsRequested: () => createAction(types.GET_SECTORS_REQUESTED),
  getSectorsSucceeded: (list) => createAction(types.GET_SECTORS_SUCCEEDED, {list}),
  createSectorsRequested: (list) => createAction(types.CREATE_SECTORS_REQUESTED, {list}),
  createSectorsSucceeded: (list) => createAction(types.CREATE_SECTORS_SUCCEEDED, {list}),

  getTimesRequested: () => createAction(types.GET_TIMES_REQUESTED),
  getTimesSucceeded: (list) => createAction(types.GET_TIMES_SUCCEEDED, {list}),
  createTimesRequested: (list) => createAction(types.CREATE_TIMES_REQUESTED, {list}),
  createTimesSucceeded: (list) => createAction(types.CREATE_TIMES_SUCCEEDED, {list}),

  getLogsRequested: () => createAction(types.GET_LOGS_REQUESTED),
  getLogsSucceeded: (list) => createAction(types.GET_LOGS_SUCCEEDED, {list}),
  createLogsRequested: (list) => createAction(types.CREATE_LOGS_REQUESTED, {list}),
};
