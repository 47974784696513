import React, { PureComponent } from "react";
import { icons } from "../../../../assets";
import { userRole } from "../../../../utils";
import { CheckBox } from "../../../components";
import { ActionPopup } from "../../../components/popups";
import "./list.scss";

export class ListItem extends PureComponent {
  state = {
    displayActions: false,
  };

  getDetail = (key, value, displayContact) => {
    const { strings } = this.props;
    switch (key) {
      case "dateDemande": {
        return (
          <div className={"date_demande "}>
            <i className={"material-icons"}>{icons.CALENDAR}</i>
            <p>{value ? value : ""}</p>
          </div>
        );
      }
      case "phone": {
        return (
          displayContact &&
          <div className={"phone"}>
          <i className={"material-icons"}>{icons.PHONE}</i>
          <p>{value ? value : ""}</p>
        </div>
        );
      }
      case "email": {
        return (
          displayContact &&
          <div className={"email"}>
            <i className={"material-icons"}>{icons.MAIL}</i>
            <p>{value ? value : ""}</p>
          </div>
        );
      }
      case "academy": {
        return (
          <div className={"academy"}>
            <p>{`${strings("academy")}:`}</p>
            <p className={"bold"}>{value ? value : ""}</p>
          </div>
        );
      }
      case "place": {
        return <p className={"place"}>{value ? value : ""}</p>;
      }
      default: {
        return <p>{value ? value : ""}</p>;
      }
    }
  };

  onToggleActions = (e) => {
    e && e.stopPropagation();
    const { displayActions } = this.state;
    this.setState({ displayActions: !displayActions });
  };

  render() {
    const {
      strings,
      onCheck,
      item,
      isChecked,
      actions,
      onClick,
      isSelectUser,
      index,
    } = this.props;
    const { displayActions } = this.state;
    return (
      <div id={"list-item"}>
        {onCheck && (
          <div className={"check"}>
            <CheckBox onCheck={onCheck(item.id)} isChecked={isChecked} />
          </div>
        )}
        <div className={"content"} onClick={onClick(item.id)}>
          <div className={"header"}>
            <div className={`label bold`}>
              <p>{item.title}</p>
            </div>
            <div className={"info"}>
              {Object.entries(item.details).map(([key, value]) => (
                <div key={key} className={"element"}>
                  {this.getDetail(key, value, item.displayContact)}
                </div>
              ))}
              {actions && (
                <div className={"options"} onClick={this.onToggleActions}>
                  <i className={"material-icons"}>{icons.THREE_DOT}</i>
                </div>
              )}
            </div>
          </div>
          {item.members && (
            <div className={"footer"}>
              <div className={"members"}>
                {!isSelectUser
                  ? Object.entries(item.members).map(([key, value]) => (
                      <div key={key} className={"item"}>
                        <div className={`pins ${key}`}>{value}</div>
                        <p className={"member-poste"}>{strings(key)}</p>
                      </div>
                    ))
                  : Object.entries(item.members).map(
                      ([key, value]) =>
                        item.role === value && (
                          <div key={key} className={"item"}>
                            <div className={`pins otther ${key}`}>{value}</div>
                            {/* <p className={'member-poste'}>{strings(key)}</p> */}
                          </div>
                        )
                    )}
              </div>
              {item.status && (
                <div className={"status"}>
                  <p>{strings("status")}</p>
                  <div className={`state ${item.status}`} />
                </div>
              )}
            </div>
          )}
        </div>
        {displayActions && (
          <ActionPopup
            display={true}
            userConcerned={item.id}
            tag={""}
            onClose={this.onToggleActions}
            items={actions}
          />
        )}
      </div>
    );
  }
}
