import {methods, url} from '../utils';
import {service} from "./middleware";

const routes = {
  getRegions: url.GET_REGIONS
};

export default {
  getRegions: () => service(methods.GET, routes.getRegions, {}, {}),
};
