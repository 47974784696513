import {createSelector} from 'reselect';
import { projectTypeKeys } from '../../utils';

const successes = state => state.params.successes || [];

const getSuccessesByType = (type) => createSelector(successes, (successes) => successes.filter(success => success.type === type));

export default {
  getSuccessesM9: getSuccessesByType(projectTypeKeys.M9),
  getSuccessesM13: getSuccessesByType(projectTypeKeys.M13),
  getSuccessesL: getSuccessesByType(projectTypeKeys.L),
  getSuccessesXL: getSuccessesByType(projectTypeKeys.XL),
  getSuccessesS: getSuccessesByType(projectTypeKeys.S),
  getSuccessesChallenge: getSuccessesByType(projectTypeKeys.X10),
};
