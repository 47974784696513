import {methods, url} from '../utils';
import {authenticatedService} from "./middleware";

const routes = {
  getSupervisors: (adminId) => url.LIST_SUPERVISORS(adminId),
  getSupervisorDetail: (adminId, id) => `/admin/${adminId}/supervisors/${id}`,
  linkProject: (adminId, userId) => url.SUPERVISOR_LINK_PROJECT(adminId, userId),
  unlinkProject: (adminId, userId) => url.SUPERVISOR_UNLINK_PROJECT(adminId, userId),
  exportSupervisors: (adminId) => url.EXPORT_SUPERVISORS(adminId)
};

export default {
  getSupervisors: (adminId) => authenticatedService(methods.GET, routes.getSupervisors(adminId), {}, {}),
  getSupervisorDetail: (adminId, id) => authenticatedService(methods.GET, routes.getSupervisorDetail(adminId, id), {}, {}),
  linkProject: (adminId, userId, projectId) => authenticatedService(methods.PUT, routes.linkProject(adminId, userId), {projectId}, {}),
  unlinkProject: (adminId, userId, projectId) => authenticatedService(methods.PUT, routes.unlinkProject(adminId, userId), {projectId}, {}),
  deleteSupervisor: (adminId, supervisorId) => authenticatedService(methods.DELETE, routes.getSupervisorDetail(adminId, supervisorId), {}, {}),
  updateSupervisor: (adminId, supervisorId, body) => authenticatedService(methods.PATCH, routes.getSupervisorDetail(adminId, supervisorId), {...body}, {}),
  createSupervisor: (adminId, body) => authenticatedService(methods.PUT, routes.getSupervisors(adminId), {...body}, {}),
  exportSupervisors: (adminId) => authenticatedService(methods.GET, routes.exportSupervisors(adminId), {}, {}, {}, 'blob'),
};
