import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { MentorsActions, MentorsSelectors } from '../../../../redux/mentors';
import { SchoolsSelectors } from '../../../../redux/schools';
import { SupervisorsActions, SupervisorsSelectors } from '../../../../redux/supervisors';
import { userRole } from '../../../../utils';
import { ProjectCreation } from './ProjectCreation';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  mentors: (page, search) => MentorsSelectors.filterMentors(getTranslate(state.locale), state.mentors.list, page, search, null, null, false),
  supervisors: (page, search) => SupervisorsSelectors.filterSupervisors(getTranslate(state.locale), state.supervisors.list, page, search, null, null, null, false),
  schoolMentors: (page, search) => SchoolsSelectors.getSchoolUsers(page, search, userRole.MENTOR)(state),
  schoolSupervisors: (page, search) => SchoolsSelectors.getSchoolUsers(page, search, userRole.SUPERVISOR)(state),
});

const mapDispatchToProps = dispatch => ({
  getMentors: () => dispatch(MentorsActions.getMentorsRequested()),
  getSupervisors: () => dispatch(SupervisorsActions.getSupervisorsRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreation);
