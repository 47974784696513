import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Switch = ({ label, checked = false, handleCheckChange }) => {
  const onCheckChange = e => handleCheckChange(e.target.checked)

  return (
    <div className={'switch-wrapper'}>
      <label className="switch">
        <input type="checkbox" onChange={onCheckChange} checked={checked}/>
        <span className="slider round"></span>
      </label>
      <p>{label}</p>
    </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  handleCheckChange: PropTypes.func
};

Switch.defaultProps = {
  label: null,
  handleCheckChange: () => console.log("Not implemented")
};

export default Switch;
