import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthSelectors } from '../../../../redux/auth';
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import {
  FreemiumsActions,
  FreemiumsSelectors,
} from '../../../../redux/freemiums';

import { SnackActions } from '../../../../redux/snackBar';
import {
  formatEnumForSelector,
  formatRegionsSelector,
  userStatus,
} from '../../../../utils';
import { FreemiumsList } from './FreemiumsList';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  freemiums: FreemiumsSelectors.getFreemiums()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  statusFilter: formatEnumForSelector(getTranslate(state.locale), userStatus),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message, popupAction) =>
    dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  getFreemiums: () => dispatch(FreemiumsActions.getFreemiumsRequested()),
  setCurrentFilter: (page, filter) =>
    dispatch(FreemiumsActions.setFreemiumsCurrentFilter(page, filter)),
  deleteFreemium: id => dispatch(FreemiumsActions.deleteFreemiumRequested(id)),
  exportList: () => dispatch(FreemiumsActions.exportFreemiumsRequested()),
  navigateTo: path => dispatch(push(path)),
  setActionSnackBar: (snackAction, actionMessage) =>
    dispatch(SnackActions.setAction(snackAction, actionMessage)),
  showSnackBar: message => dispatch(SnackActions.displayInfo(message)),
  hideSnackBar: () => dispatch(SnackActions.hideSnackBar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreemiumsList);
