import {
  FacilitatorCreation,
  FacilitatorDetail,
  FacilitatorsList,
  FreemiumCreation,
  FreemiumDetail,
  FreemiumsList,
  Inscriptions,
  LegalMentions,
  MentorCreation,
  MentorsList,
  MentorWrapperDetails,
  MiniDetail,
  MiniList,
  NotificationCreation,
  NotificationList,
  Params,
  ProjectCreation,
  ProjectDetail,
  ProjectsList,
  Reinscription,
  SchoolCreation,
  SchoolList,
  SchoolWrapperDetails,
  SupervisorCreation,
  SupervisorDetail,
  SupervisorsList,
} from '../ui/screens';
import { Routes } from '../utils';

export default {
  miniList: {
    path: Routes.MINI_LIST,
    component: MiniList,
  },
  miniCreation: {
    path: Routes.MINI_CREATION,
    component: MiniDetail,
  },
  miniDetail: {
    path: Routes.MINI_DETAIL,
    component: MiniDetail,
  },
  projectCreation: {
    path: Routes.PROJECT_CREATION,
    component: ProjectCreation,
  },
  facilitatorCreation: {
    path: Routes.FACILITATOR_CREATION,
    component: FacilitatorCreation,
  },
  freemiumCreation: {
    path: Routes.FREEMIUM_CREATION,
    component: FreemiumCreation,
  },
  supervisorCreation: {
    path: Routes.SUPERVISOR_CREATION,
    component: SupervisorCreation,
  },
  mentorCreation: {
    path: Routes.MENTOR_CREATION,
    component: MentorCreation,
  },
  projet: {
    path: Routes.PROJECT,
    component: ProjectDetail,
  },
  school: {
    path: Routes.SCHOOLS_LIST,
    component: SchoolList,
  },
  supervisors: {
    path: Routes.SUPERVISORS_LIST,
    component: SupervisorsList,
  },
  mentors: {
    path: Routes.MENTORS_LIST,
    component: MentorsList,
  },
  facilitators: {
    path: Routes.FACILITATORS_LIST,
    component: FacilitatorsList,
  },
  freemiums: {
    path: Routes.FREEMIUMS_LIST,
    component: FreemiumsList,
  },
  projects: {
    path: Routes.PROJECTS_LIST,
    component: ProjectsList,
  },
  params: {
    path: Routes.ADMIN,
    component: Params,
    isAdmin: true,
  },
  inscriptions: {
    path: Routes.INSCRIPTIONS,
    component: Inscriptions,
  },
  facilitatorDetails: {
    path: Routes.FACILITATOR,
    component: FacilitatorDetail,
  },
  freemiumDetail: {
    path: Routes.FREEMIUM,
    component: FreemiumDetail,
  },
  mentorDetails: {
    path: Routes.MENTOR,
    component: MentorWrapperDetails,
  },
  supervisorDetails: {
    path: Routes.SUPERVISOR,
    component: SupervisorDetail,
  },
  schoolCreation: {
    path: Routes.SCHOOL_CREATION,
    component: SchoolCreation,
  },
  schoolDetails: {
    path: Routes.SCHOOL,
    component: SchoolWrapperDetails,
  },
  schoolReinscription: {
    path: Routes.SCHOOL_REINSCRIPTION,
    component: Reinscription,
  },
  schoolDetailsInscriptions: {
    path: Routes.INSCRIPTIONS_SCHOOL,
    component: SchoolWrapperDetails,
  },
  schoolDetailsReinscriptions: {
    path: Routes.REINSCRIPTIONS_SCHOOL,
    component: SchoolWrapperDetails,
  },
  mentorDetailsInscriptions: {
    path: Routes.INSCRIPTIONS_MENTOR,
    component: MentorWrapperDetails,
  },
  legalMentions: {
    path: Routes.LEGAL_MENTIONS,
    component: LegalMentions,
  },
  notificationCreation: {
    path: Routes.NOTIFICATION_CREATION,
    component: NotificationCreation,
  },
  notificationList: {
    path: Routes.NOTIFICATION_LIST,
    component: NotificationList,
  },
};
