import {createReducer} from 'reduxsauce';
import {types} from './actions';


const initialState = {
  list: [],
  currentFilter: null,
  facilitator: {
    id: null,
    civility: null,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    region: null,
    role: null,
    rights: null,
    isAdmin: false,
    projects: [],
    schools: []
  }
};

const getFacilitatorsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, list}
};

const setFacilitatorsCurrentFilter = (state = initialState, action) => {
  const {page, filter} = action;
  return {...state, currentFilter: {page, filter}};
};

const linkProjectFacilitatorSucceeded = (state = initialState, action) => {
  const {facilitator} = action;
  return {...state, facilitator}
};

const unlinkProjectFacilitatorSucceeded = (state = initialState, action) => {
  const {facilitator} = action;
  return {...state, facilitator}
};

const getFacilitatorDetailSucceeded = (state = initialState, action) => {
  const { facilitator } = action;
  return { ...state, facilitator}
};

const deleteFacilitatorSucceeded = (state = initialState, action) => {
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const facilitatorIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(facilitatorIndex, 1);
  return {...state, list: copyList, facilitator: state.facilitator && state.facilitator.id === id ? initialState.facilitator:  state.facilitator};
};

const updateFacilitatorSucceeded = (state = initialState, action) => {
  const {facilitator} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const facilitatorIndex = copyList.findIndex(s => s.id === facilitator.id);
  copyList[facilitatorIndex] = facilitator;
  return {...state, list: copyList, facilitator: state.facilitator.id === facilitator.id ? facilitator:  state.facilitator};
};

const createFacilitatorSucceeded = (state = initialState, action) => {
  const {facilitator}= action;
  return {...state, facilitator};
};

const resetFacilitatorDetail = (state = initialState) => {
  return {...state, facilitator: initialState.facilitator};
};

export default createReducer(initialState, {
  [types.GET_FACILITATORS_SUCCEEDED] : getFacilitatorsSucceeded,
  [types.SET_FACILITATORS_CURRENT_FILTER] : setFacilitatorsCurrentFilter,
  [types.GET_FACILITATOR_DETAIL_SUCCEEDED]: getFacilitatorDetailSucceeded,
  [types.LINK_PROJECT_FACILITATOR_SUCCEEDED] : linkProjectFacilitatorSucceeded,
  [types.UNLINK_PROJECT_FACILITATOR_SUCCEEDED] : unlinkProjectFacilitatorSucceeded,
  [types.DELETE_FACILITATOR_SUCCEEDED] : deleteFacilitatorSucceeded,
  [types.UPDATE_FACILITATOR_SUCCEEDED] : updateFacilitatorSucceeded,
  [types.CREATE_FACILITATOR_SUCCEEDED] : createFacilitatorSucceeded,
  [types.RESET_FACILITATOR_DETAIL] : resetFacilitatorDetail
});
