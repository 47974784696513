import React from 'react';
import './LogsTable.scss';

const formatDateFR = (dateString) => {
  const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('fr-FR', options);
};
const LogsTable = ({ logs }) => (

    <table className="logs-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>User_id</th>
        <th>Type</th>
        <th>Route</th>
        <th>Titre</th>
        <th>Message</th>
        <th>Date</th>
      </tr>
      </thead>
      <tbody>
      {logs.map((log) => (
          <tr key={log.key} className={log.type === "Succès" ? "log-success" : "log-failure"}>
            <td>{log.id}</td>
            <td>{log.user_id}</td>
            <td>{log.type}</td>
            <td>{log.route_concerned}</td>
            <td>{log.title}</td>
            <td>{log.message}</td>
            <td>{formatDateFR(log.createdAt)}</td>
          </tr>
      ))}
      </tbody>
    </table>
);

export default LogsTable;
