import React from 'react';
import { User } from '../../../index';
import { validateUserForm } from './FormValidator';

export const MentorDetail = ({strings, pop, mentor, updateUser, linkProjects, unlinkProject, projectsData, fromInscription = false}) => {

  return <User
    user={mentor}
    validateUserForm={validateUserForm}
    pop={pop}
    back={strings('all_mentor')}
    updateUser={updateUser}
    projectsData={projectsData}
    fromInscription={fromInscription}
    linkProjects={(projectsId) => linkProjects(mentor.id, projectsId)}
    unlinkProject={(projectId) => unlinkProject(mentor.id, projectId)}
  />
};
