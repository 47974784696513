import React from 'react';
import { getProjectImage, projectDuration } from '../../../../utils';
import { Button, ButtonColors, CheckBox, UserItem } from '../../../components';

export const ProjectSelection = React.memo(({ strings, projects, selectedProjects, handleProjectCheckChanged, handleChangeStep }) => {
  return (
    <>
      <div className={'header'}>
        <div className={'title'}>
          <p className={'step'}>{strings('step_one_on_two')}</p>
          <p className={'label'}>{strings('select_projects')}</p>
        </div>
        <p className={'subtitle'}>{strings('select_projects_warning')}</p>
      </div>
      <div className={'horizontal-dot-separator'} />
      <div className={'existing-projects'}>
        <ul>
          {projects.map((project) => (
            <li key={project.id}>
              <div className={'header'}>
                <CheckBox onCheck={handleProjectCheckChanged(project.id)} isChecked={selectedProjects.includes(project.id)} />
                <p className={'name'}>{project.name}</p>
                <p className={'duration'}>{project.duration !== projectDuration.ONE_YEAR ? `${project.duration} ${strings('duration_several_years')}` : ''}</p>
                <div className={'type'}>{getProjectImage(strings, project.type)}</div>
              </div>
              <div className={'horizontal-dot-separator'} />
              <ul className={'users'}>
                {project.users &&
                  project.users.map((user) => (
                    <div key={user.id}>
                      <UserItem user={user} strings={strings} />
                    </div>
                  ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
      <p className={'validation-warning'}>{strings('select_projects_final_warning')}</p>
      <div className={'actions'}>
        <Button color={ButtonColors.green} label={strings('validate_go_next_step')} action={() => handleChangeStep(+1)} />
      </div>
    </>
  );
});
