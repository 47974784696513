import { createAction } from '../../utils';

// Types
export const types = {
  GET_SCHOOL_INSCRIPTIONS_REQUESTED: 'GET_SCHOOL_INSCRIPTIONS_REQUESTED',
  GET_SCHOOL_INSCRIPTIONS_SUCCEEDED: 'GET_SCHOOL_INSCRIPTIONS_SUCCEEDED',
  GET_SCHOOL_REINSCRIPTIONS_REQUESTED: 'GET_SCHOOL_REINSCRIPTIONS_REQUESTED',
  GET_SCHOOL_REINSCRIPTIONS_SUCCEEDED: 'GET_SCHOOL_REINSCRIPTIONS_SUCCEEDED',
  SET_INSCRIPTION_CURRENT_TAB: 'SET_INSCRIPTION_CURRENT_TAB',
  RESET_INSCRIPTION_CURRENT_TAB: 'RESET_INSCRIPTION_CURRENT_TAB',
  GET_SCHOOL_DETAIL_INSCRIPTIONS_REQUESTED: 'GET_SCHOOL_DETAIL_INSCRIPTIONS_REQUESTED',
  GET_SCHOOL_DETAIL_INSCRIPTIONS_SUCCEEDED: 'GET_SCHOOL_DETAIL_INSCRIPTIONS_SUCCEEDED',
  GET_SCHOOL_DETAIL_REINSCRIPTIONS_REQUESTED: 'GET_SCHOOL_DETAIL_REINSCRIPTIONS_REQUESTED',
  GET_SCHOOL_DETAIL_REINSCRIPTIONS_SUCCEEDED: 'GET_SCHOOL_DETAIL_REINSCRIPTIONS_SUCCEEDED',
  UPDATE_SCHOOL_INSCRIPTIONS_REQUESTED: 'UPDATE_SCHOOL_INSCRIPTIONS_REQUESTED',
  UPDATE_SCHOOL_INSCRIPTIONS_SUCCEEDED: 'UPDATE_SCHOOL_INSCRIPTIONS_SUCCEEDED',
  UPDATE_SCHOOL_REINSCRIPTIONS_REQUESTED: 'UPDATE_SCHOOL_REINSCRIPTIONS_REQUESTED',
  UPDATE_SCHOOL_REINSCRIPTIONS_SUCCEEDED: 'UPDATE_SCHOOL_REINSCRIPTIONS_SUCCEEDED',
  EDIT_SCHOOL_INSCRIPTION_CONVENTION_REQUESTED: 'EDIT_SCHOOL_INSCRIPTION_CONVENTION_REQUESTED',
  EDIT_SCHOOL_INSCRIPTION_CONVENTION_SUCCEEDED: 'EDIT_SCHOOL_INSCRIPTION_CONVENTION_SUCCEEDED',
  EDIT_SCHOOL_REINSCRIPTION_CONVENTION_REQUESTED: 'EDIT_SCHOOL_REINSCRIPTION_CONVENTION_REQUESTED',
  EDIT_SCHOOL_REINSCRIPTION_CONVENTION_SUCCEEDED: 'EDIT_SCHOOL_REINSCRIPTION_CONVENTION_SUCCEEDED',
  VALIDATE_SCHOOL_INSCRIPTION_REQUESTED: 'VALIDATE_SCHOOL_INSCRIPTION_REQUESTED',
  VALIDATE_SCHOOL_INSCRIPTION_SUCCEEDED: 'VALIDATE_SCHOOL_INSCRIPTION_SUCCEEDED',
  VALIDATE_SCHOOL_REINSCRIPTION_REQUESTED: 'VALIDATE_SCHOOL_REINSCRIPTION_REQUESTED',
  VALIDATE_SCHOOL_REINSCRIPTION_SUCCEEDED: 'VALIDATE_SCHOOL_REINSCRIPTION_SUCCEEDED',
  DELETE_SCHOOL_INSCRIPTION_REQUESTED: 'DELETE_SCHOOL_INSCRIPTION_REQUESTED',
  DELETE_SCHOOL_INSCRIPTION_SUCCEEDED: 'DELETE_SCHOOL_INSCRIPTION_SUCCEEDED',
  DELETE_SCHOOL_REINSCRIPTION_REQUESTED: 'DELETE_SCHOOL_REINSCRIPTION_REQUESTED',
  DELETE_SCHOOL_REINSCRIPTION_SUCCEEDED: 'DELETE_SCHOOL_REINSCRIPTION_SUCCEEDED',
  GET_MENTOR_INSCRIPTIONS_REQUESTED: 'GET_MENTOR_INSCRIPTIONS_REQUESTED',
  GET_MENTOR_INSCRIPTIONS_SUCCEEDED: 'GET_MENTOR_INSCRIPTIONS_SUCCEEDED',
  VALIDATE_MENTOR_INSCRIPTION_REQUESTED: 'VALIDATE_MENTOR_INSCRIPTION_REQUESTED',
  VALIDATE_MENTOR_INSCRIPTION_SUCCEEDED: 'VALIDATE_MENTOR_INSCRIPTION_SUCCEEDED',
  DELETE_MENTOR_INSCRIPTION_REQUESTED: 'DELETE_MENTOR_INSCRIPTION_REQUESTED',
  DELETE_MENTOR_INSCRIPTION_SUCCEEDED: 'DELETE_MENTOR_INSCRIPTION_SUCCEEDED',
  GET_MENTOR_DETAIL_INSCRIPTIONS_REQUESTED: 'GET_MENTOR_DETAIL_INSCRIPTIONS_REQUESTED',
  GET_MENTOR_DETAIL_INSCRIPTIONS_SUCCEEDED: 'GET_MENTOR_DETAIL_INSCRIPTIONS_SUCCEEDED',
  UPDATE_MENTOR_INSCRIPTIONS_REQUESTED: 'UPDATE_MENTOR_INSCRIPTIONS_REQUESTED',
  UPDATE_MENTOR_INSCRIPTIONS_SUCCEEDED: 'UPDATE_MENTOR_INSCRIPTIONS_SUCCEEDED',
  RESET_SCHOOL_DETAIL_INSCRIPTION: 'RESET_SCHOOL_DETAIL_INSCRIPTION',
  RESET_MENTOR_DETAIL_INSCRIPTION: 'RESET_MENTOR_DETAIL_INSCRIPTION',
};

// Actions
export default {
  getSchoolInscriptionsRequested: (inBackground = true) => createAction(types.GET_SCHOOL_INSCRIPTIONS_REQUESTED, {inBackground}),
  getSchoolInscriptionsSucceeded: (schools) => createAction(types.GET_SCHOOL_INSCRIPTIONS_SUCCEEDED, {schools}),
  getSchoolReinscriptionsRequested: (inBackground = true) => createAction(types.GET_SCHOOL_REINSCRIPTIONS_REQUESTED, {inBackground}),
  getSchoolReinscriptionsSucceeded: (schools) => createAction(types.GET_SCHOOL_REINSCRIPTIONS_SUCCEEDED, {schools}),
  setInscriptionCurrentTab: (tab, page, filter, subTab) => createAction(types.SET_INSCRIPTION_CURRENT_TAB, {tab, page, filter, subTab}),
  resetInscriptionCurrentTab: () => createAction(types.RESET_INSCRIPTION_CURRENT_TAB),
  getSchoolDetailInscriptionsRequested: id => createAction(types.GET_SCHOOL_DETAIL_INSCRIPTIONS_REQUESTED, {id}),
  getSchoolDetailInscriptionsSucceeded: school => createAction(types.GET_SCHOOL_DETAIL_INSCRIPTIONS_SUCCEEDED, {school}),
  getSchoolDetailReinscriptionsRequested: id => createAction(types.GET_SCHOOL_DETAIL_REINSCRIPTIONS_REQUESTED, {id}),
  getSchoolDetailReinscriptionsSucceeded: school => createAction(types.GET_SCHOOL_DETAIL_REINSCRIPTIONS_SUCCEEDED, {school}),
  updateSchoolInscriptionsRequested: school => createAction(types.UPDATE_SCHOOL_INSCRIPTIONS_REQUESTED, {school}),
  updateSchoolInscriptionsSucceeded: school => createAction(types.UPDATE_SCHOOL_INSCRIPTIONS_SUCCEEDED, {school}),
  updateSchoolReinscriptionsRequested: school => createAction(types.UPDATE_SCHOOL_REINSCRIPTIONS_REQUESTED, {school}),
  updateSchoolReinscriptionsSucceeded: school => createAction(types.UPDATE_SCHOOL_REINSCRIPTIONS_SUCCEEDED, {school}),
  editSchoolInscriptionConventionRequested: id => createAction(types.EDIT_SCHOOL_INSCRIPTION_CONVENTION_REQUESTED, {id}),
  editSchoolInscriptionConventionSucceeded: id => createAction(types.EDIT_SCHOOL_INSCRIPTION_CONVENTION_SUCCEEDED, {id}),
  editSchoolReinscriptionConventionRequested: id => createAction(types.EDIT_SCHOOL_REINSCRIPTION_CONVENTION_REQUESTED, {id}),
  editSchoolReinscriptionConventionSucceeded: id => createAction(types.EDIT_SCHOOL_REINSCRIPTION_CONVENTION_SUCCEEDED, {id}),
  validateSchoolInscriptionsRequested: id => createAction(types.VALIDATE_SCHOOL_INSCRIPTION_REQUESTED, {id}),
  validateSchoolInscriptionsSucceeded: school => createAction(types.VALIDATE_SCHOOL_INSCRIPTION_SUCCEEDED, {school}),
  validateSchoolReinscriptionsRequested: id => createAction(types.VALIDATE_SCHOOL_REINSCRIPTION_REQUESTED, {id}),
  validateSchoolReinscriptionsSucceeded: school => createAction(types.VALIDATE_SCHOOL_REINSCRIPTION_SUCCEEDED, {school}),
  deleteSchoolInscriptionsRequested: id => createAction(types.DELETE_SCHOOL_INSCRIPTION_REQUESTED, {id}),
  deleteSchoolInscriptionsSucceeded: id => createAction(types.DELETE_SCHOOL_INSCRIPTION_SUCCEEDED, {id}),
  deleteSchoolReinscriptionsRequested: id => createAction(types.DELETE_SCHOOL_REINSCRIPTION_REQUESTED, {id}),
  deleteSchoolReinscriptionsSucceeded: id => createAction(types.DELETE_SCHOOL_REINSCRIPTION_SUCCEEDED, {id}),
  getMentorInscriptionsRequested: (inBackground = true) => createAction(types.GET_MENTOR_INSCRIPTIONS_REQUESTED, {inBackground}),
  getMentorInscriptionsSucceeded: (mentors) => createAction(types.GET_MENTOR_INSCRIPTIONS_SUCCEEDED, {mentors}),
  validateMentorInscriptionsRequested: id => createAction(types.VALIDATE_MENTOR_INSCRIPTION_REQUESTED, {id}),
  validateMentorInscriptionsSucceeded: mentor => createAction(types.VALIDATE_MENTOR_INSCRIPTION_SUCCEEDED, {mentor}),
  deleteMentorInscriptionsRequested: id => createAction(types.DELETE_MENTOR_INSCRIPTION_REQUESTED, {id}),
  deleteMentorInscriptionsSucceeded: id => createAction(types.DELETE_MENTOR_INSCRIPTION_SUCCEEDED, {id}),
  getMentorDetailInscriptionsRequested: id => createAction(types.GET_MENTOR_DETAIL_INSCRIPTIONS_REQUESTED, {id}),
  getMentorDetailInscriptionsSucceeded: mentor => createAction(types.GET_MENTOR_DETAIL_INSCRIPTIONS_SUCCEEDED, {mentor}),
  updateMentorInscriptionsRequested: mentor => createAction(types.UPDATE_MENTOR_INSCRIPTIONS_REQUESTED, {mentor}),
  updateMentorInscriptionsSucceeded: mentor => createAction(types.UPDATE_MENTOR_INSCRIPTIONS_SUCCEEDED, {mentor}),
  resetSchoolDetailInscription: () => createAction(types.RESET_SCHOOL_DETAIL_INSCRIPTION, {}),
  resetMentorDetailInscription: () => createAction(types.RESET_MENTOR_DETAIL_INSCRIPTION, {}),
};
