import React, { PureComponent } from "react";
import "./radioButton.scss";

export default class RadioButton extends PureComponent {
  render() {
    const {
      className,
      label,
      child,
      value,
      isChecked,
      onChange,
      disabled,
    } = this.props;

    return (
      <div
        className={`radio-wrapper ${disabled ? "disabled" : ""} ${
          className ? className : ""
        }`}
        onClick={!disabled ? () => onChange(value) : null}
      >
        <label
          className={`radio-button ${isChecked ? "checked" : ""}`}
          title={"label"}
        >
          {label ? label : " "}
          <span />
          {child && child}
        </label>
      </div>
    );
  }
}
