import React, { useState } from "react";
import "./textArea.scss";

export const TextArea = ({
  strings,
  className,
  tag,
  label,
  maxLength,
  defaultValue,
  inputClassName,
  error,
  disabled,
  isHighlighted = true,
  handleValueChanged,
}) => {
  const [inputValue, setInputValue] = useState('');

  const onValueChange = (event) => {
    const { value } = event.target;
    setInputValue(value);
    handleValueChanged(tag, value);
  };

  const getSpanClass = (valueLength, maxLength) => {
    if (valueLength < maxLength / 2) {
      return "";
    } else if (valueLength === maxLength) {
      return "full";
    } else {
      return "half";
    }
  };

  return (
    <div className={`text-area-wrapper ${className}`}>
      <p className={"label"}>
        {label}
        {maxLength && (
          <span className={getSpanClass(inputValue.length, maxLength)}>
            {`(${inputValue.length}/${maxLength} char)`}
          </span>
        )}
      </p>
      <textarea
        className={`${isHighlighted ? "highlight" : ""} ${
          inputClassName ? inputClassName : ""
        }`}
        maxLength={maxLength}
        rows={disabled ? 0 : 5}
        onChange={onValueChange}
        value={defaultValue}
        disabled={disabled}
      />
      <span
        className={`error ${inputClassName && error ? inputClassName : ""}`}
      >
        {error}
      </span>
    </div>
  );
};
