import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { FreemiumsActions } from '../../../../redux/freemiums';
import { FreemiumDetail } from './FreemiumDetail';

const mapStateToProps = state => ({
  freemium: state.freemiums.freemium,
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  getFreemium: freemiumId => dispatch(FreemiumsActions.getFreemiumDetailRequested(freemiumId)),
  updateFreemium: freemium => dispatch(FreemiumsActions.updateFreemiumRequested(freemium)),
  upgradeFreemium: (id, supervisorInfo) => dispatch(FreemiumsActions.upgradeFreemiumRequested(id, supervisorInfo)),
  resetFreemiumDetail: () => dispatch(FreemiumsActions.resetFreemiumDetail()),
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreemiumDetail);
