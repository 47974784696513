import React from "react";
import { getIcon, InputTypes, REGEX } from "../../../../../utils";
import { Button, ButtonColors, Input } from "../../../../components";

const Tool = ({ strings, tool, roomTool, error, handleToolValueChange }) => {
  const onTestLink = (link) => () =>
    window.open(link, "_blank", "noopener,noreferrer");

  return (
    <div className={"line tool"}>
      <div className={"icon-wrapper"}>{getIcon(tool.icon)}</div>
      <p className={"label"}>{tool.label}</p>
      <Input
        type={InputTypes.TEXT}
        tag={"url"}
        defaultValue={roomTool ? roomTool.url : null}
        placeholder={strings("tool_url")}
        className={`${error && error.errors.url ? "invalid" : ""}`}
        handleValueChanged={handleToolValueChange}
      />
      <Button
        color={ButtonColors.orange}
        label={strings("test")}
        className={"test"}
        enabled={roomTool?.url?.length > 0 && REGEX.URL.test(roomTool?.url)}
        action={
          roomTool?.url?.length > 0 && REGEX.URL.test(roomTool?.url)
            ? onTestLink(roomTool.url)
            : null
        }
      />
    </div>
  );
};

export default Tool;
