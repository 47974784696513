import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { InscriptionService, SchoolsServices } from '../../services';
import {downloadAttachment, ERROR_ALREADY_EXISTS, Routes, schoolStatus} from '../../utils';
import LoaderActions from '../loader/actions';
import SnackActions from '../snackBar/actions';
import { default as SchoolsActions, types } from './actions';
import {CONFLICT} from "http-status-codes";

function* getSchoolDetailRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    SchoolsServices.getSchoolDetail,
    adminId,
    id
  );
  if (!error) {
    yield put(SchoolsActions.getSchoolDetailSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('school_detail_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getSchoolsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(SchoolsServices.getSchools, adminId);
  if (!error) {
    yield put(SchoolsActions.getSchoolsSucceeded(response.data));
    yield put(SchoolsActions.getSchoolsSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('schools_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateSchoolRequested({ school }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    SchoolsServices.updateSchool,
    adminId,
    school.id,
    school
  );

  if (!error) {
    yield put(SchoolsActions.updateSchoolSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('school_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* createSchoolRequested({ school }) {
  yield put(LoaderActions.loading());
  yield put(SchoolsActions.resetCreation());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    SchoolsServices.createSchool,
    adminId,
    school
  );

  if (!error) {
    yield put(SchoolsActions.createSchoolSucceeded(response.data));
    yield put(SnackActions.displayInfo('school_creation_success'));
  } else {
    if (error.response.status === CONFLICT && error.response.data.code === ERROR_ALREADY_EXISTS) {
      yield  put(SnackActions.displayError("school_already_exist"));
    } else {
      yield put(SnackActions.displayError('school_creation_failed'));
    }
  }
  yield put(LoaderActions.loaded());
}

function* deleteSchoolRequested({ schoolId }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(SchoolsServices.deleteSchool, adminId, schoolId);

  if (!error) {
    yield call(getSchoolsRequested);
    if (window.location.pathname !== Routes.SCHOOLS_LIST) {
      window.location = Routes.SCHOOLS_LIST;
    }
    yield put(SnackActions.displayInfo('delete_school_succeeded'));
  } else {
    yield put(SnackActions.displayError('delete_school_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* startReinscriptionRequested({
  schoolId,
  oldProjects,
  newProjects,
  editConvention,
}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, _] = yield call(
    SchoolsServices.reinscription,
    adminId,
    schoolId,
    {
      oldProjects,
      newProjects
    }
  );

  if (!error) {
    if (editConvention) {
      const [error, response] = yield call(
        InscriptionService.editSchoolConvention,
        adminId,
        schoolId,
        true,
        { status: schoolStatus.WAITING }
      );

      if (!error && response.data) {
        downloadAttachment(response);
        yield put(SnackActions.displayInfo('reinscription_succedded'));
        yield delay(500);
      } else {
        yield put(SnackActions.displayError('edit_convention_failed'));
      }
    } else {
      yield put(SnackActions.displayInfo('reinscription_succedded'));
    }
    window.location = Routes.SCHOOLS_LIST;
  } else {
    yield put(SnackActions.displayError('reinscription_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* exportSchoolsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(SchoolsServices.exportSchools, adminId);

  if (response && response.data && !error) {
    downloadAttachment(response);
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_SCHOOLS_REQUESTED, getSchoolsRequested),
  takeLatest(types.GET_SCHOOL_DETAIL_REQUESTED, getSchoolDetailRequested),
  takeLatest(types.UPDATE_SCHOOL_REQUESTED, updateSchoolRequested),
  takeLatest(types.CREATE_SCHOOL_REQUESTED, createSchoolRequested),
  takeLatest(types.DELETE_SCHOOL_REQUESTED, deleteSchoolRequested),
  takeLatest(types.START_REINSCRIPTION_REQUESTED, startReinscriptionRequested),
  takeLatest(types.EXPORT_SCHOOLS_REQUESTED, exportSchoolsRequested),
];
