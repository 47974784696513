import { call, put, select, takeLatest } from 'redux-saga/effects';
import { history } from '../../core/history';
import { FacilitatorsServices, SupervisorsServices } from '../../services';
import { downloadAttachment, Routes } from '../../utils';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { UserActions } from '../user';
import { default as FacilitatorsActions, types } from './actions';
import errorService from "../../services/ErrorService";

function* getFacilitatorsRequested() {
    yield put(LoaderActions.loading());

    const adminId = yield select(state => state.auth.id);
    const [error, response] = yield call(FacilitatorsServices.getFacilitators, adminId);
    if (!error) {
        yield put(FacilitatorsActions.getFacilitatorsSucceeded(response.data));
    } else {
      yield put(SnackActions.displayError('facilitators_retrieval_failed'));
    }
    yield put(LoaderActions.loaded());
}

function* getFacilitatorDetailRequested({facilitatorId}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(FacilitatorsServices.getFacilitatorDetail, id, facilitatorId);
  if (!error) {
    yield put(FacilitatorsActions.getFacilitatorDetailSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('facilitator_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* linkProjectFacilitatorRequested({userId, projectsId}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  let responseCall = [];
  for (let i = 0; i < projectsId.length; i++) {
    const [error, response] = yield call(FacilitatorsServices.linkProject, adminId, userId, projectsId[i]);
    responseCall = responseCall.concat({error, response});
  }

  const error = responseCall.some(item => item.error);

  if (!error) {
    const lastResponse = responseCall[responseCall.length - 1].response;
    yield put(FacilitatorsActions.linkProjectFacilitatorSucceeded(lastResponse.data));
  } else {
    yield put(SnackActions.displayError('one_or_more_link_project_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* unlinkProjectFacilitatorRequested({userId, projectId}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(FacilitatorsServices.unlinkProject, adminId, userId, projectId);

  if (!error) {
    yield put(FacilitatorsActions.linkProjectFacilitatorSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('unlink_project_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteFacilitatorRequested({id}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(FacilitatorsServices.deleteFacilitator, adminId, id);

  if (!error) {
    yield put(FacilitatorsActions.deleteFacilitatorSucceeded(id));
  } else {
    yield put(SnackActions.displayError('facilitator_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateFacilitatorRequested({facilitator}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(FacilitatorsServices.updateFacilitator, adminId, facilitator.id, facilitator);

  if (!error) {
    yield put(FacilitatorsActions.updateFacilitatorSucceeded(response.data));
    //If the updated facilitator is the current user, update the user store info to make sure it is up to date
    if (response.data.id === adminId) {
      const user = {
        id: response.data.id,
        role: response.data.role,
        email: response.data.email,
        civility: response.data.civility,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        phone: response.data.phone,
        rights: response.data.rights,
        region: response.data.region,
      };
      yield put(UserActions.getUserSucceeded(user));
    }
  } else {
    yield put(SnackActions.displayError('facilitator_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* createFacilitatorRequested({facilitator}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(FacilitatorsServices.createFacilitator, adminId, facilitator);

  if (!error) {
    yield put(FacilitatorsActions.createFacilitatorSucceeded(response.data));
    yield call(history.replace, Routes.FACILITATORS_LIST);
  } else {
    yield errorService.displayConflictError(error,'freemium_creation_failed')
  }

  yield put(LoaderActions.loaded());
}

function* exportFacilitatorsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(FacilitatorsServices.exportFacilitators, adminId);

  if (response && response.data && !error) {
    downloadAttachment(response)
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_FACILITATORS_REQUESTED, getFacilitatorsRequested),
  takeLatest(types.GET_FACILITATOR_DETAIL_REQUESTED, getFacilitatorDetailRequested),
  takeLatest(types.LINK_PROJECT_FACILITATOR_REQUESTED, linkProjectFacilitatorRequested),
  takeLatest(types.UNLINK_PROJECT_FACILITATOR_REQUESTED, unlinkProjectFacilitatorRequested),
  takeLatest(types.DELETE_FACILITATOR_REQUESTED, deleteFacilitatorRequested),
  takeLatest(types.UPDATE_FACILITATOR_REQUESTED, updateFacilitatorRequested),
  takeLatest(types.CREATE_FACILITATOR_REQUESTED, createFacilitatorRequested),
  takeLatest(types.EXPORT_FACILITATORS_REQUESTED, exportFacilitatorsRequested),
];
