import { ProjectUsers } from './ProjectUsers';
import { connect } from 'react-redux';
import {ProjectsActions, ProjectsSelectors} from '../../../../../redux/projects';
import { getTranslate } from "react-localize-redux";
import {FacilitatorsActions} from "../../../../../redux/facilitators";
import {MentorsActions} from "../../../../../redux/mentors";
import {SupervisorsActions} from "../../../../../redux/supervisors";

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  projectId: state.projects.project.id,
  projectUsers: state.projects.project.users,
  usersData: (page, search, region, academy, status, role) => ProjectsSelectors.getUsers(page, search, region, academy, status, role)(state),
});

const mapDispatchToProps = dispatch => ({
  linkUser: (users, projectId) => dispatch(ProjectsActions.linkUserRequested(users, projectId)),
  unlinkUser: (user, role, projectId) => dispatch(ProjectsActions.unlinkUserRequested(user, role, projectId)),
  getFacilitators: () => dispatch(FacilitatorsActions.getFacilitatorsRequested()),
  getMentors: () => dispatch(MentorsActions.getMentorsRequested()),
  getSupervisors: () => dispatch(SupervisorsActions.getSupervisorsRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectUsers);
