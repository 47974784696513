import React, { PureComponent } from 'react';
import { Button, ButtonColors, SideBar } from '../../../components';
import './project.scss';
import ProjectInfo from '../info';
import ProjectSchools from '../schools';
import ProjectUsers from './users';

export class ProjectCreation extends PureComponent {
  state = {
    project: null,
    lateralMenu: false,
    usersSelected: [],
    roleSelected: undefined,
    canAdd: false,
    newProject: null,
    durationError: false,
    seasonError: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { project } = this.props;

    if (prevProps.project !== project) {
      this.setState({
        users: project.users,
        project: project,
      });
    }

    this.setState({
      canAdd: this.state.usersSelected.length > 0,
    });
  }

  onValueChange = value => {
    const { newProject } = this.state;
    const existKey =
      value['duration'] === undefined
        ? newProject && newProject['duration'] === undefined
        : false;
    const durationExist = existKey == null ? true : existKey;

    const seasonExistKey =
      value["season"] === undefined
        ? newProject && newProject["season"] === undefined
        : false;
    const seasonError = seasonExistKey == null ? true : seasonExistKey;

    this.setState({
      newProject: {
        ...this.state.newProject,
        ...value,
      },
      durationError: durationExist,
      seasonError: seasonError
    });
  };

  onValidate = () => {
    const { createProject } = this.props;
    const { newProject } = this.state;
   createProject(newProject);
  };
  onFieldsValidate = () => {
    const { newProject } = this.state;
    let test = false;
    if (newProject !== null) {
      if (newProject.name && newProject.name !== '') {
        if (newProject.duration && newProject.duration > 0) {
          if (newProject.season && newProject.season !== '') {
            if (newProject.type && newProject.type !== '') {
              if (newProject.schoolId && newProject.schoolId > 0) {
                test = true;
              }
            }
          }
        }
      }
    }
    return test;
  };
  render() {
    const { strings, isCreation, navigateTo } = this.props;
    const { durationError, seasonError } = this.state;

    return (
      <div id={'project-creation'}>
        <div className={'side-bar'}>
          <SideBar />
        </div>
        <div className={'project'}>
          <ProjectInfo
            durationError={durationError}
            seasonError={seasonError}
            onValueChange={this.onValueChange}
            isCreation={isCreation}
            edition
          />
          <ProjectSchools
            category="school"
            onValueChange={this.onValueChange}
            isCreation={isCreation}
          />

          <ProjectUsers
            onValueChange={this.onValueChange}
            navigateTo={navigateTo}
            isCreation={isCreation}
          />
          <Button
            color={ButtonColors.green}
            label={strings('create')}
            className={'action-button'}
            action={this.onValidate}
            enabled={this.onFieldsValidate()}
          />
        </div>
      </div>
    );
  }
}
