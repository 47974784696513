import { call, put, takeLatest } from 'redux-saga/effects';
import {CommonsServices} from '../../services';
import { default as CommonsActions, types } from './actions';

function* getRegionsRequested() {
  const [error, response] = yield call(CommonsServices.getRegions);

  if (!error) {
    yield put(CommonsActions.getRegionsSucceeded(response.data));
  }
}


export default [
  takeLatest(types.GET_REGIONS_REQUESTED, getRegionsRequested),
];
