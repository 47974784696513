import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { icons } from "../../../assets";
import "./dateInput.scss";

const DateInput = ({
  className,
  label,
  placeholder,
  selectedDate,
  dateFormat,
  onChange,
}) => {
  return (
    <div className={"date-input"}>
      <p className={"label"}>{label}</p>
      <div className={"wrapper"}>
        <div className={"input-wrapper"}>
          <DatePicker
            className={className}
            selected={selectedDate ? Date.parse(selectedDate) : null}
            placeholderText={placeholder}
            onChange={onChange}
            dateFormat={dateFormat}
          />
          <i className={"material-icons"}>{icons.CALENDAR}</i>
        </div>
      </div>
    </div>
  );
};

export default DateInput;
