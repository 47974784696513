import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import {
  ALL,
  formatAcademiesSelector,
  formatEnumForSelector,
  formatRegionsSelector,
  pourcentFilter,
  projectStatusFilter,
  projecTypeFilter,
  userRole,
} from '../../utils';
import {
  ITEM_BY_PAGE,
  POURCENT_FILTER_KEY_STRING,
  PROJECT_STATUS_FILTER_KEY_STRING,
} from '../../utils';
import { FacilitatorsSelectors } from '../facilitators';
import { MentorsSelectors } from '../mentors';
import { SupervisorsSelectors } from '../supervisors';

const defaultFilter = {
  search: '',
  region: null,
  academy: null,
  percent: null,
  status: null,
  type: null,
  saison: null,
  duree: null,
  userProjects: false,
};
const getCurrentFilter = state =>
  state.projects.currentFilter || {
    page: 0,
    filter: defaultFilter,
  };
const sortList = (tab, sep) => {
  let changed;
  do {
    changed = false;
    for (let i = 0; i < tab.length - 1; i++) {
      if (parseInt(tab[i].split(sep)[0]) > parseInt(tab[i + 1].split(sep)[0])) {
        let tmp = tab[i];
        tab[i] = tab[i + 1];
        tab[i + 1] = tmp;
        changed = true;
      }
    }
  } while (changed);
  return tab;
};
const getListSaison = projects => {
  let saisonList = {};
  projects.map(item => {
    saisonList[item.season] = saisonList[item.season]
      ? saisonList[item.season]
      : saisonList[item.season];
  });
  let list = [{ key: 'ALL', value: { label: 'Tous', value: 'ALL' } }];
  sortList(Object.keys(saisonList), '/').map(item => {
    list.push({
      key: item,
      value: { label: item, value: item },
    });
  });
  return list;
};
const getListDuration = projects => {
  let durationList = {};
  projects.map(item => {
    durationList[item.duration] = durationList[item.duration]
      ? durationList[item.duration]
      : durationList[item.duration];
  });
  let list = [{ key: 'ALL', value: { label: 'Tous', value: 'ALL' } }];
  sortList(Object.keys(durationList), ' ').map(item => {
    list.push({
      key: item,
      value: { label: item, value: item },
    });
  });
  return list;
};
const projects = state => state.projects.list || [];
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const projectDetail = state => state.projects.project || [];
const schools = state => state.schools.list || [];
const strings = state => getTranslate(state.locale);
const facilitators = state => state.facilitators.list || [];
const supervisors = state => state.supervisors.list || [];
const mentors = state => state.mentors.list || [];
const projectCreationSaved = state => state.projects.creationSaved;
const snackBarDisplayed = state => state.snackBar.displayed;

const getProjects = () =>
  createSelector(
    strings,
    userRegion,
    getCurrentFilter,
    regions,
    projects,
    projectCreationSaved,
    snackBarDisplayed,
    (
      strings,
      userRegion,
      currentFilter,
      regions,
      projects,
      projectCreationSaved,
      snackBarDisplayed
    ) => {
      const { filter = defaultFilter, page = 0 } = currentFilter;
      const {
        search,
        region,
        academy,
        percent,
        type,
        saison,
        status,
        duree,
        userProjects = false,
      } = filter;

      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;
      const regionList = formatRegionsSelector(strings, regions);

      const regionObject = regionList.find(search =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );
      const regionAcademies = regionObject
        ? formatAcademiesSelector(strings, regionObject.value.academies)
        : [];
      const matchingAcademy = regionAcademies.find(
        search => search.key === academy
      );
      const academyObject = regionAcademies
        ? academy && matchingAcademy && regionFilter !== ALL
          ? matchingAcademy
          : regionAcademies.find(search => search.key === ALL)
        : null;

      const percentList = formatEnumForSelector(
        strings,
        pourcentFilter,
        POURCENT_FILTER_KEY_STRING
      );
      const percentObject = percentList.find(search =>
        percent
          ? search.key === percent
          : search.key ===
            Object.keys(pourcentFilter)[
              Object.values(pourcentFilter).indexOf(pourcentFilter.ALL)
            ]
      );
      const typeList = formatEnumForSelector(strings, projecTypeFilter);

      const statusList = formatEnumForSelector(
        strings,
        projectStatusFilter,
        PROJECT_STATUS_FILTER_KEY_STRING
      );

      const statusObject = statusList.find(search =>
        status ? search.key === status : search.key === "ALL"
      );

      const typeObject = typeList.find(search =>
        type
          ? search.key === type
          : search.key ===
            Object.keys(projecTypeFilter)[
              Object.values(projecTypeFilter).indexOf(projecTypeFilter.ALL)
            ]
      );

      const saisonList = getListSaison(projects);
      const saisonObject = saisonList.find(search =>
        saison ? search.key === saison : search.key === 'ALL'
      );

      const dureeList = getListDuration(projects);
      const dureeObject = dureeList.find(search =>
        duree ? search.key === duree : search.key === 'ALL'
      );
      const pageFilter = {
        page: page,
        filter: {
          search: search,
          //Do not override the region if there is one selected
          region: regionObject,
          status: statusObject,
          academy: academyObject,
          percent: percentObject,
          type: typeObject,
          saison: saisonObject,
          duree: dureeObject,
          userProjects,
        },
      };

      const filteredProjects = filterProjects(
        strings,
        projects,
        page,
        search,
        regionFilter,
        academyObject ? academyObject.key : academyObject,
        percentObject,
        typeObject,
        saisonObject,
        statusObject,
        dureeObject,
        userProjects
      );

      return {
        ...pageFilter,
        regionAcademies,
        ...filteredProjects,
        saisons: saisonList,
        durees: dureeList,
        projectCreationSaved,
        snackBarDisplayed,
      };
    }
  );

const getUsers = (page, search, region, academy, status, role) =>
  createSelector(
    facilitators,
    supervisors,
    mentors,
    (facilitators, supervisors, mentors) => {
      switch (role) {
        case userRole.FACILITATOR: {
          return paginateUsers(
            page,
            FacilitatorsSelectors.filterFacilitators(
              strings,
              facilitators,
              page,
              search,
              region,
              status
            )
          );
        }
        case userRole.MENTOR: {
          return paginateUsers(
            page,
            MentorsSelectors.filterMentors(
              strings,
              mentors,
              page,
              search,
              region,
              status,
              true
            )
          );
        }
        case userRole.SUPERVISOR: {
          return paginateUsers(
            page,
            SupervisorsSelectors.filterSupervisors(
              strings,
              supervisors,
              page,
              search,
              region,
              academy,
              status,
              true
            )
          );
        }
        default: {
          return null;
        }
      }
    }
  );

const getSchoolById = id =>
  createSelector(schools, schools => {
    return schools.find(item => item.id === id);
  });

const filterProjects = (
  strings,
  projects,
  page,
  search,
  region,
  academy,
  percent,
  type,
  saison,
  status,
  duree,
  userProjects
) => {
  let filteredProjects = projects;

  if (search.length > 0) {
    filteredProjects = filteredProjects.filter(p =>
      `${p.name} ${p.school.name} ${p.school.postalCode} ${p.school.city}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }

  if (region && region !== ALL) {
    filteredProjects = filteredProjects.filter(p => p.school.region === region);
  }
  if (academy && academy !== ALL) {
    filteredProjects = filteredProjects.filter(
      p => p.school.academy === academy
    );
  }
  if (type && type.key !== 'ALL') {
    filteredProjects = filteredProjects.filter(
      p => p.type === type.value.value
    );
  }
  if (saison && saison.key !== 'ALL') {
    filteredProjects = filteredProjects.filter(
      p => p.season === saison.value.label
    );
  }
  if (status && status.key !== 'ALL') {
    filteredProjects = filteredProjects.filter(
      p => p.status === status.value.value
    );
  }
  if (duree && duree.key !== 'ALL') {
    filteredProjects = filteredProjects.filter(
      p => p.duration === duree.value.label
    );
  }
  if (
    percent &&
    percent.key !==
      Object.keys(pourcentFilter)[
        Object.values(pourcentFilter).indexOf(pourcentFilter.ALL)
      ]
  ) {
    filteredProjects = filteredProjects.filter(
      p => p.progress >= percent.value.low && p.progress <= percent.value.high
    );
  }
  if (userProjects) {
    filteredProjects = filteredProjects.filter(p => p.isUserProject);
  }

  filteredProjects = formatProjects(filteredProjects, strings);

  return {
    all: filteredProjects,
    listed: filteredProjects.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredProjects.length / ITEM_BY_PAGE),
  };
};

const paginateUsers = (page, { all }) => {
  return {
    all: all,
    listed: all.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ),
    pageCount: Math.ceil(all.length / ITEM_BY_PAGE),
  };
};

const getProjectDetailInfo = createSelector(projectDetail, projectDetail => {
  const { id, name, type, code, password, status, season, duration, successes } =
    projectDetail;

  return { id, name, type, code, password, status, season, duration, successes };
});

const formatProjects = (projects, strings) => {
  return projects.map(item => ({
    id: item.id,
    title: item.name ? item.name : strings('project_default_name'),
    details: {
      place: `${item.school.name} ${item.school.postalCode} ${item.school.city}`,
      time: item.type,
    },
    otherDetails: {
      code: item.code,
      password: item.password,
    },
    members: {
      supervisors: item.supervisorsCount,
      mentors: item.mentorsCount,
      facilitators: item.facilitatorsCount,
    },
    progress: item.progress,
    region: item.school.region,
    academy: item.school.academy,
    saison: item.season,
    duree: item.duration,
    status: item.status
  }));
};

export default {
  getProjects,
  getUsers,
  getProjectDetailInfo,
  getSchoolById,
  filterProjects,
};
