import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { CommonsSelectors } from "../../../../redux/commons";
import { NotifActions } from "../../../../redux/notifications";
import { default as NotificationCreation } from "./NotificationCreation";

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  regions: CommonsSelectors.getRegions(state),
  academies: CommonsSelectors.getAcademies(state),
  draft: state.notifications.draft,
});

const mapDispatchToProps = (dispatch) => ({
  pop: () => dispatch(goBack()),
  navigateTo: (path) => dispatch(push(path)),
  createNotif: (notif) => dispatch(NotifActions.createNotifRequested(notif)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationCreation);
