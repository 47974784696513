import {methods, url} from '../utils';
import {authenticatedService} from "./middleware";

const routes = {
  getFacilitators: (adminId) => url.LIST_FACILITATORS(adminId),
  getFacilitatorDetail: (adminId, facilitatorId) => url.FACILITATOR_DETAIL(adminId, facilitatorId),
  linkProject: (adminId, userId) => url.FACILITATOR_LINK_PROJECT(adminId, userId),
  unlinkProject: (adminId, userId) => url.FACILITATOR_UNLINK_PROJECT(adminId, userId),
  exportFacilitators: (adminId) => url.EXPORT_FACILITATORS(adminId)
};

export default {
  getFacilitators: (adminId) => authenticatedService(methods.GET, routes.getFacilitators(adminId), {}, {}),
  getFacilitatorDetail: (adminId, facilitatorId) => authenticatedService(methods.GET, routes.getFacilitatorDetail(adminId, facilitatorId), {}, {}),
  linkProject: (adminId, userId, projectId) => authenticatedService(methods.PUT, routes.linkProject(adminId, userId), {projectId}, {}),
  unlinkProject: (adminId, userId, projectId) => authenticatedService(methods.PUT, routes.unlinkProject(adminId, userId), {projectId}, {}),
  deleteFacilitator: (adminId, facilitatorId) => authenticatedService(methods.DELETE, routes.getFacilitatorDetail(adminId, facilitatorId), {}, {}),
  updateFacilitator: (adminId, facilitatorId, body) => authenticatedService(methods.PATCH, routes.getFacilitatorDetail(adminId, facilitatorId), {...body}, {}),
  createFacilitator: (adminId, body) => authenticatedService(methods.PUT, routes.getFacilitators(adminId), {...body}, {}),
  exportFacilitators: (adminId) => authenticatedService(methods.GET, routes.exportFacilitators(adminId), {}, {}, {}, 'blob'),
};
