import {createAction} from '../../utils/index';

// Types
export const types = {
  CREATE_NOTIF_REQUESTED: "CREATE_NOTIF_REQUESTED",
  CREATE_NOTIF_SUCCEEDED: "CREATE_NOTIF_SUCCEEDED",
  GET_NOTIF_LIST_REQUESTED: "GET_NOTIF_LIST_REQUESTED",
  GET_NOTIF_LIST_SUCCEEDED: "GET_NOTIF_LIST_SUCCEEDED",
  DELETE_NOTIFS_REQUESTED: "DELETE_NOTIFS_REQUESTED",
  DELETE_NOTIFS_SUCCEEDED: "DELETE_NOTIFS_SUCCEEDED",
  SET_NOTIFS_CURRENT_FILTER: 'SET_NOTIFS_CURRENT_FILTER',
  SET_NOTIF_DRAFT: 'SET_NOTIF_DRAFT',
};

// Actions
export default {
  createNotifRequested : (notif) => createAction(types.CREATE_NOTIF_REQUESTED, {notif}),
  createNotifSucceeded : (notif) => createAction(types.CREATE_NOTIF_SUCCEEDED, {notif}),
  getNotifListRequested : () => createAction(types.GET_NOTIF_LIST_REQUESTED, {}),
  getNotifListSucceeded: (list) => createAction(types.GET_NOTIF_LIST_SUCCEEDED, {list}),
  deleteNotisfRequested : (ids) => createAction(types.DELETE_NOTIFS_REQUESTED, {ids}),
  deleteNotifsSucceeded: (ids) => createAction(types.DELETE_NOTIFS_SUCCEEDED, {ids}),
  setNotifsCurrentFilter : (page, filter) => createAction(types.SET_NOTIFS_CURRENT_FILTER, {page, filter}),
  setNotifDraft : (notif) => createAction(types.SET_NOTIF_DRAFT, {notif}),
};
