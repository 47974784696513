import { getTranslate } from "react-localize-redux/lib/index";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { default as ProjectNavigation } from "./ProjectNavigation";

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  location: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (path) => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectNavigation);
