import React from 'react';
import { icons, images } from '../../../assets';
import { navigationMenu, Routes } from '../../../utils';
import { default as Item } from './item';
import { default as Menu } from './menu';
import './navigations.scss';

export const Navigation = (
  {
    strings,
    isAdmin,
    logout,
    navigateTo
  }) => (
  <div id={'navigation'}>
    <div id={'fixed-wrapper'}>
      <Menu
      isAdmin={isAdmin}
      navigateTo={navigateTo}/>
      <div className={'main-logo'} onClick={() => navigateTo(Routes.INSCRIPTIONS)}>
        <img src={images.epaLogo} alt={'navigation-logo'}/>
      </div>
      <ul className={'items'}>
        {navigationMenu.map((item, key) =>
          <Item
            key={key}
            text={strings(item.label)}
            route={item.route}
          />,
        )}
      </ul>
      <div className={'icons-wrapper'}>
        {isAdmin && <i className="material-icons" onClick={() => navigateTo(Routes.ADMIN)}>{icons.SETTINGS}</i>}
        <i className="material-icons" onClick={logout}>{icons.LOGOUT}</i>
      </div>
    </div>
  </div>
);
