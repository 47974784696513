import { call, put, select, takeLatest } from 'redux-saga/effects';
import { history } from '../../core/history';
import { FreemiumsServices } from '../../services';
import {downloadAttachment, Routes} from '../../utils';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as FreemiumsActions, types } from './actions';
import errorService from "../../services/ErrorService";

function* getFreemiumsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(FreemiumsServices.getFreemiums, adminId);
  if (!error) {
    yield put(FreemiumsActions.getFreemiumsSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('freemiums_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}
function* getFreemiumDetailRequested({ freemiumId }) {
  yield put(LoaderActions.loading());
  const { id } = yield select(state => state.auth);
  const [error, response] = yield call(
    FreemiumsServices.getFreemiumDetail,
    id,
    freemiumId
  );
  if (!error) {
    yield put(FreemiumsActions.getFreemiumDetailSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('freemium_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* deleteFreemiumRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(FreemiumsServices.deleFreemium, adminId, id);

  if (!error) {
    yield put(FreemiumsActions.deleteFreemiumSucceeded(id));
  } else {
    yield put(SnackActions.displayError('freemium_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateFreemiumRequested({ freemium }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    FreemiumsServices.updateFreemium,
    adminId,
    freemium.id,
    freemium
  );

  if (!error) {
    yield put(FreemiumsActions.updateFreemiumSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('freemium_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* createFreemiumRequested({ freemium }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    FreemiumsServices.createFreemium,
    adminId,
    freemium
  );

  if (!error) {
    yield put(FreemiumsActions.createFreemiumSucceeded(response.data));
    yield call(history.replace, Routes.FREEMIUMS_LIST);
  } else {
    yield errorService.displayConflictError(error,'freemium_creation_failed')
  }

  yield put(LoaderActions.loaded());
}

function* upgradeFreemiumRequested({ id, supervisorInfo }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(
    FreemiumsServices.upgradeFreemium,
    adminId,
    id,
    supervisorInfo
  );

  if (!error) {
    yield put(FreemiumsActions.upgradeFreemiumSucceeded());
    yield call(history.replace, Routes.FREEMIUMS_LIST);
    yield put(SnackActions.displayInfo('freemium_upgrade_succeed'));
  } else {
    yield put(SnackActions.displayError('freemium_upgrade_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* exportFreemiumsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    FreemiumsServices.exportFreemiums,
    adminId
  );

  if (response && response.data && !error) {
    downloadAttachment(response);
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_FREEMIUMS_REQUESTED, getFreemiumsRequested),
  takeLatest(types.GET_FREEMIUM_DETAIL_REQUESTED, getFreemiumDetailRequested),
  takeLatest(types.DELETE_FREEMIUMS_REQUESTED, deleteFreemiumRequested),
  takeLatest(types.UPDATE_FREEMIUM_REQUESTED, updateFreemiumRequested),
  takeLatest(types.CREATE_FREEMIUM_REQUESTED, createFreemiumRequested),
  takeLatest(types.UPGRADE_FREEMIUM_REQUESTED, upgradeFreemiumRequested),
  takeLatest(types.EXPORT_FREEMIUMS_REQUESTED, exportFreemiumsRequested),
];
