import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { icons } from "../../../../../assets";
import { InputTypes } from "../../../../../utils";
import { Input } from "../../../../components";

const Success = ({ success, index, handleValueChange, handleDeleteSuccess }) => {
  return (
    <Draggable
      draggableId={success.id ? success.id.toString() : success.key}
      index={index}
    >
      {(provided, snapshot) => (
        <div
          className={"success"}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <i className="material-icons drag">{icons.DRAG}</i>
          <Input
            type={InputTypes.TEXT}
            tag={"label"}
            defaultValue={success.label}
            handleValueChanged={handleValueChange(index)}
          />
          <i className={'material-icons delete'} onClick={handleDeleteSuccess(index)}>
            {icons.CLOSE}
          </i>
        </div>
      )}
    </Draggable>
  );
};

export default Success;
