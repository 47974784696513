import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { SupervisorsActions } from '../../../../redux/supervisors';
import { SupervisorCreation } from './SupervisorCreation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  createSupervisor: supervisor => dispatch(SupervisorsActions.createSupervisorRequested(supervisor)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SupervisorCreation);
