import React, { useEffect } from 'react';
import User from '../../user';
import { validateUserForm } from './FormValidator';

export const SupervisorDetail = ({ getSupervisor, supervisor, match, pop, strings, updateSupervisor, linkProjects, unlinkProject, projectsData, resetSupervisorDetail}) => {
  useEffect(() => { getSupervisor(match.params.supervisorId) }, [match.params.supervisorId, getSupervisor]);

  useEffect(() => {
    return () => {
      resetSupervisorDetail();
    }
  }, [resetSupervisorDetail]);

  const updateUser = (isValid, result) => {
    if (isValid && JSON.stringify(result) !== JSON.stringify(supervisor)) {
      updateSupervisor(result)
    }
  };

  return <User
    user={supervisor}
    validateUserForm={validateUserForm}
    pop={pop}
    back={strings('all_supervisor')}
    updateUser={updateUser}
    projectsData={projectsData}
    linkProjects={(projectsId) => linkProjects(supervisor.id, projectsId)}
    unlinkProject={(projectId) => unlinkProject(supervisor.id, projectId)}
  />
};
