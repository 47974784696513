import LateralMenu from './LateralMenu';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import {
  formatEnumForSelector,
  formatRegionsSelector,
  userStatus,
} from '../../../utils';
import { UserActions, UserSelectors } from '../../../redux/user';
import { AuthSelectors } from '../../../redux/auth';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  users: UserSelectors.getUsers()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  regionsSlice: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ).slice(1),
  statusFilter: formatEnumForSelector(getTranslate(state.locale), userStatus),
  creationSaved: state.schools.creationSaved,
  schoolSaved: state.schools.creationSaved,
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});
const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
  pop: () => dispatch(goBack()),
  getUsers: () => dispatch(UserActions.getUsersRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LateralMenu);
