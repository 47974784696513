import React from 'react';
import { CheckBox } from '../../../components';
import { UserBadge } from '../../../components/usersList/userBadge';

export const UserItem = ({strings, user, role, isChecked, handleCheck}) => {

  const onCheckUser = id => () => {
    if (handleCheck) {
      handleCheck(id)()
    }
  };

  return (
    <div className={'user-item'}>
      {handleCheck &&
      <div className={'check'}>
        <CheckBox onCheck={handleCheck(user.id)} isChecked={isChecked}/>
      </div>
      }
      <div className={'content'} onClick={onCheckUser(user.id)}>
        <div className={`user-badge`}>
          <UserBadge className={'user-badge'} role={role}/>
        </div>
        <div className={'identity'}><p>{`${user.civility} ${user.firstName} ${user.lastName}`}</p></div>
        <div className={'email'}><p>{user.email}</p></div>
        <div className={'phone'}>{`${user.phone}`}</div>
      </div>
    </div>
  );
};
