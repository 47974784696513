import {createReducer} from 'reduxsauce';
import {types} from './actions';


const initialState = {
  successes: [],
  operations: [],
  categories: [],
  levels: [],
  sectors: [],
  times: [],
  logs: [],
};

const getSuccessesSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, successes: list}
};

const getOperationsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, operations: list}
};

const createOperationsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, operations: list}
};

const getCategoriesSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, categories: list}
};

const createCategoriesSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, categories: list}
};

const getLevelsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, levels: list}
};

const createLevelsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, levels: list}
};

const getSectorsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, sectors: list}
};

const createSectorsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, sectors: list}
};

const getTimesSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, times: list}
};

const createTimesSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, times: list}
};

const getLogsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, logs: list}
};

export default createReducer(initialState, {
  [types.GET_SUCCESSES_SUCCEEDED] : getSuccessesSucceeded,
  [types.GET_OPERATIONS_SUCCEEDED] : getOperationsSucceeded,
  [types.GET_CATEGORIES_SUCCEEDED] : getCategoriesSucceeded,
  [types.GET_LEVELS_SUCCEEDED] : getLevelsSucceeded,
  [types.GET_SECTORS_SUCCEEDED] : getSectorsSucceeded,
  [types.GET_TIMES_SUCCEEDED] : getTimesSucceeded,
  [types.GET_LOGS_SUCCEEDED] : getLogsSucceeded,
  [types.CREATE_OPERATIONS_SUCCEEDED] : createOperationsSucceeded,
  [types.CREATE_CATEGORIES_SUCCEEDED] : createCategoriesSucceeded,
  [types.CREATE_LEVELS_SUCCEEDED] : createLevelsSucceeded,
  [types.CREATE_SECTORS_SUCCEEDED] : createSectorsSucceeded,
  [types.CREATE_TIMES_SUCCEEDED] : createTimesSucceeded,
});
