import React from 'react';
import './projectUsers.scss';
import { icons } from '../../../../../assets';
import { Routes, userRole, civilityValues } from '../../../../../utils';
import { UserBadge } from '../../../../components/usersList/userBadge';

export const UserItem = ({user, navigateTo, unlinkUser, strings}) => {

  let route;
  switch(user.role) {
    case userRole.MENTOR:
      route = Routes.MENTOR.replace(':mentorId', user.id);
      break;
    case userRole.SUPERVISOR:
      route = Routes.SUPERVISOR.replace(':supervisorId', user.id);
      break;
    case userRole.FACILITATOR:
    case userRole.ADMIN:
      route = Routes.FACILITATOR.replace(':facilitatorId', user.id);
      break;
    default:
      route = Routes.DEFAULT;
  }

  const handleUnlink = (event) => {
    event.stopPropagation();
    unlinkUser();
  };

  const handleClick = () => {
    if (navigateTo) {
      navigateTo(route)
    }
  }

  const civility = user.civility === civilityValues.MR ? strings('mr') : strings('mrs');
  
  return (
    <div 
      className={"users-item"}
      onClick={handleClick}
    >
      <div className={'content'}>
        <div className={`user-badge`}>
          <UserBadge className={'user-badge'} role={user.role} />
        </div>
        <div className={'identity'}><p>{`${civility} ${user.firstName} ${user.lastName}`}</p></div>
        <div className={'email'}><p>{user.email}</p></div>
        <div className={'phone'}>{`${user.phone}`}</div>
        {unlinkUser && <i className={"material-icons"} onClick={handleUnlink}>{icons.CANCEL}</i>}
      </div>
      <div className={'user-separator'}/>
    </div>
  )
}
