import { createReducer } from 'reduxsauce';
import { schoolStatus } from '../../utils';
import { types } from './actions';

const initialState = {
  schools: [],
  reinscriptions: [],
  mentors: [],
  currentTab: null,
  school: {
    id: null,
    rne: null,
    name: null,
    address: null,
    postalCode: null,
    city: null,
    region: null,
    academy: null,
    country: null,
    secretariatPhone: null,
    principalCivility: null,
    principalFirstName: null,
    principalLastName: null,
    principalEmail: null,
    principalPhone: null,
    projects: []
  },
  mentor: {
    id: null,
    firstName: '',
    projects: [],
    schools: []
  }
};

const getSchoolInscriptionsSucceeded = (state = initialState, action) => {
  const {schools} = action;
  return {...state, schools};
};

const getSchoolReinscriptionsSucceeded = (state = initialState, action) => {
  const {schools} = action;
  return {...state, reinscriptions: schools};
};

const setInscriptionCurrentTab = (state = initialState, action) => {
  const {tab, page, filter, subTab} = action;
  return {...state, currentTab: {tab, page, filter, subTab}};
};

const resetInscriptionCurrentTab = (state = initialState) => {
  return {...state, currentTab: initialState.currentTab};
};

const getSchoolDetailInscriptionsSucceeded = (state = initialState, action) => {
  const {school} = action;
  return {...state, school: school};
};

const updateSchoolInscriptionsSucceeded = (state = initialState, action) => {
  const {school} = action;

  const copyList = JSON.parse(JSON.stringify(state.schools));
  const schoolIndex = copyList.findIndex(s => s.id === school.id);
  copyList[schoolIndex] = school;
  return {...state, schools: copyList, school: state.school.id === school.id ? school:  state.school};
};

const updateSchoolReinscriptionsSucceeded = (state = initialState, action) => {
  const {school} = action;

  const copyList = JSON.parse(JSON.stringify(state.reinscriptions));
  const schoolIndex = copyList.findIndex(s => s.id === school.id);
  copyList[schoolIndex] = school;
  return {...state, reinscriptions: copyList, school: state.school.id === school.id ? school:  state.school};
};

const editSchoolInscriptionsSucceeded = (state = initialState, action) => {
  const {school} = state;
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.schools));
  const schoolIndex = copyList.findIndex(s => s.id === id);
  copyList[schoolIndex].status = schoolStatus.WAITING;

  return {...state, schools: copyList, school: school.id === id ? {...school, status: schoolStatus.WAITING}:  state.school};
};

const editSchoolReinscriptionsSucceeded = (state = initialState, action) => {
  const {school} = state;
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.reinscriptions));
  const schoolIndex = copyList.findIndex(s => s.id === id);
  copyList[schoolIndex].status = schoolStatus.WAITING;

  return {...state, reinscriptions: copyList, school: school.id === id ? {...school, status: schoolStatus.WAITING}:  state.school};
};

const validateSchoolInscriptionsSucceeded = (state = initialState, action) => {
  const {school} = action;

  const copyList = JSON.parse(JSON.stringify(state.schools));
  const schoolIndex = copyList.findIndex(s => s.id === school.id);
  copyList.splice(schoolIndex, 1);
  return {...state, schools: copyList, school: state.school.id === school.id ? school:  state.school};
};

const validateSchoolReinscriptionsSucceeded = (state = initialState, action) => {
  const {school} = action;

  const copyList = JSON.parse(JSON.stringify(state.reinscriptions));
  const schoolIndex = copyList.findIndex(s => s.id === school.id);
  copyList.splice(schoolIndex, 1);
  return {...state, reinscriptions: copyList, school: state.school.id === school.id ? school:  state.school};
};

const deleteSchoolInscriptionsSucceeded = (state = initialState, action) => {
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.schools));
  const schoolIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(schoolIndex, 1);
  return {...state, schools: copyList, school: state.school.id === id ? initialState.school:  state.school};
};

const deleteSchoolReinscriptionsSucceeded = (state = initialState, action) => {
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.reinscriptions));
  const schoolIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(schoolIndex, 1);
  return {...state, reinscriptions: copyList, school: state.school.id === id ? initialState.school:  state.school};
};

const getMentorInscriptionsSucceeded = (state = initialState, action) => {
  const {mentors} = action;
  return {...state, mentors};
};


const validateMentorInscriptionsSucceeded = (state = initialState, action) => {
  const {mentor} = action;

  const copyList = JSON.parse(JSON.stringify(state.mentors));
  const mentorIndex = copyList.findIndex(s => s.id === mentor.id);
  copyList.splice(mentorIndex, 1);
  return {...state, mentors: copyList, mentor: state.mentor && state.mentor.id === mentor.id ? mentor:  state.mentor};
};

const deleteMentorInscriptionsSucceeded = (state = initialState, action) => {
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.mentors));
  const mentorIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(mentorIndex, 1);
  return {...state, mentors: copyList, mentor: state.mentor && state.mentor.id === id ? initialState.mentor:  state.mentor};
};

const getMentorDetailInscriptionsSucceeded = (state = initialState, action) => {
  const {mentor} = action;
  return {...state, mentor: mentor};
};

const updateMentorInscriptionsSucceeded = (state = initialState, action) => {
  const {mentor} = action;

  const copyList = JSON.parse(JSON.stringify(state.mentors));
  const mentorIndex = copyList.findIndex(s => s.id === mentor.id);
  copyList[mentorIndex] = mentor;
  return {...state, mentors: copyList, mentor: state.mentor.id === mentor.id ? mentor:  state.mentor};
};

const resetSchoolDetailInscription = (state = initialState) => {
  return {...state, school: initialState.school};
};

const resetMentorDetailInscription = (state = initialState) => {
  return {...state, mentor: initialState.mentor};
};

export default createReducer(initialState, {
  [types.GET_SCHOOL_INSCRIPTIONS_SUCCEEDED]: getSchoolInscriptionsSucceeded,
  [types.GET_SCHOOL_REINSCRIPTIONS_SUCCEEDED]: getSchoolReinscriptionsSucceeded,
  [types.SET_INSCRIPTION_CURRENT_TAB]: setInscriptionCurrentTab,
  [types.RESET_INSCRIPTION_CURRENT_TAB]: resetInscriptionCurrentTab,
  [types.GET_SCHOOL_DETAIL_INSCRIPTIONS_SUCCEEDED]: getSchoolDetailInscriptionsSucceeded,
  [types.GET_SCHOOL_DETAIL_REINSCRIPTIONS_SUCCEEDED]: getSchoolDetailInscriptionsSucceeded,
  [types.UPDATE_SCHOOL_INSCRIPTIONS_SUCCEEDED]: updateSchoolInscriptionsSucceeded,
  [types.UPDATE_SCHOOL_REINSCRIPTIONS_SUCCEEDED]: updateSchoolReinscriptionsSucceeded,
  [types.EDIT_SCHOOL_INSCRIPTION_CONVENTION_SUCCEEDED]: editSchoolInscriptionsSucceeded,
  [types.EDIT_SCHOOL_REINSCRIPTION_CONVENTION_SUCCEEDED]: editSchoolReinscriptionsSucceeded,
  [types.VALIDATE_SCHOOL_INSCRIPTION_SUCCEEDED]: validateSchoolInscriptionsSucceeded,
  [types.VALIDATE_SCHOOL_REINSCRIPTION_SUCCEEDED]: validateSchoolReinscriptionsSucceeded,
  [types.DELETE_SCHOOL_INSCRIPTION_SUCCEEDED]: deleteSchoolInscriptionsSucceeded,
  [types.DELETE_SCHOOL_REINSCRIPTION_SUCCEEDED]: deleteSchoolReinscriptionsSucceeded,
  [types.GET_MENTOR_INSCRIPTIONS_SUCCEEDED]: getMentorInscriptionsSucceeded,
  [types.VALIDATE_MENTOR_INSCRIPTION_SUCCEEDED]: validateMentorInscriptionsSucceeded,
  [types.DELETE_MENTOR_INSCRIPTION_SUCCEEDED]: deleteMentorInscriptionsSucceeded,
  [types.GET_MENTOR_DETAIL_INSCRIPTIONS_SUCCEEDED]: getMentorDetailInscriptionsSucceeded,
  [types.UPDATE_MENTOR_INSCRIPTIONS_SUCCEEDED]: updateMentorInscriptionsSucceeded,
  [types.RESET_SCHOOL_DETAIL_INSCRIPTION]: resetSchoolDetailInscription,
  [types.RESET_MENTOR_DETAIL_INSCRIPTION]: resetMentorDetailInscription,
});
