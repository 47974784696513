import React from 'react';
import './checkBox.scss';

export const CheckBox = ({label, isChecked, onCheck, disabled}) => {
  return (
    <label className={'checkbox'}>
      <input type="checkbox" checked={isChecked} onChange={onCheck} disabled={disabled}/>
      <span className={`checkmark ${disabled ? 'disabled' : 'enabled'}`}/>
      <p className={'label'}>{label}</p>
    </label>
  )
};
