import {methods, url} from '../utils';
import {authenticatedService} from "./middleware";

const routes = {
  getSchools: (adminId) => url.LIST_SCHOOLS(adminId),
  getSchoolDetail: (adminId, id) => url.SCHOOL_DETAIL(adminId, id),
  deleteSchool: (adminId, schoolId) => url.DELETE_SCHOOL(adminId, schoolId),
  reinscription: (adminId, schoolId) => url.REINSCRIPTION(adminId, schoolId),
  exportSchools: (adminId) => url.EXPORT_SCHOOLS(adminId),
};

export default {
  getSchools: (adminId) => authenticatedService(methods.GET, routes.getSchools(adminId), {}, {}),
  getSchoolDetail: (adminId, id) => authenticatedService(methods.GET, routes.getSchoolDetail(adminId, id), {}, {}),
  updateSchool: (adminId, id, body) => authenticatedService(methods.PATCH, routes.getSchoolDetail(adminId, id), {...body}, {}),
  createSchool: (adminId, body) => authenticatedService(methods.PUT, routes.getSchools(adminId), {...body}, {}),
  deleteSchool: (adminId, schoolId) => authenticatedService(methods.DELETE, routes.deleteSchool(adminId, schoolId), {}, {}),
  reinscription: (adminId, schoolId, body) => authenticatedService(methods.PATCH, routes.reinscription(adminId, schoolId), {...body}, {}, {}, 'blob'),
  exportSchools: (adminId) => authenticatedService(methods.GET, routes.exportSchools(adminId), {}, {}, {}, 'blob'),
};
