import React, { useState } from 'react';
import './projects-list.scss';
import { icons } from '../../../assets';
import ProjectItem from './projectItem';
import { Button, ButtonColors } from '../buttons';
import { LateralMenu } from '../index';

export const ProjectsList = ({projects, strings, isEditable = true, lateralListData, getProjects, linkProjects, unlinkProject, allowDetailNavigation = true, allowAddProject, enableAddProject, navigateTo}) => {
  const [lateralMenu, setLateralMenu] = useState(false);
  const [projectsSelected, setProjectsSelected] = useState([]);

  const getProjectsIdSelected = () => {
    return projectsSelected ? projectsSelected.map(item => item.projectId) : [];
  };

  const onAdd = () => {
    getProjects();

    setLateralMenu(true);
  };

  const validateAdd = () => {
    onClose();

    linkProjects(getProjectsIdSelected());
  };

  const onUserSelected = (id) => () => {
    setProjectsSelected(
      projectsSelected.findIndex(item => item.projectId === id) !== -1
        ? projectsSelected.filter(item => !(item.projectId === id))
        : projectsSelected.concat({projectId: id}),
    );
  };

  const onClose = () => {
    setLateralMenu(false);
    setProjectsSelected([]);
  };

  return (
    <div id={'projectsList'}>
      {lateralListData && <LateralMenu
        strings={strings}
        visible={lateralMenu}
        filterLabel={strings('projects')}
        label={`${strings('link_one_more')} ${strings('projects')}`}
        onClose={onClose}
        data={lateralListData}
        itemsChecked={getProjectsIdSelected()}
        onItemSelected={onUserSelected}
        onAdd={validateAdd}
        canAdd={projectsSelected.length > 0}
        isProject={true}
      />}
      <div className={'title'}>
        <div>{strings('projects')}</div>
        {(allowAddProject && lateralListData && isEditable) &&
        <Button
          color={ButtonColors.orange}
          label={strings('button_text_add')}
          icon={icons.ADD}
          enabled={enableAddProject}
          className={'edit-button'}
          action={onAdd}
        />
        }
      </div>
      <hr className={'separator'}/>
      <div className="list">
        {(projects && projects.length > 0) ?
          projects.map(project =>
            <ProjectItem
              project={project}
              strings={strings}
              key={project.id}
              allowDetailNavigation={allowDetailNavigation}
              navigateTo={navigateTo}
              unlinkProject={unlinkProject}
            />,
          )
          : (<p className={'no-projects'}>{strings('no_projects')}</p>)
        }
      </div>
    </div>
  );
};
