import React, { PureComponent } from 'react';
import { Routes } from '../../../../utils';
import { Button, ButtonColors, LateralMenu, Panel } from '../../../components';
import { icons } from '../../../../assets';
import './projectSchools.scss';

export class ProjectSchools extends PureComponent {
  state = {
    lateralMenu: false,
    isCreateSchool: false,
    isSelectSchool: false,
    isSelectUser: false,
    schoolSelected: null,
    userSelected: null,
    schoolIsValidated: false,
    allUsers: this.props.users.all,
    projectSchools: this.props.isCreation ? null : this.props.projectSchools(),
  };
  onUserSelected = id => () => {
    const { schoolSelected, userSelected } = this.state;
    const { category } = this.props;

    category !== 'user'
      ? this.setState({
          schoolSelected:
            schoolSelected && schoolSelected.schoolId === id
              ? null
              : { schoolId: id },
        })
      : this.setState({
          userSelected:
            userSelected && userSelected.userId === id ? null : { userId: id },
        });
  };

  getSchoolsIdSelected = () => {
    const { schoolSelected, userSelected } = this.state;
    const { category } = this.props;
    if (category !== 'user') {
      return schoolSelected ? [schoolSelected.schoolId] : [];
    } else {
      return userSelected ? [userSelected.userId] : [];
    }
  };

  onAdd = () => {
    const { getSchools, getUsers, category } = this.props;
    getSchools();
    getUsers();
    this.setState({
      lateralMenu: true,
      isCreateSchool: false,
      isSelectSchool: category !== 'user' ? true : false,
      isSelectUser: category !== 'user' ? false : true,
    });
  };
  onCreate = () => {
    this.setState({
      lateralMenu: true,
      isCreateSchool: true,
      isSelectSchool: false,
      isSelectUser: false,
    });
  };
  validateAdd = () => {
    const { projectSchool, onValueChange, category } = this.props;
    const { schoolSelected, userSelected, allUsers } = this.state;

    let user =
      category !== 'user'
        ? []
        : allUsers.filter(item => item.id === userSelected?.userId)[0];
    this.setState({
      schoolIsValidated: true,
      projectSchools:
        category !== 'user' ? projectSchool(schoolSelected.schoolId) : user,
      lateralMenu: false,
      isCreateSchool: false,
      isSelectSchool: false,
      isSelectUser: false,
      schoolSelected: null,
      userSelected: null,
    });

    onValueChange &&
      onValueChange(
        category !== 'user'
          ? { schoolId: schoolSelected.schoolId }
          : { userId: userSelected.userId }
      );
  };

  onClickSchool = () => {
    const { navigateTo, isCreation } = this.props;
    const { projectSchools } = this.state;

    if (!isCreation && projectSchools) {
      navigateTo(Routes.SCHOOL.replace(':schoolId', projectSchools.id));
    }
  };

  render() {
    const { strings, isCreation, schoolsList, navigateTo, category } =
      this.props;
    const {
      lateralMenu,
      schoolSelected,
      userSelected,
      projectSchools,
      isCreateSchool,
      isSelectSchool,
      isSelectUser,
    } = this.state;

    return (
      <Panel id={'projectSchools'}>
        {isCreation && (
          <>
            <LateralMenu
              category={category}
              strings={strings}
              visible={lateralMenu}
              filterLabel={
                category !== 'user' ? strings('school') : strings('user')
              }
              label={`${strings(isCreateSchool ? 'create_one' : 'link_one')} ${
                category !== 'user' ? strings('school') : strings('user')
              }`}
              onClose={() =>
                this.setState({ lateralMenu: false, usersSelected: [] })
              }
              data={schoolsList}
              itemsChecked={this.getSchoolsIdSelected()}
              onItemSelected={this.onUserSelected}
              onAdd={this.validateAdd}
              onShowList={this.onAdd}
              canAdd={category !== 'user' ? schoolSelected : userSelected}
              isProject={false}
              isCreateSchool={isCreateSchool}
              isSelectSchool={isSelectSchool}
              isSelectUser={isSelectUser}
            />
          </>
        )}
        <div className={'header'}>
          <p className={`medium title`}>
            {category !== 'user' ? strings('school') : strings('user')}
          </p>
          {isCreation && (
            <>
              <Button
                label={strings('button_text_add')}
                icon={icons.ADD}
                className={`add-button`}
                color={ButtonColors.orange}
                action={this.onAdd}
              />
              {category !== 'user' && (
                <Button
                  label={strings('create')}
                  className={`add-button`}
                  color={ButtonColors.green}
                  action={this.onCreate}
                />
              )}
            </>
          )}
        </div>
        {projectSchools && (
          <div>
            <div className={'horizontal-dot-separator'} />
            <div
              className={`school ${isCreation ? '' : 'clickable'}`}
              onClick={this.onClickSchool}
            >
              <div className={'code'}>
                {category !== 'user' ? projectSchools.rne : projectSchools.id}
              </div>
              <div className={'name-wrapper'}>
                <div className={'name'}>
                  {category !== 'user'
                    ? projectSchools.name
                    : projectSchools.title}
                </div>
                <div className={'address'}>
                  {category !== 'user'
                    ? projectSchools.address
                    : projectSchools.role}
                </div>
              </div>
              <div className={'contact'}>
                <div>
                  {category !== 'user'
                    ? projectSchools.secretariatPhone
                    : projectSchools.details.phone}
                </div>
              </div>
            </div>
          </div>
        )}
      </Panel>
    );
  }
}
