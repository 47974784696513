import { resettableReducer } from 'reduxsauce';
import { AuthReducers, AuthSagas } from './auth';
import { InscriptionsReducers, InscriptionsSagas } from './inscriptions';
import { LoaderReducers } from './loader';
import { ProjectsReducers, ProjectsSagas } from './projects';
import { SchoolsReducers, SchoolsSagas } from './schools';
import { SnackReducers, SnackSagas } from './snackBar';
import { ParamsReducers, ParamsSagas } from './params';
import { SupervisorsReducers, SupervisorsSagas } from './supervisors';
import { UserReducers, UserSagas } from './user';
import {CommonsSagas, CommonsReducers} from "./commons";
import {MentorsReducers, MentorsSagas} from "./mentors";
import {FacilitatorsReducers, FacilitatorsSagas} from "./facilitators";
import {FreemiumsReducers, FreemiumsSagas} from "./freemiums";
import {ConfirmPopupReducers, ConfirmPopupSagas} from "./confirmPopup";
import {NotifReducers, NotifSagas} from "./notifications";
import {MinisReducers, MinisSagas} from "./minis";

// Specify reset action type rule
const resettable = resettableReducer('RESET_REDUCERS');

const reducers = Object.assign({},
  {
    commons: resettable(CommonsReducers),
    auth: resettable(AuthReducers),
    user: resettable(UserReducers),
    supervisors: resettable(SupervisorsReducers),
    loader: resettable(LoaderReducers),
    projects: resettable(ProjectsReducers),
    mentors: resettable(MentorsReducers),
    facilitators: resettable(FacilitatorsReducers),
    freemiums: resettable(FreemiumsReducers),
    snackBar: SnackReducers,
    schools: resettable(SchoolsReducers),
    params: resettable(ParamsReducers),
    inscriptions: resettable(InscriptionsReducers),
    confirmPopup: ConfirmPopupReducers,
    notifications: NotifReducers,
    minis: MinisReducers,
  });

export const rootSaga = [
  ...AuthSagas,
  ...UserSagas,
  ...SnackSagas,
  ...ProjectsSagas,
  ...SupervisorsSagas,
  ...SchoolsSagas,
  ...MentorsSagas,
  ...FacilitatorsSagas,
  ...FreemiumsSagas,
  ...ParamsSagas,
  ...InscriptionsSagas,
  ...CommonsSagas,
  ...ConfirmPopupSagas,
  ...NotifSagas,
  ...MinisSagas,
];

export default reducers;
