import { ProjectsList } from './ProjectsList';
import { connect } from 'react-redux';
import {push} from "react-router-redux";

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);