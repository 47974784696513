import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import {
  formatEnumForSelector,
  formatRegionsSelector,
  userStatus,
} from '../../utils';
import { ALL, ITEM_BY_PAGE } from '../../utils/constants';
import { ProjectsSelectors } from '../projects';

const defaultFilter = {
  search: '',
  region: null,
  status: null,
};
const getCurrentFilter = state =>
  state.facilitators.currentFilter || {
    page: 0,
    filter: defaultFilter,
  };

const facilitators = state => state.facilitators.list || [];
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const facilitatorDetail = state => state.facilitators.facilitator || [];
const projects = state => state.projects.list || [];
const strings = state => getTranslate(state.locale);

const getFacilitators = () =>
  createSelector(
    strings,
    userRegion,
    regions,
    getCurrentFilter,
    facilitators,
    (strings, userRegion, regions, currentFilter, facilitators) => {
      const { filter = defaultFilter, page = 0 } = currentFilter;
      const { search, region, status } = filter;

      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;

      const regionList = formatRegionsSelector(strings, regions);
      const regionObject = regionList.find(search =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );

      const statusList = formatEnumForSelector(strings, userStatus);
      const statusObject = statusList.find(search =>
        status
          ? search.key === status
          : search.key ===
            Object.keys(userStatus)[
              Object.values(userStatus).indexOf(userStatus.ALL)
            ]
      );

      const pageFilter = {
        page: page,
        filter: {
          search: search,
          //Do not override the region if there is one selected
          region: regionObject,
          status: statusObject,
        },
      };

      const filteredFacilitators = filterFacilitators(
        strings,
        facilitators,
        page,
        search,
        regionFilter,
        status
      );

      return { ...pageFilter, ...filteredFacilitators };
    }
  );

const getProjects = (page, search, region, academy) =>
  createSelector(
    strings,
    projects,
    facilitatorDetail,
    (strings, projects, facilitatorDetail) => {
      return filterProjects(
        page,
        facilitatorDetail.projects,
        ProjectsSelectors.filterProjects(
          strings,
          projects,
          page,
          search,
          region,
          academy
        )
      );
    }
  );

const filterFacilitators = (
  strings,
  facilitators,
  page,
  search,
  region,
  status
) => {
  let filteredFacilitators = facilitators;

  if (search.length > 0) {
    filteredFacilitators = filteredFacilitators.filter(p =>
      `${p.firstName} ${p.lastName}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredFacilitators = filteredFacilitators.filter(
      p =>
        p.regions &&
        p.regions.length > 0 &&
        p.regions.findIndex(r => r === region) !== -1
    );
  }
  if (
    status &&
    status !==
      Object.keys(userStatus)[Object.values(userStatus).indexOf(userStatus.ALL)]
  ) {
    filteredFacilitators = filteredFacilitators.filter(
      p => p.displayedStatus === status
    );
  }

  filteredFacilitators = formatFacilitators(filteredFacilitators, strings);

  return {
    all: filteredFacilitators,
    listed: filteredFacilitators.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredFacilitators.length / ITEM_BY_PAGE),
  };
};

const filterProjects = (page, projects, { all }) => {
  const allProjects = all.filter(item => !projects.some(p => p.id === item.id));

  return {
    all: allProjects,
    listed: allProjects.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(allProjects.length / ITEM_BY_PAGE),
  };
};

const formatFacilitators = facilitators => {
  return facilitators.map(item => ({
    id: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    civility: item.civility,
    role: item.role,
    title: `${item.firstName} ${item.lastName}`,
    details: {
      phone: item.phone,
      email: item.email,
    },
    members: {
      projects: item.projectsCount,
      schools: item.schoolsCount,
      supervisors: item.supervisorsCount,
      mentors: item.mentorsCount,
    },
    status: item.displayedStatus ? item.displayedStatus.toLowerCase() : null,
    academies: item.academies,
    regions: item.regions,
  }));
};

export default {
  getFacilitators,
  getProjects,
  filterFacilitators,
};
