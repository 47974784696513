import {call, put, select, takeLatest} from 'redux-saga/effects';
import { NotifServices } from '../../services';
import LoaderActions from '../loader/actions';
import SnackActions from '../snackBar/actions';
import {default as NotifActions, types} from './actions';
import {history} from "../../core/history";
import { downloadAttachment, Routes } from '../../utils';

function* createNotifRequested({notif}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(NotifServices.createNotif, adminId, notif);

  if (!error) {
    yield put(NotifActions.createNotifSucceeded(response.data));
    yield call(history.replace, Routes.NOTIFICATION_LIST);
  } else {
    yield put(SnackActions.displayError('create_notif_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* getNotifListRequested({}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(NotifServices.getNotifs, adminId);

  if (!error) {
    yield put(NotifActions.getNotifListSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('get_notif_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteNotifsRequested({ids}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(NotifServices.deleteNotifs, adminId, ids);

  if (!error) {
    yield put(NotifActions.deleteNotifsSucceeded(ids));
  } else {
    yield put(SnackActions.displayError('delete_notifs_failed'));
  }

  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.CREATE_NOTIF_REQUESTED, createNotifRequested),
  takeLatest(types.GET_NOTIF_LIST_REQUESTED, getNotifListRequested),
  takeLatest(types.DELETE_NOTIFS_REQUESTED, deleteNotifsRequested),
];
