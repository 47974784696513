import React from 'react';
import { icons } from '../../../../../assets';
import { InputTypes, userRole } from '../../../../../utils';
import { Button, ButtonColors, Input } from '../../../../components';
import User from './User';

const School = ({ strings, school, schoolSupervisors, supervisors, distance, isLast, handleAddSupervisor, handleDeleteUser, handleSchoolChanged }) => {
  return (
    <div className={'school'}>
      <div className={'content'}>
        <div className={'line'}>
          <div className={'info'}>
            <p className={'name'}>{school.name}</p>
            <p className={'address'}>{school.address}</p>
          </div>
          {distance && (
            <Button color={ButtonColors.orange} label={strings('button_text_add')} icon={icons.ADD} className={'add'} action={handleAddSupervisor(school.id)} />
          )}
        </div>
        {distance ? (
          <>
            {schoolSupervisors && schoolSupervisors.length > 0 && (
              <div className={'supervisors'}>
                <div className={'dashed-separator'} />
                {schoolSupervisors.map((user, index) => (
                  <User
                    key={user.id}
                    strings={strings}
                    user={user.civility ? user : supervisors.find((usr) => usr.id === user.id)}
                    userRole={userRole.SUPERVISOR}
                    isLast={index === schoolSupervisors.length - 1}
                    handleDeleteUser={handleDeleteUser('supervisors', index)}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <Input
            type={InputTypes.NUMBER}
            tag={'supervisorsCount'}
            label={strings('supervisors_count')}
            className={'supervisors'}
            defaultValue={school.supervisorsCount || ''}
            handleValueChanged={handleSchoolChanged}
          />
        )}
      </div>
      {!isLast && <div className={'dashed-separator'} />}
    </div>
  );
};

export default School;
