import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import {
  formatAcademiesSelector,
  formatEnumForSelector,
  formatRegionsSelector, userRole,
  userStatus,
} from '../../utils';
import { ALL, ITEM_BY_PAGE } from '../../utils/constants';
import { MentorsSelectors } from '../mentors';
import { SupervisorsSelectors } from '../supervisors';

const defaultFilter = {
  search: '',
  region: null,
  academy: null,
};
const getCurrentFilter = state => state.schools.currentFilter || {
  page: 0,
  filter: defaultFilter,
};

const schools = state => state.schools.list || [];
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const strings = state => getTranslate(state.locale);
const school = state => state.schools.school;
const schoolSaved = state => state.schools.schoolSaved;


const getSchools = () => createSelector(strings, userRegion, regions, getCurrentFilter, schools,schoolSaved, (strings, userRegion, regions, currentFilter, schools) => {
  const {filter = defaultFilter, page = 0} = currentFilter;
  const {search, region, academy} = filter;

  //Do not override the region if there is one selected
  const regionFilter = userRegion && !region ? userRegion : region;

  const regionList = formatRegionsSelector(strings, regions);
  const regionObject = regionList.find(search => regionFilter ? search.key === regionFilter : search.key === ALL);
  const regionAcademies = regionObject ? formatAcademiesSelector(strings, regionObject.value.academies) : [];
  const matchingAcademy = regionAcademies.find(search => search.key === academy);
  const academyObject = regionAcademies ? (academy && matchingAcademy && regionFilter !== ALL ? matchingAcademy : regionAcademies.find(search => search.key === ALL)) : null;

  const pageFilter = {
    page: page,
    filter: {
      search: search,
      //Do not override the region if there is one selected
      region: regionObject,
      academy: academyObject,
    },
  };

  const filteredSchools = filterSchools(strings, schools, page, search, regionFilter, academyObject ? academyObject.key : academyObject);

  return {...pageFilter, regionAcademies, ...filteredSchools};
});

const getSchoolsForProjectBinding = (page, search, region, academy) => createSelector(strings, schools, (strings, schools) => {
  return filterSchools(strings, schools, page, search, region, academy)
});


const filterSchools = (strings, schools, page, search, region, academy) => {
  let filteredSchools = schools;

  if (search.length > 0) {
    filteredSchools = filteredSchools
      .filter(p => `${p.name} ${p.postalCode} ${p.address} ${p.city}`.toLowerCase().includes(search.toLowerCase()));
  }
  if (region && region !== ALL) {
    filteredSchools = filteredSchools.filter(p => p.region === region);
  }
  if (academy && academy !== ALL) {
    filteredSchools = filteredSchools.filter(p => p.academy === academy);
  }

  filteredSchools = formatSchools(filteredSchools, strings);

  return {
    all: filteredSchools,
    listed: filteredSchools.slice(page * ITEM_BY_PAGE, page * ITEM_BY_PAGE + ITEM_BY_PAGE), // pour le pagers
    pageCount: Math.ceil(filteredSchools.length / ITEM_BY_PAGE),
  };
};

const formatSchools = (schools) => {
  return schools.map(item => ({
    id: item.id,
    title: item.name,
    details: {
      place: `${item.address} ${item.postalCode} ${item.city}`,
      phone: item.secretariatPhone,
      academy: item.academy,
    },
    members: {
      projects: item.projectsCount,
      supervisors: item.supervisorsCount,
      mentors: item.mentorsCount,
      facilitators: item.facilitatorsCount,
    },
    academy: item.academy,
    region: item.region,
  }));
};

const getSchoolUsers = (page, search, role) => createSelector(strings, school, (strings, school) => {
  const schoolUsers = school.projects.map(project => project.users).reduce((acc, list) => [...acc, ...list], []);
  const distinctUsers = schoolUsers.filter((user, index) => index === schoolUsers.findIndex(searchUsr => searchUsr.id === user.id));

  const usersByRole = distinctUsers.filter(user => user.role === role);
  switch (role) {
    case userRole.MENTOR: {
      return filterUsers(strings, usersByRole, page, search);
    }
    case userRole.SUPERVISOR: {
      return filterUsers(strings, usersByRole, page, search);
    }
    default : {
      return null;
    }
  }
});

const filterUsers = (strings, users, page, search) => {
  let filteredUsers = users;

  if (search.length > 0) {
    filteredUsers = filteredUsers
      .filter(p =>
        `${p.firstName} ${p.lastName}`.toLowerCase().includes(search.toLowerCase()),
      );
  }

  return {
    all: filteredUsers,
    listed: filteredUsers.slice(page * ITEM_BY_PAGE, page * ITEM_BY_PAGE + ITEM_BY_PAGE), // pour le pagers
    pageCount: Math.ceil(filteredUsers.length / ITEM_BY_PAGE),
  };
};


export default {
  getSchools,
  getSchoolsForProjectBinding,
  getSchoolUsers,
};
