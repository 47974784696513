import React from 'react';
import { UserBadge } from '../userBadge';
import { Routes, userRole, civilityValues } from '../../../../utils';
import './user-item.scss';

export const UserItem = ({user, navigateTo, strings}) => {
  let route;
  switch(user.role) {
    case userRole.MENTOR:
      route = Routes.MENTOR.replace(':mentorId', user.id);
      break;
    case userRole.SUPERVISOR:
      route = Routes.SUPERVISOR.replace(':supervisorId', user.id);
      break;
    case userRole.FACILITATOR:
    case userRole.ADMIN:
      route = Routes.FACILITATOR.replace(':facilitatorId', user.id);
      break;
    default:
      route = Routes.DEFAULT;
  }
  
  const civility = user.civility === civilityValues.MR ? strings('mr') : strings('mrs');

  const handleClick = e => {
    if (navigateTo) {
      e.stopPropagation();
      navigateTo(route);
    }
  };

  return (
    <React.Fragment key={user.id}>
      <li 
        className={'user-item'}
        onClick={handleClick}
      >
        <div className={'user-badge'}>
          <UserBadge role={user.role}/>
        </div>
        <div className={'identity'}><p>{`${civility} ${user.firstName} ${user.lastName}`}</p></div>
        <div className={'email'}><p>{user.email}</p></div>
        <div className={'phone'}>{`${user.phone}`}</div>
      </li>
      <div className={'user-separator'}/>
    </React.Fragment>
  )
}
