import React, { PureComponent } from 'react';
import { Button, ButtonColors, Panel, SchoolInfo, SideBar } from '../../../components/index';
import './schoolCreation.scss';

export class SchoolCreation extends PureComponent {

  state = {
    checkForm: false,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {creationSaved, pop,resetCreation} = this.props;

    if (creationSaved) {
      resetCreation();
      pop();
    }
  }

  onClickValidate = () => {
    this.setState({
      checkForm: true,
    });
  };

  saveSchool = (isValid, school) => {
    this.setState({
      checkForm: false,
    });

    if (isValid) {
      this.props.createSchool(school);
    }
  };

  render() {
    const {strings, regions} = this.props;
    const {checkForm} = this.state;

    return (
      <div id={'school-creation-screen'}>
        <div className={'side-bar'}>
          <SideBar />
        </div>
        <div className={'school-creation'}>
          <Panel>
            <SchoolInfo
              strings={strings}
              creationMode={true}
              checkForm={checkForm}
              updateSchool={this.saveSchool}
              regions={regions}
            />
          </Panel>
          <Button
            color={ButtonColors.green}
            label={strings('create')}
            className={'action-button'}
            action={this.onClickValidate}
          />
        </div>
      </div>
    );
  }
};

