import { REGEX } from "../../../../../utils";

export const validateDraft = (mini) => {
  const errors = {};

  const toolsErrors = mini.tools
    .map((tool) => {
      const toolError = {};
      (!tool.label || tool.label.length < 2) &&
        (toolError.label = "partner_name_error");
      tool.url &&
        !REGEX.URL.test(tool.url) &&
        (toolError.url = "tool_link_invalid");
    
      return Object.keys(toolError).length > 0
        ? { key: tool.key, id: tool.id, errors: toolError }
        : {};
    })
    .filter((tool) => Object.keys(tool).length > 0);
    toolsErrors.length > 0 && (errors.tools = toolsErrors);

  return errors;
};
