import React, { useState } from "react";
import { icons, images } from "../../../../assets";
import { ActionPopup, CheckBox } from "../../../components";

const MiniItem = ({ strings, mini, isChecked = false, onCheck, onClick, actions }) => {
  const [displayActions, setDisplayActions] = useState(false);

  const onToggleActions = (e) => {
    e && e.stopPropagation();
    setDisplayActions(!displayActions);
  };

  return (
    <div className={'mini'}>
      {onCheck && (
        <div className={'line'}>
          <div className={'check'}>
            <CheckBox onCheck={onCheck(mini.id)} isChecked={isChecked} />
          </div>
          <div className={'vertical-separator'} />
        </div>
      )}
      <div className={'content column'} onClick={onClick}>
        <div className={'line info'}>
          <div className={'column start'}>
            <p className={'name'}>{mini.name}</p>
            <div className={'separator'} />
            <div className={'partners'}>
              <p>
                {mini.partners.map(partner => (
                  <span key={partner.id}>{partner.name}</span>
                ))}
              </p>
            </div>
          </div>
          <div className={'vertical-separator'} />
          <div className={'line end'}>
            <div className={'column'}>
              <div className={'line date'}>
                <p className={'label'}>{strings('date')}</p>
                <p className={'value'}>{mini.startDate}</p>
              </div>
              <p className={'place'}>
                {mini.distance ? strings('online') : mini.placeName}
              </p>
            </div>
            <div className={'image-wrapper'}>
              <img src={images.epaLogoMiniS} />
            </div>
          </div>
        </div>
        <div className={'separator'} />
        <div className={'line counts'}>
          <div className={'line members'}>
            {Object.entries(mini.members).map(([key, value]) => (
              <div key={key} className={'line item'}>
                <div className={`pins ${key}`}>{value}</div>
                <p className={'category'}>{strings(key)}</p>
              </div>
            ))}
          </div>
          <div className={'line organization'}>
            <div className={'line item'}>
              <div className={'value'}>{mini.studentsCount}</div>
              <i className={'material-icons'}>{icons.PERSON}</i>
              <p className={'category'}>{strings('participants')}</p>
            </div>
            <div className={'line item'}>
              <div className={'value'}>{mini.roomsCount}</div>
              <i className={'material-icons'}>{icons.SCHOOL}</i>
              <p className={'category'}>{strings('rooms')}</p>
            </div>
            <div className={'line item'}>
              <div className={'value'}>{mini.duration ? mini.duration : 0}</div>
              <i className={'material-icons'}>{icons.SCHEDULE}</i>
              <p className={'category'}>{strings('duration')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={'line status'}>
        <div className={'vertical-separator'} />
        <div className={'column'}>
          {actions && (
            <div className={'actions'} onClick={onToggleActions}>
              <i className={'material-icons'}>{icons.THREE_DOT}</i>
            </div>
          )}
          <div className={'status'}>
            <div className={`state ${mini.status.toLowerCase()}`} />
          </div>
        </div>
      </div>

      {displayActions && (
        <ActionPopup
          display={true}
          userConcerned={mini.id}
          tag={''}
          onClose={onToggleActions}
          items={actions}
        />
      )}
    </div>
  );
};

export default MiniItem;
