import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_MINI_LIST_REQUESTED: 'GET_MINI_LIST_REQUESTED',
  GET_MINI_LIST_SUCCEEDED: 'GET_MINI_LIST_SUCCEEDED',
  SET_MINIS_CURRENT_FILTER: 'SET_MINIS_CURRENT_FILTER',
  GET_MINI_REQUESTED: 'GET_MINI_REQUESTED',
  GET_MINI_SUCCEEDED: 'GET_MINI_SUCCEEDED',
  CREATE_MINI_REQUESTED: 'CREATE_MINI_REQUESTED',
  CREATE_MINI_SUCCEEDED: 'CREATE_MINI_SUCCEEDED',
  UPDATE_MINI_REQUESTED: 'UPDATE_MINI_REQUESTED',
  UPDATE_MINI_SUCCEEDED: 'UPDATE_MINI_SUCCEEDED',
  GET_SCHOOLS_FORM_DATA_REQUESTED: 'GET_SCHOOLS_FORM_DATA_REQUESTED',
  GET_TEAM_FORM_DATA_REQUESTED: 'GET_TEAM_FORM_DATA_REQUESTED',
  DUPLICATE_MINI_REQUESTED: 'DUPLICATE_MINI_REQUESTED',
  DUPLICATE_MINI_SUCCEEDED: 'DUPLICATE_MINI_SUCCEEDED',
  DELETE_MINI_REQUESTED: 'DELETE_MINI_REQUESTED',
  DELETE_MINI_SUCCEEDED: 'DELETE_MINI_SUCCEEDED',
  EXPORT_MINI_PROJECTS_REQUESTED: 'EXPORT_MINI_PROJECTS_REQUESTED',
  EXPORT_ARCHIVE_MINI_PROJECTS:'EXPORT_ARCHIVE_MINI_PROJECTS'
};

// Actions
export default {
  getMiniListRequested: () => createAction(types.GET_MINI_LIST_REQUESTED, {}),
  getMiniListSucceeded: list =>
    createAction(types.GET_MINI_LIST_SUCCEEDED, { list }),
  setMinisCurrentFilter: (page, filter) =>
    createAction(types.SET_MINIS_CURRENT_FILTER, { page, filter }),
  getMiniRequested: id => createAction(types.GET_MINI_REQUESTED, { id }),
  getMiniSucceeded: mini => createAction(types.GET_MINI_SUCCEEDED, { mini }),
  createMiniRequested: mini =>
    createAction(types.CREATE_MINI_REQUESTED, { mini }),
  createMiniSucceeded: mini =>
    createAction(types.CREATE_MINI_SUCCEEDED, { mini }),
  updateMiniRequested: mini =>
    createAction(types.UPDATE_MINI_REQUESTED, { mini }),
  updateMiniSucceeded: mini =>
    createAction(types.UPDATE_MINI_SUCCEEDED, { mini }),
  getSchoolsFormDataRequested: () =>
    createAction(types.GET_SCHOOLS_FORM_DATA_REQUESTED, {}),
  getTeamFormDataRequested: () =>
    createAction(types.GET_TEAM_FORM_DATA_REQUESTED, {}),
  duplicateMiniRequested: id =>
    createAction(types.DUPLICATE_MINI_REQUESTED, { id }),
  duplicateMiniSucceeded: mini =>
    createAction(types.DUPLICATE_MINI_SUCCEEDED, { mini }),
  deleteMiniRequested: id => createAction(types.DELETE_MINI_REQUESTED, { id }),
  deleteMiniSucceeded: id => createAction(types.DELETE_MINI_SUCCEEDED, { id }),
  exportMiniProjectsRequested: () =>
    createAction(types.EXPORT_MINI_PROJECTS_REQUESTED, {}),
  exportArchivesMiniProjects: () =>
    createAction(types.EXPORT_ARCHIVE_MINI_PROJECTS, {}),
};
