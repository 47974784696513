import {createSelector} from 'reselect';
import { userRole } from '../../utils';

const user = state => state.user.user;

const isUserAuthenticated = (accessToken) => {
  return accessToken !== null && accessToken !== undefined;
};

const isUserAdmin = createSelector(user, (user) => user.role === userRole.ADMIN);

export default {
  isUserAuthenticated,
  isUserAdmin
}
