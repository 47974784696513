import { toolType } from "../../../../utils";

export const validateLaunch = (mini) => {
  const errors = {};

  !mini.startDate && (errors.startDate = "mini_missing_start_date");
  !mini.subject && (errors.subject = "mini_missing_subject");
  (!mini.partners || mini.partners.length === 0) &&
    (errors.partners = "mini_missing_partner");
  (!mini.schools || mini.schools.length === 0) &&
    (errors.schools = "mini_missing_school");
  mini.schools &&
    mini.schools.some(
      (school) => !school.groups || school.groups.length === 0
    ) &&
    (errors.schools = "mini_missing_group");

  if (
    mini.schools &&
    mini.schools.some(
      (school) =>
        school.groups &&
        school.groups.some(
          (group) =>
            group.boysCount === null ||
            group.girlsCount === null ||
            !group.category ||
            !group.level ||
            !group.sector
        )
    )
  ) {
    errors.groups = "mini_invalid_group";
  }

  if (
    mini.tools &&
    mini.tools.some(
      (tool) =>
        (tool.type === toolType.COMMON_LINK &&
          (!tool.url || !tool.icon || !tool.label)) ||
        (tool.type === toolType.VIDEO && (!tool.video || !tool.label)) ||
        (tool.type === toolType.ROOM_LINK && (!tool.icon || !tool.label))
    )
  ) {
    errors.tools = "mini_invalid_tool";
  }

  mini.distance &&
    (!mini.rooms || mini.rooms.length === 0) &&
    (errors.rooms = "mini_missing_room");

  const roomTools = mini.tools.filter(
    (tool) => tool.type === toolType.ROOM_LINK
  );
  if (
    roomTools &&
    mini.rooms &&
    mini.rooms.some(
      (room) =>
        !room.tools ||
        room.tools.length < roomTools.length ||
        room.tools.some((tool) => !tool.url)
    )
  ) {
    errors.rooms = "mini_invalid_room_tool";
  }

  return errors;
};
