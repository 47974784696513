import React, { useEffect } from 'react';
import { ListWrapper, ScreenWrapper } from '../../../components';
import './miniList.scss';
import MiniItem from './MiniItem';
import { miniStatus, Routes, setSelectorLabel } from '../../../../utils';
import { ActionsBar, Filter } from '../../commons';

const MiniList = ({
  strings,
  exportList,
  exportListArchive,
  minis,
  regions,
  statusFilter,
  getMinis,
  setCurrentFilter,
  updateMini,
  duplicateMini,
  deleteMini,
  showPopup,
  navigateTo,
  isBoUserAdmin,
}) => {
  const initializeFilter = () => {
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        academy: 'all',
        status: 'ALL',
      });
  };
  useEffect(() => {
    getMinis();
    initializeFilter();
  }, []);

  const goToCreate = () => {
    navigateTo(Routes.MINI_CREATION);
  };
  const onClickDownload = () => {
    exportList();
  };
  const onClickDownloadArchive = () => {
    exportListArchive();
  };
  const onFiltersChange = key => value => {
    const { filter } = minis;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, academy, status } = filter;
      let newFilter = {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  const onPageChange = page => {
    const { filter, page: oldPage } = minis;
    const { search, region, academy, status } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      });
    }
  };

  const onClick = id => () => {
    navigateTo(Routes.MINI_DETAIL.replace(':miniId', id));
  };

  const onCheckChange = id => () => {};

  const actions = [
    {
      label: 'duplicate',
      action: id => duplicateMini(id),
    },
    {
      label: 'delete',
      action: id =>
        showPopup(strings('confirm_delete_mini'), () => deleteMini(id)),
    },
    {
      label: 'archive',
      action: id =>
        showPopup(strings('confirm_archive_mini'), () =>
          updateMini({ id, status: miniStatus.ARCHIVED })
        ),
    },
  ];

  const { page, filter, regionAcademies, all, listed, pageCount } = minis;
  const { search, region, academy, status } = filter;

  return (
    <ScreenWrapper id={'miniList'}>
      <Filter
        strings={strings}
        label={`${strings('projects')} (${all.length})`}
        academies={regionAcademies}
        regions={regions}
        status={statusFilter}
        currentSearch={search}
        currentRegion={region}
        currentAcademy={academy}
        currentStatus={status}
        onSearchChange={onFiltersChange('search')}
        onRegionChange={onFiltersChange('region')}
        onAcademyChange={onFiltersChange('academy')}
        onStatusChange={onFiltersChange('status')}
      />
      <ListWrapper
        strings={strings}
        className={`list-wrapper`}
        currentPage={page}
        pageCount={pageCount}
        onPageChange={onPageChange}
      >
        {all.length > 0 ? (
          listed.map(mini => (
            <MiniItem
              key={mini.id}
              strings={strings}
              mini={mini}
              actions={actions}
              onCheck={onCheckChange}
              onClick={onClick(mini.id)}
            />
          ))
        ) : (
          <p className={'no-list'}>{strings('no_minis')}</p>
        )}
      </ListWrapper>
      <ActionsBar
        isBoUserAdmin={isBoUserAdmin}
        strings={strings}
        onClickDownloadArchive={onClickDownloadArchive}
        onClickDownload={onClickDownload}
        onClickCreate={goToCreate}
      />
    </ScreenWrapper>
  );
};

export default MiniList;
