import React, { PureComponent } from 'react';
import { InscriptionsTabs, schoolStatus } from '../../../utils';
import { ListWrapper } from '../../components';
import { ListItem } from '../commons/index';

export class List extends PureComponent {

  state = {
    currentPage: this.props.currentPage ? this.props.currentPage : 0,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.data !== this.props.data && prevState.currentPage !== this.props.currentPage) {
      this.setState({
        currentPage: this.props.currentPage,
      });
    }
  }

  onPageChange = (page) => {
    const {onPageChange} = this.props;

    this.setState({
      currentPage: page,
    });
    onPageChange(page)
  };

  getItemActions = (actions, item, currentTab) => {
    if (currentTab === InscriptionsTabs.SCHOOLS && item.status === schoolStatus.DRAFT.toLocaleLowerCase()) {
      return actions.filter(search => search.isAvailableForDraft)
    }

    return actions;
  };

  render() {
    const {strings, className, tag, data, actions, onClickItem, onCheck, mentorsSelected, currentTab} = this.props;
    const {currentPage} = this.state;
    const {all, listed, pageCount} = data;

    return (
      <ListWrapper
        strings={strings}
        className={`list-wrapper ${className ? className : ''}`}
        currentPage={currentPage}
        pageCount={pageCount}
        onPageChange={this.onPageChange}
        currentTab={currentTab}>
        {all.length > 0
          ? listed.map(
            (item, key) =>
          <ListItem
            key={key}
            strings={strings}
            item={item}
            onClick={onClickItem}
            actions={this.getItemActions(actions, item, currentTab)}
            tag={tag}
            isChecked={mentorsSelected.some(m => m.id === item.id)}
            onCheck={onCheck}
          />
          )
          : <p className={'no-list'}>{strings(`no_${tag}`)}</p>
        }
      </ListWrapper>
    );
  }
}
