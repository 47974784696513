import React, { PureComponent } from 'react';
import { Routes, setSelectorLabel } from '../../../../utils';
import { ListWrapper, ScreenWrapper } from '../../../components';
import { ActionsBar, Filter, ListItem } from '../../commons';
import './facilitatorsList.scss';

export class FacilitatorsList extends PureComponent {
  state = {
    facilitatorsSelected: [],
  };

  componentDidMount() {
    const { getFacilitators } = this.props;
    this.initializeFilter();
    getFacilitators();
  }
  initializeFilter = () => {
    const { setCurrentFilter, isBoUserAdmin } = this.props;
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        status: 'ALL',
      });
  };
  handleSendMail = id => {
    const { facilitators } = this.props;
    const { all } = facilitators;

    const facilitator = all.find(facilitator => facilitator.id === id);
    if (facilitator) {
      window.location.href = `mailto:${facilitator.details.email}`;
    }
  };

  onClick = id => () => {
    const { navigateTo } = this.props;
    navigateTo(Routes.FACILITATOR.replace(':facilitatorId', id));
  };

  onPageChange = page => {
    const { facilitators, setCurrentFilter } = this.props;
    const { filter, page: oldPage } = facilitators;
    const { search, region, status } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        status: status.key,
      });
    }
  };

  onFiltersChange = key => value => {
    const { facilitators, setCurrentFilter } = this.props;
    const { filter } = facilitators;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, status } = filter;
      let newFilter = {
        search,
        region: region.key,
        status: status.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  onClickCreate = () => {
    this.props.navigateTo(Routes.FACILITATOR_CREATION);
  };

  onClickDownload = () => {
    this.props.exportList();
  };

  onCheckChange = all => id => () => {
    const { showSnackBar, setActionSnackBar, hideSnackBar } = this.props;
    const { facilitatorsSelected } = this.state;

    const newFacilitatorsSelected = facilitatorsSelected.some(
      item => item.id === id
    )
      ? facilitatorsSelected.filter(item => !(item.id === id))
      : facilitatorsSelected.concat(all.find(m => m.id === id));

    this.setState({
      facilitatorsSelected: newFacilitatorsSelected,
    });

    setActionSnackBar(this.onMailSend, 'send');
    newFacilitatorsSelected.length > 0
      ? showSnackBar(
          newFacilitatorsSelected.length === 1
            ? 'want_send_mail_facilitator'
            : 'want_send_mail_facilitators'
        )
      : hideSnackBar();
  };

  onMailSend = () => {
    const { facilitatorsSelected } = this.state;

    window.location.href = `mailto:?bcc=${facilitatorsSelected
      .filter(e => (e.details.email !== undefined ? e.details.email : null))
      .map(e => e.details.email)
      .join(';')}`;

    this.setState({
      facilitatorsSelected: [],
    });
  };

  onDeleteFacilitator = id => {
    const { facilitators, deleteFacilitator, setCurrentFilter } = this.props;
    const { page, filter, listed } = facilitators;
    const { search, region, status } = filter;

    deleteFacilitator(id);

    if (listed.length === 1 && page > 0) {
      setCurrentFilter(page - 1, {
        search,
        region: region.key,
        status: status.key,
      });
    }
  };

  render() {
    const { strings, facilitators, regions, statusFilter, showPopup } =
      this.props;
    const { facilitatorsSelected } = this.state;
    const { page, filter, all, listed, pageCount } = facilitators;
    const { search, region, status } = filter;

    const actions = [
      {
        label: 'facilitator_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_facilitator'), () =>
            this.onDeleteFacilitator(id)
          ),
      },
      {
        label: 'send_mail_action',
        action: id => this.handleSendMail(id),
      },
    ];

    return (
      <ScreenWrapper id={'facilitators'}>
        <Filter
          strings={strings}
          label={`${strings('facilitators')} (${all.length})`}
          currentSearch={search}
          currentRegion={region}
          currentStatus={status}
          regions={regions}
          status={statusFilter}
          onSearchChange={this.onFiltersChange('search')}
          onRegionChange={this.onFiltersChange('region')}
          onStatusChange={this.onFiltersChange('status')}
        />
        <ListWrapper
          strings={strings}
          className={`list-wrapper`}
          currentPage={page}
          pageCount={pageCount}
          onPageChange={this.onPageChange}
        >
          {all.length > 0 ? (
            listed.map(item => (
              <ListItem
                key={item.id}
                strings={strings}
                item={item}
                isChecked={facilitatorsSelected.some(f => f.id === item.id)}
                onCheck={this.onCheckChange(all)}
                onClick={this.onClick}
                actions={actions}
              />
            ))
          ) : (
            <p className={'no-list'}>{strings('no_facilitators')}</p>
          )}
        </ListWrapper>
        <ActionsBar
          strings={strings}
          onClickDownload={this.onClickDownload}
          onClickCreate={this.onClickCreate}
        />
      </ScreenWrapper>
    );
  }
}
