import React, { PureComponent } from "react";
import {projectTypeKeys} from "../../../utils";
import { List } from "./List";
import "./params.scss";

export class Params extends PureComponent {
  menu = {
    SUCCESS: {
      key: "SUCCESS",
      label: "successes",
      reordonnable: true,
      loadAction: () => this.props.getSuccesses(),
      saveAction: (type) => (list) => this.props.saveSuccesses(type, list),
    },
    OPERATION: {
      key: "OPERATION",
      label: "operation_types",
      reordonnable: false,
      loadAction: () => this.props.getOperations(),
      saveAction: (list) => this.props.saveOperations(list),
    },
    CATEGORY: {
      key: "CATEGORY",
      label: "class_categories",
      reordonnable: false,
      loadAction: () => this.props.getCategories(),
      saveAction: (list) => this.props.saveCategories(list),
    },
    LEVEL: {
      key: "LEVEL",
      label: "class_levels",
      reordonnable: false,
      loadAction: () => this.props.getLevels(),
      saveAction: (list) => this.props.saveLevels(list),
    },
    SECTOR: {
      key: "SECTOR",
      label: "class_sectors",
      reordonnable: false,
      loadAction: () => this.props.getSectors(),
      saveAction: (list) => this.props.saveSectors(list),
    },
    TIME: {
      key: "TIME",
      label: "dedicated_times",
      reordonnable: false,
      loadAction: () => this.props.getTimes(),
      saveAction: (list) => this.props.saveTimes(list),
    },
    LOGS: {
      key: "LOGS",
      label: "logs",
      reordonnable: false,
      loadAction: () => this.props.getLogs(),
      saveAction: (list) => this.props.saveLogs(list),
    }

  };

  state = {
    selectedMenuEntry: this.menu.SUCCESS,
  };

  componentDidMount() {
    const { selectedMenuEntry } = this.state;
    selectedMenuEntry.loadAction();
  }

  handleMenuItemClick = (item) => (_) => {
    const { selectedMenuEntry } = this.state;
    if (selectedMenuEntry.key !== item.key) {
      this.setState({ selectedMenuEntry: item });
      item.loadAction();
    }
  };

  getMenuItemList = (key) => {
    switch (key) {
      case this.menu.OPERATION.key:
        return this.props.operations;
      case this.menu.CATEGORY.key:
        return this.props.categories;
      case this.menu.LEVEL.key:
        return this.props.levels;
      case this.menu.SECTOR.key:
        return this.props.sectors;
      case this.menu.TIME.key:
        return this.props.times;
      case this.menu.LOGS.key:
        return this.props.logs;
      default:
        return [];
    }
  };

  getList = (menuEntry) => {
    const {
      strings,
      operations,
      successesM9,
      successesM13,
      successesChallenge,
      successesL,
      successesXL,
      successesS,
        logs
    } = this.props;

    if (menuEntry.key === this.menu.SUCCESS.key) {
      return (
        <div>
          <List
            strings={strings}
            title={strings("short_success")}
            data={successesS}
            reordonnable={menuEntry.reordonnable}
            saveAction={menuEntry.saveAction(projectTypeKeys.S)}
          />
          <List
            strings={strings}
            title={strings("m9_success")}
            data={successesM9}
            reordonnable={menuEntry.reordonnable}
            saveAction={menuEntry.saveAction(projectTypeKeys.M9)}
          />
          <List
            strings={strings}
            title={strings("m13_success")}
            data={successesM13}
            reordonnable={menuEntry.reordonnable}
            saveAction={menuEntry.saveAction(projectTypeKeys.M13)}
          />
          <List
            strings={strings}
            title={strings("long_success")}
            data={successesL}
            reordonnable={menuEntry.reordonnable}
            saveAction={menuEntry.saveAction(projectTypeKeys.L)}
          />
          <List
            strings={strings}
            title={strings("xlong_success")}
            data={successesXL}
            reordonnable={menuEntry.reordonnable}
            saveAction={menuEntry.saveAction(projectTypeKeys.XL)}
          />
          <List
            strings={strings}
            title={strings("challenge_success")}
            data={successesChallenge}
            reordonnable={menuEntry.reordonnable}
            saveAction={menuEntry.saveAction(projectTypeKeys.X10)}
          />
        </div>
      );
    }

    if (menuEntry.key === this.menu.LOGS.key) {
      return (
          <div>
            <List
                strings={strings}
                title={strings("logs")}
                data={logs}
                reordonnable={menuEntry.reordonnable}
                saveAction={menuEntry.saveAction}
            />
          </div>
      );
    }

    const data = this.getMenuItemList(menuEntry.key);
    return (
      <List
        strings={strings}
        title={strings(menuEntry.label)}
        data={data}
        reordonnable={menuEntry.reordonnable}
        isOperation={menuEntry.key === this.menu.OPERATION.key}
        saveAction={menuEntry.saveAction}
      />
    );
  };

  render() {
    const { strings } = this.props;
    const { selectedMenuEntry } = this.state;

    return (
      <div id={"params"}>
        <ul className={"menu"}>
          {Object.values(this.menu).map((item) => (
            <li
              key={item.key}
              className={`${
                item.key === selectedMenuEntry.key ? "selected" : ""
              }`}
              onClick={this.handleMenuItemClick(item)}
            >
              <p>{strings(item.label)}</p>
            </li>
          ))}
        </ul>
        <div className={"container"}>{this.getList(selectedMenuEntry)}</div>
      </div>
    );
  }
}
