import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_PROJECTS_REQUESTED: 'GET_PROJECTS_REQUESTED',
  GET_PROJECTS_SUCCEEDED: 'GET_PROJECTS_SUCCEEDED',
  SET_PROJECTS_CURRENT_FILTER: 'SET_PROJECTS_CURRENT_FILTER',
  GET_PROJECT_DETAIL_REQUESTED: 'GET_PROJECTS_DETAILS_REQUESTED',
  GET_PROJECT_DETAIL_SUCCEEDED: 'GET_PROJECTS_DETAILS_SUCCEEDED',
  LINK_USER_REQUESTED: 'LINK_USER_REQUESTED',
  LINK_USER_SUCCEEDED: 'LINK_USER_SUCCEEDED',
  UNLINK_USER_REQUESTED: 'UNLINK_USER_REQUESTED',
  UNLINK_USER_SUCCEEDED: 'UNLINK_USER_SUCCEEDED',
  DELETE_PROJECT_REQUESTED: 'DELETE_PROJECT_REQUESTED',
  ARCHIVE_PROJECT_REQUESTED: 'ARCHIVE_PROJECT_REQUESTED',
  ARCHIVE_PROJECT_SUCCEEDED: 'ARCHIVE_PROJECT_SUCCEEDED',
  ARCHIVE_PROJECTS_REQUESTED: 'ARCHIVE_PROJECTS_REQUESTED',
  CHANGE_TO_FINISHED_PROJECTS_REQUESTED: 'CHANGE_TO_FINISHED_PROJECTS_REQUESTED',
  UPDATE_INFO_REQUESTED: 'UPDATE_INFO_REQUESTED',
  UPDATE_INFO_SUCCEEDED: 'UPDATE_INFO_SUCCEEDED',
  CREATE_PROJECT_REQUESTED: 'CREATE_PROJECT_REQUESTED',
  CREATE_PROJECT_SUCCEEDED: 'CREATE_PROJECT_SUCCEEDED',
  RESET_DETAIL_PROJECT: 'RESET_DETAIL_PROJECT',
  EXPORT_PROJECTS_REQUESTED: 'EXPORT_PROJECTS_REQUESTED',
  EXPORT_ARCHIVES_PROJECTS_REQUESTED: 'EXPORT_ARCHIVES_PROJECTS_REQUESTED',
};

// Actions
export default {
  getProjectsRequested: () => createAction(types.GET_PROJECTS_REQUESTED, {}),
  getProjectsSucceeded: list =>
    createAction(types.GET_PROJECTS_SUCCEEDED, { list }),
  setProjectsCurrentFilter: (page, filter) =>
    createAction(types.SET_PROJECTS_CURRENT_FILTER, { page, filter }),
  getProjectDetailRequested: id =>
    createAction(types.GET_PROJECT_DETAIL_REQUESTED, { id }),
  getProjectDetailSucceeded: project =>
    createAction(types.GET_PROJECT_DETAIL_SUCCEEDED, { project }),
  linkUserRequested: (users, projectId) =>
    createAction(types.LINK_USER_REQUESTED, { users, projectId }),
  linkUserSucceeded: project =>
    createAction(types.LINK_USER_SUCCEEDED, { project }),
  unlinkUserRequested: (user, role, projectId) =>
    createAction(types.UNLINK_USER_REQUESTED, { user, role, projectId }),
  unlinkUserSucceeded: project =>
    createAction(types.UNLINK_USER_SUCCEEDED, { project }),
  deleteProjectRequested: projectId =>
    createAction(types.DELETE_PROJECT_REQUESTED, { projectId }),
  archiveProjectRequested: projectId =>
    createAction(types.ARCHIVE_PROJECT_REQUESTED, { projectId }),
  archiveProjectSucceeded: project =>
    createAction(types.ARCHIVE_PROJECT_SUCCEEDED, { project }),
  archiveProjectsRequested: projectsIds =>
    createAction(types.ARCHIVE_PROJECTS_REQUESTED, { projectsIds }),
  changeToFinishedProjectsRequested: projectsIds =>
    createAction(types.CHANGE_TO_FINISHED_PROJECTS_REQUESTED, { projectsIds }),
  updateInfoRequested: projectInfo =>
    createAction(types.UPDATE_INFO_REQUESTED, { projectInfo }),
  updateInfoSucceeded: project =>
    createAction(types.UPDATE_INFO_SUCCEEDED, { project }),
  createProjectRequested: newProject =>
    createAction(types.CREATE_PROJECT_REQUESTED, { newProject }),
  createProjectSucceeded: project =>
    createAction(types.CREATE_PROJECT_SUCCEEDED, { project }),
  resetDetailRequested: () => createAction(types.RESET_DETAIL_PROJECT, {}),
  exportProjectsRequested: () =>
    createAction(types.EXPORT_PROJECTS_REQUESTED, {}),
  exportArchivesProjectsRequested: () =>
    createAction(types.EXPORT_ARCHIVES_PROJECTS_REQUESTED, {}),
};
