import React, { Fragment, useEffect, useState } from 'react';
import { userRole } from '../../../../utils';
import { Button, ButtonColors } from '../../../components/buttons';
import User from '../../user';
import { validateFreemiumForm } from './FormValidator';
import './freemiumDetail.scss';
import { UpgradeToMentorPopup } from './popups/UpgradeToMentorPopup';
import { UpgradeToSupervisorPopup } from './popups/UpgradeToSupervisorPopup';

export const FreemiumDetail = ({getFreemium, freemium, match, strings, pop, updateFreemium, upgradeFreemium, resetFreemiumDetail}) => {
  useEffect(() => {
    getFreemium(match.params.freemiumId);
  }, [match.params.freemiumId, getFreemium]);

  useEffect(() => {
    return () => {
      resetFreemiumDetail();
    }
  }, [resetFreemiumDetail]);

  const [shouldDisplaySupervisorPopup, setShouldDisplaySupervisorPopup] = useState(false);
  const [shouldDisplayMentorPopup, setShouldDisplayMentorPopup] = useState(false);

  const updateUser = (isValid, result) => {
    if (isValid && JSON.stringify(result) !== JSON.stringify(freemium)) {
      updateFreemium(result);
    }
  };

  const toggleSupervisorPopupVisibility = () => {
    setShouldDisplaySupervisorPopup(!shouldDisplaySupervisorPopup);
  };

  const upgradeToSupervisor = (phone) => {
    setShouldDisplaySupervisorPopup(false);
    upgradeFreemium(freemium.id, {
      role: userRole.SUPERVISOR,
      phone: phone ? phone : freemium.phone,
    });
  };

  const toggleMentorPopupVisibility = () => {
    setShouldDisplayMentorPopup(!shouldDisplayMentorPopup);
  };

  const upgradeToMentor = (mentorInfo) => {
    setShouldDisplayMentorPopup(false);
    upgradeFreemium(freemium.id, {role: userRole.MENTOR, ...mentorInfo});
  };

  return <Fragment>
    <User
      user={freemium}
      validateUserForm={validateFreemiumForm}
      pop={pop}
      back={strings('all_freemiums')}
      updateUser={updateUser}>
      <div className={'upgrade-actions'}>
        <Button
          color={ButtonColors.green}
          enabled={freemium.id}
          label={strings('upgrade_supervisor')}
          action={toggleSupervisorPopupVisibility}
        />
        <Button
          color={ButtonColors.blue}
          enabled={freemium.id}
          label={strings('upgrade_mentor')}
          action={toggleMentorPopupVisibility}
        />
      </div>
    </User>
    {shouldDisplaySupervisorPopup &&
    <UpgradeToSupervisorPopup
      strings={strings}
      onCancel={toggleSupervisorPopupVisibility}
      onValidate={upgradeToSupervisor}
      showForm={!freemium.phone}
    />
    }
    {shouldDisplayMentorPopup &&
    <UpgradeToMentorPopup
      strings={strings}
      onCancel={toggleMentorPopupVisibility}
      onValidate={upgradeToMentor}
      phone={freemium.phone}
    />
    }
  </Fragment>;
};
