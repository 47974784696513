import { REGEX } from '../../../../../utils/index';

export const validateUserForm = (user, mentorProfile, errors, mentorErrors) => {
  // Validate user
  const {civility, firstName, lastName, email, phone, region} = user;

  errors.civility.onError = !civility;
  errors.civility.errorMsg = 'input_require';
  errors.firstName.onError = !firstName || firstName.length < 2;
  errors.firstName.errorMsg = 'input_require';
  errors.lastName.onError = !lastName || lastName.length < 2;
  errors.lastName.errorMsg = 'input_require';
  errors.email.onError = !REGEX.EMAIL.test(email);
  errors.email.errorMsg = !email ? 'input_require' : 'wrong_email';
  errors.phone.onError = !phone || !REGEX.PHONE.test(phone);
  errors.phone.errorMsg = !phone ? 'input_require' : 'wrong_phone';
  errors.region.onError = !region || region.length < 2;
  errors.region.errorMsg = 'input_require';

  // Validate mentor profile
  const {description, companyName, companyAddress, companyPostalCode, companyCity, companyCountry, ceoLastName, ceoFirstName, ceoPhone, ceoEmail} = mentorProfile;
  mentorErrors.description.onError = !description || description.length < 2;
  mentorErrors.description.errorMsg = 'input_require';
  mentorErrors.companyName.onError = !companyName || companyName.length < 2;
  mentorErrors.companyName.errorMsg = 'input_require';
  mentorErrors.companyAddress.onError = !companyAddress || companyAddress.length < 2;
  mentorErrors.companyAddress.errorMsg = 'input_require';
  mentorErrors.companyPostalCode.onError = !companyPostalCode || companyPostalCode.length < 2;
  mentorErrors.companyPostalCode.errorMsg = 'input_require';
  mentorErrors.companyCity.onError = !companyCity || companyCity.length < 2;
  mentorErrors.companyCity.errorMsg = 'input_require';
  mentorErrors.companyCountry.onError = !companyCountry || companyCountry.length < 2;
  mentorErrors.companyCountry.errorMsg = 'input_require';
  mentorErrors.ceoLastName.onError = ceoLastName && ceoLastName.length < 2;
  mentorErrors.ceoLastName.errorMsg = 'input_invalid';
  mentorErrors.ceoFirstName.onError = ceoFirstName && ceoFirstName.length < 2;
  mentorErrors.ceoFirstName.errorMsg = 'input_invalid';
  mentorErrors.ceoPhone.onError = ceoPhone && !REGEX.PHONE.test(ceoPhone);
  mentorErrors.ceoPhone.errorMsg = mentorErrors.ceoPhone.onError && ceoPhone.value && ceoPhone.length < 1 ? 'input_require' : 'wrong_phone';
  mentorErrors.ceoEmail.onError = ceoEmail && !REGEX.EMAIL.test(ceoEmail);
  mentorErrors.ceoEmail.errorMsg = mentorErrors.ceoEmail.onError && ceoEmail && ceoEmail.length < 1 ? 'input_require' : 'wrong_email';

  return {errors, mentorErrors};
};



