import React, { PureComponent } from 'react';
import { userRole } from '../../../../utils';
import { Button, ButtonColors, Panel, SideBar, UserSummary } from '../../../components/index';
import { validateUserForm } from '../details/FormValidator';
import './facilitatorCreation.scss';

export class FacilitatorCreation extends PureComponent {

  state = {
    checkForm: false,
  };

  onClickValidate = () => {
    this.setState({
      checkForm: true,
    });
  };

  saveFacilitator = (isValid, facilitator) => {
    this.setState({
      checkForm: false,
    });

    if (isValid) {
      this.props.createFacilitator(facilitator);
    }
  };

  render() {
    const {strings} = this.props;
    const {checkForm} = this.state;

    return (
      <div id={'facilitator-creation-screen'}>
        <div className={'side-bar'}>
          <SideBar />
        </div>
        <div className={'facilitator-creation'}>
          <Panel>
            <UserSummary
              userFound={false}
              strings={strings}
              checkForm={checkForm}
              validateUserForm={validateUserForm}
              updateUser={this.saveFacilitator}
              user={{role: userRole.FACILITATOR}}
              creationMode={true}/>
          </Panel>
          <Button
            color={ButtonColors.green}
            label={strings('create')}
            action={this.onClickValidate}
          />
        </div>
      </div>
    );
  }
};

