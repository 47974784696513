import React from 'react';
import { projectStatus, schoolStatus } from '../../../../../utils';
import {
  SideBar,
  Panel,
  ProjectsList,
  SchoolInfo,
  Button,
  ButtonColors,
} from '../../../../components/index';
import './schoolDetails.scss';
import { RecordInfo } from '../../../../components/recordInfo';

export const SchoolDetails = ({strings, pop, school, updateSchool, handleDeletion, handleReinscription, handleEditConvention, handleValidationSchool, regions, goToPrevious}) => {
  const menu = {
    detailsSchool: {id: 'school-info', label: strings('details')},
    detailsRecord: {id: 'school-record', label: strings('record')},
    detailsProjects: {id: 'projects-list', label: strings('projects')},
  };

  if (!school.record) {
    delete menu.detailsRecord;
  }

  if (!school) return null;

  return (
    <div id={'school-details'}>
      <div className={'side-bar'}>
        <div className={'wrapper'}>
          <SideBar
            menu={menu}
            goToPrevious={goToPrevious} />
          <div className={'actions'}>
            {handleEditConvention &&
            <Button
              color={ButtonColors.blue}
              label={strings('school_convention_action')}
              action={handleEditConvention}
            />
            }
            {handleValidationSchool &&
            <Button
              color={ButtonColors.blue}
              label={strings('school_validate_action')}
              action={handleValidationSchool}
            />
            }
            {handleReinscription &&
            <Button
              color={ButtonColors.blue}
              label={strings('manual_reinscription')}
              action={handleReinscription}
            />
            }
            <Button
              color={ButtonColors.blue}
              label={strings('delete')}
              action={handleDeletion}
            />
          </div>
        </div>
      </div>
      <div className={'school-details-panel'}>
        <Panel className={'school-info'}>
          <SchoolInfo
            school={school}
            isDraft={school.status === schoolStatus.DRAFT}
            strings={strings}
            updateSchool={updateSchool}
            regions={regions}
          />
        </Panel>
        {school.record &&
        <Panel className={'school-record'}>
          <RecordInfo
            record={school.record}
            updateDate={school.updatedAt}
            strings={strings}
          />
        </Panel>
        }
        <Panel className={'projects-list'}>
          <ProjectsList
            projects={school.projects}
            strings={strings}
            isEditable={false}
            allowDetailNavigation={school.status === schoolStatus.VALIDATED}
          />
        </Panel>
      </div>
    </div>
  );
};

