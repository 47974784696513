import { createAction } from '../../utils';

// Types
export const types = {
  DISPLAY_CONFIRM_POPUP: 'DISPLAY_CONFIRM_POPUP',
  CONFIRM_CONFIRM_POPUP: 'CONFIRM_CONFIRM_POPUP',
  REFUSE_CONFIRM_POPUP: 'REFUSE_CONFIRM_POPUP',
  HIDE_CONFIRM_POPUP: 'HIDE_CONFIRM_POPUP',
};

// Actions
export default {
  displayPopup: (message, popupAction, title = null) => createAction(types.DISPLAY_CONFIRM_POPUP, {message, popupAction, title}),
  hidePopup : () => createAction(types.HIDE_CONFIRM_POPUP, {}),
  confirmPopup: () => createAction(types.CONFIRM_CONFIRM_POPUP, {}),
  refusePopup: () => createAction(types.REFUSE_CONFIRM_POPUP, {}),
};
