import { getTranslate } from "react-localize-redux";
import { createSelector } from "reselect";
import {
  formatAcademiesSelector,
  formatRegionsSelector,
} from "../../utils";
import { ALL, ITEM_BY_PAGE } from "../../utils/constants";

const defaultFilter = {
  search: "",
  region: null,
  academy: null,
};

const getCurrentFilter = (state) =>
  state.notifications.currentFilter || {
    page: 0,
    filter: defaultFilter,
  };

const notifs = (state) => state.notifications.list || [];
const userRegion = (state) => state.user.user.region || null;
const regions = (state) => state.commons.regions || [];
const strings = (state) => getTranslate(state.locale);

const getNotifications = () =>
  createSelector(
    strings,
    userRegion,
    regions,
    getCurrentFilter,
    notifs,
    (strings, userRegion, regions, currentFilter, notifs) => {
      const { filter = defaultFilter, page = 0 } = currentFilter;
      const { search, region, academy } = filter;

      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;

      const regionList = formatRegionsSelector(strings, regions);
      const regionObject = regionList.find((search) =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );

      const regionAcademies = regionObject ? formatAcademiesSelector(strings, regionObject.value.academies) : [];
  const matchingAcademy = regionAcademies.find(search => search.key === academy);
  const academyObject = regionAcademies ? (academy && matchingAcademy && regionFilter !== ALL ? matchingAcademy : regionAcademies.find(search => search.key === ALL)) : null;


      const pageFilter = {
        page: page,
        filter: {
          search: search,
          region: regionObject,
          academy: academyObject,
        },
      };

      const filteredNotifs = filterNotifs(
        strings,
        notifs,
        page,
        search,
        regionObject,
        academyObject.key
      );

      return { ...pageFilter, regionAcademies, ...filteredNotifs };
    }
  );

const filterNotifs = (strings, notifs, page, search, region, academy) => {
  let filteredNotifs = notifs;

  if (search.length > 0) {
    filteredNotifs = filteredNotifs.filter((item) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    );
  }

  if (region && region.key !== ALL) {
    filteredNotifs = filteredNotifs.filter(
      (p) =>
        (p.recipients.regions && p.recipients.regions.length > 0 && p.recipients.regions.some((r) => r === region.key)) ||
        (p.recipients.academies && p.recipients.academies.length > 0 && p.recipients.academies.some((a) => region.value.academies.some((s) => s === a)))
    );
  }

  if (academy && academy !== ALL) {
    filteredNotifs = filteredNotifs.filter((p) => (p.recipients.academies && p.recipients.academies.length > 0 && p.recipients.academies.some((a) => a === academy)));
  }

  return {
    all: filteredNotifs,
    listed: filteredNotifs.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredNotifs.length / ITEM_BY_PAGE),
  };
};

export default {
  getNotifications,
};
