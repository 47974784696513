import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { FreemiumsActions } from '../../../../redux/freemiums';
import { FreemiumCreation } from './FreemiumCreation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  createFreemium: freemium => dispatch(FreemiumsActions.createFreemiumRequested(freemium)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FreemiumCreation);
