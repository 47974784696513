export const icons = {
  ADD: 'add',
  CANCEL: 'cancel',
  CLOSE: 'close',
  MAIL: 'mail_outline',
  EYE: 'remove_red_eye',
  PHONE: "phone",
  ARROW_BACK: "arrow_back",
  ARROW_FORWARD: "arrow_forward",
  ARROW_DROPDOWN: 'arrow_drop_down',
  THREE_DOT: 'more_vert',
  LINK: 'link',
  LOCK: 'lock',
  MENU: 'menu',
  LOGOUT: 'power_settings_new',
  SETTINGS: 'settings',
  EDIT: 'edit',
  DONE: 'done',
  SEARCH: 'search',
  DOWNLOAD: 'file_download',
  DELETE: 'delete',
  CHEVRON_RIGHT: 'chevron_right',
  CALENDAR: 'date_range',
  SCHEDULE: 'schedule',
  DRAG: 'drag_indicator',
  SCHOOL: 'school',
  PERSON: 'person',
};
