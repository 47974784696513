import React, { PureComponent } from 'react';
import { projectStatus } from '../../../../utils';
import {
  Button,
  ButtonColors,
  SchoolsList,
  SideBar,
} from '../../../components';
import './project.scss';
import { Panel } from '../../../components/panel';
import ProjectInfo from '../info';
import ProjectUsers from './users';

export class Project extends PureComponent {
  state = {
    users: this.props.project.users,
    project: this.props.project,
    lateralMenu: false,
    usersSelected: [],
    roleSelected: undefined,
    canAdd: false,
  };

  componentDidMount() {
    const { getProjectsDetails } = this.props;
    const projectId = this.props.match.params.projectId;

    getProjectsDetails(projectId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { project } = this.props;

    if (prevProps.project !== project) {
      this.setState({
        users: project.users,
        project: project,
      });
    }

    this.setState({
      canAdd: this.state.usersSelected.length > 0,
    });
  }

  componentWillUnmount() {
    const { resetDetailRequested } = this.props;
    resetDetailRequested();
  }

  onAdd = () => {
    const { linkUserRequested, project } = this.props;
    const { usersSelected } = this.state;

    this.setState({ lateralMenu: false, usersSelected: [] });

    linkUserRequested(usersSelected, project.id);
  };

  onDelete = () => {
    const { strings, project, showPopup, deleteProject } = this.props;

    showPopup(strings('confirm_delete_project'), () =>
      deleteProject(project.id)
    );
  };

  onArchive = () => {
    const { strings, project, showPopup, archiveProject } = this.props;

    showPopup(
      strings('confirm_archive_project'),
      () => archiveProject(project.id),
      strings('confirm_warning')
    );
  };

  onAccess = () => {
    window.open(
      `${process.env.REACT_APP_DASHBOARD_URL}/projects/${this.props.project.id}`,
      '_blank'
    );
  };

  render() {
    const { project } = this.state;
    const { school } = project;
    const { strings, navigateTo, isBoUserAdmin } = this.props;

    const menu = {
      detailsProject: {
        id: 'project-info',
        label: strings('details'),
      },
      detailsUsers: {
        id: 'project-users',
        label: strings('users'),
      },
      detailsSchool: {
        id: 'project-schools',
        label: strings('school'),
      },
    };

    return (
      <div id={'project-screen'}>
        <div className={'side-bar'}>
          <div className={'wrapper'}>
            <SideBar menu={menu} />
            <div className={'actions'}>
              <Button
                color={ButtonColors.blue}
                label={strings('delete')}
                action={this.onDelete}
              />
              {(project.status === projectStatus.VALIDATED || project.status === projectStatus.COMMING || project.status === projectStatus.FINISHED) && (
                <>
                  <Button
                    color={ButtonColors.blue}
                    label={strings('archive')}
                    action={this.onArchive}
                  />
                  <Button
                    color={ButtonColors.blue}
                    label={strings('project_access_action')}
                    action={this.onAccess}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={'project'}>
          <ProjectInfo />
          <ProjectUsers projectId={project.id} navigateTo={navigateTo} />
          <Panel className={'project-schools'}>
            <SchoolsList
              schools={school ? [school] : null}
              strings={strings}
              navigateTo={navigateTo}
            />
          </Panel>
        </div>
      </div>
    );
  }
}
