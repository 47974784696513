import React from 'react';
import { icons } from '../../../../assets/icons/materialIcons';
import { getProjectImage, projectDuration, reinscriptionProjectTypes, userRole } from '../../../../utils';
import { ButtonColors, FormDropDown, RadioButton, SelectorButton } from '../../../components';
import { UserForm } from './UserForm';
import { UserItem } from './UserItem';

const actions = {
  EXISTING_SUPERVISOR: 'EXISTING_SUPERVISOR',
  NEW_SUPERVISOR: 'NEW_SUPERVISOR',
  EXISTING_MENTOR: 'EXISTING_MENTOR',
};

export const ProjectItem = ({strings, className, project, index, editedSupervisor, projectsUsers, handleValueChanged, handleAddExistingUser, handleAddNewUser, handleRemoveExistingUser, handleRemoveNewUser, handleEditNewUser, handleUpdateUser, handleDeleteProject, handleAddFiledUser, handleDeleteSupervisor, handleAddSupervisor, handleProjectSupervisorChanged, switchToEditMode, conflictingEmails}) => {

  const handleAddUser = item => {
    if (item.key === actions.NEW_SUPERVISOR) {
      handleAddNewUser(index);
    } else {
      handleAddExistingUser(item.key === actions.EXISTING_MENTOR ? userRole.MENTOR : userRole.SUPERVISOR);
    }
  };

  const handleDeleteExistingUser = (index, id) => () => handleRemoveExistingUser(index, id);

  const handleDeleteNewUser = (index, key) => () => handleRemoveNewUser(index, key);

  const handleEditUser = (index, key) => () => handleEditNewUser(index, key);

  const handleAddUserFromDropDown = (tag, value) => {
    const user = supervisorsForDropDown.find(user => user.key === value.key);
    if (user) {
      handleAddFiledUser(index, user);
    }
  };

  const durationList = [
    {
      key: projectDuration.ONE_YEAR,
      value: {
        label: strings('duration_one_year'),
      },
    },
    {
      key: projectDuration.TWO_YEARS,
      value: {
        label: `${projectDuration.TWO_YEARS} ${strings('duration_several_years')}`,
      },
    },
  ];
  const currentDuration = project.duration ? durationList.find(search => search.key === project.duration) : null;

  const actionList = [
    {key: actions.EXISTING_SUPERVISOR, value: strings('add_existing_supervisor')},
    {key: actions.NEW_SUPERVISOR, value: strings('add_new_supervisor')},
    {key: actions.EXISTING_MENTOR, value: strings('add_mentor')},
  ];

  const projectUsersEmails = project.users ? project.users.map(user => {
    if (user.id) {
      return user.email;
    } else {
      return user.email.value;
    }
  }) : [];
  const supervisorsForDropDown = projectsUsers ? projectsUsers.filter(user => !user.id && !projectUsersEmails.includes(user.email.value)) : [];
  const dropDownList = supervisorsForDropDown.map(sup => ({
    value: {label: `${sup.firstName.value} ${sup.lastName.value}`},
    key: sup.key,
  }));

  return (
    <div className={`section ${className ? className : ''}`}>
      <div className={'info'}>
        <p className={'title'}>{`${strings('project_title')}${index + 1}`}</p>
        <div className={'type'}>
          {reinscriptionProjectTypes.map(({value}) =>
            <RadioButton
              key={value}
              className={'medium'}
              child={getProjectImage(strings, value)}
              value={value}
              isChecked={value === project.type}
              onChange={handleValueChanged('type')}
            />,
          )}
        </div>
        <div className={'delete-wrapper'} onClick={handleDeleteProject(index)}>
          <i className={'material-icons delete'}>{icons.DELETE}</i>
        </div>
      </div>
      <div className={'horizontal-dot-separator'}/>
      <div className={'duration-wrapper'}>
        <FormDropDown
          tag={'duration'}
          className={'duration'}
          label={strings('duration')}
          data={durationList}
          current={currentDuration ? currentDuration.value.label : null}
          dropDownClassName={`${project.onError && !currentDuration ? 'invalid' : ''}`}
          error={project.onError && !currentDuration ? strings('input_require') : null}
          onValueChange={(_, value) => handleValueChanged('duration')(value.key)}
        />
        <SelectorButton
          color={ButtonColors.orange}
          label={strings('add_user')}
          icon={icons.ADD}
          className={`add-button`}
          action={handleAddUser}
          data={actionList}
          enabled={!editedSupervisor}
        />
      </div>
      {index > 0 && supervisorsForDropDown && supervisorsForDropDown.length > 0 &&
      <FormDropDown
        tag={'supervisor'}
        className={'supervisor-selector'}
        label={strings('supervisors')}
        data={dropDownList}
        onValueChange={handleAddUserFromDropDown}
      />
      }
      <div className={'users'}>
        <p className={'label'}>{strings('users')}</p>
        <div className={'horizontal-dot-separator'}/>
        {
          project.users &&
          <>
            <ul className={'existing'}>
              {project.users.filter(user => user.id).map(user =>
                <li key={user.id}>
                  <div className={'wrapper'}>
                    <UserItem
                      strings={strings}
                      user={user}
                      role={user.role}/>
                    <i className="material-icons"
                       onClick={handleDeleteExistingUser(index, user.id)}>{icons.DELETE}</i>
                  </div>
                  <div className={'horizontal-dot-separator'}/>
                </li>,
              )}
            </ul>
            <ul className={'new'}>
              {project.users.filter(user => !user.id).map(user =>
                <li key={user.key}>
                  <UserForm
                    strings={strings}
                    user={user}
                    editable={!editedSupervisor}
                    isEditing={editedSupervisor && editedSupervisor.user === user.key && editedSupervisor.project === index}
                    projectsUsers={projectsUsers}
                    handleUpdateUser={handleUpdateUser}
                    handleDeleteUser={handleDeleteNewUser(index, user.key)}
                    handleEditUser={handleEditUser(index, user.key)}/>
                </li>,
              )}
            </ul>
          </>
        }
      </div>
      {project.onError &&
      <div className={'error'}>
        <p>{strings(project.errorMsg)}</p>
      </div>
      }
      <div className={'horizontal-plain-separator'}/>
    </div>
  );
};
