import { FORBIDDEN, UNAUTHORIZED } from 'http-status-codes';
import { call, put, select } from 'redux-saga/effects';
import { default as AuthActions } from '../redux/auth/actions';
import { default as AuthorizeService } from './authorize';
import { clearUrlParams, fetch, ERROR_INVALID_TOKEN } from '../utils';

function* authorize(service) {
  const { refreshToken, accessToken } = yield select((state) => state.auth);
  service.headers = { ...service.headers, Authorization: `Bearer ${accessToken}` };
  let [error, response] = yield call(fetch, service);

  if (error && error.response.status === UNAUTHORIZED) {
    const [refreshError, refreshResponse] = yield call(retryingRefresh, 2);

    if (refreshError || !refreshResponse.data.id || !refreshResponse.data.accessToken || !refreshResponse.data.refreshToken) {
      window.location = `${process.env.REACT_APP_AUTH_URL}?origin=F&redirectUrl=${clearUrlParams(window.location.href)}`;
      return [refreshError];
    }

    yield put(AuthActions.refreshTokensSucceed(refreshResponse.data.id, refreshResponse.data.accessToken, refreshResponse.data.refreshToken));

    [error, response] = yield call(authorize, service);
  } else if (error && error.response.status === FORBIDDEN && error.response.data.code === ERROR_INVALID_TOKEN) {
    window.location = `${process.env.REACT_APP_AUTH_URL}?origin=F&redirectUrl=${clearUrlParams(window.location.href)}`;
  }

  return [error, response];
}

function* retryingRefresh(retryCount) {
  const { refreshToken } = yield select((state) => state.auth);
  const [refreshError, refreshResponse] = yield call(AuthorizeService.refreshTokens, refreshToken);

  if (refreshError && retryCount > 0) {
    return yield call(retryingRefresh, retryCount - 1);
  }

  return [refreshError, refreshResponse];
}

export function* authenticatedService(method, url, data = {}, params = {}, headers = {}, responseType = '') {
  return yield call(authorize, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
  });
}

export function* service(method, url, data = {}, params = {}, headers = {}, responseType = '') {
  return yield call(fetch, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
  });
}
