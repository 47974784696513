import {createReducer} from 'reduxsauce';
import {types} from './actions';

const initialState = {
  list: [],
  currentFilter: null,
  mentor: {
    id: null,
    firstName: '',
    projects: [],
    schools: []
  }
};

const getMentorsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, list}
};

const setMentorsCurrentFilter = (state = initialState, action) => {
  const {page, filter} = action;
  return {...state, currentFilter: {page, filter}};
};

const linkProjectMentorSucceeded = (state = initialState, action) => {
  const {mentor} = action;
  return {...state, mentor}
};

const unlinkProjectMentorSucceeded = (state = initialState, action) => {
  const {mentor} = action;
  return {...state, mentor}
};

const getMentorDetailSucceeded = (state = initialState, action) => {
  const {mentor} = action;
  return {...state, mentor}
};

const deleteMentorSucceeded = (state = initialState, action) => {
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const mentorIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(mentorIndex, 1);
  return {
    ...state,
    list: copyList,
    mentor: state.mentor && state.mentor.id === id ? initialState.mentor : state.mentor
  };
};

const updateMentorSucceeded = (state = initialState, action) => {
  const {mentor} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const mentorIndex = copyList.findIndex(s => s.id === mentor.id);
  copyList[mentorIndex] = mentor;
  return {...state, list: copyList, mentor: state.mentor.id === mentor.id ? mentor : state.mentor};
};

const createMentorSucceeded = (state = initialState, action) => {
  const {mentor} = action;

  return {...state, mentor};
};

const resetMentorDetail = (state = initialState) => {
  return {...state, mentor: initialState.mentor};
};

export default createReducer(initialState, {
  [types.GET_MENTORS_SUCCEEDED]: getMentorsSucceeded,
  [types.SET_MENTORS_CURRENT_FILTER] : setMentorsCurrentFilter,
  [types.GET_MENTOR_DETAIL_SUCCEEDED]: getMentorDetailSucceeded,
  [types.LINK_PROJECT_MENTOR_SUCCEEDED]: linkProjectMentorSucceeded,
  [types.UNLINK_PROJECT_MENTOR_SUCCEEDED]: unlinkProjectMentorSucceeded,
  [types.DELETE_MENTOR_SUCCEEDED]: deleteMentorSucceeded,
  [types.UPDATE_MENTOR_SUCCEEDED]: updateMentorSucceeded,
  [types.CREATE_MENTOR_SUCCEEDED]: createMentorSucceeded,
  [types.RESET_MENTOR_DETAIL]: resetMentorDetail,
});
