import moment from 'moment';
import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import {
  ALL,
  formatAcademiesSelector,
  formatEnumForSelector,
  formatRegionsSelector,
  pourcentFilter,
  userRole,
} from '../../utils';
import {
  ITEM_BY_PAGE,
  POURCENT_FILTER_KEY_STRING,
} from '../../utils/constants';
import { miniStatusDropDown } from '../../utils/enum';
import { FacilitatorsSelectors } from '../facilitators';
import { MentorsSelectors } from '../mentors';
import { SupervisorsSelectors } from '../supervisors';

const defaultFilter = {
  search: '',
  region: null,
  academy: null,
  percent: null,
};
const getCurrentFilter = state =>
  state.minis.currentFilter || {
    page: 0,
    filter: defaultFilter,
  };

const strings = state => getTranslate(state.locale);
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const list = state => state.minis.list || [];
const mini = state => state.minis.mini || null;

const getMinis = createSelector(
  strings,
  userRegion,
  getCurrentFilter,
  regions,
  list,
  (strings, userRegion, currentFilter, regions, minis) => {
    const { filter = defaultFilter, page = 0 } = currentFilter;
    const { search, region, academy, status } = filter;

    //Do not override the region if there is one selected
    const regionFilter = userRegion && !region ? userRegion : region;

    const regionList = formatRegionsSelector(strings, regions);
    const regionObject = regionList.find(search =>
      regionFilter ? search.key === regionFilter : search.key === ALL
    );
    const regionAcademies = regionObject
      ? formatAcademiesSelector(strings, regionObject.value.academies)
      : [];
    const matchingAcademy = regionAcademies.find(
      search => search.key === academy
    );
    const academyObject = regionAcademies
      ? academy && matchingAcademy && regionFilter !== ALL
        ? matchingAcademy
        : regionAcademies.find(search => search.key === ALL)
      : null;

    const statusList = formatEnumForSelector(strings, miniStatusDropDown);
    const statusObject = statusList.find(search =>
      status
        ? search.key === status
        : search.key ===
          Object.keys(miniStatusDropDown)[
            Object.values(miniStatusDropDown).indexOf(miniStatusDropDown.ALL)
          ]
    );

    const pageFilter = {
      page: page,
      filter: {
        search: search,
        region: regionObject,
        academy: academyObject,
        status: statusObject,
      },
    };

    const filteredMinis = filterMinis(
      minis,
      page,
      search,
      regionFilter,
      academyObject ? academyObject.key : academyObject,
      status
    );

    return { ...pageFilter, regionAcademies, ...filteredMinis };
  }
);

const filterMinis = (minis, page, search, region, academy, status) => {
  let filteredMinis = minis;

  if (search.length > 0) {
    filteredMinis = filteredMinis.filter(m =>
      m.name.toLowerCase().includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredMinis = filteredMinis.filter(m => m.regions.includes(region));
  }
  if (academy && academy !== ALL) {
    filteredMinis = filteredMinis.filter(m => m.academies.includes(academy));
  }
  if (
    status &&
    status !==
      Object.keys(miniStatusDropDown)[
        Object.values(miniStatusDropDown).indexOf(miniStatusDropDown.ALL)
      ]
  ) {
    filteredMinis = filteredMinis.filter(p => p.status === status);
  }

  const formattedMinis = filteredMinis.map(mini => formatMini(mini));
  return {
    all: formattedMinis,
    listed: formattedMinis.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(formattedMinis.length / ITEM_BY_PAGE),
  };
};

const formatMini = mini => {
  return {
    ...mini,
    startDate: mini.startDate
      ? moment(mini.startDate).format('DD MMMM YYYY')
      : null,
    members: {
      schools: mini.schoolsCount,
      supervisors: mini.supervisorsCount,
      mentors: mini.mentorsCount,
      facilitators: mini.facilitatorsCount,
    },
  };
};

const getMini = createSelector(mini, mini => {
  return { ...mini };
});

export default {
  getMinis,
  getMini,
};
