import { REGEX } from '../../../../utils';

export const validateFreemiumForm = (user, errors) => {
  const formErrors = JSON.parse(JSON.stringify(errors));
  // Validate user
  const {civility, firstName, lastName, email, phone, region} = user;

  formErrors.civility.onError = !civility;
  formErrors.civility.errorMsg = 'input_require';
  formErrors.firstName.onError = !firstName || firstName.length < 2;
  formErrors.firstName.errorMsg = 'input_require';
  formErrors.lastName.onError = !lastName || lastName.length < 2;
  formErrors.lastName.errorMsg = 'input_require';
  formErrors.email.onError = !REGEX.EMAIL.test(email);
  formErrors.email.errorMsg = !email ? 'input_require' : 'wrong_email';
  formErrors.phone.onError = phone && !REGEX.PHONE.test(phone);
  formErrors.phone.errorMsg = 'wrong_phone';
  formErrors.region.onError = !region || region.length < 2;
  formErrors.region.errorMsg = 'input_require';

  return formErrors;
};

export const validateMentorUpgradeForm = (mentorInfo, errors) => {
  const formErrors = JSON.parse(JSON.stringify(errors));
  const {phone, description, companyName, companyAddress, companyPostalCode, companyCity, companyCountry, ceoLastName, ceoFirstName, ceoPhone, ceoEmail} = mentorInfo;

  formErrors.phone.onError = !phone || !REGEX.PHONE.test(phone);
  formErrors.phone.errorMsg = !phone ? 'input_require' : 'wrong_phone';
  formErrors.description.onError = !description || description.length < 2;
  formErrors.description.errorMsg = 'input_require';
  formErrors.companyName.onError = !companyName || companyName.length < 2;
  formErrors.companyName.errorMsg = 'input_require';
  formErrors.companyAddress.onError = !companyAddress || companyAddress.length < 2;
  formErrors.companyAddress.errorMsg = 'input_require';
  formErrors.companyPostalCode.onError = !companyPostalCode || companyPostalCode.length < 2;
  formErrors.companyPostalCode.errorMsg = 'input_require';
  formErrors.companyCity.onError = !companyCity || companyCity.length < 2;
  formErrors.companyCity.errorMsg = 'input_require';
  formErrors.companyCountry.onError = !companyCountry || companyCountry.length < 2;
  formErrors.companyCountry.errorMsg = 'input_require';
  formErrors.ceoLastName.onError = ceoLastName && ceoLastName.length < 2;
  formErrors.ceoLastName.errorMsg = 'input_invalid';
  formErrors.ceoFirstName.onError = ceoFirstName && ceoFirstName.length < 2;
  formErrors.ceoFirstName.errorMsg = 'input_invalid';
  formErrors.ceoPhone.onError = ceoPhone && !REGEX.PHONE.test(ceoPhone);
  formErrors.ceoPhone.errorMsg = 'wrong_phone';
  formErrors.ceoEmail.onError = ceoEmail && !REGEX.EMAIL.test(ceoEmail);
  formErrors.ceoEmail.errorMsg = 'wrong_email';

  return formErrors;
};


