import React, { PureComponent } from 'react';
import { Routes, setSelectorLabel } from '../../../../utils';
import { ListWrapper, ScreenWrapper } from '../../../components';
import { ActionsBar, Filter, ListItem } from '../../commons';
import './supervisorsList.scss';

export class SupervisorsList extends PureComponent {
  state = {
    supervisorsSelected: [],
  };

  componentDidMount() {
    const { getSupervisors } = this.props;
    this.initializeFilter();
    getSupervisors();
  }

  handleSendMail = id => {
    const { supervisors } = this.props;
    const { all } = supervisors;

    const supervisor = all.find(supervisor => supervisor.id === id);
    if (supervisor) {
      window.location.href = `mailto:${supervisor.details.email}`;
    }
  };
  initializeFilter = () => {
    const { setCurrentFilter, isBoUserAdmin } = this.props;
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        academy: 'all',
        status: 'ALL',
      });
  };
  onPageChange = page => {
    const { supervisors, setCurrentFilter } = this.props;
    const { filter, page: oldPage } = supervisors;
    const { search, region, academy, status } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      });
    }
  };

  onClick = id => () => {
    const { navigateTo } = this.props;
    navigateTo(Routes.SUPERVISOR.replace(':supervisorId', id));
  };

  onFiltersChange = key => value => {
    const { supervisors, setCurrentFilter } = this.props;
    const { filter } = supervisors;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, academy, status } = filter;
      let newFilter = {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  onClickCreate = () => {
    this.props.navigateTo(Routes.SUPERVISOR_CREATION);
  };

  onClickDownload = () => {
    this.props.exportList();
  };

  onCheckChange = all => id => () => {
    const { showSnackBar, setActionSnackBar, hideSnackBar } = this.props;
    const { supervisorsSelected } = this.state;

    const newSupervisorsSelected = supervisorsSelected.some(
      item => item.id === id
    )
      ? supervisorsSelected.filter(item => !(item.id === id))
      : supervisorsSelected.concat(all.find(m => m.id === id));

    this.setState({
      supervisorsSelected: newSupervisorsSelected,
    });

    setActionSnackBar(this.onMailSend, 'send');
    newSupervisorsSelected.length > 0
      ? showSnackBar(
          newSupervisorsSelected.length === 1
            ? 'want_send_mail_supervisor'
            : 'want_send_mail_supervisors'
        )
      : hideSnackBar();
  };

  onMailSend = () => {
    const { supervisorsSelected } = this.state;

    window.location.href = `mailto:?bcc=${supervisorsSelected
      .filter(e => (e.details.email !== undefined ? e.details.email : null))
      .map(e => e.details.email)
      .join(';')}`;

    this.setState({
      supervisorsSelected: [],
    });
  };

  onDeleteSupervisor = id => {
    const { supervisors, deleteSupervisor, setCurrentFilter } = this.props;
    const { page, filter, listed } = supervisors;
    const { search, region, academy, status } = filter;

    deleteSupervisor(id);

    if (listed.length === 1 && page > 0) {
      setCurrentFilter(page - 1, {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      });
    }
  };
  
  render() {
    const { strings, supervisors, regions, statusFilter, showPopup } =
      this.props;
    const { supervisorsSelected } = this.state;
    const { page, filter, regionAcademies, all, listed, pageCount } =
      supervisors;
    const { search, region, academy, status } = filter;

    const actions = [
      {
        label: 'supervisor_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_supervisor'), () =>
            this.onDeleteSupervisor(id)
          ),
      },
      {
        label: 'send_mail_action',
        action: id => this.handleSendMail(id),
      },  
    ];
    return (
      <ScreenWrapper id={'supervisors'}>
        <Filter
          strings={strings}
          label={`${strings('supervisors')} (${all.length})`}
          currentSearch={search}  
          currentRegion={region}
          currentAcademy={academy}
          currentStatus={status}
          academies={regionAcademies}
          regions={regions}
          status={statusFilter}
          onSearchChange={this.onFiltersChange('search')}
          onRegionChange={this.onFiltersChange('region')}
          onAcademyChange={this.onFiltersChange('academy')}
          onStatusChange={this.onFiltersChange('status')}
        />
        <ListWrapper
          strings={strings}
          className={`list-wrapper`}
          currentPage={page}
          pageCount={pageCount}
          onPageChange={this.onPageChange}
        >
          {all.length > 0 ? (
            listed.map(item => (
              <ListItem
                key={item.id}
                strings={strings}
                item={item}
                isChecked={supervisorsSelected.some(s => s.id === item.id)}
                onCheck={this.onCheckChange(all)}
                onClick={this.onClick}
                actions={actions}
              />
            ))
          ) : (
            <p className={'no-list'}>{strings('no_supervisors')}</p>
          )}
        </ListWrapper>
        <ActionsBar
          strings={strings}
          onClickDownload={this.onClickDownload}
          onClickCreate={this.onClickCreate}
        />
      </ScreenWrapper>
    );
  }
}
