import { ProjectInfo } from './ProjectInfo';
import { connect } from 'react-redux';
import {ProjectsActions, ProjectsSelectors} from '../../../../redux/projects';
import { getTranslate } from "react-localize-redux";
import { SnackActions } from '../../../../redux/snackBar';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  projectInfo: ProjectsSelectors.getProjectDetailInfo(state),
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  updateInfoProject: (info) => dispatch(ProjectsActions.updateInfoRequested(info)),
  displayError: (error) => dispatch(SnackActions.displayError(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfo);
