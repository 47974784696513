import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthSelectors } from '../../../../redux/auth';
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import { MentorsActions, MentorsSelectors } from '../../../../redux/mentors';
import { SnackActions } from '../../../../redux/snackBar';
import { formatEnumForSelector, formatRegionsSelector, userStatus } from '../../../../utils';
import { MentorsList } from './MentorsList';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  mentors: MentorsSelectors.getMentors()(state),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  statusFilter: formatEnumForSelector(getTranslate(state.locale), userStatus),
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message, popupAction) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  getMentors: () => dispatch(MentorsActions.getMentorsRequested()),
  setCurrentFilter: (page, filter) => dispatch(MentorsActions.setMentorsCurrentFilter(page, filter)),
  deleteMentor: (id) => dispatch(MentorsActions.deleteMentorRequested(id)),
  exportList: () => dispatch(MentorsActions.exportMentorsRequested()),
  navigateTo: path => dispatch(push(path)),
  setActionSnackBar: (snackAction, actionMessage) => dispatch(SnackActions.setAction(snackAction, actionMessage)),
  showSnackBar: (message) => dispatch(SnackActions.displayInfo(message)),
  hideSnackBar: () => dispatch(SnackActions.hideSnackBar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorsList);
