import {createReducer} from 'reduxsauce';
import { userRealStatus } from '../../utils';
import {types} from './actions';

const initialState = {
  list: [],
  currentFilter: null,
  supervisor: {
    id: null,
    firstName: '',
    displayContacts: null,
    status: userRealStatus.PENDING,
    projects: [],
    schools: []
  },
};

const getSchoolsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, list}
};

const setSupervisorsCurrentFilter = (state = initialState, action) => {
  const {page, filter} = action;
  return {...state, currentFilter: {page, filter}};
};

const linkProjectSupervisorSucceeded = (state = initialState, action) => {
  const {supervisor} = action;
  return {...state, supervisor}
};

const unlinkProjectSupervisorSucceeded = (state = initialState, action) => {
  const {supervisor} = action;
  return {...state, supervisor}
};

const getSupervisorDetailSucceeded = (state = initialState, action) => {
  const {supervisor} = action;
  return {...state, supervisor}
};

const updateSupervisorSucceeded = (state = initialState, action) => {
  const {supervisor} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const supervisorIndex = copyList.findIndex(s => s.id === supervisor.id);
  copyList[supervisorIndex] = supervisor;
  return {...state, list: copyList, supervisor: state.supervisor.id === supervisor.id ? supervisor : state.supervisor};
};

const deleteSupervisorSucceeded = (state = initialState, action) => {
  const {id} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const supervisorIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(supervisorIndex, 1);
  return {
    ...state,
    list: copyList,
    supervisor: state.supervisor && state.supervisor.id === id ? initialState.supervisor : state.supervisor
  };
};

const createSupervisorSucceeded = (state = initialState, action) => {
  const {supervisor} = action;
  return {...state, supervisor};
};

const resetSupervisorDetail = (state = initialState) => {
  return {...state, supervisor: initialState.supervisor}
};

export default createReducer(initialState, {
  [types.GET_SUPERVISORS_SUCCEEDED]: getSchoolsSucceeded,
  [types.SET_SUPERVISORS_CURRENT_FILTER] : setSupervisorsCurrentFilter,
  [types.GET_SUPERVISOR_DETAIL_SUCCEEDED]: getSupervisorDetailSucceeded,
  [types.LINK_PROJECT_SUPERVISOR_SUCCEEDED]: linkProjectSupervisorSucceeded,
  [types.UNLINK_PROJECT_SUPERVISOR_SUCCEEDED]: unlinkProjectSupervisorSucceeded,
  [types.DELETE_SUPERVISOR_SUCCEEDED]: deleteSupervisorSucceeded,
  [types.UPDATE_SUPERVISOR_SUCCEEDED]: updateSupervisorSucceeded,
  [types.CREATE_SUPERVISOR_SUCCEEDED]: createSupervisorSucceeded,
  [types.RESET_SUPERVISOR_DETAIL]: resetSupervisorDetail,
});
