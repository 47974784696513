import React, { useEffect } from 'react';
import { MentorDetail } from './common';

export const MentorWrapperDetails = ({getMentor, getMentorInscription, match, updateMentor, updateMentorInscription, mentor, linkProjects, unlinkProject, projectsData, pop, strings, mentorInscriptions, resetMentorDetail, resetMentorInscriptionDetail} ) => {
  const fromInscription = match.url.indexOf('/inscriptions') !== -1;

  useEffect(() => {
    const {mentorId} = match.params;

    if (fromInscription) {
      getMentorInscription(mentorId);
    } else {
      getMentor(mentorId);
    }
  }, [getMentorInscription, getMentor, fromInscription, match.params]);

  useEffect(() => {
    return () => {
      if (fromInscription) {
        resetMentorInscriptionDetail();
      } else {
        resetMentorDetail();
      }
    }
  }, [fromInscription, resetMentorDetail, resetMentorInscriptionDetail]);

  const _updateMentor = (isValid, result) => {
    if (isValid) {
      
      if (fromInscription) {
        if (JSON.stringify(result) !== JSON.stringify(mentorInscriptions)) {
          updateMentorInscription(result);
        }
      } else {
        if (JSON.stringify(result) !== JSON.stringify(mentor)) {
          updateMentor(result);
        }
      }
    }
  };
    
    return (
      <MentorDetail
        pop={pop}
        strings={strings}
        mentor={fromInscription ? mentorInscriptions : mentor}
        updateUser={_updateMentor}
        linkProjects={linkProjects}
        unlinkProject={unlinkProject}
        projectsData={projectsData}
        fromInscription={fromInscription}
      />
    );
};
