import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  list: [],
  currentFilter: null,
  school: {
    id: null,
    rne: null,
    name: null,
    address: null,
    postalCode: null,
    city: null,
    region: null,
    academy: null,
    country: null,
    secretariatPhone: null,
    principalCivility: null,
    principalFirstName: null,
    principalLastName: null,
    principalEmail: null,
    principalPhone: null,
    projects: [],
  },
  schoolSaved: null,
  creationSaved: false,
};

const getSchoolsSucceeded = (state = initialState, action) => {
  const { list } = action;
  const { schoolSaved, creationSaved } = state;
  const curentSchoolSaved = creationSaved
    ? list.filter(s => s.id === schoolSaved.id)
    : [];
  const reverseList =
    curentSchoolSaved.length > 0
      ? curentSchoolSaved.concat(list.filter(s => s.id !== schoolSaved.id))
      : list;

  return { ...state, list: creationSaved ? reverseList : list };
};

const setSchoolCurrentFilter = (state = initialState, action) => {
  const { page, filter } = action;
  return { ...state, currentFilter: { page, filter }, creationSaved: false };
};

const getSchoolDetailSucceeded = (state = initialState, action) => {
  const { school } = action;
  return { ...state, school: school, creationSaved: false };
};

const updateSchoolSucceeded = (state = initialState, action) => {
  const { school } = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const schoolIndex = copyList.findIndex(s => s.id === school.id);
  copyList[schoolIndex] = school;
  return {
    ...state,
    list: copyList,
    school: state.school.id === school.id ? school : state.school,
    creationSaved: false,
  };
};

const createSchoolSucceeded = (state = initialState, action) => {
  const { school } = action;
  return { ...state, creationSaved: true, schoolSaved: school };
};

const resetCreation = (state = initialState) => {
  return { ...state, creationSaved: false };
};

const resetSchoolDetail = (state = initialState) => {
  return { ...state, school: initialState.school, creationSaved: false };
};

export default createReducer(initialState, {
  [types.GET_SCHOOLS_SUCCEEDED]: getSchoolsSucceeded,
  [types.SET_SCHOOLS_CURRENT_FILTER]: setSchoolCurrentFilter,
  [types.GET_SCHOOL_DETAIL_SUCCEEDED]: getSchoolDetailSucceeded,
  [types.UPDATE_SCHOOL_SUCCEEDED]: updateSchoolSucceeded,
  [types.CREATE_SCHOOL_SUCCEEDED]: createSchoolSucceeded,
  [types.RESET_CREATION]: resetCreation,
  [types.RESET_SCHOOL_DETAIL]: resetSchoolDetail,
});
