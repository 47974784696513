import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthSelectors } from '../../../../redux/auth';
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import { FacilitatorsActions, FacilitatorsSelectors } from '../../../../redux/facilitators';
import { SnackActions } from '../../../../redux/snackBar';
import { formatEnumForSelector, formatRegionsSelector, userStatus } from '../../../../utils';
import { FacilitatorsList } from './FacilitatorsList';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  facilitators: FacilitatorsSelectors.getFacilitators()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  statusFilter: formatEnumForSelector(getTranslate(state.locale), userStatus),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message, popupAction) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  getFacilitators: () => dispatch(FacilitatorsActions.getFacilitatorsRequested()),
  setCurrentFilter: (page, filter) => dispatch(FacilitatorsActions.setFacilitatorsCurrentFilter(page, filter)),
  deleteFacilitator: (id) => dispatch(FacilitatorsActions.deleteFacilitatorRequested(id)),
  exportList: () => dispatch(FacilitatorsActions.exportFacilitatorsRequested()),
  navigateTo: path => dispatch(push(path)),
  setActionSnackBar: (snackAction, actionMessage) => dispatch(SnackActions.setAction(snackAction, actionMessage)),
  showSnackBar: (message) => dispatch(SnackActions.displayInfo(message)),
  hideSnackBar: () => dispatch(SnackActions.hideSnackBar()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitatorsList);
