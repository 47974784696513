import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { SideBar } from './SideBar';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
