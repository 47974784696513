import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthSelectors } from '../../../../redux/auth';
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import { ProjectsActions, ProjectsSelectors } from '../../../../redux/projects';
import { SnackActions } from '../../../../redux/snackBar';
import {
  formatEnumForSelector,
  formatRegionsSelector,
  POURCENT_FILTER_KEY_STRING,
  PROJECT_STATUS_FILTER_KEY_STRING,
  pourcentFilter,
  projecTypeFilter,
  projectStatusFilter
} from '../../../../utils';
import { ProjectsList } from './ProjectsList';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
  projects: ProjectsSelectors.getProjects()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  types: formatEnumForSelector(getTranslate(state.locale), projecTypeFilter),
  percentFilters: formatEnumForSelector(
    getTranslate(state.locale),
    pourcentFilter,
    POURCENT_FILTER_KEY_STRING
  ),
  projetStatusFilters: formatEnumForSelector(
    getTranslate(state.locale),
    projectStatusFilter,
    PROJECT_STATUS_FILTER_KEY_STRING
  )
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message, popupAction, title = null) =>
    dispatch(ConfirmPopupActions.displayPopup(message, popupAction, title)),
  getProjects: () => dispatch(ProjectsActions.getProjectsRequested()),
  setCurrentFilter: (page, filter) =>
    dispatch(ProjectsActions.setProjectsCurrentFilter(page, filter)),
  deleteProject: id => dispatch(ProjectsActions.deleteProjectRequested(id)),
  archiveProject: id => dispatch(ProjectsActions.archiveProjectRequested(id)),
  archiveProjects: ids =>
    dispatch(ProjectsActions.archiveProjectsRequested(ids)),
  changeToFinishProjects: ids =>
    dispatch(ProjectsActions.changeToFinishedProjectsRequested(ids)),
  setActionSnackBar: (snackAction, actionMessage) =>
    dispatch(SnackActions.setAction(snackAction, actionMessage)),
  showSnackBar: (message,time= 5000) => dispatch(SnackActions.displayInfo(message,time)),
  hideSnackBar: () => dispatch(SnackActions.hideSnackBar()),
  exportList: () => dispatch(ProjectsActions.exportProjectsRequested()),
  exportListArchive: () => dispatch(ProjectsActions.exportArchivesProjectsRequested()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
