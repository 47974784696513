import { AuthSelectors } from '../../../redux/auth';
import { formatRegionsSelector } from '../../../utils';
import { UserSummary } from './UserSummary';
import { connect } from 'react-redux';
import { CommonsActions } from '../../../redux/commons';
import { getTranslate } from "react-localize-redux";

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  regions: formatRegionsSelector(getTranslate(state.locale), state.commons.regions).slice(1),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  getRegions: () => dispatch(CommonsActions.getRegionsRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSummary);
