import React from 'react';
import { icons } from '../../../../../assets';
import { formatEnumForSelector, Gender, InputTypes } from '../../../../../utils';
import { FormDropDown, Input } from '../../../../components';

const Partner = ({ strings, index, partner, isLast, errors, handlePartnerInfoChanged, handleRemovePartner }) => {
  const civilities = formatEnumForSelector(strings, Gender);
  const civilityLabel =
    partner.contactCivility && civilities.find((c) => c.key === partner.contactCivility)
      ? civilities.find((c) => c.key === partner.contactCivility).value.label
      : '';

  const partnerError =
    errors && errors.some((partnerError) => (partner.id ? partnerError.key === partner.id : partnerError.key === partner.key))
      ? errors.find((partnerError) => (partner.id ? partnerError.key === partner.id : partnerError.key === partner.key)).errors
      : {};

  const handleCivilityChanged = (index) => (tag, value) => handlePartnerInfoChanged(index)(tag, value.key);

  return (
    <div className={'partner'}>
      <div className={'line'}>
        <div className={'group'}>
          <Input
            type={InputTypes.TEXT}
            tag={'name'}
            label={strings('partner_name')}
            className={'name'}
            defaultValue={partner.name || ''}
            inputClassName={`${partnerError.name ? 'invalid' : ''}`}
            error={partnerError.name ? strings(partnerError.name) : null}
            handleValueChanged={handlePartnerInfoChanged(index)}
          />
        </div>
        <div className={'group'}>
          <i className={'material-icons delete'} onClick={handleRemovePartner(index)}>
            {icons.DELETE}
          </i>
        </div>
      </div>

      <div className={'line'}>
        <div className={'group'}>
          <FormDropDown
            tag={'contactCivility'}
            className={'civility'}
            label={strings('civility')}
            data={civilities}
            current={civilityLabel}
            onValueChange={handleCivilityChanged(index)}
          />
          <Input
            type={InputTypes.TEXT}
            tag={'contactLastname'}
            label={strings('lastname')}
            className={'lastname'}
            defaultValue={partner.contactLastname || ''}
            inputClassName={`${partnerError.lastname ? 'invalid' : ''}`}
            error={partnerError.lastname ? strings(partnerError.lastname) : null}
            handleValueChanged={handlePartnerInfoChanged(index)}
          />
        </div>
        <div className={'group'}>
          <Input
            type={InputTypes.TEXT}
            tag={'contactFirstname'}
            label={strings('firstname')}
            className={'firstname'}
            defaultValue={partner.contactFirstname || ''}
            inputClassName={`${partnerError.firstname ? 'invalid' : ''}`}
            error={partnerError.firstname ? strings(partnerError.firstname) : null}
            handleValueChanged={handlePartnerInfoChanged(index)}
          />
        </div>
      </div>
      <div className={'line'}>
        <div className={'group'}>
          <Input
            type={InputTypes.TEXT}
            tag={'contactEmail'}
            label={strings('email')}
            className={'email'}
            defaultValue={partner.contactEmail || ''}
            inputClassName={`${partnerError.email ? 'invalid' : ''}`}
            error={partnerError.email ? strings(partnerError.email) : null}
            handleValueChanged={handlePartnerInfoChanged(index)}
          />
        </div>
        <div className={'group'}>
          <Input
            type={InputTypes.TEXT}
            tag={'contactPhone'}
            label={strings('phone')}
            className={'phone'}
            defaultValue={partner.contactPhone || ''}
            inputClassName={`${partnerError.phone ? 'invalid' : ''}`}
            error={partnerError.phone ? strings(partnerError.phone) : null}
            handleValueChanged={handlePartnerInfoChanged(index)}
          />
        </div>
      </div>
      {!isLast && <div className={'dashed-separator'} />}
    </div>
  );
};

export default Partner;
