import React, { useState } from "react";
import { icons } from "../../../../../assets";
import Tool from "./Tool";

const Room = ({
  strings,
  index,
  room,
  total,
  tools,
  error,
  handleDeleteRoom,
  handleToolValueChange,
}) => {
  const [showTools, setShowTools] = useState(false);

  const toggleToolsVisibility = () => {
    setShowTools(!showTools);
  };

  return (
    <div className={"room"}>
      <div className={"line summary"}>
        <p className={"label"}>{`${strings("room_number")}${index + 1}`}</p>
        <p className={"label"}>{strings("students")}</p>
        <p className={"value"}>{total}</p>
        <i className={"material-icons delete"} onClick={handleDeleteRoom}>
          {icons.CLOSE}
        </i>
      </div>
      <div className={"separator"} />
      {tools && tools.length > 0 && (
        <>
          <div className={`column tools ${showTools ? "displayed" : ""}`}>
            <div className={"line"} onClick={toggleToolsVisibility}>
              <p className={"label"}>{strings("room_tools")}</p>
              <i className={"material-icons arrow"}>{icons.CHEVRON_RIGHT}</i>
            </div>
            <div className={"list"}>
              {tools.map((tool, index) => (
                <div key={tool.id}>
                  <div className={"dashed-separator"} />
                  <Tool
                    strings={strings}
                    tool={tool}
                    roomTool={
                      room.tools
                        ? room.tools.find((t) => t.toolId === tool.id)
                        : null
                    }
                    error={
                      error &&
                      error.errors &&
                      error.errors.tools &&
                      error.errors.tools.find((err) => tool.id === err.id)
                    }
                    handleToolValueChange={handleToolValueChange(tool.id)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={"separator"} />
        </>
      )}
      <div className={"line footer"}>
        <div className={"line link"}>
          <i className={"material-icons"}>{icons.LINK}</i>
          {room.code && <p>{room.code}</p>}
        </div>
        <div className={"line password"}>
          <i className={"material-icons"}>{icons.LOCK}</i>
          {room.password && <p>{room.password}</p>}
        </div>
      </div>
    </div>
  );
};

export default Room;
