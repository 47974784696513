export const formatProjects = (projects) => {
  
  return projects.map(project => {
    const users = project.users.map(user => {
      if (user.id) {
        return {id: user.id};
      } else {
        return {
          firstName: user.firstName.value,
          lastName: user.lastName.value,
          civility: user.civility.value,
          phone: user.phone.value,
          email: user.email.value,
        };
      }
    });
    return {type: project.type, duration: project.duration, users};
  });
};
