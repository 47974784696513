import React, { PureComponent } from 'react';
import './tab.scss';

export class Tab extends PureComponent {
  state = {
    current: this.props.currentTab ? this.props.currentTab : this.props.tab[0].activate,
  };

  handleClickTab = tab => () => {
    if (!tab.match(this.state.currentTab)) {
      this.setState({
        current: tab,
      });
    }
    return this.handleCurrentTab(tab);
  };

  handleCurrentTab = tab => this.props.handleCurrentTab(tab);

  render() {
    const {strings, tab, currentTab} = this.props;
    const {current} = this.state;

    return (
      <div className={'tab-container'}>
        {tab.map((t, index) => (
          <div key={index} className={'tab-wrapper'}>
            {(index > 0) && <div className={'separator'}/>}
            <div
              key={index}
              className={`tab ${(currentTab ? currentTab : current) === t.activate ? 'selected' : ''}`}
              onClick={this.handleClickTab(t.activate)}>
              <p>{`${strings(t.label).toUpperCase()} (${t.number ? t.number : '0'})`}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}
