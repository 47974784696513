import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import {MentorsActions, MentorsSelectors} from '../../../../redux/mentors';
import { InscriptionsActions } from '../../../../redux/inscriptions';
import { MentorWrapperDetails } from './MentorWrapperDetails';

const mapStateToProps = state => ({
  mentor: state.mentors.mentor,
  strings: getTranslate(state.locale),
  mentorInscriptions: state.inscriptions.mentor,
  projectsData: (page, search, region, academy) => MentorsSelectors.getProjects(page, search, region, academy)(state),
});

const mapDispatchToProps = dispatch => ({
  linkProjects: (userId, projectsId) => dispatch(MentorsActions.linkProjectMentorRequested(userId, projectsId)),
  unlinkProject: (userId, projectId) => dispatch(MentorsActions.unlinkProjectMentorRequested(userId, projectId)),
  getMentor: mentorId => dispatch(MentorsActions.getMentorDetailRequested(mentorId)),
  getMentorInscription: mentorId => dispatch(InscriptionsActions.getMentorDetailInscriptionsRequested(mentorId)),
  pop: () => dispatch(goBack()),
  updateMentor: mentor => dispatch(MentorsActions.updateMentorRequested(mentor)),
  updateMentorInscription: mentor => dispatch(InscriptionsActions.updateMentorInscriptionsRequested(mentor)),
  resetMentorDetail: () => dispatch(MentorsActions.resetMentorDetail()),
  resetMentorInscriptionDetail: () => dispatch(InscriptionsActions.resetMentorDetailInscription()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorWrapperDetails);
