import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { PopUp } from './Popup';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
