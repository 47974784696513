import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { FacilitatorsActions } from '../../../../redux/facilitators';
import { FacilitatorCreation } from './FacilitatorCreation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  createFacilitator: facilitator => dispatch(FacilitatorsActions.createFacilitatorRequested(facilitator)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitatorCreation);
