import React, { useEffect } from 'react';
import User from '../../user';
import { validateUserForm } from './FormValidator';

export const FacilitatorDetail = ({ getFacilitator, facilitator, match, strings, pop, updateFacilitator, projectsData, linkProjects, unlinkProject, resetFacilitatorDetail}) => {
  useEffect(() => { 
    getFacilitator(match.params.facilitatorId) 
  }, [match.params.facilitatorId, getFacilitator]);

  useEffect(() => {
    return () => {
      resetFacilitatorDetail();
    }
  }, [resetFacilitatorDetail]);

  const updateUser = (isValid, result) => {
    if (isValid && JSON.stringify(result) !== JSON.stringify(facilitator)) {
      updateFacilitator(result)
    }
  };

  return <User
    user={facilitator}
    validateUserForm={validateUserForm}
    pop={pop}
    back={strings('all_facilitator')}
    updateUser={updateUser}
    projectsData={projectsData}
    linkProjects={(projectsId) => linkProjects(facilitator.id, projectsId)}
    unlinkProject={(projectId) => unlinkProject(facilitator.id, projectId)}
  />
};
