import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {logger} from '../../../utils';
import {ButtonColors} from './Colors';
import {Button} from "./Button";
import './button.scss';

export const SelectorButton = ({action, data, color, label, icon, className, enabled = true}) => {
  const [displayList, setDisplayList] = useState(false);

  const onClickItem = (item) => () => {
    action(item);
    setDisplayList(false)
  };

  return (
    <div id={'selector-button'}>
      <Button
        color={color}
        label={label}
        icon={icon}
        className={className}
        enabled={enabled}
        action={() => setDisplayList(!displayList)}
      />
      <div
        className={`selector-list ${displayList ? 'display' : ''}`}>
        {data.map((d) =>
          <div key={d.key} className={`row ${color}`} onClick={onClickItem(d)}>
            <p>{d.value}</p>
          </div>,
        )}
      </div>
    </div>
  )
};

Button.propsType = {
  label: PropTypes.string.isRequired,
  color: PropTypes.oneOf([...Object.values(ButtonColors)]),
  icon: PropTypes.string,
  action: PropTypes.func.isRequired,
};

Button.defaultProps = {
  color: ButtonColors.green,
  action: () => logger.warn('action not implemented.'),
};
