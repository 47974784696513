import React from 'react';
import './school-details.scss';
import { Routes } from '../../../../utils';

export const SchoolDetails = ({school, navigateTo}) => {
  const {name, rne, address, secretariatPhone} = school;
  return (
    <React.Fragment key={school.id}>
    <div className={'school detail clickable'} onClick={() => navigateTo(Routes.SCHOOL.replace(':schoolId', school.id))}>
      <div className={'code'}>{rne}</div>
      <div className={"name-wrapper"}>
        <div className={'name'}>{name}</div>
        <div className={'address'}>{address}</div>
      </div>
      <p className={'contact'}>
        {secretariatPhone}
      </p>
    </div>
    <hr className={'separator'}/>
    </React.Fragment>
  )
};
