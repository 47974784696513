import { connect } from "react-redux";
import { ParamsActions, ParamsSelectors } from "../../../../../redux/params";
import { default as Successes } from "./Successes";

const mapStateToProps = (state) => ({
  miniSuccesses: ParamsSelectors.getSuccessesS(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSuccesses: () => dispatch(ParamsActions.getSuccessesRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Successes);
