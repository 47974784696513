import React from 'react';

export const Item = ({text, route, location, navigateTo, close, isSideMenu}) =>
  <li
    className={`item ${location.includes(route) ? 'is-current' : ''}`}
    onClick={() => {
      if (isSideMenu) close();
      route !== location && navigateTo(route);
    }}>
    {text}
  </li>
;
