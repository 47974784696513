import React, { PureComponent } from 'react';
import { userRole } from '../../../../utils';
import { Button, ButtonColors, Panel, SideBar, UserSummary } from '../../../components/index';
import { validateUserForm } from '../details/common/FormValidator';
import './mentorCreation.scss';

export class MentorCreation extends PureComponent {

  state = {
    checkForm: false,
  };

  onClickValidate = () => {
    this.setState({
      checkForm: true,
    });
  };

  saveMentor = (isValid, mentor) => {
    this.setState({
      checkForm: false,
    });

    if (isValid) {
      this.props.createMentor(mentor);
    }
  };

  render() {
    const {strings} = this.props;
    const {checkForm} = this.state;

    return (
      <div id={'supervisor-creation-screen'}>
        <div className={'side-bar'}>
          <SideBar/>
        </div>
        <div className={'supervisor-creation'}>
          <Panel>
            <UserSummary
              userFound={false}
              user={{role: userRole.MENTOR}}
              strings={strings}
              checkForm={checkForm}
              validateUserForm={validateUserForm}
              updateUser={this.saveMentor}
              creationMode={true}/>
          </Panel>
          <Button
            color={ButtonColors.green}
            label={strings('create')}
            action={this.onClickValidate}
          />
        </div>
      </div>
    );
  }
};

