import { call, put, select, takeLatest } from 'redux-saga/effects';
import { UsersService } from '../../services';
import { SnackActions } from '../snackBar';
import { default as UserActions, types } from './actions';

function* getUserRequested() {
  const { id } = yield select(state => state.auth);
  const [error, response] = yield call(UsersService.getUser, id);

  if (!error) {
    yield put(UserActions.getUserSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('profile_retrieval_failed'));
  }
}
function* getUsersRequested() {
  // yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(UsersService.getUsers, adminId);

  if (!error) {
    yield put(UserActions.getUsersSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('users_retrieval_failed'));
  }
}

export default [
  takeLatest(types.GET_USER_REQUESTED, getUserRequested),
  takeLatest(types.GET_USERS_REQUESTED, getUsersRequested),
];
