import React from 'react';
import { getProjectImage, projectDuration } from '../../../../utils';
import { User } from './User';

export const Project = ({ strings, project, index }) => {
  return (
    <>
      <div className={'info'}>
        <p className={'name'}>{project.name ? project.name : `${strings('project_title')}${index + 1}`}</p>
        <p className={'duration'}>{project.duration !== projectDuration.ONE_YEAR ? `${project.duration} ${strings('duration_several_years')}` : ''}</p>
        <div className={'type'}>{getProjectImage(strings, project.type)}</div>
      </div>
      <div className={'users'}>
        {project.users.map((user, idx) => (
          <User key={idx} strings={strings} user={user} />
        ))}
      </div>
      <div className={'horizontal-plain-separator'} />
    </>
  );
};
