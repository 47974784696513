import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_FREEMIUMS_REQUESTED: 'GET_FREEMIUMS_REQUESTED',
  GET_FREEMIUMS_SUCCEEDED: 'GET_FREEMIUMS_SUCCEEDED',
  SET_FREEMIUMS_CURRENT_FILTER: 'SET_FREEMIUMS_CURRENT_FILTER',
  GET_FREEMIUM_DETAIL_REQUESTED: 'GET_FREEMIUM_DETAIL_REQUESTED',
  GET_FREEMIUM_DETAIL_SUCCEEDED: 'GET_FREEMIUM_DETAIL_SUCCEEDED',
  DELETE_FREEMIUMS_REQUESTED: 'DELETE_FREEMIUMS_REQUESTED',
  DELETE_FREEMIUMS_SUCCEEDED: 'DELETE_FREEMIUMS_SUCCEEDED',
  UPDATE_FREEMIUM_REQUESTED: 'UPDATE_FACILITATOR_REQUESTED',
  UPDATE_FREEMIUM_SUCCEEDED: 'UPDATE_FREEMIUM_SUCCEEDED',
  CREATE_FREEMIUM_REQUESTED: 'CREATE_FREEMIUM_REQUESTED',
  CREATE_FREEMIUM_SUCCEEDED: 'CREATE_FREEMIUM_SUCCEEDED',
  UPGRADE_FREEMIUM_REQUESTED: 'UPGRADE_FREEMIUM_REQUESTED',
  UPGRADE_FREEMIUM_SUCCEEDED: 'UPGRADE_FREEMIUM_SUCCEEDED',
  RESET_FREEMIUM_DETAIL: 'RESET_FREEMIUM_DETAIL',
  EXPORT_FREEMIUMS_REQUESTED: 'EXPORT_FREEMIUMS_REQUESTED',
};

// Actions
export default {
  getFreemiumsRequested: () => createAction(types.GET_FREEMIUMS_REQUESTED, {}),
  getFreemiumsSucceeded: list =>
    createAction(types.GET_FREEMIUMS_SUCCEEDED, { list }),
  setFreemiumsCurrentFilter: (page, filter) =>
    createAction(types.SET_FREEMIUMS_CURRENT_FILTER, { page, filter }),
  getFreemiumDetailRequested: freemiumId =>
    createAction(types.GET_FREEMIUM_DETAIL_REQUESTED, { freemiumId }),
  getFreemiumDetailSucceeded: freemium =>
    createAction(types.GET_FREEMIUM_DETAIL_SUCCEEDED, { freemium }),
  deleteFreemiumRequested: id =>
    createAction(types.DELETE_FREEMIUMS_REQUESTED, { id }),
  deleteFreemiumSucceeded: id =>
    createAction(types.DELETE_FREEMIUMS_SUCCEEDED, { id }),
  updateFreemiumRequested: freemium =>
    createAction(types.UPDATE_FREEMIUM_REQUESTED, { freemium }),
  updateFreemiumSucceeded: freemium =>
    createAction(types.UPDATE_FREEMIUM_SUCCEEDED, { freemium }),
  createFreemiumRequested: freemium =>
    createAction(types.CREATE_FREEMIUM_REQUESTED, { freemium }),
  createFreemiumSucceeded: freemium =>
    createAction(types.CREATE_FREEMIUM_SUCCEEDED, { freemium }),
  upgradeFreemiumRequested: (id, supervisorInfo) =>
    createAction(types.UPGRADE_FREEMIUM_REQUESTED, { id, supervisorInfo }),
  upgradeFreemiumSucceeded: () =>
    createAction(types.UPGRADE_FREEMIUM_SUCCEEDED, {}),
  resetFreemiumDetail: () => createAction(types.RESET_FREEMIUM_DETAIL, {}),
  exportFreemiumsRequested: () =>
    createAction(types.EXPORT_FREEMIUMS_REQUESTED, {}),
};
