import React, { useEffect, useState } from 'react';
import { icons } from '../../../../assets';
import { formatEnumForSelector, Gender, REGEX } from '../../../../utils/index';
import { FormDropDown, Input } from '../../../components/index';
import { validateSupervisorForm } from './FormValidator';

export const UserForm = ({strings, user, isEditing, editable, projectsUsers, handleUpdateUser, handleDeleteUser, handleEditUser}) => {
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    setUserInfo(user);
  }, [user]);

  const handleEditSaveAction = () => {
    if (isEditing) {
      const result = validateSupervisorForm(userInfo);

      const conflictingUser = projectsUsers ? projectsUsers.find(user => user.email === result.email.value || user.email.value === result.email.value) : null;
      if (conflictingUser) {
        if (conflictingUser.id ||
          !(conflictingUser.firstName.value === result.firstName.value &&
            conflictingUser.lastName.value === result.lastName.value &&
            conflictingUser.civility.value === result.civility.value &&
            conflictingUser.phone.value === result.phone.value)) {
          result.email.onError = true;
          result.email.errorMsg = 'conflict_message';
        }
      }
      setUserInfo(result);

      if (!Object.entries(result).filter(([key]) => key !== 'key').some(([_, val]) => val.onError)) {
        handleUpdateUser(user);
      }
    } else {
      handleEditUser();
    }
  };

  const handleDelete = () => handleDeleteUser();

  const handleValueChanged = (tag, value) => {
    const userInfoCopy = Object.assign({}, userInfo);

    if (userInfoCopy[tag]) {
      userInfoCopy[tag].value = (value !== '' ? value : null);
    } else {
      userInfoCopy[tag] = {value: value !== '' ? value : null};
    }

    //Reset on error state
    Object.entries(userInfoCopy).filter(([key]) => key !== 'key').map(([key, val]) => val.onError = false);

    setUserInfo(userInfoCopy);
  };

  const civilities = formatEnumForSelector(strings, Gender);
  const civility = userInfo.civility && userInfo.civility.value && civilities.find(c => c.key === userInfo.civility.value) ?
    civilities.find(c => c.key === userInfo.civility.value).value.label : '';

  return (
    <div className={'user-form'}>
      <div className={'wrapper'}>
        <FormDropDown
          tag={'civility'}
          className={'civility'}
          label={strings('civility')}
          data={civilities}
          dropDownClassName={`${userInfo.civility && userInfo.civility.onError ? 'invalid' : ''}`}
          error={userInfo.civility && userInfo.civility.onError ? strings(userInfo.civility.errorMsg) : null}
          current={civility}
          onValueChange={(tag, value) => handleValueChanged(tag, value.key)}
          disabled={!isEditing}
        />
        <Input
          tag={'lastName'}
          className={'lastName'}
          label={strings('lastname')}
          type={'text'}
          inputClassName={`${userInfo.lastName && userInfo.lastName.onError ? 'invalid' : 'valid'}`}
          error={userInfo.lastName && userInfo.lastName.onError ? strings(userInfo.lastName.errorMsg) : null}
          defaultValue={userInfo.lastName && userInfo.lastName.value}
          handleValueChanged={handleValueChanged}
          disabled={!isEditing}
        />
        <Input
          tag={'firstName'}
          className={'firstName'}
          label={strings('firstname')}
          type={'text'}
          inputClassName={`${userInfo.firstName && userInfo.firstName.onError ? 'invalid' : 'valid'}`}
          error={userInfo.firstName && userInfo.firstName.onError ? strings(userInfo.firstName.errorMsg) : null}
          defaultValue={userInfo.firstName && userInfo.firstName.value}
          handleValueChanged={handleValueChanged}
          disabled={!isEditing}
        />
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'email'}
          className={'email'}
          label={strings('email')}
          type={'text'}
          inputClassName={`${userInfo.email && userInfo.email.onError ? 'invalid' : 'valid'}`}
          error={userInfo.email && userInfo.email.onError ? strings(userInfo.email.errorMsg) : null}
          defaultValue={userInfo.email && userInfo.email.value}
          handleValueChanged={handleValueChanged}
          disabled={!isEditing}
        />
        <Input
          tag={'phone'}
          className={'phone'}
          label={strings('phone')}
          type={'text'}
          inputClassName={`${userInfo.phone && userInfo.phone.onError ? 'invalid' : 'valid'}`}
          error={userInfo.phone && userInfo.phone.onError ? strings(userInfo.phone.errorMsg) : null}
          defaultValue={userInfo.phone && userInfo.phone.value}
          handleValueChanged={handleValueChanged}
          disabled={!isEditing}
        />
      </div>
      <div className={'horizontal-dot-separator'}/>
      <div className={'actions'}>
        {/*{conflictError &&*/}
        {/*<p>{strings('conflict_message')}</p>*/}
        {/*}*/}
        <div className={`edit-wrapper ${(isEditing || editable) ? '' : 'disabled'}`}
             onClick={handleEditSaveAction}>
          <p>{strings(isEditing ? 'validate' : 'edit')}</p>
          <i className={'material-icons edit'}>{isEditing ? icons.DONE : icons.EDIT}</i>
        </div>
        <div className={'delete-wrapper'} onClick={handleDelete}>
          <p>{strings('delete')}</p>
          <i className={'material-icons delete'}>{icons.DELETE}</i>
        </div>
      </div>
    </div>
  );
};
