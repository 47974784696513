import React, { useState, useEffect } from 'react';
import { InputTypes } from '../../../../../utils';
import { Input } from '../../../../components';
import { MentorProfile } from '../../../../components/userSummary/mentorProfile/MentorProfile.jsx';
import { validateMentorUpgradeForm } from '../FormValidator';
import { FreemiumUpgradePopup } from './FreemiumUpgradePopup';

export const UpgradeToMentorPopup = ({strings, phone, onCancel, onValidate}) => {

  const [mentorInfo, setMentorInfo] = useState({
    phone: phone,
    description: null,
    companyName: null,
    companyAddress: null,
    companyCity: null,
    companyPostalCode: null,
    companyCountry: null,
    ceoFirstName: null,
    ceoLastName: null,
    ceoEmail: null,
    ceoPhone: null,
  });
  const [errors, setErrors] = useState({
    phone: {},
    description: {},
    companyName: {},
    companyAddress: {},
    companyCity: {},
    companyPostalCode: {},
    companyCountry: {},
    ceoFirstName: {},
    ceoLastName: {},
    ceoEmail: {},
    ceoPhone: {},
  });

  //When an error is raised by validation, scroll to the first input in error
  useEffect(() => {
    const popup = document.querySelector(`.upgrade-popup`);
    const firstInvalidElement = document.querySelector(`.invalid`);
    if (firstInvalidElement) {
      const offsetTop = firstInvalidElement.offsetTop - popup.offsetTop - 35;

      // Internet Explorer 6-11
      const isIE = !!document.documentMode;
      // Edge 20+
      const isEdge = !isIE && !!window.StyleMedia;
      if (isIE || isEdge) {
        popup.scrollTop = offsetTop;
      } else {
        popup.scrollTo({
          top: offsetTop,
          // top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  });

  const handleValueChanged = (tag, value) => {
    setMentorInfo({...mentorInfo, [tag]: value});
    const updatedErrors = JSON.parse(JSON.stringify(errors));
    Object.values(updatedErrors).map(val => val.onError = false);
    setErrors(updatedErrors);
  };

  const validateForm = () => {
    const result = validateMentorUpgradeForm(mentorInfo, errors);
    const isInError = Object.values(result).some(val => val.onError);
    if (isInError) {
      setErrors(result);
    } else {
      onValidate(mentorInfo);
    }
  };

  return <FreemiumUpgradePopup
    strings={strings}
    label={strings('upgrade_mentor')}
    message={strings('upgrade_mentor_description')}
    onCancel={onCancel}
    onValidate={validateForm}>
    {!phone &&
    <div className={'user-info'}>
      <p>{strings('user_info')}</p>
      <Input
        type={InputTypes.TEXT}
        label={strings('phone')}
        tag={'phone'}
        defaultValue={mentorInfo.phone}
        inputClassName={`${errors.phone.onError ? 'invalid' : ''}`}
        error={errors.phone.onError ? strings(errors.phone.errorMsg) : null}
        isHighlighted={true}
        handleValueChanged={handleValueChanged}
      />
    </div>
    }
    <MentorProfile
      strings={strings}
      mentor={mentorInfo}
      errors={errors}
      isEditing={true}
      handleValueChanged={handleValueChanged}
    />
  </FreemiumUpgradePopup>;
};
