import React, {PureComponent} from 'react';
import { Routes } from '../../../../utils';
import { SchoolDetails } from './common';

export class SchoolWrapperDetails extends PureComponent {

  state = {
    fromInscription: false,
    fromReinscription: false,
  };

  componentDidMount() {
    const {getSchoolDetails, getSchoolDetailInscriptions, getSchoolDetailReinscriptions, match} = this.props;
    const {schoolId}= match.params;

    const fromInscription = match.url.indexOf("/inscriptions") !== -1;
    const fromReinscription = match.url.indexOf("/reinscriptions") !== -1;
    this.setState({
      fromInscription,
      fromReinscription,
    });

    if (fromInscription) {
      getSchoolDetailInscriptions(schoolId)
    } else if (fromReinscription) {
      getSchoolDetailReinscriptions(schoolId);
    } else {
      getSchoolDetails(schoolId);
    }
  }

  componentWillUnmount() {
    const {resetSchoolDetail, resetSchoolDetailInscription} = this.props;
    const {fromInscription, fromReinscription} = this.state;

    if (fromInscription || fromReinscription) {
      resetSchoolDetailInscription();
    } else {
      resetSchoolDetail();
    }
  }

  updateSchool = (isValid, result) => {
    if (isValid) {
      const {updateSchool, updateSchoolInscriptions, updateSchoolReinscriptions, school, schoolInscriptions} = this.props;
      const {fromInscription, fromReinscription} = this.state;

      if (fromInscription) {
        if (JSON.stringify(result) !== JSON.stringify(schoolInscriptions)) {
          updateSchoolInscriptions(result)
        }
      } else if (fromReinscription) {
        if (JSON.stringify(result) !== JSON.stringify(schoolInscriptions)) {
          updateSchoolReinscriptions(result)
        }
      } else {
        if (JSON.stringify(result) !== JSON.stringify(school)) {
          updateSchool(result)
        }
      }
    }
  };

  onDeleteSchool = () => {
    const {deleteSchool, school} = this.props;

    deleteSchool(school.id)
  };

  onDeleteInscription = () => {
    const {deleteSchoolInscription, deleteSchoolReinscription, schoolInscriptions} = this.props;
    const {fromInscription} = this.state;

    if (fromInscription) {
      deleteSchoolInscription(schoolInscriptions.id)
    } else {
      deleteSchoolReinscription(schoolInscriptions.id)
    }
  };

  onStartReinscription = () => {
    const {navigateTo, school} = this.props;

    navigateTo(Routes.SCHOOL_REINSCRIPTION.replace(':schoolId', school.id))
  };

  onEditConvention = () => {
    const {editSchoolInscriptionConvention, editSchoolReinscriptionConvention, schoolInscriptions} = this.props;
    const {fromInscription} = this.state;

    if (fromInscription) {
      editSchoolInscriptionConvention(schoolInscriptions.id)
    } else {
      editSchoolReinscriptionConvention(schoolInscriptions.id)
    }
  };

  onValidateSchool = () => {
    const {validateSchoolInscription, validateSchoolReinscription, schoolInscriptions} = this.props;
    const {fromInscription} = this.state;

    if (fromInscription) {
      validateSchoolInscription(schoolInscriptions.id)
    } else {
      validateSchoolReinscription(schoolInscriptions.id)
    }
  };

  render() {
    const {strings, pop, school, schoolInscriptions, regions, navigateTo} = this.props;
    const {fromInscription, fromReinscription} = this.state;

    const isValidatedSchool = !fromInscription && !fromReinscription;
    return (
      <SchoolDetails
        pop={pop}
        strings={strings}
        school={isValidatedSchool ? school : schoolInscriptions}
        updateSchool={this.updateSchool}
        handleReinscription={isValidatedSchool ? this.onStartReinscription : null}
        handleEditConvention={isValidatedSchool ? null : this.onEditConvention}
        handleValidationSchool={isValidatedSchool ? null : this.onValidateSchool}
        handleDeletion={isValidatedSchool ? this.onDeleteSchool : this.onDeleteInscription}
        regions={regions}
        goToPrevious={isValidatedSchool ? () => navigateTo(Routes.SCHOOLS_LIST) : () => navigateTo(Routes.INSCRIPTIONS)}
      />
    )
  }
}
