import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import { userRole, formatRegionsSelector } from '../../utils';
import { ALL, ITEM_BY_PAGE } from '../../utils/constants';

const defaultFilter = {
  search: '',
  region: null,
};
const getCurrentFilter = state =>
  state.freemiums.currentFilter || {
    page: 0,
    filter: defaultFilter,
  };
const user = state => state.user || {};
const users = state => state.user.list || [];
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const strings = state => getTranslate(state.locale);

const hasUserAccessToBO = createSelector(user, user => {
  return (
    user !== {} &&
    (user.role === userRole.ADMIN || user.role === userRole.FACILITATOR)
  );
});

const getUsers = () =>
  createSelector(
    strings,
    userRegion,
    regions,
    getCurrentFilter,
    users,
    (strings, userRegion, regions, currentFilter, users) => {
      const { filter = defaultFilter, page = 0 } = currentFilter;
      const { search, region } = filter;
      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;

      const regionList = formatRegionsSelector(strings, regions);
      const regionObject = regionList.find(search =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );

      const pageFilter = {
        page: page,
        filter: {
          search: search,
          //Do not override the region if there is one selected
          region: regionObject,
        },
      };

      const filteredUsers = filterUsers(
        strings,
        users,
        page,
        search,
        regionFilter
      );

      return { ...pageFilter, ...filteredUsers };
    }
  );
const filterUsers = (strings, users, page, search, region) => {
  let filteredUsers = users;
  if (search.length > 0) {
    filteredUsers = filteredUsers.filter(p =>
      `${p.firstName} ${p.lastName}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredUsers = filteredUsers.filter(
      p =>
        p.regions &&
        p.regions.length > 0 &&
        p.regions.findIndex(r => r === region) !== -1
    );
  }

  filteredUsers = formatUsers(filteredUsers, strings);

  return {
    all: filteredUsers,
    listed: filteredUsers.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredUsers.length / ITEM_BY_PAGE),
  };
};
const formatUsers = users => {
  return users.map(item => ({
    id: item.id,
    title: `${item.firstName} ${item.lastName}`,
    details: {
      place: '',
      time: '',
      phone: item.phone,
      academy: item.academies.join(','),
    },
    otherDetails: {
      code: '',
      password: '',
    },
    members: {
      supervisors: userRole.SUPERVISOR,
      mentors: userRole.MENTOR,
      facilitators: userRole.FACILITATOR,
    },
    role: item.role,
    progress: 0,
    regions: item.regions.join(','),
    academy: item.academies.join(','),
  }));
};
export default {
  hasUserAccessToBO,
  getUsers,
};
