import React from 'react';
import './user-badge.scss';

export const UserBadge = ({role}) => {
  let letter;
  let className;

  switch(role) {
    case 'SUPERVISOR':
      letter = 'E'; className = 'supervisor';
      break;
    case 'MENTOR':
      letter = 'M'; className = 'mentor';
      break;
    case 'FACILITATOR':
      letter = 'F'; className = 'facilitator';
      break;
    case 'ADMIN':
      letter = 'F'; className = 'admin';
      break;
    default:
      letter = '?'; className = 'other';
      break;
  }

  return <div id={'userBadge'} className={className}>{letter}</div>
}