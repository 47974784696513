import { methods, url } from '../utils';
import { authenticatedService } from './middleware';

const { GET } = methods;
const routes = {
  getUser: id => `/users/${id}`,
  getUsers: adminId => url.LIST_USERS(adminId),
};

export default {
  getUser: (adminId, id) =>
    authenticatedService(GET, routes.getUser(adminId, id), {}, {}),
  getUsers: adminId =>
    authenticatedService(methods.GET, routes.getUsers(adminId), {}, {}),
};
