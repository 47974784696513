import {createReducer} from 'reduxsauce';
import {types} from './actions';
import {logger} from "../../utils/logger";

const initialState = {
  message: '',
  title: null,
  displayed: false,
  actionPopup: () => logger.warn("no function implemented")
};

const displayConfirmPopup = (state = initialState, action) => {
  const {message, title, popupAction} = action;
  return {...state, message, title, actionPopup: popupAction, displayed: true};
};

const hideConfirmPopup = (state = initialState) => {
  return {...state, message: initialState.message, title: initialState.title, displayed: false};
};

export default createReducer(initialState, {
  [types.DISPLAY_CONFIRM_POPUP]: displayConfirmPopup,
  [types.HIDE_CONFIRM_POPUP]: hideConfirmPopup,
});
