import { connect } from "react-redux";
import { MinisActions } from '../../../../../redux/minis';
import { SchoolsSelectors } from "../../../../../redux/schools";
import { formatListWithIdForSelector } from '../../../../../utils';
import { default as Schools } from "./Schools";

const mapStateToProps = (state) => ({
  schoolsForPanel: (page, search, region, academy) => SchoolsSelectors.getSchoolsForProjectBinding(page, search, region, academy)(state),
  schools : state.schools.list,
  categories: formatListWithIdForSelector(state.params.categories),
  levels: formatListWithIdForSelector(state.params.levels),
  sectors: formatListWithIdForSelector(state.params.sectors),
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(MinisActions.getSchoolsFormDataRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Schools);
