import React from "react";
import { icons } from "../../../../../assets";
import { civilityValues } from '../../../../../utils';
import { UserBadge } from "../../../../components/usersList/userBadge";

const User = ({ strings, user, userRole, isLast, handleDeleteUser }) => {
  const civility =
    user.civility === civilityValues.MR ? strings("mr") : strings("mrs");

  return (
    <div className={"user"}>
      <div className={"content"}>
        <div className={"ser-badge"}>
          <UserBadge className={"user-badge"} role={userRole} />
        </div>
        <div className={"identity"}>
          <p>{`${civility} ${user.firstName} ${user.lastName}`}</p>
        </div>
        <div className={"email"}>
          <p>{user.email}</p>
        </div>
        <div className={"phone"}>{user.phone}</div>
        <i className={"material-icons"} onClick={handleDeleteUser}>
          {icons.DELETE}
        </i>
      </div>
      {!isLast && <div className={"dashed-separator"} />}
    </div>
  );
};

export default User;
