import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import { SchoolsActions } from '../../../../redux/schools';
import { formatRegionsSelector } from '../../../../utils';
import { SchoolCreation } from './SchoolCreation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  regions: formatRegionsSelector(getTranslate(state.locale), state.commons.regions).slice(1),
  creationSaved: state.schools.creationSaved,
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  createSchool: school => dispatch(SchoolsActions.createSchoolRequested(school)),
  resetCreation: () => dispatch(SchoolsActions.resetCreation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolCreation);
