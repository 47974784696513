import { createReducer } from "reduxsauce";
import { types } from "./actions";

const initialState = {
  currentFilter: null,
  mini: null,
  list: [],
};

const getMiniListSucceeded = (state = initialState, action) => {
  const { list } = action;
  return { ...state, list };
};

const setMinisCurrentFilter = (state = initialState, action) => {
  const {page, filter} = action;
  return {...state, currentFilter: {page, filter}};
};

const getMiniSucceeded = (state = initialState, action) => {
  const { mini } = action;
  return { ...state, mini };
};

const createMiniSucceeded = (state = initialState, action) => {
  const { mini } = action;
  return { ...state, mini };
};

const updateMiniSucceeded = (state = initialState, action) => {
  const { mini } = action;
  const { list } = state;

  const newList = list.slice();
  const miniIndex = newList.findIndex(item => item.id === mini.id);
  if (miniIndex >= 0) {
    newList[miniIndex] = {...JSON.parse(JSON.stringify(newList[miniIndex])), status: mini.status}
  }

  return { ...state, mini, list: newList };
};

const duplicateMiniSucceeded = (state = initialState, action) => {
  const { mini } = action;
  return { ...state, mini };
};

const deleteMiniSucceeded = (state = initialState, action) => {
  const { id } = action;
  const { list, mini } = state;

  const newList = list.slice();
  const miniIndex = newList.findIndex(item => item.id === id);
  if (miniIndex >= 0) {
    newList.splice(miniIndex, 1);
  }

  return { ...state, list: newList, mini: mini && id === mini.id ? initialState.mini : mini };
};

export default createReducer(initialState, {
  [types.GET_MINI_LIST_SUCCEEDED]: getMiniListSucceeded,
  [types.SET_MINIS_CURRENT_FILTER] : setMinisCurrentFilter,
  [types.GET_MINI_SUCCEEDED]: getMiniSucceeded,
  [types.CREATE_MINI_SUCCEEDED]: createMiniSucceeded,
  [types.UPDATE_MINI_SUCCEEDED]: updateMiniSucceeded,
  [types.DUPLICATE_MINI_SUCCEEDED]: duplicateMiniSucceeded,
  [types.DELETE_MINI_REQUESTED]: deleteMiniSucceeded,
});
