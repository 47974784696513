import React, {PureComponent} from 'react';
import {Button, ButtonColors, Panel, SideBar, UserSummary} from '../../../components/index';
import {validateUserForm} from '../details/FormValidator';
import './supervisorCreation.scss';

export class SupervisorCreation extends PureComponent {

  state = {
    checkForm: false,
  };

  onClickValidate = () => {
    this.setState({
      checkForm: true,
    });
  };

  saveSupervisor = (isValid, supervisor) => {
    this.setState({
      checkForm: false,
    });

    if (isValid) {
      this.props.createSupervisor(supervisor);
    }
  };

  render() {
    const {strings} = this.props;
    const {checkForm} = this.state;

    return (
      <div id={'supervisor-creation-screen'}>
        <div className={'side-bar'}>
          <SideBar />
        </div>
        <div className={'supervisor-creation'}>
          <Panel>
            <UserSummary
              userFound={false}
              strings={strings}
              checkForm={checkForm}
              validateUserForm={validateUserForm}
              updateUser={this.saveSupervisor}
              creationMode={true}/>
          </Panel>
          <Button
            color={ButtonColors.green}
            label={strings('create')}
            action={this.onClickValidate}
          />
        </div>
      </div>
    );
  }
};

