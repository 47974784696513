import { createReducer } from 'reduxsauce';
import { types } from './actions';

const initialState = {
  currentFilter: null,
  project: {
    id: null,
    code: null,
    name: null,
    password: null,
    school: null,
    type: null,
    users: [],
  },
  list: [],
  creationSaved: false,
  projectSaved: null,
};

const getProjectDetailSucceeded = (state = initialState, action) => {
  const { project } = action;
  return {
    ...state,
    project: project,
    creationSaved: false,
  };
};

const setProjectsCurrentFilter = (state = initialState, action) => {
  const { page, filter } = action;
  return { ...state, currentFilter: { page, filter }, creationSaved: false };
};

const getProjectsSucceeded = (state = initialState, action) => {
  const { list } = action;

  return { ...state, list };
};

const updateInfoSucceeded = (state = initialState, action) => {
  const { project } = action;

  return {
    ...state,
    project: project === null ? state.project : project,
    creationSaved: false,
  };
};

const linkUserSucceeded = (state = initialState, action) => {
  const { project } = action;
  return { ...state, project, creationSaved: false };
};

const unlinkUserSucceeded = (state = initialState, action) => {
  const { project } = action;
  return { ...state, project };
};

const createProjectSucceeded = (state = initialState, action) => {
  const { project } = action;
  return { ...state, projectSaved: project, project, creationSaved: true };
};

const resetProjectDetail = (state = initialState) => {
  return { ...state, project: initialState.project };
};

const archiveProjectSucceeded = (state = initialState, action) => {
  const { project } = action;
  const { project: projectState } = state;

  if (project.id === projectState.id) {
    return { ...state, project };
  } else {
    return { ...state };
  }
};

export default createReducer(initialState, {
  [types.GET_PROJECTS_SUCCEEDED]: getProjectsSucceeded,
  [types.SET_PROJECTS_CURRENT_FILTER]: setProjectsCurrentFilter,
  [types.GET_PROJECT_DETAIL_SUCCEEDED]: getProjectDetailSucceeded,
  [types.LINK_USER_SUCCEEDED]: linkUserSucceeded,
  [types.UNLINK_USER_SUCCEEDED]: unlinkUserSucceeded,
  [types.UPDATE_INFO_SUCCEEDED]: updateInfoSucceeded,
  [types.CREATE_PROJECT_SUCCEEDED]: createProjectSucceeded,
  [types.RESET_DETAIL_PROJECT]: resetProjectDetail,
  [types.ARCHIVE_PROJECT_SUCCEEDED]: archiveProjectSucceeded,
});
