import React, { useState } from "react";
import Shortid from "shortid";
import { icons } from "../../../../../assets";
import { InputTypes } from "../../../../../utils";
import { Input } from "../../../../components";
import Tool from "./Tool";

const Dispatch = ({ strings, schools, rooms, handleDispatchValueChanged }) => {
  const handleDispatchChanged =
    (schoolIndex, groupIndex, roomIndex) => (tag, value) => {
      handleDispatchValueChanged(schoolIndex, groupIndex, roomIndex, value);
    };

  const schoolsGroups = schools
    .map((school) => (school.groups ? school.groups : []))
    .reduce((acc, val) => [...acc, ...val], []);

  const totalsByRoom = schoolsGroups
    .map((group) => group.dispatch)
    .reduce(
      (acc, val) =>
        acc.map((value, index) =>
          isNaN(parseInt(val[index])) ? value : value + parseInt(val[index])
        ),
      new Array(rooms.length).fill(0)
    );

  const totalGroups = schoolsGroups
    .map((group) => group.boysCount + group.girlsCount)
    .reduce((acc, val) => acc + val, 0);
  const totalDispatched = totalsByRoom.reduce((acc, val) => acc + val, 0);

  return (
    <div className={"dispatch-wrapper"}>
      <div className={"dispatch"}>
        <div className={"rooms row"}>
          {rooms.map((room, index) => (
            <div key={room.id ? room.id : room.key} className={"room"}>
              <p className={"label"}>{`S${index + 1}`}</p>
            </div>
          ))}
        </div>
        <div className={"dashed-separator"} />
        <div className={"schools column"}>
          {schools.map((school, schoolIndex) => (
            <div key={school.id} className={"school column"}>
              <p className={"label"}>{school.name}</p>
              <div className={"separator"} />
              <div className={"groups column"}>
                {school.groups.map((group, groupIndex) => (
                  <div key={group.id} className={"group row"}>
                    <p className={"label"}>{`G${groupIndex + 1}`}</p>
                    <div className={"rooms row"}>
                      {group.dispatch.map((value, dispatchIndex) => (
                        <Input
                          key={dispatchIndex}
                          className={"room"}
                          type={InputTypes.NUMBER}
                          tag={"dispatch"}
                          defaultValue={value}
                          handleValueChanged={handleDispatchChanged(
                            schoolIndex,
                            groupIndex,
                            dispatchIndex
                          )}
                        />
                      ))}
                    </div>
                    <div className={"summary row"}>
                      <p className={"total"}>{`/${
                        group.boysCount + group.girlsCount
                      }`}</p>
                      <p className={"remaining"}>
                        [
                        <span>
                          {group.boysCount +
                            group.girlsCount -
                            group.dispatch.reduce(
                              (acc, val) =>
                                isNaN(parseInt(val))
                                  ? acc
                                  : acc + parseInt(val),
                              0
                            )}
                        </span>
                        ]
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className={"totals row"}>
          {rooms.map((room, index) => (
            <div key={room.id ? room.id : room.key} className={"total"}>
              <p className={"label"}>{totalsByRoom[index]}</p>
            </div>
          ))}
          <div className={"summary row"}>
            <p className={"total"}>{totalGroups}</p>
            <p className={"remaining"}>
              [<span>{totalGroups - totalDispatched}</span>]
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dispatch;
