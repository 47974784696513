import { createAction } from '../../utils/index';

// Types
export const types = {
  GET_USER_REQUESTED: 'GET_USER_REQUESTED',
  GET_USERS_REQUESTED: 'GET_USERS_REQUESTED',
  GET_USER_SUCCEEDED: 'GET_USER_SUCCEEDED',
  GET_USERS_SUCCEEDED: 'GET_USERS_SUCCEEDED',
};

// Actions
export default {
  getUserRequested: () => createAction(types.GET_USER_REQUESTED),
  getUsersRequested: () => createAction(types.GET_USERS_REQUESTED, {}),
  getUsersSucceeded: list => createAction(types.GET_USERS_SUCCEEDED, { list }),
  getUserSucceeded: user => createAction(types.GET_USER_SUCCEEDED, { user }),
};
