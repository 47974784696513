import { put, takeLatest } from 'redux-saga/effects';
import { CommonsActions } from '../commons';
import { types } from './actions';

function* logout() {
  yield put(CommonsActions.resetReducers());
  window.location = `${process.env.REACT_APP_AUTH_URL}?action=logout`;
}

export default [
  takeLatest(types.LOGOUT_REQUESTED, logout),
];
