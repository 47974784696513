import React, { Fragment, PureComponent } from 'react';
import {
  Button,
  ButtonColors,
  FormDropDown,
  Input,
  Panel,
  RadioButton,
} from '../../../components';
import { icons } from '../../../../assets';
import {
  getProjectImage,
  InputTypes,
  projectDuration,
  projectStatus,
  projectTypes,
  userRole,
} from '../../../../utils';
import './projectInfo.scss';
import moment from 'moment';

export class ProjectInfo extends PureComponent {
  state = {
    currentProjectStatus: this.props.isCreation ? null : this.props.projectInfo.status,
    edition: this.props.edition || false,
    data: this.props.isCreation ? null : { ...this.props.projectInfo },
    type: projectTypes[0].value,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.projectInfo !== prevProps.projectInfo) {
      this.setState({ data: { ...this.props.projectInfo } });
    }
  }

  onEdit = () => {
    const { projectInfo, updateInfoProject, onValueChange, isCreation } =
      this.props;
    const { edition, data } = this.state;

    if (edition === true) {
      if (!data.duration) {
        this.setState({ durationError: true });
      } else {
        if (JSON.stringify(projectInfo) !== JSON.stringify(data)) {
          isCreation && onValueChange(data);
          !isCreation && updateInfoProject(data);
        }
        this.setState({ edition: false });
      }
    } else {
      this.setState({ edition: true });
    }
  };

  onTypeChange = (tag, newType) => {
    const { onValueChange, displayError, isCreation } = this.props;
    const { data } = this.state;
    if (!isCreation) {
      const { successes, type } = data;

      if (tag === "type") {
        let hasValidatedSuccess = successes.find((success) => {
          return success.validation.validationDate !== null;
        });

        if (hasValidatedSuccess && type !== newType) {
          displayError("update_project_failed_has_success");
          return;
        }
      }
    }

    this.setState({
      data: {
        ...data,
        [tag]: newType,
      },
    });

    onValueChange({ [tag]: newType });
  };

  onDurationChange = (tag, value) => {
    const { data } = this.state;
    const { onValueChange } = this.props;
    this.setState({
      data: {
        ...data,
        [tag]: value.key,
      },
    });
    onValueChange({ [tag]: value.key });
  };

  getStatusLabel = status => {
    const { strings } = this.props;

    switch (status) {
      case projectStatus.VALIDATED:
        return strings('project_status_validated');
      case projectStatus.ARCHIVED:
        return strings('project_status_archived');
      case projectStatus.COMMING:
        return strings('project_status_comming');
      case projectStatus.FINISHED:
        return strings('project_status_finished');
      default:
        return '';
    }
  };

  getSeasons = () => {
    let now = moment();
    let limitDate = moment(`01/09/${now.get("year")}`, 'DD/MM/YYYY');
    if (now.isBefore(limitDate)) {
      return [`${now.get('year') - 1}/${now.get("year")}`, `${now.get('year')}/${now.get("year") + 1}`];
    } else {
      return [`${now.get('year')}/${now.get("year") + 1}`, `${now.get('year') + 1}/${now.get("year") + 2}`]
    }
  }

  render() {
    const { strings, isCreation, durationError, seasonError, user } = this.props;
    const { data, edition, currentProjectStatus } = this.state;
    const durationList = [
      {
        key: projectDuration.ONE_YEAR,
        value: {
          label: strings('duration_one_year'),
        },
      },
      {
        key: projectDuration.TWO_YEARS,
        value: {
          label: `${projectDuration.TWO_YEARS} ${strings(
            'duration_several_years'
          )}`,
        },
      },
    ];
    const seasonList = [
      {
        key: this.getSeasons()[0],
        value: {
          label: this.getSeasons()[0]
        }
      },
      {
        key: this.getSeasons()[1],
        value: {
          label: this.getSeasons()[1]
        }
      }
    ];

    let statusList = [];
    let currentStatus = null;
    if (data) {
      statusList = [
        {
          key: currentProjectStatus,
          value: {
            label: this.getStatusLabel(currentProjectStatus) || ''
          }
        },
        {
          key: projectStatus.FINISHED,
          value: {
            label: this.getStatusLabel(projectStatus.FINISHED) || ''
          }
        }
      ];
      currentStatus =
        data && data.status
          ? statusList.find(search => search.key === data.status)
          : null;
    }

    const currentDuration =
      data && data.duration
        ? durationList.find(search => search.key === data.duration)
        : null;

    const currentSeason =
      data && data.season
        ? seasonList.find(search => search.key === data.season)
        : null;

    return (
      <Panel id={'projectInfo'} className={'project-info'}>
        <div className={'header'}>
          <Input
            type={InputTypes.TEXT}
            placeholder={strings('project_name')}
            tag={'name'}
            className={`medium title`}
            defaultValue={data && (data.name || '')}
            disabled={!edition}
            isHighlighted={false}
            handleValueChanged={this.onTypeChange}
          />
          <Button
            label={edition ? strings('button_text_save') : strings('button_text_modify')}
            className={'edit-button'}
            color={ButtonColors.orange}
            icon={edition ? icons.DONE : icons.EDIT}
            enabled={data && data.id}
            action={this.onEdit}
          />
        </div>
        <div className={'horizontal-dot-separator'} />
        <div className={'type'}>
          <p>{strings('type')}</p>
          <div className={'list-radio'}>
            {projectTypes.map(({ value }) => (
              <RadioButton
                key={value}
                className={'medium'}
                child={getProjectImage(strings, value)}
                value={value}
                isChecked={value === (data && data.type)}
                onChange={type => this.onTypeChange('type', type)}
                disabled={!edition}
              />
            ))}
          </div>
        </div>
        {!isCreation ? (
          <Fragment>
            <div className={'horizontal-dot-separator'} />
            <div className={'pass'}>
              <Input
                tag={'code'}
                className={'medium'}
                label={strings('code')}
                defaultValue={data && (data.code || '')}
                disabled
                isHighlighted={false}
                handleValueChanged={this.onTypeChange}
              />
              <Input
                tag={'password'}
                className={'medium'}
                label={strings('password')}
                defaultValue={data && (data.password || '')}
                disabled
                isHighlighted={false}
                handleValueChanged={this.onTypeChange}
              />
            </div>
            <div className={'horizontal-dot-separator'} />
            <div className={'status'}>
              {edition ? (
                <Fragment>
                  {user.user.role == userRole.FACILITATOR || user.user.role == userRole.ADMIN ? (
                    <FormDropDown
                    tag={'status'}
                    className={'medium'}
                    label={strings('project_status')}
                    data={statusList}
                    current={currentStatus ? currentStatus.value.label : null}
                    onValueChange={this.onDurationChange}
                  />
                  ) : (
                    <Input
                      tag={'status'}
                      className={'medium'}
                      label={strings('project_status')}
                      defaultValue={data && (this.getStatusLabel(data.status) || '')}
                      disabled
                      isHighlighted={false}
                      handleValueChanged={this.onTypeChange}
                    />
                  )}
                  <FormDropDown
                    tag={'season'}
                    className={'medium'}
                    label={strings('season')}
                    data={seasonList}
                    current={currentSeason ? currentSeason.value.label : null}
                    onValueChange={this.onDurationChange}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <Input
                      tag={'status'}
                      className={'medium'}
                      label={strings('project_status')}
                      defaultValue={data && (this.getStatusLabel(data.status) || '')}
                      disabled
                      isHighlighted={false}
                      handleValueChanged={this.onTypeChange}
                    />
                  <Input
                    tag={'season'}
                    className={'medium'}
                    label={strings('season')}
                    defaultValue={
                      data && (`${strings('season_year')} ${data.season}` || '')
                    }
                    disabled
                    isHighlighted={false}
                  />
                </Fragment>
              )}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={'horizontal-dot-separator'} />
            <div className={'status'}>
              <FormDropDown
                tag={'season'}
                className={'medium'}
                label={strings('season')}
                data={seasonList}
                current={currentSeason ? currentSeason.value.label : null}
                dropDownClassName={`${seasonError && !currentSeason ? 'invalid' : ''
                  }`}
                onValueChange={this.onDurationChange}
                error={
                  seasonError &&
                  isCreation &&
                  strings('project_error_no_season')
                }
              />
            </div>
          </Fragment>
        )}
        <div className={'horizontal-dot-separator'} />
        <div className={'duration'}>
          <FormDropDown
            tag={'duration'}
            className={'duration'}
            label={strings('duration')}
            data={durationList}
            current={currentDuration ? currentDuration.value.label : null}
            dropDownClassName={`${durationError && !currentDuration ? 'invalid' : ''
              }`}
            onValueChange={this.onDurationChange}
            disabled={!edition}
            error={
              durationError &&
              isCreation &&
              strings('project_error_no_duration')
            }
          />
        </div>
      </Panel>
    );
  }
}

ProjectInfo.defaultProps = {
  onValueChange: () => { }
}
