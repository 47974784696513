import React, { useEffect, useState } from 'react';
import ShortId from 'shortid';
import { isIeOrEdge, Routes } from '../../../utils';
import { validateNewProjectsForm } from './creation/FormValidator';
import { formatProjects } from './Format';
import { Navigation } from './navigation/Navigation';
import './reinscription.scss';
import { ProjectSelection } from './selection/ProjectSelection';
import { default as ProjectCreation } from './creation';
import { Validation } from './validation/Validation';

const ReinscriptionSteps = {
  PROJECTS_SELECTION: {
    key: 'PROJECTS_SELECTION',
    label: 'projects_handling',
  },
  ADD_PROJECTS: {
    key: 'ADD_PROJECTS',
    label: 'projects_handling',
  },
  VALIDATION: {
    key: 'VALIDATION',
    label: 'reinscription_validation',
  },
};

const defaultSupervisor = {
  civility: {
    value: null,
    onError: false,
  },
  firstName: {
    value: null,
    onError: false,
  },
  lastName: {
    value: null,
    onError: false,
  },
  email: {
    value: null,
    onError: false,
  },
  phone: {
    value: null,
    onError: false,
  },
};

export const Reinscription = React.memo(({match, strings, school, getSchool, showPopup, startReinscription, pop, navigateTo}) => {
  const [step, setStep] = useState(ReinscriptionSteps.PROJECTS_SELECTION);
  const [shouldPop, setShouldPop] = useState(true);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [newProjects, setNewProjects] = useState([]);
  const [editedSupervisor, setEditedSupervisor] = useState(null);
  const [pendingModif, setPendingModif] = useState(false);

  useEffect(() => {
    const {schoolId} = match.params;
    if (!school || parseInt(schoolId) !== school.id) {
      setShouldPop(false);
      getSchool(schoolId);
    }
  }, [school, getSchool]);

  const handleProjectCheckChanged = id => () => {
    const newProjects = selectedProjects.includes(id)
      ? selectedProjects.filter(item => item !== id)
      : selectedProjects.concat(id);

    setSelectedProjects(newProjects);
  };

  const handleProjectValueChanged = index => tag => value => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));
    projectsCopy[index][tag] = value;
    projectsCopy[index].onError = null;
    setNewProjects(projectsCopy);
    setPendingModif(true);
  };

  const handleAddUsersToProject = (index, users) => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));
    const project = projectsCopy[index];
    if (project.users) {
      const newUsers = users.filter(usr => !project.users.some(search => search.id === usr.id));
      project.users = [...project.users, ...newUsers];
    } else {
      project.users = users;
    }

    setPendingModif(true);
    setNewProjects(projectsCopy);
  };

  const handleAddNewUserToProject = index => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));
    const project = projectsCopy[index];
    const userKey = ShortId.generate();
    const newUser = {...JSON.parse(JSON.stringify(defaultSupervisor)), key: userKey};
    if (project.users) {
      project.users = [...project.users, newUser];
    } else {
      project.users = [newUser];
    }

    setPendingModif(true);
    setNewProjects(projectsCopy);
    setEditedSupervisor({project: index, user: userKey});
  };

  const handleUpdateUser = (user) => {
    if (editedSupervisor) {
      const projectsCopy = JSON.parse(JSON.stringify(newProjects));
      const project = projectsCopy[editedSupervisor.project];

      const userIndex = project.users.findIndex(search => search.key === user.key);
      project.users[userIndex] = user;

      project.onError = false;
      project.users.filter(usr => !usr.id).map(user => Object.entries(user).filter(([key]) => key !== 'key').map(([_, value]) => value))
        .reduce((acc, current) => acc.concat(current), [])
        .map(val => val.onError = false);

      setPendingModif(true);
      setNewProjects(projectsCopy);
      setEditedSupervisor(null);
    }
  };

  const handleAddFiledUser = (index, user) => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));
    const project = projectsCopy[index];

    if (project.users) {
      project.users = [...project.users, user];
    } else {
      project.users = [user];
    }

    setPendingModif(true);
    setNewProjects(projectsCopy);
  };

  const handleRemoveExistingUser = (index, id) => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));
    const project = projectsCopy[index];

    if (project.users && project.users.some(user => user.id === id)) {
      const userIndex = project.users.findIndex(user => user.id === id);
      project.users.splice(userIndex, 1);

      project.onError = false;
      project.users.filter(usr => !usr.id).map(user => Object.entries(user).filter(([key]) => key !== 'key').map(([_, value]) => value))
        .reduce((acc, current) => acc.concat(current), [])
        .map(val => val.onError = false);

      setPendingModif(true);
      setNewProjects(projectsCopy);
    }
  };

  const handleRemoveNewUser = (index, key) => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));
    const project = projectsCopy[index];

    if (project.users && project.users.some(user => user.key === key)) {
      const userIndex = project.users.findIndex(user => user.key === key);
      project.users.splice(userIndex, 1);

      project.onError = false;
      project.users.filter(usr => !usr.id).map(user => Object.entries(user).filter(([key]) => key !== 'key').map(([_, value]) => value))
        .reduce((acc, current) => acc.concat(current), [])
        .map(val => val.onError = false);

      setPendingModif(true);
      setNewProjects(projectsCopy);
      setEditedSupervisor(null);
    }
  };

  const handleEditNewUser = (index, key) => {
    if (!editedSupervisor) {
      setPendingModif(true);
      setEditedSupervisor({project: index, user: key});
    }
  };

  const handleAddProject = () => {
    setPendingModif(true);
    setNewProjects([...newProjects, {}]);
  };

  const handleDeleteProject = index => () => {
    const projectsCopy = JSON.parse(JSON.stringify(newProjects));

    if (projectsCopy.length > index) {
      projectsCopy.splice(index, 1);

      setNewProjects(projectsCopy);
      if (editedSupervisor && editedSupervisor.project === index) {
        setEditedSupervisor(null);
      }
    }
  };

  const handleGiveUp = () => {
    showPopup(strings('give_up_warning'), () => shouldPop ? pop() : navigateTo(Routes.SCHOOL.replace(':schoolId', match.params.schoolId)));
  };

  const handleValidation = editConvention => () => {
    startReinscription(school.id, selectedProjects, formatProjects(newProjects), editConvention);
  };

  const handleChangeStep = (translation) => {
    if (translation < 0 && step === ReinscriptionSteps.PROJECTS_SELECTION) {
      showPopup(strings('give_up_warning'), () => shouldPop ? pop() : navigateTo(Routes.SCHOOL.replace(':schoolId', match.params.schoolId)));
      return;
    }
    if (translation > 0 && step === ReinscriptionSteps.VALIDATION) {
      return;
    }
    if (translation > 0 && step === ReinscriptionSteps.ADD_PROJECTS) {
      setPendingModif(false);
      const result = validateNewProjectsForm(newProjects);

      if (result.some(project => project.onError)) {
        setNewProjects(result);
        return;
      }
    }

    const currentStepIndex = Object.values(ReinscriptionSteps).indexOf(step);
    if (currentStepIndex >= 0 && currentStepIndex + translation >= 0 && currentStepIndex + translation < Object.values(ReinscriptionSteps).length) {
      setStep(Object.values(ReinscriptionSteps)[currentStepIndex + translation]);
    }

    const root = document.querySelector(`#root`);
    if (isIeOrEdge(window, document)) {
      root.scrollTop = 0;
    } else {
      root.scrollTo(0,0);
    }
  };

  const renderStep = () => {
    switch (step) {
      case ReinscriptionSteps.PROJECTS_SELECTION:
        return (
          <ProjectSelection
            strings={strings}
            projects={school.projects}
            selectedProjects={selectedProjects}
            handleProjectCheckChanged={handleProjectCheckChanged}
            handleChangeStep={handleChangeStep}/>
        );
      case ReinscriptionSteps.ADD_PROJECTS:
        return (
          <ProjectCreation
            strings={strings}
            selectedProjects={school.projects.filter(project => selectedProjects.includes(project.id))}
            newProjects={newProjects}
            pendingModif={pendingModif}
            editedSupervisor={editedSupervisor}
            handleChangeStep={handleChangeStep}
            handleValueChanged={handleProjectValueChanged}
            handleAddUsersToProject={handleAddUsersToProject}
            handleAddNewUserToProject={handleAddNewUserToProject}
            handleRemoveExistingUser={handleRemoveExistingUser}
            handleRemoveNewUser={handleRemoveNewUser}
            handleEditNewUser={handleEditNewUser}
            handleUpdateUser={handleUpdateUser}
            handleAddFiledUser={handleAddFiledUser}
            handleAddProject={handleAddProject}
            handleDeleteProject={handleDeleteProject}
          />
        );
      case ReinscriptionSteps.VALIDATION:
        return (
          <Validation
            strings={strings}
            school={school}
            keptProjects={school.projects.filter(project => selectedProjects.includes(project.id))}
            newProjects={newProjects}
            handleValidation={handleValidation}/>
        );
      default:
        return null;
    }
  };

  return (
    <div id={'reinscription'}>
      <div className={'container'}>
        <Navigation
          strings={strings}
          handleGiveUp={handleGiveUp}
          handleChangeStep={handleChangeStep}
          allowPrevStep={true}
          allowNextStep={step !== ReinscriptionSteps.VALIDATION}/>
        <div className={'wrapper'}>
          <div className={'step-container'}>
            <p className={'title'}>{strings(step.label)}</p>
            <div className={'horizontal-plain-separator'}/>
            {renderStep()}
          </div>
        </div>
      </div>
    </div>
  );
});
