import { push } from 'react-router-redux';
import {SchoolList} from './SchoolList'
import {getTranslate} from "react-localize-redux";
import {connect} from 'react-redux'
import {SchoolsActions, SchoolsSelectors} from "../../../redux/schools";
import {formatRegionsSelector} from "../../../utils";
import {ConfirmPopupActions} from "../../../redux/confirmPopup";
import { AuthSelectors } from '../../../redux/auth';


const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  schools: SchoolsSelectors.getSchools()(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message, popupAction)=> dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  getSchools: () => dispatch(SchoolsActions.getSchoolsRequested()),
  setCurrentFilter: (page, filter) => dispatch(SchoolsActions.setSchoolsCurrentFilter(page, filter)),
  exportList: () => dispatch(SchoolsActions.exportSchoolsRequested()),
  navigateTo: path => dispatch(push(path)),
  deleteSchool: id => dispatch(SchoolsActions.deleteSchoolRequested(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolList)
//TODO : mettre dans un index à la base du dossier parent une fois le component SchoolList déplacé dans un dossier "/list"
export {default as SchoolWrapperDetails} from "./details/index"
export {default as SchoolCreation} from "./creation/index"
