import { ProjectCreation } from './ProjectCreation';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { goBack } from 'react-router-redux';
import { ProjectsActions } from '../../../../redux/projects';
import { push } from 'react-router-redux';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isCreation: true,
});

const mapDispatchToProps = dispatch => ({
  pop: () => dispatch(goBack()),
  createProject: newProject =>
    dispatch(ProjectsActions.createProjectRequested(newProject)),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreation);
