import {createAction} from '../../utils/index';

// Types
export const types = {
  GET_FACILITATORS_REQUESTED: "GET_FACILITATORS_REQUESTED",
  GET_FACILITATORS_SUCCEEDED: "GET_FACILITATORS_SUCCEEDED",
  SET_FACILITATORS_CURRENT_FILTER: 'SET_FACILITATORS_CURRENT_FILTER',
  GET_FACILITATOR_DETAIL_REQUESTED: 'GET_FACILITATOR_DETAIL_REQUESTED',
  GET_FACILITATOR_DETAIL_SUCCEEDED: 'GET_FACILITATOR_DETAIL_SUCCEEDED',
  LINK_PROJECT_FACILITATOR_REQUESTED: 'LINK_PROJECT_FACILITATOR_REQUESTED',
  LINK_PROJECT_FACILITATOR_SUCCEEDED: 'LINK_PROJECT_FACILITATOR_SUCCEEDED',
  UNLINK_PROJECT_FACILITATOR_REQUESTED: 'UNLINK_PROJECT_FACILITATOR_REQUESTED',
  UNLINK_PROJECT_FACILITATOR_SUCCEEDED: 'UNLINK_PROJECT_FACILITATOR_SUCCEEDED',
  DELETE_FACILITATOR_REQUESTED: 'DELETE_FACILITATOR_REQUESTED',
  DELETE_FACILITATOR_SUCCEEDED: 'DELETE_FACILITATOR_SUCCEEDED',
  UPDATE_FACILITATOR_REQUESTED: "UPDATE_FACILITATOR_REQUESTED",
  UPDATE_FACILITATOR_SUCCEEDED: "UPDATE_FACILITATOR_SUCCEEDED",
  CREATE_FACILITATOR_REQUESTED: "CREATE_FACILITATOR_REQUESTED",
  CREATE_FACILITATOR_SUCCEEDED: "CREATE_FACILITATOR_SUCCEEDED",
  RESET_FACILITATOR_DETAIL: "RESET_FACILITATOR_DETAIL",
  EXPORT_FACILITATORS_REQUESTED: "EXPORT_FACILITATORS_REQUESTED",
};

// Actions
export default {
  getFacilitatorsRequested : () => createAction(types.GET_FACILITATORS_REQUESTED, {}),
  getFacilitatorsSucceeded : (list) => createAction(types.GET_FACILITATORS_SUCCEEDED, {list}),
  setFacilitatorsCurrentFilter : (page, filter) => createAction(types.SET_FACILITATORS_CURRENT_FILTER, {page, filter}),
  getFacilitatorDetailRequested: facilitatorId => createAction(types.GET_FACILITATOR_DETAIL_REQUESTED, {facilitatorId}),
  getFacilitatorDetailSucceeded: facilitator => createAction(types.GET_FACILITATOR_DETAIL_SUCCEEDED, {facilitator}),
  linkProjectFacilitatorRequested: (userId, projectsId) => createAction(types.LINK_PROJECT_FACILITATOR_REQUESTED, {userId, projectsId}),
  linkProjectFacilitatorSucceeded: facilitator => createAction(types.LINK_PROJECT_FACILITATOR_SUCCEEDED, {facilitator}),
  unlinkProjectFacilitatorRequested: (userId, projectId) => createAction(types.UNLINK_PROJECT_FACILITATOR_REQUESTED, {userId, projectId}),
  unlinkProjectFacilitatorSucceeded: facilitator => createAction(types.UNLINK_PROJECT_FACILITATOR_SUCCEEDED, {facilitator}),
  deleteFacilitatorRequested: id => createAction(types.DELETE_FACILITATOR_REQUESTED, {id}),
  deleteFacilitatorSucceeded: id => createAction(types.DELETE_FACILITATOR_SUCCEEDED, {id}),
  updateFacilitatorRequested : facilitator => createAction(types.UPDATE_FACILITATOR_REQUESTED, {facilitator}),
  updateFacilitatorSucceeded : facilitator => createAction(types.UPDATE_FACILITATOR_SUCCEEDED, {facilitator}),
  createFacilitatorRequested : facilitator => createAction(types.CREATE_FACILITATOR_REQUESTED, {facilitator}),
  createFacilitatorSucceeded : facilitator => createAction(types.CREATE_FACILITATOR_SUCCEEDED, {facilitator}),
  resetFacilitatorDetail : () => createAction(types.RESET_FACILITATOR_DETAIL, {}),
  exportFacilitatorsRequested : () => createAction(types.EXPORT_FACILITATORS_REQUESTED, {}),
};
