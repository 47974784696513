import { getTranslate } from "react-localize-redux";
import { createSelector } from "reselect";
import {
  ALL,
  formatAcademiesSelector,
  formatEnumForSelector,
  formatRegionsSelector,
  ITEM_BY_PAGE,
  userStatus,
} from "../../utils";
import { ProjectsSelectors } from "../projects";

const defaultFilters = {
  search: "",
  region: null,
  academy: null,
  status: null,
};
const getCurrentFilter = (state) =>
  state.supervisors.currentFilter || {
    page: 0,
    filter: defaultFilters,
  };

const supervisors = (state) => state.supervisors.list || [];
const userRegion = (state) => state.user.user.region || null;
const regions = (state) => state.commons.regions || [];
const supervisorDetail = (state) => state.supervisors.supervisor || [];
const projects = (state) => state.projects.list || [];
const strings = (state) => getTranslate(state.locale);

const getSupervisors = () =>
  createSelector(
    strings,
    userRegion,
    regions,
    getCurrentFilter,
    supervisors,
    (strings, userRegion, regions, currentFilter, supervisors) => {
      const { filter = defaultFilters, page = 0 } = currentFilter;
      const { search, region, academy, status } = filter;
      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;

      const regionList = formatRegionsSelector(strings, regions);
      const regionObject = regionList.find((search) =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );
      const regionAcademies = regionObject
        ? formatAcademiesSelector(strings, regionObject.value.academies)
        : [];
      const matchingAcademy = regionAcademies.find(
        (search) => search.key === academy
      );
      const academyObject = regionAcademies
        ? academy && matchingAcademy && regionFilter !== ALL
          ? matchingAcademy
          : regionAcademies.find((search) => search.key === ALL)
        : null;

      const statusList = formatEnumForSelector(strings, userStatus);
      const statusObject = statusList.find((search) =>
        status
          ? search.key === status
          : search.key ===
            Object.keys(userStatus)[
              Object.values(userStatus).indexOf(userStatus.ALL)
            ]
      );

      const pageFilter = {
        page: page,
        filter: {
          search: search,
          //Do not override the region if there is one selected
          region: regionObject,
          academy: academyObject,
          status: statusObject,
        },
      };

      const filteredSupervisors = filterSupervisors(
        strings,
        supervisors,
        page,
        search,
        regionFilter,
        academyObject ? academyObject.key : academyObject,
        status,
        true
      );

      return { ...pageFilter, regionAcademies, ...filteredSupervisors };
    }
  );

const getProjects = (page, search, region, academy) =>
  createSelector(
    strings,
    projects,
    supervisorDetail,
    (strings, projects, supervisorDetail) => {
      return filterProjects(
        page,
        supervisorDetail.projects,
        ProjectsSelectors.filterProjects(
          strings,
          projects,
          page,
          search,
          region,
          academy
        )
      );
    }
  );

const filterSupervisors = (
  strings,
  supervisors,
  page,
  search,
  region,
  academy,
  status,
  withFormatting = true
) => {
  let filteredSupervisors = supervisors;
  if (search.length > 0) {
    filteredSupervisors = filteredSupervisors.filter((p) =>
      `${p.firstName} ${p.lastName}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredSupervisors = filteredSupervisors.filter(
      (p) =>
        p.regions &&
        p.regions.length > 0 &&
        p.regions.findIndex((r) => r === region) !== -1
    );
  }
  if (academy && academy !== ALL) {
    filteredSupervisors = filteredSupervisors.filter(
      (p) => p.academies.findIndex((a) => a === academy) !== -1
    );
  }
  if (
    status &&
    status !==
      Object.keys(userStatus)[Object.values(userStatus).indexOf(userStatus.ALL)]
  ) {
    filteredSupervisors = filteredSupervisors.filter(
      (p) => p.displayedStatus === status
    );
  }

  if (withFormatting) {
    filteredSupervisors = formatSupervisors(filteredSupervisors, strings);
  }
  return {
    all: filteredSupervisors,
    listed: filteredSupervisors.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredSupervisors.length / ITEM_BY_PAGE),
  };
};

const filterProjects = (page, projects, { all }) => {
  const allProjects = all.filter(
    (item) => !projects.some((p) => p.id === item.id)
  );

  return {
    all: allProjects,
    listed: allProjects.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(allProjects.length / ITEM_BY_PAGE),
  };
};

const formatSupervisors = (supervisors) => {
  return supervisors.map((item) => ({
    id: item.id,
    firstName: item.firstName,
    lastName: item.lastName,
    civility: item.civility,
    displayContact: item.displayContact,
    role: item.role,
    title: `${item.firstName} ${item.lastName}`,
    place: `${item.firstName} ${item.lastName}`,
    details: {
      phone: item.phone,
      email: item.email,
    },
    members: {
      projects: item.projectsCount,
      schools: item.schoolsCount,
      mentors: item.mentorsCount,
      facilitators: item.facilitatorsCount,
    },
    status: item.displayedStatus ? item.displayedStatus.toLowerCase() : null,
  }));
};

export default {
  getSupervisors,
  getProjects,
  filterSupervisors,
};
