import React, { useEffect, useState } from 'react';
import ShortId from 'shortid';
import { getProjectImage, projectDuration, userRole } from '../../../../utils';
import { Button, ButtonColors } from '../../../components';
import { ProjectItem } from './ProjectItem';
import { SelectUserPanel } from './SelectUserPanel';

export const ProjectCreation = React.memo(
  ({
    strings,
    selectedProjects,
    newProjects,
    pendingModif,
    editedSupervisor,
    handleValueChanged,
    mentors,
    supervisors,
    schoolMentors,
    schoolSupervisors,
    getMentors,
    getSupervisors,
    handleChangeStep,
    handleAddUsersToProject,
    handleAddNewUserToProject,
    handleRemoveExistingUser,
    handleRemoveNewUser,
    handleEditNewUser,
    handleUpdateUser,
    handleAddFiledUser,
    handleAddProject,
    handleDeleteProject,
  }) => {
    const [panelInfo, setPanelInfo] = useState(null);
    const [showPanel, setShowPanel] = useState(false);

    useEffect(() => {
      if (newProjects && newProjects.some((project) => project.onError) && !pendingModif) {
        const projectOnErrorIndex = newProjects.findIndex((project) => project.onError);

        const elements = document.querySelectorAll('.new-projects .section');

        if (elements && elements.length > projectOnErrorIndex) {
          const root = document.querySelector(`#root`);
          root.scrollTo({
            top: elements[projectOnErrorIndex].offsetTop - 20,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    }, [newProjects, pendingModif]);

    const handleAddExistingUser = (index) => (role) => {
      switch (role) {
        case userRole.MENTOR: {
          getMentors();
          break;
        }
        case userRole.SUPERVISOR: {
          getSupervisors();
          break;
        }
        default: {
          break;
        }
      }

      setPanelInfo({ index, role });
      setShowPanel(true);
    };

    const handleClosePanel = () => setShowPanel(false);

    const handleAddUsers = (users) => {
      if (panelInfo && panelInfo.index !== null) {
        handleAddUsersToProject(panelInfo.index, users);
        setShowPanel(false);
      }
    };

    const panelOptions = [
      {
        label: strings('attached_to_school'),
        list: panelInfo && panelInfo.role === userRole.SUPERVISOR ? schoolSupervisors : schoolMentors,
      },
      {
        label: strings(`${panelInfo && panelInfo.role === userRole.SUPERVISOR ? 'all_supervisors' : 'all_mentors'}`),
        list: panelInfo && panelInfo.role === userRole.SUPERVISOR ? supervisors : mentors,
      },
    ];

    const projectsUsers = newProjects.map((project) => (project.users ? project.users : [])).reduce((acc, list) => acc.concat(list), []);

    return (
      <>
        <SelectUserPanel
          strings={strings}
          visible={showPanel}
          title={strings(`${panelInfo && panelInfo.role === userRole.SUPERVISOR ? 'add_supervisors' : 'add_mentors'}`)}
          options={panelInfo ? panelOptions : []}
          role={panelInfo ? panelInfo.role : null}
          handleClose={handleClosePanel}
          handleAddUsers={handleAddUsers}
        />
        <div className={'kept-projects'}>
          <div className={'wrapper'}>
            <p>{strings('kept_projects')}</p>
            <ul>
              {selectedProjects.map((project) => (
                <li key={project.id}>
                  <p className={'name'}>{project.name}</p>
                  <p className={'duration'}>
                    {project.duration !== projectDuration.ONE_YEAR ? `${project.duration} ${strings('duration_several_years')}` : ''}
                  </p>
                  <div className={'type'}>{getProjectImage(strings, project.type)}</div>
                </li>
              ))}
            </ul>
          </div>
          <div className={'horizontal-plain-separator'} />
        </div>
        <div className={'header'}>
          <div className={'title'}>
            <p className={'step'}>{strings('step_two_on_two')}</p>
            <p className={'label'}>{strings('add_projects')}</p>
          </div>
        </div>
        <div className={'horizontal-dot-separator'} />
        <div className={'new-projects'}>
          <ul>
            {newProjects.map((project, index) => (
              <li key={ShortId.generate()}>
                <ProjectItem
                  strings={strings}
                  project={project}
                  projectsUsers={projectsUsers}
                  index={index}
                  editedSupervisor={editedSupervisor}
                  handleValueChanged={handleValueChanged(index)}
                  handleAddExistingUser={handleAddExistingUser(index)}
                  handleAddNewUser={handleAddNewUserToProject}
                  handleRemoveExistingUser={handleRemoveExistingUser}
                  handleRemoveNewUser={handleRemoveNewUser}
                  handleEditNewUser={handleEditNewUser}
                  handleUpdateUser={handleUpdateUser}
                  handleAddFiledUser={handleAddFiledUser}
                  handleDeleteProject={handleDeleteProject}
                />
              </li>
            ))}
          </ul>
          <Button className={'add-project'} color={ButtonColors.orange} label={strings('add_project')} action={handleAddProject} enabled={!editedSupervisor} />
          <div className={'horizontal-plain-separator'} />
        </div>
        <div className={'actions'}>
          <Button color={ButtonColors.green} label={strings('validate_go_next_step')} action={() => handleChangeStep(+1)} enabled={!editedSupervisor} />
        </div>
      </>
    );
  }
);
