import React, { useState } from "react";
import ShortId from "shortid";
import { icons } from "../../../../../assets";
import { toolType } from "../../../../../utils";
import { Button, ButtonColors, Panel, Switch } from "../../../../components";
import Dispatch from "./Dispatch";
import { validateDraft } from "./FormValidator";
import Room from "./Room";

const Rooms = ({
  strings,
  mini,
  handleMiniInfoChanged,
  handleMiniChange,
  saveMini,
}) => {
  const [errors, setErrors] = useState({});

  const handleSave = () => {
    const errors = validateDraft(mini);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveMini();
    }
  };

  const handleCheckChange = (checked) => {
    handleMiniInfoChanged("showProgress", checked);
  };

  const handleDispatchValueChanged = (
    schoolIndex,
    groupIndex,
    roomIndex,
    value
  ) => {
    const schoolsCopy = mini.schools.slice();
    schoolsCopy[schoolIndex].groups[groupIndex].dispatch[roomIndex] = value;

    handleMiniInfoChanged("schools", schoolsCopy);
  };

  const handleAddRoom = () => {
    const miniCopy = JSON.parse(JSON.stringify(mini));
    miniCopy.rooms = [
      ...miniCopy.rooms,
      { key: ShortId.generate(), tools: [] },
    ];
    miniCopy.schools.forEach((school) => {
      school.groups.forEach((group) => {
        group.dispatch = [...group.dispatch, "0"];
      });
    });

    handleMiniChange(miniCopy);
  };

  const handleDeleteRoom = (index) => () => {
    const miniCopy = JSON.parse(JSON.stringify(mini));
    miniCopy.rooms.splice(index, 1);
    miniCopy.schools.forEach((school) => {
      school.groups.forEach((group) => {
        group.dispatch.splice(index, 1);
      });
    });

    setErrors({});
    handleMiniChange(miniCopy);
  };

  const handleToolValueChanged = (roomIndex) => (toolId) => (tag, value) => {
    const list = mini.rooms.slice();
    const room = list[roomIndex];
    const tool = room.tools
      ? room.tools.find((t) => t.toolId === toolId)
      : null;

    if (tool) {
      tool[tag] = value;
    } else {
      room.tools = [...room.tools, { toolId, [tag]: value }];
    }

    setErrors({});
    handleMiniInfoChanged("rooms", list);
  };

  const totalStudents = mini.schools
    ? mini.schools
        .map((school) => (school.groups ? school.groups : []))
        .reduce((acc, val) => [...acc, ...val], [])
        .map(
          (group) =>
            parseInt(group.boysCount ? group.boysCount : 0) +
            parseInt(group.girlsCount ? group.girlsCount : 0)
        )
        .reduce((acc, val) => acc + val, 0)
    : 0;

  const roomTools = mini.tools
    ? mini.tools.filter((tool) => tool.type === toolType.ROOM_LINK)
    : [];

  const totalsByRoom = mini.schools
    .map((school) => (school.groups ? school.groups : []))
    .reduce((acc, val) => [...acc, ...val], [])
    .map((group) => group.dispatch)
    .reduce(
      (acc, val) =>
        acc.map((value, index) =>
          isNaN(parseInt(val[index])) ? value : value + parseInt(val[index])
        ),
      new Array(mini.rooms.length).fill(0)
    );

  return (
    <Panel className={"rooms"}>
      <div className={"header"}>
        <p className={"name"}>{mini.name}</p>
        <div className={"title-wrapper"}>
          <p>{strings("rooms")}</p>
        </div>
      </div>
      <div className={"separator"} />
      <div className={"summary"}>
        <div className={"row"}>
          <div className={"column students"}>
            <p className={"label"}>{strings("total_students_rooms")}</p>
            <p className={"value"}>{totalStudents}</p>
          </div>
          <div className={"column rooms"}>
            <p className={"label"}>{strings("rooms")}</p>
            <p className={"value"}>{mini.rooms.length}</p>
          </div>
          <div className={"row progress"}>
            <p>{strings("room_show_progress")}</p>
            <Switch
              checked={mini.showProgress}
              label={strings("room_show_progress")}
              handleCheckChange={handleCheckChange}
            />
          </div>
        </div>
      </div>
      <div className={"separator"} />
      <Dispatch
        schools={mini.schools}
        rooms={mini.rooms}
        handleDispatchValueChanged={handleDispatchValueChanged}
      />
      <div className={"list"}>
        {mini.rooms &&
          mini.rooms.length > 0 &&
          mini.rooms.map((room, index) => (
            <Room
              key={room.id ? room.id : room.key}
              index={index}
              strings={strings}
              room={room}
              total={totalsByRoom[index]}
              tools={roomTools}
              error={
                errors.rooms &&
                errors.rooms.find((err) =>
                  room.id ? room.id === err.id : err.key === room.key
                )
              }
              handleDeleteRoom={handleDeleteRoom(index)}
              handleToolValueChange={handleToolValueChanged(index)}
            />
          ))}
      </div>
      <div className={"button-wrapper"}>
        <Button
          color={ButtonColors.orange}
          label={strings("add_room")}
          icon={icons.ADD}
          className={"add"}
          action={handleAddRoom}
        />
      </div>
      <div className={"actions"}>
        <Button
          color={ButtonColors.green}
          label={strings("button_text_save")}
          className={"save"}
          action={handleSave}
        />
      </div>
    </Panel>
  );
};

export default Rooms;
