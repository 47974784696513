import { ALL } from './constants';

export const formatEnumForSelector = (strings, enumeration, keyString = '') => {
  return Object.entries(enumeration).map(([label, value]) => ({
    key: label,
    value: {
      ...value,
      label: strings(`${keyString}${value.label}`),
    },
  }));
};

export const formatListWithIdForSelector = list => {
  return list.map(({ id, label }) => ({
    key: id,
    value: {
      value: { id, label },
      label,
    },
  }));
};

export const formatRegionsSelector = (strings, regions) => {
  const all = {
    key: ALL,
    value: {
      label: strings('all'),
      academies: [],
    },
  };

  return [
    all,
    ...regions
      .map(item => ({
        key: item.name,
        value: {
          label: item.name,
          academies: item.academies,
        },
      }))
      .sort((a, b) => a.value.label.localeCompare(b.value.label)),
  ];
};
export const setSelectorLabel = (objectSelector, newlabel) => {
  const { label } = objectSelector.value ? objectSelector?.value : 'Tous';
  const old = {
    ...objectSelector,
    value: { ...objectSelector?.value, label: newlabel },
  };

  return label === 'Tous' ? old : objectSelector;
};
export const formatAcademiesSelector = (strings, academies) => {
  const all = {
    key: ALL,
    value: {
      label: strings('all'),
    },
  };

  return [
    all,
    ...academies
      .map(aca => ({
        key: aca,
        value: {
          label: aca,
        },
      }))
      .sort((a, b) => a.value.label.localeCompare(b.value.label)),
  ];
};
