export const EMAIL_REGEXP = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ITEM_BY_PAGE = 20;

export const REGEX = {
  EMAIL: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE: /((0[1-9]|00([\s\\.\\-]*[0-9]){3}|\+[0-9]{2}[\s\\.\\-]*[1-9])([\s\\.\\-]*[0-9]){8})/,
  URL: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
};

export const ALL = 'all';

export const POURCENT_FILTER_KEY_STRING = 'pourcent_filter_';

export const PROJECT_STATUS_FILTER_KEY_STRING = "project_status_";

export const URL = {
  epaWebsite: 'https://www.entreprendre-pour-apprendre.fr/',
};

export const ERROR_INVALID_TOKEN = "InvalidToken"

export const ERROR_ALREADY_EXISTS = "AlreadyExists";

export const VALIDATION_ERROR = 'ValidationError';