import { methods, url } from '../utils';
import { authenticatedService } from './middleware';

const {GET, PATCH, DELETE} = methods;
const routes = {
  getSchoolInscriptions: (adminId, isReinscription = false) => url.LIST_SCHOOLS_INSCRIPTIONS(adminId, isReinscription),
  getSchoolDetailInscriptions: (adminId, id, isReinscription = false) => url.SCHOOL_DETAIL_INSCRIPTIONS(adminId, id, isReinscription),
  getMentorInscriptions: adminId => url.LIST_MENTORS_INSCRIPTIONS(adminId),
  getMentorDetailInscriptions: (adminId, id) => url.MENTOR_DETAIL_INSCRIPTIONS(adminId, id),
};

export default {
  getSchoolInscriptions: (adminId, isReinscription = false) => authenticatedService(GET, routes.getSchoolInscriptions(adminId, isReinscription), {}, {}),
  deleteSchoolInscriptions: (adminId, id, isReinscription = false) => authenticatedService(DELETE, routes.getSchoolDetailInscriptions(adminId, id, isReinscription), {}, {}),
  getMentorInscriptions: adminId => authenticatedService(GET, routes.getMentorInscriptions(adminId), {}, {}),
  updateMentorInscriptions: (adminId, id, body) => authenticatedService(PATCH, routes.getMentorDetailInscriptions(adminId, id), {...body}, {}, {}),
  deleteMentorInscriptions: (adminId, id) => authenticatedService(DELETE, routes.getMentorDetailInscriptions(adminId, id), {}, {}),
  getSchoolDetailInscriptions: (adminId, id, isReinscription = false) => authenticatedService(methods.GET, routes.getSchoolDetailInscriptions(adminId, id, isReinscription), {}, {}),
  updateSchoolInscriptions: (adminId, id, isReinscription, body) => authenticatedService(PATCH, routes.getSchoolDetailInscriptions(adminId, id, isReinscription), {...body}, {}, {}),
  editSchoolConvention: (adminId, id, isReinscription = false, body) => authenticatedService(methods.PATCH, routes.getSchoolDetailInscriptions(adminId, id, isReinscription), {...body}, {}, {}, 'blob'),
  validateSchoolInscription: (adminId, id, isReinscription = false, body) => authenticatedService(methods.PATCH, routes.getSchoolDetailInscriptions(adminId, id, isReinscription), {...body}, {}, {}),
  getMentorDetailInscriptions: (adminId, id) => authenticatedService(methods.GET, routes.getMentorDetailInscriptions(adminId, id), {}, {}),
};
