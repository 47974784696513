import moment from 'moment';
import React from 'react';
import { InputTypes } from '../../../utils';
import { Input } from '../inputs';
import './record-info.scss';

export const RecordInfo = ({record, updateDate, strings}) => {

  const date = moment(updateDate).format('DD/MM/YYYY HH:mm');
  return (
    <div className={'record-info'}>
      <div className={'title'}>
        <p>{strings('record_info')}</p>
      </div>
      <hr className={'separator'}/>
      <div className={'container'}>
        <Input
          type={InputTypes.TEXT}
          defaultValue={date}
          disabled={true}
          label={strings('last_update')}
        />
        <Input
          type={InputTypes.TEXT}
          defaultValue={record.code}
          disabled={true}
          label={strings('record_ref')}
        />
      </div>
      <div className={'container'}>
        <Input
          type={InputTypes.TEXT}
          defaultValue={record.firstName}
          disabled={true}
          label={strings('firstname')}
        />
        <Input
          type={InputTypes.TEXT}
          defaultValue={record.lastName}
          disabled={true}
          label={strings('lastname')}
        />
      </div>
      <div className={'container email'}>
        <Input
          className={'email'}
          type={InputTypes.TEXT}
          defaultValue={record.email}
          disabled={true}
          label={strings('email')}
        />
      </div>
    </div>
  );
};
