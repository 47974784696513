import React, { PureComponent } from 'react';
import { Routes, setSelectorLabel } from '../../../../utils';
import { ListWrapper, ScreenWrapper } from '../../../components';
import { ActionsBar, Filter, ListItem } from '../../commons';
import './mentorsList.scss';

export class MentorsList extends PureComponent {
  state = {
    mentorsSelected: [],
  };

  componentDidMount() {
    const { getMentors } = this.props;
    this.initializeFilter();
    getMentors();
  }
  initializeFilter = () => {
    const { setCurrentFilter, isBoUserAdmin } = this.props;
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        status: 'ALL',
      });
  };
  onPageChange = page => {
    const { mentors, setCurrentFilter } = this.props;
    const { filter, page: oldPage } = mentors;
    const { search, region, status } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        status: status.key,
      });
    }
  };

  handleSendMail = id => {
    const { mentors } = this.props;
    const { all } = mentors;

    const mentor = all.find(mentor => mentor.id === id);
    if (mentor) {
      window.location.href = `mailto:${mentor.details.email}`;
    }
  };

  onClick = id => () => {
    const { navigateTo } = this.props;
    navigateTo(Routes.MENTOR.replace(':mentorId', id));
  };

  onFiltersChange = key => value => {
    const { mentors, setCurrentFilter } = this.props;
    const { filter } = mentors;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, status } = filter;

      let newFilter = {
        search,
        region: region.key,
        status: status.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  onClickCreate = () => {
    this.props.navigateTo(Routes.MENTOR_CREATION);
  };

  onClickDownload = () => {
    this.props.exportList();
  };

  onCheckChange = all => id => () => {
    const { showSnackBar, setActionSnackBar, hideSnackBar } = this.props;
    const { mentorsSelected } = this.state;

    const newMentorsSelected = mentorsSelected.some(item => item.id === id)
      ? mentorsSelected.filter(item => !(item.id === id))
      : mentorsSelected.concat(all.find(m => m.id === id));

    this.setState({
      mentorsSelected: newMentorsSelected,
    });

    setActionSnackBar(this.onMailSend, 'send');
    newMentorsSelected.length > 0
      ? showSnackBar(
          newMentorsSelected.length === 1
            ? 'want_send_mail_mentor'
            : 'want_send_mail_mentors'
        )
      : hideSnackBar();
  };

  onMailSend = () => {
    const { mentorsSelected } = this.state;

    window.location.href = `mailto:?bcc=${mentorsSelected
      .filter(e => (e.details.email !== undefined ? e.details.email : null))
      .map(e => e.details.email)
      .join(';')}`;

    this.setState({
      mentorsSelected: [],
    });
  };

  onDeleteMentor = id => {
    const { mentors, deleteMentor, setCurrentFilter } = this.props;
    const { page, filter, listed } = mentors;
    const { search, region, status } = filter;

    deleteMentor(id);

    if (listed.length === 1 && page > 0) {
      setCurrentFilter(page - 1, {
        search,
        region: region.key,
        status: status.key,
      });
    }
  };

  render() {
    const { strings, mentors, regions, statusFilter, showPopup } = this.props;
    const { mentorsSelected } = this.state;
    const { page, filter, all, listed, pageCount } = mentors;
    const { search, region, status } = filter;

    const actions = [
      {
        label: 'mentor_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_mentor'), () =>
            this.onDeleteMentor(id)
          ),
      },
      {
        label: 'send_mail_action',
        action: id => this.handleSendMail(id),
      },
    ];

    return (
      <ScreenWrapper id={'mentors'}>
        <Filter
          strings={strings}
          label={`${strings('mentors')} (${all.length})`}
          currentSearch={search}
          currentRegion={region}
          currentStatus={status}
          regions={regions}
          status={statusFilter}
          onSearchChange={this.onFiltersChange('search')}
          onRegionChange={this.onFiltersChange('region')}
          onStatusChange={this.onFiltersChange('status')}
        />
        <ListWrapper
          strings={strings}
          className={`list-wrapper`}
          currentPage={page}
          pageCount={pageCount}
          onPageChange={this.onPageChange}
        >
          {all.length > 0 ? (
            listed.map(item => (
              <ListItem
                key={item.id}
                strings={strings}
                item={item}
                isChecked={mentorsSelected.some(m => m.id === item.id)}
                onCheck={this.onCheckChange(all)}
                onClick={this.onClick}
                actions={actions}
              />
            ))
          ) : (
            <p className={'no-list'}>{strings('no_mentors')}</p>
          )}
        </ListWrapper>
        <ActionsBar
          strings={strings}
          onClickDownload={this.onClickDownload}
          onClickCreate={this.onClickCreate}
        />
      </ScreenWrapper>
    );
  }
}
