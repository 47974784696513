import React, { useEffect, useState } from 'react';
import './filter.scss';
import { Panel } from '../../../components/panel';
import { DropDown, SearchBar } from '../../../components';
import { ALL, setSelectorLabel } from '../../../../utils';

export const Filter = ({
  strings,
  label,
  currentTab,
  tabs,
  pourcent,
  currentPourcent,
  onPourcentChange,
  onTabChange,
  currentSearch,
  onSearchChange,
  currentAcademy,
  currentRegion,
  academies,
  regions,
  onRegionChange,
  onAcademyChange,
  currentStatus,
  status,
  onStatusChange,
  types,
  currentType,
  onTypeChange,
  saisons,
  currentSaison,
  onSaisonChange,
  durations,
  currentDuration,
  onDurationChange,
}) => {
  const tabClick = item => () => {
    onTabChange(item);
  };

  const onValueChange = e => {
    onSearchChange(e.target.value);
  };

  async function regionChange(value) {
    if (value.value.key !== currentRegion.key) {
      onRegionChange(value.value);
    }
  }

  const academyChange = value => {
    if (value.value.key !== currentAcademy.key) {
      onAcademyChange(value.value);
    }
  };

  const statusChange = value => {
    onStatusChange(value.value);
  };

  const pourcentChange = value => {
    onPourcentChange(value.value);
  };

  const typeChange = value => {
    onTypeChange(value.value);
  };
  const saisonChange = value => {
    onSaisonChange(value.value);
  };
  const durationChange = value => {
    onDurationChange(value.value);
  };

  return (
    <Panel className={'filter'}>
      <div className={'content'}>
        <div className={'tabs'}>
          {tabs &&
            tabs.map((item, index) => (
              <div
                key={index}
                className={`title ${tabs.length > 1 ? 'clickable' : ''}`}
                onClick={tabs.length > 1 ? tabClick(item) : undefined}
              >
                {index !== 0 && <div className={'separator-vert'} />}
                <p
                  className={
                    (currentTab && currentTab === item.active) ||
                    tabs.length === 1
                      ? 'isActive'
                      : ''
                  }
                >
                  {`${item.label} (${item.count})`}
                </p>
              </div>
            ))}
          {label && <p className={`label`}>{label}</p>}
        </div>

        <div className={'filters'}>
          <div className={'filters_search'}>
            <label className="filter-label">Recherche</label>
           
            <SearchBar
              strings={strings}
              value={currentSearch}
              onValueChange={onValueChange}
            />
          </div>

          <div className={'filters_dropdown'}>
            {regions && (
              <div>
                <label className="filter-label">Région</label>
                <DropDown
                  current={currentRegion}
                  data={regions}
                  onValueChange={regionChange}
                />
              </div>
            )}
            {academies && (
              <div>
                <label className="filter-label">Académie</label>
                <DropDown
                  current={currentAcademy}
                  data={academies}
                  onValueChange={academyChange}
                  disabled={currentRegion && currentRegion.key === ALL}
                />
              </div>
            )}
            {status && (
              <div>
                <label className="filter-label">Statut</label>
                <DropDown
                  current={currentStatus}
                  data={status}
                  onValueChange={statusChange}
                />
              </div>
            )}
            {pourcent && (
              <div>
                <label className="filter-label">Progression</label>
                <DropDown
                  current={currentPourcent}
                  data={pourcent}
                  onValueChange={pourcentChange}
                />
              </div>
            )}
            {types && (
              <div>
                <label className="filter-label">Type</label>
                <DropDown
                  current={currentType}
                  data={types}
                  onValueChange={typeChange}
                />
              </div>
            )}
            {saisons && (
              <div>
                <label className="filter-label">Saison</label>
                <DropDown
                  current={currentSaison}
                  data={saisons}
                  onValueChange={saisonChange}
                />
              </div>
            )}
            {durations && (
              <div>
                <label className="filter-label">Durée</label>
                <DropDown
                  current={currentDuration}
                  data={durations}
                  onValueChange={durationChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Panel>
  );
};
