import React, { PureComponent } from 'react';
import { Paginate } from '../../paginate';
import './wrapper.scss';

export class Wrapper extends PureComponent {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentPage !== this.props.currentPage || prevProps.currentTab !== this.props.currentTab) {
      this.container.scrollTop = 0;
    }
  }

  onPageChange = (page) => {
    this.container.scrollTop = 0;
    this.props.onPageChange(page);
  };

  render() {
    const {strings, className, pageCount, currentPage, children} = this.props;
    return (
      <div className={`wrapper-list ${className ? className : ''}`}>
        <div className={'container'} ref={r => this.container = r}>
          {children}
        </div>
        {pageCount > 1 &&
        <div className={'paginate'}>
          <Paginate
            strings={strings}
            numberPages={pageCount}
            currentPage={currentPage}
            onPageChange={this.onPageChange}
          />
        </div>
        }
      </div>
    );
  }

};
