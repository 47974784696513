import React, { PureComponent } from 'react';
import { icons } from '../../../../../assets';
import { Button, ButtonColors } from '../../../../components/buttons';

export class FreemiumUpgradePopup extends PureComponent {

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const {onCancel} = this.props;
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      event.stopPropagation();
      onCancel(event);
    }
  };

  render() {
    const {strings, label, message, onCancel, onValidate, children} = this.props;

    return (
      <div className={'upgrade-popup-wrapper'}>
        <div className={'upgrade-popup'}
             onClick={this.handleClickOutside}
             ref={this.boxRef}>
          <div className={'header'}>
            <p>{label}</p>
            <i className={'material-icons'} onClick={onCancel}>{icons.CLOSE}</i>
          </div>
          <p className={'message'}>{message}</p>
          <div className={'form'}>
            {children}
          </div>
          <div className={'actions'}>
            <Button
              label={strings('cancel')}
              color={ButtonColors.orange}
              action={onCancel}
            />
            <Button
              label={strings('validate')}
              color={ButtonColors.green}
              action={onValidate}
            />
          </div>
        </div>
      </div>
    );
  }
}

