import React, { PureComponent } from 'react';
import { icons } from '../../../assets';
import { logger } from '../../../utils';
import { operations } from '../../../utils/enum';
import { FormDropDown } from '../../components/selector';

export class ListItemOperation extends PureComponent {

  valueChanged = event => {
    const {value} = event.target;
    const {index, handleValueChanged} = this.props;

    handleValueChanged(index, value);
  };

  dropDrownValueChanged = (_, value) => {
    const {index, handleOperationChanged} = this.props;

    handleOperationChanged(index, value.key)
  };

  itemDeleted = () => {
    const {index, handleItemDeletion} = this.props;

    handleItemDeletion(index);
  };

  render() {
    const {strings, label, operation, operationList, onError} = this.props;

    return (
      <li className={'cell operation-cell'}>
        <div>
          <FormDropDown
            tag={'operation'}
            className={'operation'}
            label={strings('operation')}
            data={operationList}
            current={operation ? operation : null}
            dropDownClassName={`${onError && !operation ? 'invalid' : ''}`}
            onValueChange={this.dropDrownValueChanged}
          />
          <input
            className={`${onError && (!label || label.length === 0) ? 'invalid' : ''}`}
            type={'text'}
            defaultValue={label ? label : ''}
            onChange={this.valueChanged}
          />
          <i onClick={this.itemDeleted} className={'material-icons'}>{icons.CANCEL}</i>
        </div>
      </li>
    );
  }
}

ListItemOperation.defaultProps = {
  handleValueChanged: () => logger.warn('handleValueChanged not implemented.'),
};


