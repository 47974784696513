import React, { useEffect, useRef } from "react";
import { icons } from "../../../../../assets";
import { getIcon, iconList } from "../../../../../utils";

const IconPicker = ({ strings, handleClose, handleIconSelected }) => {
  const iconPicker = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) =>
      iconPicker &&
      iconPicker.current &&
      !iconPicker.current.contains(e.target) &&
      handleClose &&
      handleClose();

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [iconPicker]);

  return (
    <div className={`icon-picker-wrapper fade`}>
      <div className={"icon-picker"} ref={iconPicker}>
        <div className={"title"}>
          <p>{strings("choose_icon")}</p>
          <i className={"material-icons close"} onClick={handleClose}>
            {icons.CLOSE}
          </i>
        </div>
        <ul className={"icon-list"}>
          {Object.entries(iconList).map(([key, value]) => (
            <li className={"icon"} key={key} onClick={handleIconSelected(value)}>
              {getIcon(key)}
              <p>{value}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default IconPicker;
