import { ProjectSchools } from './ProjectSchools';
import { connect } from 'react-redux';
import {ProjectsActions, ProjectsSelectors} from '../../../../redux/projects';
import { getTranslate } from "react-localize-redux";
import {SchoolsActions, SchoolsSelectors} from "../../../../redux/schools";
import { UserActions, UserSelectors } from '../../../../redux/user';

const mapStateToProps = (state, props) => {
  return {
    strings: getTranslate(state.locale),
    users: UserSelectors.getUsers()(state),
    projectSchool: id =>
      ProjectsSelectors.getSchoolById(
        id ? id : state.projects.project.school.id
      )(state),
    schoolsList: (page, search, region, academy) =>
      SchoolsSelectors.getSchoolsForProjectBinding(
        page,
        search,
        region,
        academy
      )(state),
  };};

const mapDispatchToProps = dispatch => ({
  updateInfoProject: info =>
    dispatch(ProjectsActions.updateInfoRequested(info)),
  getSchools: () => dispatch(SchoolsActions.getSchoolsRequested()),
  getUsers: () => dispatch(UserActions.getUsersRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectSchools);
