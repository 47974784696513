import { getTranslate } from 'react-localize-redux';
import { createSelector } from 'reselect';
import { formatRegionsSelector } from '../../utils';
import { ALL, ITEM_BY_PAGE } from '../../utils/constants';

const defaultFilter = {
  search: '',
  region: null,
};
const getCurrentFilter = state =>
  state.freemiums.currentFilter || {
    page: 0,
    filter: defaultFilter,
  };

const freemiums = state => state.freemiums.list || [];
const userRegion = state => state.user.user.region || null;
const regions = state => state.commons.regions || [];
const strings = state => getTranslate(state.locale);

const getFreemiums = () =>
  createSelector(
    strings,
    userRegion,
    regions,
    getCurrentFilter,
    freemiums,
    (strings, userRegion, regions, currentFilter, freemiums) => {
      const { filter = defaultFilter, page = 0 } = currentFilter;
      const { search, region } = filter;

      //Do not override the region if there is one selected
      const regionFilter = userRegion && !region ? userRegion : region;

      const regionList = formatRegionsSelector(strings, regions);
      const regionObject = regionList.find(search =>
        regionFilter ? search.key === regionFilter : search.key === ALL
      );

      const pageFilter = {
        page: page,
        filter: {
          search: search,
          //Do not override the region if there is one selected
          region: regionObject,
        },
      };

      const filteredFreemiums = filterFreemiums(
        strings,
        freemiums,
        page,
        search,
        regionFilter
      );

      return { ...pageFilter, ...filteredFreemiums };
    }
  );

const filterFreemiums = (strings, freemiums, page, search, region) => {
  let filteredFreemiums = freemiums;

  if (search.length > 0) {
    filteredFreemiums = filteredFreemiums.filter(p =>
      `${p.firstName} ${p.lastName}`
        .toLowerCase()
        .includes(search.toLowerCase())
    );
  }
  if (region && region !== ALL) {
    filteredFreemiums = filteredFreemiums.filter(
      p =>
        p.regions &&
        p.regions.length > 0 &&
        p.regions.findIndex(r => r === region) !== -1
    );
  }

  filteredFreemiums = formatFreemiums(filteredFreemiums, strings);

  return {
    all: filteredFreemiums,
    listed: filteredFreemiums.slice(
      page * ITEM_BY_PAGE,
      page * ITEM_BY_PAGE + ITEM_BY_PAGE
    ), // pour le pagers
    pageCount: Math.ceil(filteredFreemiums.length / ITEM_BY_PAGE),
  };
};

const formatFreemiums = freemiums => {
  return freemiums.map(item => ({
    id: item.id,
    title: `${item.firstName} ${item.lastName}`,
    details: {
      email: item.email,
      dateDemande: item.dateDemande,
    },
    members: null,
    regions: item.regions,
  }));
};

export default {
  getFreemiums,
};
