import React from 'react';
import { icons } from '../../../../assets';
import { Button, ButtonColors } from '../../../components/buttons';
import './actionsBar.scss';

export const ActionsBar = ({
  strings,
  onClickDownload,
  onClickDownloadArchive,
  onClickCreate,
  isBoUserAdmin,
}) => {
  return (
    <div id={'actionBar'}>
      {onClickDownload ? (
        <Button
          color={ButtonColors.blue}
          label={strings('download')}
          icon={icons.DOWNLOAD}
          className={'action-button'}
          action={onClickDownload}
        />
      ) : (
        <div />
      )}
      {onClickDownloadArchive && isBoUserAdmin ? (
        <Button
          color={ButtonColors.blue}
          label={strings('download_archive')}
          icon={icons.DOWNLOAD}
          className={'action-button'}
          action={onClickDownloadArchive}
        />
      ) : (
        <div />
      )}
      {onClickCreate && (
        <Button
          color={ButtonColors.green}
          label={strings('create')}
          className={'action-button'}
          action={onClickCreate}
        />
      )}
    </div>
  );
};
