import React from 'react';
import './schools-list.scss';

import { SchoolDetails } from '..';

export const SchoolsList = ({schools, strings, navigateTo}) => {
  return (
    <div id={'schoolsList'}>
      <div className={'title'}>
        <h1 className={'name'}>{strings('schools')}</h1>
      </div>
      <hr className={'separator'}/>
      <div>
        {(schools && schools.length > 0)
          ? schools.map(school =>
            <SchoolDetails
              school={school}
              className={'details'}
              key={school.id}
              navigateTo={navigateTo}
            />,
          )
          : (<div className={'no-school-msg'}>{strings('no_schools')}</div>)
        }
      </div>
    </div>
  );
};
