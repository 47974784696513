import React from "react";
import { Panel } from "../../../components";

const Sidebar = ({ strings, entries, currentEntry, handleEntrySelected }) => {
  return (
    <Panel className={"sidebar"}>
      {entries && (
        <ul className={"menu"}>
          {entries.map((entry, index) => (
            <li
              key={index}
              className={`${entry.key === currentEntry ? "selected" : ""}`}
              onClick={handleEntrySelected(entry.key)}
            >
              {strings(entry.label)}
            </li>
          ))}
        </ul>
      )}
    </Panel>
  );
};

export default Sidebar;
