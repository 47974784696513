import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { ConfirmPopup } from './ConfirmPopup';
import {ConfirmPopupActions} from "../../../../redux/confirmPopup";

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  message: state.confirmPopup.message,
  title: state.confirmPopup.title,
  displayed: state.confirmPopup.displayed,
});

const mapDispatchToProps = dispatch => ({
  showPopup: (message) => dispatch(ConfirmPopupActions.displayPopup(message)),
  hidePopup: () => dispatch(ConfirmPopupActions.hidePopup()),
  validatePopup: () => dispatch(ConfirmPopupActions.confirmPopup())
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPopup);
