import React from 'react';
import './wrapper.scss';

export const Wrapper = ({id, className, children}) => {

  return (
    <div id={'screenWrapper'}>
      <div id={id} className={`container ${className ? className : ''}`}>
        {children}
      </div>
    </div>
  );
};
