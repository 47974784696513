import React, {PureComponent} from 'react';
import { icons } from '../../../../assets';
import './popup.scss';

export class PopUp extends PureComponent {

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const {onClose} = this.props;
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      event.stopPropagation();
      onClose(event);
    }
  };

  handleAction = (action) => {
    const {onClose} = this.props;
    onClose(null);
    action()
  };

  render() {
    const {strings, tag, userConcerned, onClose, items} = this.props;

    return (
      <div className={'action-popup'}
           onClick={this.handleClickOutside}
           ref={this.boxRef}>
        <div className={'header'}>
          <i className={'material-icons'} onClick={onClose}>{icons.CLOSE}</i>
        </div>
        <div className={'items'}>
          {items.map((item, id) => (
            <div
              className={'item'}
              key={(`action-item-${id}`)}
              onClick={() => this.handleAction(() => item.action(userConcerned, tag))}
            >
              <p>{strings(item.label)}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

