import React, { useState, useEffect, useRef }  from 'react';
import { icons } from '../../../../assets';
import { navigationMenu, Routes } from '../../../../utils';
import { default as Item } from '../item';

export const Menu = ({strings, isAdmin, navigateTo, logout}) => {
  const [displayed, setDisplayed] = useState(false);
  const responsiveMenu = useRef(null);

  const handleToggleMenu = () => setDisplayed(!displayed);
  
  const handleClickOutside = e =>
    e.clientX > responsiveMenu.current.getBoundingClientRect().width && setDisplayed(false);

  useEffect(() => {
    if (displayed) document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [displayed]);

  return (
    <div id={'menu'}>
      <i onClick={handleToggleMenu} className={'material-icons menu'}>{displayed ? icons.CLOSE : icons.MENU}</i>
      <div className={'actions'}>
        {isAdmin && <i className="material-icons" onClick={() => navigateTo(Routes.ADMIN)}>{icons.SETTINGS}</i>}
        <i onClick={logout} className={'material-icons logout'}>{icons.LOGOUT}</i>
      </div>

      <div className={`wrapper ${displayed && 'displayed'}`} ref={responsiveMenu}>
        <ul className={'items'}>
          {navigationMenu.map((item, index) =>
            <Item
              key={index}
              text={strings(item.label)}
              route={item.route}
              close={handleToggleMenu}
              isSideMenu={displayed}
            />,
          )}
        </ul>
      </div>
    </div>
  );
}
