import { REGEX } from '../../../utils';

export const validateSchoolForm = (school, errors) => {
  // Validate school
  const {rne, name, address, postalCode, city, region, academy, country, secretariatPhone} = school;
  errors.rne.onError = rne && rne.length !== 8;
  errors.rne.errorMsg = 'wrong_rne';
  errors.name.onError = !name || name.length < 2;
  errors.name.errorMsg = 'input_require';
  errors.address.onError = !address || address.length < 2;
  errors.address.errorMsg = 'input_require';
  errors.postalCode.onError = !postalCode || postalCode.length < 2;
  errors.postalCode.errorMsg = 'input_require';
  errors.city.onError = !city || city.length < 2;
  errors.city.errorMsg = 'input_require';
  errors.region.onError = !region || region.length < 2;
  errors.region.errorMsg = 'input_require';
  errors.academy.onError = !academy || academy.length < 2;
  errors.academy.errorMsg = 'input_require';
  errors.country.onError = !country || country.length < 2;
  errors.country.errorMsg = 'input_require';
  errors.secretariatPhone.onError = !secretariatPhone || !REGEX.PHONE.test(secretariatPhone);
  errors.secretariatPhone.errorMsg = !secretariatPhone ? 'input_require' : 'wrong_phone';

  // Validate principal info
  const {principalCivility, principalLastName, principalFirstName, principalPhone, principalEmail} = school;
  errors.principalCivility.onError = !principalCivility;
  errors.principalCivility.errorMsg = 'input_require';
  errors.principalLastName.onError = !principalLastName || principalLastName.length < 2;
  errors.principalLastName.errorMsg = 'input_require';
  errors.principalFirstName.onError = !principalFirstName || principalFirstName.length < 2;
  errors.principalFirstName.errorMsg = 'input_require';
  errors.principalEmail.onError = !principalEmail || !REGEX.EMAIL.test(principalEmail);
  errors.principalEmail.errorMsg = !principalEmail ? 'input_require' : 'wrong_email';
  errors.principalPhone.onError = !principalPhone || !REGEX.PHONE.test(principalPhone);
  errors.principalPhone.errorMsg = !principalPhone ? 'input_require' : 'wrong_phone';

  // Validate referent info
  const {referentCivility, referentLastName, referentFirstName, referentPhone, referentEmail} = school;
  errors.referentCivility.onError = false;
  errors.referentCivility.errorMsg = 'input_require';
  errors.referentLastName.onError = referentLastName && referentLastName.length < 2;
  
  errors.referentLastName.errorMsg = 'input_require';
  errors.referentFirstName.onError = referentFirstName && referentFirstName.length < 2;
  errors.referentFirstName.errorMsg = 'input_require';
  errors.referentEmail.onError = referentEmail && !REGEX.EMAIL.test(referentEmail);
  errors.referentEmail.errorMsg = !referentEmail ? 'input_require' : 'wrong_email';
  errors.referentPhone.onError = referentPhone && !REGEX.PHONE.test(referentPhone);
  errors.referentPhone.errorMsg = !principalPhone ? 'input_require' : 'wrong_phone';

  return errors;
};


export const validateSchoolDraftForm = (school, errors) => {
  // Validate school
  const {rne, name, address, postalCode, city, region, academy, country, secretariatPhone} = school;
  errors.rne.onError = rne && rne.length !== 8;
  errors.rne.errorMsg = 'wrong_rne';
  errors.name.onError = name && name.length < 2;
  errors.name.errorMsg = 'input_require';
  errors.address.onError = address && address.length < 2;
  errors.address.errorMsg = 'input_require';
  errors.postalCode.onError = postalCode && postalCode.length < 2;
  errors.postalCode.errorMsg = 'input_require';
  errors.city.onError = city && city.length < 2;
  errors.city.errorMsg = 'input_require';
  errors.region.onError = region && region.length < 2;
  errors.region.errorMsg = 'input_require';
  errors.academy.onError = academy && academy.length < 2;
  errors.academy.errorMsg = 'input_require';
  errors.country.onError = country && country.length < 2;
  errors.country.errorMsg = 'input_require';
  errors.secretariatPhone.onError = secretariatPhone && !REGEX.PHONE.test(secretariatPhone);
  errors.secretariatPhone.errorMsg = 'wrong_phone';

  // Validate principal info
  const {principalLastName, principalFirstName, principalPhone, principalEmail} = school;
  errors.principalLastName.onError = principalLastName && principalLastName.length < 2;
  errors.principalLastName.errorMsg = 'input_require';
  errors.principalFirstName.onError = principalFirstName && principalFirstName.length < 2;
  errors.principalFirstName.errorMsg = 'input_require';
  errors.principalEmail.onError = principalEmail && !REGEX.EMAIL.test(principalEmail);
  errors.principalEmail.errorMsg = 'wrong_email';
  errors.principalPhone.onError = principalPhone && !REGEX.PHONE.test(principalPhone);
  errors.principalPhone.errorMsg = 'wrong_phone';

  // Validate referent info
  const {referentLastName, referentFirstName, referentPhone, referentEmail} = school;
  errors.referentLastName.onError = referentLastName && referentLastName.length < 2;
  errors.referentLastName.errorMsg = 'input_require';
  errors.referentFirstName.onError = referentFirstName && referentFirstName.length < 2;
  errors.referentFirstName.errorMsg = 'input_require';
  errors.referentEmail.onError = referentEmail && !REGEX.EMAIL.test(referentEmail);
  errors.referentEmail.errorMsg = 'wrong_email';
  errors.referentPhone.onError = referentPhone && !REGEX.PHONE.test(referentPhone);
  errors.referentPhone.errorMsg = 'wrong_phone';

  return errors;
};


