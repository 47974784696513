import React, { useEffect, useState } from "react";
import ShortId from "shortid";
import { icons } from "../../../../../assets";
import {
  Button,
  ButtonColors,
  LateralMenu,
  Panel,
} from "../../../../components";
import { default as School } from "./School";

const Schools = ({
  strings,
  mini,
  schools,
  schoolsForPanel,
  categories,
  levels,
  sectors,
  getData,
  handleMiniInfoChanged,
  saveMini,
}) => {
  const [showLateralPanel, setShowLateralPanel] = useState(false);
  const [selectedSchools, setSelectedSchools] = useState(mini.schools.map(school => school.id));
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const handleAddSchool = () => setShowLateralPanel(true);

  const handleSave = () => {
    saveMini();
  };

  const handleAddSelectedSchool = (id) => () => {
    if (!selectedSchools.includes(id)) {
      setSelectedSchools([...selectedSchools, id]);
    } else {
      const newSelectedSchools = selectedSchools.slice();
      newSelectedSchools.splice(newSelectedSchools.indexOf(id), 1);
      setSelectedSchools(newSelectedSchools);
    }
  };

  const handleValidateSelectedSchool = () => {
    const schools = mini.schools
      ? [
          ...mini.schools
            .slice()
            .filter((school) => selectedSchools.includes(school.id)),
          ...selectedSchools
            .filter((id) => !mini.schools.some((school) => school.id === id))
            .map((id) => ({ id })),
        ]
      : selectedSchools.map((id) => ({ id }));
    handleMiniInfoChanged("schools", schools);
    setShowLateralPanel(false);
  };

  const handleCloseLateralPanel = () => {
    setShowLateralPanel(false);
    setSelectedSchools([]);
  };

  const handleDeleteSchool = (index) => () => {
    const schools = mini.schools.slice();
    schools.splice(index, 1);
    handleMiniInfoChanged("schools", schools);
    const newSelectedSchools = selectedSchools.slice();
    newSelectedSchools.splice(index, 1);
    setSelectedSchools(newSelectedSchools);
  };

  const handleAddGroup = (index) => () => {
    const schools = mini.schools.slice();
    const school = schools[index];
    schools[index] = {
      ...school,
      groups: [
        ...(schools[index].groups ? schools[index].groups : []),
        { key: ShortId.generate() },
      ],
    };
    handleMiniInfoChanged("schools", schools);
  };

  const handleDeleteGroup = (schoolIndex, groupIndex) => () => {
    const schools = mini.schools.slice();
    const school = schools[schoolIndex];
    const groups = school.groups.slice();
    groups.splice(groupIndex, 1);
    school.groups = groups;
    schools[schoolIndex] = school;
    handleMiniInfoChanged("schools", schools);
  };

  const handleGroupChange = (schoolIndex, groupIndex) => (field, value) => {
    const schools = mini.schools.slice();
    const school = schools[schoolIndex];
    const group = school.groups[groupIndex];
    group[field] = value;
    school.groups[groupIndex] = group;
    schools[schoolIndex] = school;
    handleMiniInfoChanged("schools", schools);
  };

  const totalStudents = mini.schools
    ? mini.schools
        .map((school) => (school.groups ? school.groups : []))
        .reduce((acc, val) => [...acc, ...val], [])
        .map(
          (group) =>
            parseInt(group.boysCount ? group.boysCount : 0) +
            parseInt(group.girlsCount ? group.girlsCount : 0)
        )
        .reduce((acc, val) => acc + val, 0)
    : 0;

  return (
    <Panel className={"schools"}>
      <div className={"header"}>
        <p className={"name"}>{mini.name}</p>
        <div className={"title-wrapper"}>
          <p>{strings("schools")}</p>
          <Button
            color={ButtonColors.orange}
            label={strings("button_text_add")}
            icon={icons.ADD}
            className={"add"}
            action={handleAddSchool}
          />
        </div>
      </div>
      <LateralMenu
        strings={strings}
        visible={showLateralPanel}
        filterLabel={strings("schools")}
        label={`${strings("link_one")} ${strings("school")}`}
        onClose={handleCloseLateralPanel}
        data={schoolsForPanel}
        itemsChecked={selectedSchools}
        onItemSelected={handleAddSelectedSchool}
        onAdd={handleValidateSelectedSchool}
        canAdd={selectedSchools.length > 0}
        isProject={false}
      />
      <div className={"separator"} />
      {mini.schools && mini.schools.length > 0 && (
        <>
          <div className={"list"}>
            {mini.schools.map((school, index) => (
              <School
                strings={strings}
                key={school.id}
                index={index}
                school={school.name ? school : schools.find((s) => s.id === school.id)}
                groups={school.groups}
                isLast={index === mini.schools.length - 1}
                categories={categories}
                levels={levels}
                sectors={sectors}
                handleAddGroup={handleAddGroup}
                handleGroupChange={handleGroupChange}
                handleDeleteGroup={handleDeleteGroup}
                handleDeleteSchool={handleDeleteSchool}
              />
            ))}
          </div>

          <div className={"separator"} />
        </>
      )}
      <div className={"students"}>
        <p className={"label"}>{strings("total_students_mini")}</p>
        <p className={"value"}>{totalStudents}</p>
      </div>
      <div className={"separator"} />
      <div className={"actions"}>
        <Button
          color={ButtonColors.green}
          label={strings("button_text_save")}
          className={"save"}
          action={handleSave}
        />
      </div>
    </Panel>
  );
};

export default Schools;
