import React, {Component} from 'react';
import './dropDown.scss';

export class DropDown extends Component {
  state = {
    displayList: false,
  };

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      this.setState({
        displayList: false,
      });
      event.stopPropagation();
    }
  };

  handleClickBox = () => {
    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  handleClickItem = item => () => {
    const {label, onValueChange} = this.props;

    onValueChange({key: label, value: item});

    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  render() {
    const {current, data, strings, disabled} = this.props;
    const {displayList} = this.state;

    return (
     
      <div className={`drop-down`}>
        
        <div
          className={`box ${disabled ? 'disabled' : ''}`}
          onClick={() => !disabled ? this.handleClickBox() : undefined}
          ref={this.boxRef}
        >
          <p className={'current'}>{current && current.value && current.value.label !== "" ? current.value.label : strings("select")}</p>
          <div className={'arrow ' + (displayList ? 'up' : '')}/>
        </div>
        <div
          className={`list ${displayList && 'display'}`}>
          {data.map((d, i) =>
            <div key={i} className={`row`} onClick={this.handleClickItem(d)}>
              <p>{d.value.label}</p>
            </div>,
          )}
        </div>
      </div>
    );
  }
}
