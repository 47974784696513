import React, { PureComponent } from 'react';
import {
  ALL,
  InscriptionsTabs,
  Routes,
  setSelectorLabel,
} from '../../../utils';
import { SchoolInscriptionsTabs } from '../../../utils/enum';
import { ScreenWrapper } from '../../components';
import { Filter } from '../commons';
import './inscriptions.scss';
import { List } from './List';

export class Inscriptions extends PureComponent {
  state = {
    mentorsSelected: [],
  };
  initializeFilter() {
    const { inscriptions, setCurrentTab, isBoUserAdmin } = this.props;
    const { tab, subTab } = inscriptions;
    isBoUserAdmin &&
      setCurrentTab(
        tab,
        0,
        {
          search: '',
          region: 'all',
          academy: 'all',
          status: 'ALL',
        },
        subTab
      );
  }
  componentDidMount() {
    const {
      getSchoolInscriptions,
      getSchoolReinscriptions,
      getMentorInscriptions,
      currentTab,
    } = this.props;
    //Initialize all filters
    this.initializeFilter();
    //Request for the current tab is blocking, the other one is not
    getSchoolInscriptions(
      currentTab &&
        currentTab.tab === InscriptionsTabs.SCHOOLS &&
        currentTab.subTab === SchoolInscriptionsTabs.INSCRIPTIONS
    );
    getSchoolReinscriptions(
      currentTab &&
        currentTab.tab === InscriptionsTabs.SCHOOLS &&
        currentTab.subTab === SchoolInscriptionsTabs.REINSCRIPTIONS
    );
    getMentorInscriptions(
      currentTab && currentTab.tab === InscriptionsTabs.MENTORS
    );
  }

  handleClickTab = newTab => {
    this.initializeFilter();
    const { inscriptions, setCurrentTab } = this.props;
    const { tab } = inscriptions;
    if (newTab.active !== tab) {
      const filter = {
        search: '',
        region: null,
        academy: null,
        status: null,
      };
      setCurrentTab(newTab.active, 0, filter);
    }
  };

  handleClickSubTab = newSubTab => () => {
    const { inscriptions, setCurrentTab } = this.props;
    const { tab, subTab } = inscriptions;
    if (newSubTab !== subTab) {
      const filter = {
        search: '',
        region: null,
        academy: null,
        status: null,
      };
      setCurrentTab(tab, 0, filter, newSubTab);
    }
  };

  handleSendMail = id => {
    const { regions, currentTab, mentorInscriptions } = this.props;
    const { search, region } = currentTab.filter;

    const filterRegion = region
      ? regions.find(search => search.key === region)
      : regions.find(search => search.key === ALL);
    const mentors = mentorInscriptions(currentTab.page, search, filterRegion);

    const mentor = mentors.all.find(mentor => mentor.id === id);
    if (mentor) {
      window.location.href = `mailto:${mentor.details.email}`;
    }
  };

  onPageChange = page => {
    const { inscriptions, setCurrentTab } = this.props;
    const { tab, subTab, filter, page: oldPage } = inscriptions;

    if (oldPage !== page) {
      const { search, region, academy, status } = filter;

      setCurrentTab(
        tab,
        page,
        {
          search,
          region: region.key,
          academy: academy.key,
          status: status.key,
        },
        subTab
      );
    }
  };

  onFiltersChange = key => value => {
    const { inscriptions, setCurrentTab } = this.props;
    const { tab, subTab, filter } = inscriptions;

    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, academy, status } = filter;
      let newFilter = {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentTab(tab, 0, newFilter, subTab);
    }
  };

  onClickItem = id => () => {
    const { inscriptions, navigateTo } = this.props;
    const { tab, subTab } = inscriptions;

    if (tab === InscriptionsTabs.SCHOOLS) {
      if (subTab === SchoolInscriptionsTabs.INSCRIPTIONS) {
        navigateTo(Routes.INSCRIPTIONS_SCHOOL.replace(':schoolId', id));
      } else {
        navigateTo(Routes.REINSCRIPTIONS_SCHOOL.replace(':schoolId', id));
      }
    } else {
      navigateTo(Routes.INSCRIPTIONS_MENTOR.replace(':mentorId', id));
    }
  };

  onDeleteSchoolInscription = id => {
    const {
      inscriptions,
      deleteSchoolInscription,
      deleteSchoolReinscription,
      setCurrentTab,
    } = this.props;
    const { subTab, page, filter, listed } = inscriptions;
    const { search, region, academy, status } = filter;

    if (subTab === SchoolInscriptionsTabs.INSCRIPTIONS) {
      deleteSchoolInscription(id);
    } else {
      deleteSchoolReinscription(id);
    }

    if (listed.length === 1 && page > 0) {
      setCurrentTab(
        page - 1,
        {
          search,
          region: region.key,
          academy: academy.key,
          status: status.key,
        },
        subTab
      );
    }
  };

  onDeleteMentorInscription = id => {
    const { inscriptions, deleteMentorInscription, setCurrentTab } = this.props;
    const { page, filter, listed } = inscriptions;
    const { search, region, academy, status } = filter;

    deleteMentorInscription(id);

    if (listed.length === 1 && page > 0) {
      setCurrentTab(page - 1, {
        search,
        region: region.key,
        academy: academy.key,
        status: status.key,
      });
    }
  };

  onCheckChange = all => id => () => {
    const { showSnackBar, setActionSnackBar, hideSnackBar } = this.props;
    const { mentorsSelected } = this.state;

    const newMentorsSelected = mentorsSelected.some(item => item.id === id)
      ? mentorsSelected.filter(item => !(item.id === id))
      : mentorsSelected.concat(all.find(m => m.id === id));

    this.setState({
      mentorsSelected: newMentorsSelected,
    });

    setActionSnackBar(this.onMailSend, 'send');
    newMentorsSelected.length > 0
      ? showSnackBar(
          newMentorsSelected.length === 1
            ? 'want_send_mail_mentor'
            : 'want_send_mail_mentors'
        )
      : hideSnackBar();
  };

  onMailSend = () => {
    const { mentorsSelected } = this.state;

    window.location.href = `mailto:?bcc=${mentorsSelected
      .filter(e => (e.details.email !== undefined ? e.details.email : null))
      .map(e => e.details.email)
      .join(';')}`;

    this.setState({
      mentorsSelected: [],
    });
  };

  render() {
    const {
      strings,
      inscriptions,
      regions,
      statusFilter,
      editSchoolInscriptionConvention,
      editSchoolReinscriptionConvention,
      validateSchoolInscription,
      validateSchoolReinscription,
      validateMentorInscription,
      showPopup,
      setCurrentTab,
    } = this.props;
    const { mentorsSelected } = this.state;
    const {
      tab,
      subTab,
      page,
      filter,
      regionAcademies,
      all,
      listed,
      pageCount,
      schoolsCount,
      mentorsCount,
    } = inscriptions;
    const { search, region, academy, status } = filter;

    const listData = { all, listed, pageCount };

    const tabs = [
      {
        label: `${strings('schools')}`,
        count: tab === InscriptionsTabs.SCHOOLS ? all.length : schoolsCount,
        active: InscriptionsTabs.SCHOOLS,
      },
      {
        label: strings('mentors'),
        count: tab === InscriptionsTabs.MENTORS ? all.length : mentorsCount,
        active: InscriptionsTabs.MENTORS,
      },
    ];

    const mentorActions = [
      {
        label: 'mentor_validate_action',
        action: id => validateMentorInscription(id),
      },
      {
        label: 'mentor_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_school'), () =>
            this.onDeleteMentorInscription(id)
          ),
      },
      {
        label: 'send_mail_action',
        action: id => this.handleSendMail(id),
      },
    ];

    const schoolActions = [
      {
        label: 'school_convention_action',
        action: id =>
          subTab === SchoolInscriptionsTabs.INSCRIPTIONS
            ? editSchoolInscriptionConvention(id)
            : editSchoolReinscriptionConvention(id),
        isAvailableForDraft: false,
      },
      {
        label: 'school_validate_action',
        action: id =>
          subTab === SchoolInscriptionsTabs.INSCRIPTIONS
            ? validateSchoolInscription(id)
            : validateSchoolReinscription(id),
        isAvailableForDraft: false,
      },
      {
        label: 'school_delete_action',
        action: id =>
          showPopup(strings('confirm_delete_school'), () =>
            this.onDeleteSchoolInscription(id)
          ),
        isAvailableForDraft: true,
      },
    ];
    return (
      <ScreenWrapper id={'inscriptions'}>
        <Filter
          strings={strings}
          tabs={tabs}
          academies={tab === InscriptionsTabs.SCHOOLS ? regionAcademies : null}
          regions={regions}
          status={tab === InscriptionsTabs.SCHOOLS ? statusFilter : null}
          currentTab={tab}
          currentSearch={search}
          currentRegion={region}
          currentAcademy={academy}
          currentStatus={status}
          onTabChange={this.handleClickTab}
          onSearchChange={this.onFiltersChange('search')}
          onRegionChange={this.onFiltersChange('region')}
          onAcademyChange={this.onFiltersChange('academy')}
          onStatusChange={this.onFiltersChange('status')}
          setCurrentTab={setCurrentTab}
        />
        {tab === InscriptionsTabs.SCHOOLS && (
          <div className={'secondary-filter'}>
            <p
              onClick={this.handleClickSubTab(
                SchoolInscriptionsTabs.INSCRIPTIONS
              )}
              className={`${
                subTab === SchoolInscriptionsTabs.INSCRIPTIONS ? 'selected' : ''
              }`}
            >
              {strings('new_inscriptions')}
            </p>
            <p
              onClick={this.handleClickSubTab(
                SchoolInscriptionsTabs.REINSCRIPTIONS
              )}
              className={`${
                subTab === SchoolInscriptionsTabs.REINSCRIPTIONS
                  ? 'selected'
                  : ''
              }`}
            >
              {strings('reinscriptions')}
            </p>
          </div>
        )}
        <List
          strings={strings}
          className={`${
            tab === InscriptionsTabs.SCHOOLS ? 'schools' : 'mentors'
          }`}
          tag={`${tab === InscriptionsTabs.SCHOOLS ? 'schools' : 'mentors'}`}
          data={listData}
          currentPage={page}
          actions={
            tab === InscriptionsTabs.SCHOOLS ? schoolActions : mentorActions
          }
          onPageChange={this.onPageChange}
          onClickItem={this.onClickItem}
          mentorsSelected={
            tab === InscriptionsTabs.SCHOOLS ? [] : mentorsSelected
          }
          onCheck={
            tab === InscriptionsTabs.SCHOOLS
              ? undefined
              : this.onCheckChange(all)
          }
          currentTab={tab}
        />
      </ScreenWrapper>
    );
  }
}
