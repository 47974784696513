import { REGEX } from "../../../../../utils";

export const validateDraft = (mini) => {
  const errors = {};

  (!mini.name || mini.name.length < 2) && (errors.name = "mini_name_error");
  (!!mini.duration && isNaN(parseInt(mini.duration))) && (errors.duration = "mini_duration_invalid");

  const partnerErrors = mini.partners
    .map((partner) => {
      const partnerError = {};
      (!partner.name || partner.name.length < 2) &&
        (partnerError.name = "partner_name_error");
      partner.contactFirstname &&
        partner.contactFirstname.length < 2 &&
        (partnerError.firstname = "partner_firstname_invalid");
      partner.contactLastname &&
        partner.contactLastname.length < 2 &&
        (partnerError.lastname = "partner_lastname_invalid");
      partner.contactEmail &&
        !REGEX.EMAIL.test(partner.contactEmail) &&
        (partnerError.email = "wrong_email");
      partner.contactPhone &&
        !REGEX.PHONE.test(partner.contactPhone) &&
        (partnerError.phone = "wrong_phone");

      return Object.keys(partnerError).length > 0
        ? { key: partner.id || partner.key, errors: partnerError }
        : {};
    })
    .filter((partner) => Object.keys(partner).length > 0);
  partnerErrors.length > 0 && (errors.partners = partnerErrors);

  return errors;
};
