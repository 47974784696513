import { REGEX } from "../../../../../utils";

export const validateDraft = (mini) => {
  const errors = {};

  const roomsErrors = mini.rooms
    .map((room) => {
      const roomError = {};

      const toolsErrors = room.tools
        .map((tool) => {
          const toolError = {};

          tool.url &&
            !REGEX.URL.test(tool.url) &&
            (toolError.url = "tool_link_invalid");

          return Object.keys(toolError).length > 0
            ? { key: tool.key, id: tool.toolId, errors: toolError }
            : {};
        })
        .filter((tool) => Object.keys(tool).length > 0);

      (toolsErrors && toolsErrors.length > 0)  && (roomError.tools = toolsErrors);

      return Object.keys(roomError).length > 0
        ? { key: room.key, id: room.id, errors: roomError }
        : {};
    })
    .filter((room) => Object.keys(room).length > 0);
    roomsErrors.length > 0 && (errors.rooms = roomsErrors);

  return errors;
};
