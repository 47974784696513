import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { icons, images } from '../../../../assets';
import { history } from '../../../../core/history';
import { getProjectImage, InputTypes, projectTypeKeys, projectTypes, recipientType, REGEX, Routes } from '../../../../utils';
import { Button, ButtonColors, CheckBox, Input, Switch, TextArea } from '../../../components';
import { SelectRecipient } from './SelectRecipient';
import './style.scss';

const defaultNotif = {
  recipients: [],
  sendToL: false,
  sendToM9: false,
  sendToM13: false,
  sendTo10X: false,
  showToStudents: false,
  title: '',
  description: '',
  links: [
    { title: '', link: '' },
    { title: '', link: '' },
  ],
};

const NotificationCreation = ({ strings, regions, academies, draft, createNotif, navigateTo, pop }) => {
  const [notif, setNotif] = useState(defaultNotif);
  const [showPanel, setShowPanel] = useState(false);
  const [academiesRecipients, setAcademiesRecipients] = useState([]);
  const [regionsRecipients, setRegionsRecipients] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (draft) {
      const draftNotif = JSON.parse(JSON.stringify(draft));
      if (draft.links.length < 2) {
        draftNotif.links.push({ title: '', link: '' });
      }
      if (draft.links.length < 1) {
        draftNotif.links.push({ title: '', link: '' });
      }

      draftNotif.recipients = [
        ...draft.recipients.academies.map((a) => ({ type: recipientType.ACADEMY, recipient: a })),
        ...draft.recipients.regions.map((r) => ({ type: recipientType.REGION, recipient: r })),
      ];
      setNotif(draftNotif);
      setAcademiesRecipients(draft.recipients.academies);
      setRegionsRecipients(draft.recipients.regions);
    }
  }, [draft]);

  const onGiveUp = () => {
    if (!!history.oldLocation) {
      pop();
    } else {
      navigateTo(Routes.NOTIFICATION_LIST);
    }
  };

  const onSendNotification = () => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    notifCopy.links = notifCopy.links.filter((search) => search.title.length > 0 && search.link.length > 0);
    createNotif(notifCopy);
  };

  const validateForm = (notif) => {
    if (notif.recipients.length === 0) {
      setErrorMessage('error_no_recipient');
      return;
    }
    if (!notif.sendToL && !notif.sendToM9 && !notif.sendToM13 && !notif.sendTo10X) {
      setErrorMessage('error_no_course');
      return;
    }
    if (!notif.title || notif.title.length === 0) {
      setErrorMessage('error_no_title');
      return;
    }
    if (!notif.description || notif.description.length === 0) {
      setErrorMessage('error_no_description');
      return;
    }
    if (notif.links.some((search) => (!search.title || search.title.length === 0) && search.link && search.link.length > 0)) {
      setErrorMessage('error_no_link_title');
      return;
    }
    if (notif.links.some((search) => (!search.link || search.link.length === 0) && search.title && search.title.length > 0)) {
      setErrorMessage('error_no_link');
      return;
    }
    if (notif.links.some((search) => search.link && search.link.length > 0 && !REGEX.URL.test(search.link))) {
      setErrorMessage('error_invalid_link');
      return;
    }
    setErrorMessage(null);
  };

  const onAddRecipients = () => setShowPanel(true);

  const handleClosePanel = () => setShowPanel(false);

  const handleAddRecipient = (type, recipients) => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    if (type === recipientType.ACADEMY) {
      setAcademiesRecipients(recipients);
    } else {
      setRegionsRecipients(recipients);
    }
    const otherTypeRecipients = notifCopy.recipients ? notifCopy.recipients.filter((search) => search.type !== type) : [];
    notifCopy.recipients = [...otherTypeRecipients, ...recipients.map((recipient) => ({ type, recipient }))];
    setNotif(notifCopy);
    validateForm(notifCopy);
  };

  const onDeleteRecipient = (recipient, index) => () => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    notifCopy.recipients.splice(index, 1);

    if (recipient.type === recipientType.ACADEMY) {
      const academiesCopy = JSON.parse(JSON.stringify(academiesRecipients));
      const searchIndex = academiesCopy.findIndex((search) => search === recipient.recipient);
      index > -1 && academiesCopy.splice(searchIndex, 1);
      setAcademiesRecipients(academiesCopy);
    } else {
      const regionsCopy = JSON.parse(JSON.stringify(regionsRecipients));
      const searchIndex = regionsCopy.findIndex((search) => search === recipient.recipient);
      index > -1 && regionsCopy.splice(searchIndex, 1);
      setRegionsRecipients(regionsCopy);
    }

    setNotif(notifCopy);
    validateForm(notifCopy);
  };

  const onCourseCheckChanged = (tag) => () => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    notifCopy[tag] = !notifCopy[tag];
    setNotif(notifCopy);
    validateForm(notifCopy);
  };

  const handleValueChanged = (tag, value) => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    notifCopy[tag] = value;
    setNotif(notifCopy);
    validateForm(notifCopy);
  };

  const handleButtonValueChanged = (index) => (tag, value) => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    notifCopy.links[index][tag] = value;
    setNotif(notifCopy);
    validateForm(notifCopy);
  };

  const handleCheckChange = (checked) => {
    const notifCopy = JSON.parse(JSON.stringify(notif));
    notifCopy.showToStudents = checked;
    setNotif(notifCopy);
    validateForm(notifCopy);
  };

  const onTestLink = (link) => () => window.open(link, '_blank', 'noopener,noreferrer');

  const buttonForm = (index) => {
    const link = notif.links[index].link;

    return (
      <div className={'section content'}>
        <p className={'title'}>{`${strings('button')} ${index + 1}`}</p>
        <div className={'horizontal-dot-separator'} />
        <div className={'button-form'}>
          <div className={'wrapper'}>
            <Input
              type={InputTypes.TEXT}
              className={'title'}
              tag={'title'}
              label={strings('title')}
              maxLength={64}
              defaultValue={notif.links[index].title}
              handleValueChanged={handleButtonValueChanged(index)}
            />
            <Input
              type={InputTypes.TEXT}
              className={'link'}
              tag={'link'}
              label={strings('link')}
              maxLength={512}
              defaultValue={link}
              inputClassName={`${link.length > 0 && !REGEX.URL.test(link) ? 'invalid' : ''}`}
              handleValueChanged={handleButtonValueChanged(index)}
            />
          </div>
          <Button
            color={ButtonColors.orange}
            label={strings('test')}
            className={'test'}
            enabled={link.length > 0 && REGEX.URL.test(link)}
            action={link.length > 0 && REGEX.URL.test(link) ? onTestLink(link) : null}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={'notif-creation'}>
      <SelectRecipient
        strings={strings}
        visible={showPanel}
        title={strings('select_recipients')}
        regions={regions}
        academies={academies}
        regionsSelected={regionsRecipients}
        academiesSelected={academiesRecipients}
        handleClose={handleClosePanel}
        handleAddRecipient={handleAddRecipient}
      />
      <div className={'wrapper'}>
        <div className={'side-navigation'}>
          <Button color={ButtonColors.grey} label={strings('give_up')} className={'give-up'} icon={icons.CLOSE} action={onGiveUp} />
        </div>
        <div className={'form'}>
          <p className={'title'}>{strings('new_message')}</p>
          <div className={'section'}>
            <p className={'title'}>{strings('recipients')}</p>
            <div className={'horizontal-dot-separator'} />
            <div className={'recipients-wrapper'}>
              <div className={'recipients'}>
                {notif.recipients &&
                  notif.recipients.length > 0 &&
                  notif.recipients.map((recipient, index) => (
                    <div className={'recipient'} key={recipient.recipient}>
                      <p>{recipient.recipient}</p>
                      <i className={'material-icons'} onClick={onDeleteRecipient(recipient, index)}>
                        {icons.CLOSE}
                      </i>
                    </div>
                  ))}
              </div>
              <Button color={ButtonColors.orange} label={strings('add_recipients')} className={'add-button'} icon={icons.ADD} action={onAddRecipients} />
            </div>
            <div className={'horizontal-dot-separator'} />
            <div className={'include'}>
              <p>{strings('recipients_message')}</p>
              <Switch label={strings('allow_students')} checked={notif.showToStudents} handleCheckChange={handleCheckChange} />
            </div>
          </div>
          <div className={'section'}>
            <p className={'title'}>{strings('courses')}</p>
            <div className={'horizontal-dot-separator'} />
            <div className={'courses'}>
              <div className={'course'}>
                <CheckBox onCheck={onCourseCheckChanged('sendToM9')} isChecked={notif.sendToM9} />
                {getProjectImage(strings, projectTypeKeys.M9)}
              </div>
              <div className={'course'}>
                <CheckBox onCheck={onCourseCheckChanged('sendToM13')} isChecked={notif.sendToM13} />
                {getProjectImage(strings, projectTypeKeys.M13)}
              </div>
              <div className={'course'}>
                <CheckBox onCheck={onCourseCheckChanged('sendToL')} isChecked={notif.sendToL} />
                {getProjectImage(strings, projectTypeKeys.L)}
              </div>
              <div className={'course'}>
                <CheckBox onCheck={onCourseCheckChanged('sendTo10X')} isChecked={notif.sendTo10X} />
                {getProjectImage(strings, projectTypeKeys.X10)}
              </div>
            </div>
          </div>
          <div className={'section content'}>
            <p className={'title'}>{strings('message')}</p>
            <div className={'horizontal-dot-separator'} />
            <Input
              type={InputTypes.TEXT}
              tag={'title'}
              label={strings('title')}
              maxLength={128}
              defaultValue={notif.title}
              handleValueChanged={handleValueChanged}
            />
            <TextArea
              type={InputTypes.TEXT}
              tag={'description'}
              label={strings('content')}
              maxLength={512}
              defaultValue={notif.description}
              handleValueChanged={handleValueChanged}
            />
          </div>
          {buttonForm(0)}
          {buttonForm(1)}
          <div className={'horizontal-dot-separator'} />
          {errorMessage && <p className={'error-message'}>{strings(errorMessage)}</p>}
          <div className='actions'>
            <Button color={ButtonColors.darkGrey} label={strings('cancel')} className={'cancel'} action={onGiveUp} />
            <Button
              color={ButtonColors.green}
              label={strings('send')}
              className={'send'}
              enabled={!!notif.title && !errorMessage}
              action={onSendNotification}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

NotificationCreation.propTypes = {
  strings: PropTypes.func,
};

NotificationCreation.defaultProps = {};

export default NotificationCreation;
