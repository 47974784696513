import { methods, url } from '../utils';
import { authenticatedService } from './middleware';

const routes = {
  getProject: (adminId, id) => url.PROJECT_DETAIL(adminId, id),
  getListProjects: adminId => url.LIST_PROJECTS(adminId),
  linkUser: (adminId, projectId) => url.PROJECT_LINK_USERS(adminId, projectId),
  unlinkUser: (adminId, projectId) =>
    url.PROJECT_UNLINK_USERS(adminId, projectId),
  deleteProject: (adminId, projectId) => url.DELETE_PROJECT(adminId, projectId),
  archiveProject: (adminId, projectId) =>
    url.ARCHIVE_PROJECT(adminId, projectId),
  archiveProjects: adminId => url.ARCHIVE_PROJECTS(adminId),
  changeToFinishProjects: adminId => url.CHANGE_TO_FINISH_PROJECTS(adminId),
  saveProjectInfo: (adminId, projectId) =>
    url.SAVE_PROJECT_INFO(adminId, projectId),
  createProject: adminId => url.CREATE_PROJECT(adminId),
  exportProjects: adminId => url.EXPORT_PROJECTS(adminId),
  exportArchivesProjects: adminId => url.EXPORT_ARCHIVES_PROJECTS(adminId),
};

export default {
  getListProjects: adminId =>
    authenticatedService(methods.GET, routes.getListProjects(adminId), {}, {}),
  getProject: (adminId, id) =>
    authenticatedService(methods.GET, routes.getProject(adminId, id), {}, {}),
  saveProjectInfo: (adminId, info) =>
    authenticatedService(
      methods.PATCH,
      routes.saveProjectInfo(adminId, info.id),
      info,
      {}
    ),
  linkUser: (user, adminId, projectId) =>
    authenticatedService(
      methods.PUT,
      routes.linkUser(adminId, projectId),
      user,
      {}
    ),
  unlinkUser: (user, adminId, projectId) =>
    authenticatedService(
      methods.PUT,
      routes.unlinkUser(adminId, projectId),
      user,
      {}
    ),
  deleteProject: (adminId, projectId) =>
    authenticatedService(
      methods.DELETE,
      routes.deleteProject(adminId, projectId),
      {},
      {}
    ),
  archiveProject: (adminId, projectId, body) =>
    authenticatedService(
      methods.PATCH,
      routes.archiveProject(adminId, projectId),
      body,
      {}
    ),
  archiveProjects: (adminId, body) =>
    authenticatedService(
      methods.POST,
      routes.archiveProjects(adminId),
      body,
      {}
    ),
  changeToFinishProjects: (adminId, body) =>
    authenticatedService(
      methods.POST,
      routes.changeToFinishProjects(adminId),
      body,
      {}
    ),
  createProject: (adminId, newProject) => {
    return authenticatedService(
      methods.PUT,
      routes.createProject(adminId),
      newProject,
      {}
    );
  },
  exportProjects: (adminId, newProject) =>
    authenticatedService(
      methods.GET,
      routes.exportProjects(adminId),
      newProject,
      {},
      {},
      'blob'
    ),
  exportArchivesProjects: (adminId, newProject) =>
    authenticatedService(
      methods.GET,
      routes.exportArchivesProjects(adminId),
      newProject,
      {},
      {},
      'blob'
    ),
};
