import { createBrowserHistory } from "history";
import { isIeOrEdge } from "../utils";

export const history = createBrowserHistory();
history.oldLocation = null;
history.listen((location) => {
  // Hook to scroll to top when route changes occurs
  const root = document.querySelector(`#root`);
  if (isIeOrEdge(window, document)) {
    root.scrollTop = 0;
  } else {
    root.scrollTo(0, 0);
  }
  history.oldLocation = location;
});
