import { getTranslate } from "react-localize-redux";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import { MinisActions, MinisSelectors } from '../../../../redux/minis';
import { default as Mini } from "./Mini";

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  existingMini: MinisSelectors.getMini(state),
});

const mapDispatchToProps = (dispatch) => ({
  pop: () => dispatch(goBack()),
  navigateTo: (path) => dispatch(push(path)),
  getMini: (id) => dispatch(MinisActions.getMiniRequested(id)),
  createMini: (mini) => dispatch(MinisActions.createMiniRequested(mini)),
  updateMini: (mini) => dispatch(MinisActions.updateMiniRequested(mini)),
  duplicateMini: (id) => dispatch(MinisActions.duplicateMiniRequested(id)),
  deleteMini: (id) => dispatch(MinisActions.deleteMiniRequested(id)),
  showPopup: (message, popupAction, title = null) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction, title)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Mini);
