import React, { PureComponent } from 'react';
import './lateralMenu.scss';
import { icons } from '../../../assets';
import { ListItem, ProjectListItem } from '../../screens/commons';
import { Button, ButtonColors } from '../buttons';
import { Filter } from '../../screens/commons';
import {
  formatAcademiesSelector,
  ITEM_BY_PAGE
} from '../../../utils';
import { ListWrapper } from '../wrapper';
import SchoolInfo from '../schoolInfo';

export default class LateralMenu extends PureComponent {
  state = {
    currentPage: 0,
    filters: {
      search: '',
      region: this.props.regions[0],
      academy: formatAcademiesSelector(
        this.props.strings,
        this.props.regions[0].value.academies
      )[0],
      status: this.props.statusFilter[0],
    },
    checkForm: false,
    schoolCreated: null,
    userList: this.props.users,
    listedd: [],
  };
  filterUser = (search, region, page = 0) => {
    const { users } = this.props;
    let filteredUsers = users.all;
    if (search.length > 0) {
      filteredUsers = filteredUsers.filter(p =>
        `${p.title}`.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (region.length > 0) {
      filteredUsers = filteredUsers.filter(p =>
        `${p.regions}`.toLowerCase().includes(region.toLowerCase())
      );
    }
    if (search === 'all' || region === 'all') {
      filteredUsers = users.all;
    }
    return {
      all: filteredUsers,
      listed: filteredUsers.slice(
        page * ITEM_BY_PAGE,
        page * ITEM_BY_PAGE + ITEM_BY_PAGE
      ), // pour le pagers,
      pageCount: Math.ceil(filteredUsers.length / ITEM_BY_PAGE),
    };
  };
  componentDidMount() {
    const { getUsers, onShowList, isCreateSchool } = this.props;

    getUsers();
    if (isCreateSchool) {
      onShowList();
    }
  }
  onPageChange = page => {
    this.setState({
      currentPage: page,
    });
  };

  onFiltersChange = key => value => {
    const { strings, regions } = this.props;
    const { filters } = this.state;

    if (key === 'region') {

      const defaultAcademy = formatAcademiesSelector(
        strings,
        regions[0].value.academies
      )[0];

      this.setState({
        filters: { ...filters, [key]: value, academy: defaultAcademy },
        userList: this.filterUser('', value.key, 0),
      });
    } else {
      this.setState({
        filters: { ...filters, [key]: value },
        userList: this.filterUser(value, '', 0),
      });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { creationSaved, isCreateSchool, pop, resetCreation } = this.props;
    if (creationSaved && isCreateSchool) {
      resetCreation();
      pop();
    }
  }
  onClickValidate = () => {
    this.setState({
      checkForm: true,
    });
  };

  saveSchool = async (isValid, school) => {
    const {
      createSchool,
      onClose,
      onShowList,
    } = this.props;
    this.setState({
      checkForm: false,
      schoolCreated: school,
    });

    if (isValid) {
      createSchool(school);
      onClose();
      onShowList();
    }
  };

  render() {
    const {
      strings,
      data,
      visible,
      label,
      onClose,
      regions,
      regionsSlice,
      statusFilter,
      displayStatusFilter,
      displayAcademiesFilter,
      onItemSelected,
      role,
      itemsChecked,
      onAdd,
      canAdd,
      filterLabel,
      isProject,
      isCreateSchool,
      isSelectUser,
    } = this.props;
    const {
      filters,
      currentPage,
      checkForm,
    } = this.state;
    const { academy, status, search, region } = filters;

    const { all, listed, pageCount } = data(
      currentPage,
      search,
      region.key,
      academy.key,
      status.key,
      role
    );

    return (
      <div className={`lateral-menu ${visible ? 'show' : 'hide'}`}>
        <div className={`content ${visible ? 'show' : 'hide'}`}>
          <div className={'header'}>
            <p className={'title'}>{label}</p>
            <i className={`material-icons`} onClick={onClose}>
              {icons.CLOSE}
            </i>
          </div>
          {!isCreateSchool ? (
            <>
              <div className={'filter-bar'}>
                <Filter
                  strings={strings}
                  label={`${filterLabel} (${all.length})`}
                  currentSearch={search}
                  currentAcademy={academy}
                  currentRegion={region}
                  currentStatus={status}
                  regions={regions}
                  status={displayStatusFilter ? statusFilter : null}
                  academies={
                    displayAcademiesFilter
                      ? formatAcademiesSelector(strings, region.value.academies)
                      : null
                  }
                  onSearchChange={this.onFiltersChange('search')}
                  onRegionChange={this.onFiltersChange('region')}
                  onAcademyChange={this.onFiltersChange('academy')}
                  onStatusChange={this.onFiltersChange('status')}
                />
              </div>
              <div className={'list-lateral'}>
                <ListWrapper
                  strings={strings}
                  className={`list-wrapper`}
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPageChange={this.onPageChange}
                >
                  {isProject
                    ? listed.map((item, key) => {
                        return (
                          <ProjectListItem
                            key={key}
                            isChecked={itemsChecked.some(iC => iC === item.id)}
                            strings={strings}
                            item={item}
                            onCheck={onItemSelected}
                            onClick={onItemSelected}
                          />
                        );
                      })
                    : listed.map((item, key) => (
                        <ListItem
                          key={key}
                          isChecked={itemsChecked.some(iC => iC === item.id)}
                          strings={strings}
                          item={item}
                          onCheck={onItemSelected}
                          onClick={onItemSelected}
                          isSelectUser={isSelectUser}
                          index={key}
                        />
                      ))}
                </ListWrapper>
              </div>
              <div className={'add'}>
                <Button
                  label={strings('add')}
                  className={'add-button'}
                  action={onAdd}
                  enabled={canAdd}
                />
              </div>
            </>
          ) : (
            <div id={'school-creation-screen'}>
              <div className={'school-creation'}>
                <SchoolInfo
                  strings={strings}
                  creationMode={true}
                  checkForm={checkForm}
                  updateSchool={this.saveSchool}
                  regions={regionsSlice}
                />
                <Button
                  color={ButtonColors.green}
                  label={strings('create')}
                  className={'action-button'}
                  action={this.onClickValidate}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
