import React from "react";
import { Routes } from "../../../utils";
import './projectNavigation.scss';

const ProjectNavigation = ({ location, strings, navigateTo }) => {
  const handleNavigation = (destination) => () => {
    if (location !== destination) {
      navigateTo(destination);
    }
  };

  return (
    <>
      {(location === Routes.PROJECTS_LIST || location === Routes.MINI_LIST) && (
        <div id={"projectNavigation"}>
          <p
            className={`${location === Routes.PROJECTS_LIST ? "selected" : ""}`}
            onClick={handleNavigation(Routes.PROJECTS_LIST)}
          >
            {strings("projects_l_m")}
          </p>
          <p
            className={`${location === Routes.MINI_LIST ? "selected" : ""}`}
            onClick={handleNavigation(Routes.MINI_LIST)}
          >
            {strings("projects_mini_s")}
          </p>
        </div>
      )}
    </>
  );
};

export default ProjectNavigation;
