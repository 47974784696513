import schools from '../../../../services/schools';

export const formatMini = (mini) => {
  const response = {
    ...mini,
    duration: mini.duration && !isNaN(parseInt(mini.duration)) ? parseInt(mini.duration) : null,
    mentorsCount: mini.mentorsCount && !isNaN(parseInt(mini.mentorsCount)) ? parseInt(mini.mentorsCount) : null,
  };
  if (response.schools) {
    response.schools.map((school) => {
      school.supervisorsCount = school.supervisorsCount && !isNaN(parseInt(school.supervisorsCount)) ? parseInt(school.supervisorsCount) : null;
      if (school.groups) {
        school.groups.map((group) => {
          group.boysCount = group.boysCount && !isNaN(parseInt(group.boysCount)) ? parseInt(group.boysCount) : null;
          group.girlsCount = group.girlsCount && !isNaN(parseInt(group.girlsCount)) ? parseInt(group.girlsCount) : null;
          if (group.dispatch) {
            group.dispatch = group.dispatch.map((val) => (isNaN(parseInt(val)) ? 0 : parseInt(val)));
          }
        });
      }
    });
  }

  cleanObject(response);
  delete response.status;

  return response;
};

const cleanObject = (object) => {
  Object.entries(object).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete object[key];
    } else if (typeof value === 'object') {
      cleanObject(value);
    } else if (Array.isArray(value)) {
      value.forEach((item) => cleanObject(item));
    }
  });
};
