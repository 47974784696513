import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Routes, setSelectorLabel } from '../../../../utils';
import { CheckBox, ListWrapper, ScreenWrapper } from '../../../components';
import { ActionsBar, Filter } from '../../commons';
import { default as ListItem } from './ListItem';
import './style.scss';

const NotificationList = ({
  strings,
  notifications,
  regions,
  getNotifications,
  setCurrentFilter,
  duplicateNotif,
  deleteNotifs,
  showPopup,
  setActionSnackBar,
  showSnackBar,
  hideSnackBar,
  navigateTo,
  isBoUserAdmin,
}) => {
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);
  const [notifSelected, setNotifSelected] = useState([]);
  const initializeFilter = () => {
    isBoUserAdmin &&
      setCurrentFilter(0, {
        search: '',
        region: 'all',
        academy: 'all',
      });
  };
  useEffect(() => {
    initializeFilter();
    getNotifications();
    duplicateNotif(null);
  }, []);

  const { regionAcademies, page, filter, all, listed, pageCount } =
    notifications;
  const { search, region, academy } = filter;

  const handleMassAction = list => {
    setActionSnackBar(
      () =>
        showPopup(
          strings(
            list.length === 1 ? 'confirm_delete_notif' : 'confirm_delete_notifs'
          ),
          () => onDeleteNotifs(list)
        ),
      'delete'
    );
    list.length > 0
      ? showSnackBar(
          list.length === 1 ? 'want_delete_notif' : 'want_delete_notifs'
        )
      : hideSnackBar();
  };

  const onSelectAllChange = () => {
    const newValue = !isSelectAllChecked;
    let newNotifList = [];
    if (newValue) {
      newNotifList = all.map(notif => notif.id);
    }
    setNotifSelected(newNotifList);
    setSelectAllChecked(newValue);
    handleMassAction(newNotifList);
  };

  const onFiltersChange = key => value => {
    if (key === 'search' || value.key !== filter[key].key) {
      const { search, region, academy } = filter;
      let newFilter = {
        search,
        region: region.key,
        academy: academy.key,
      };

      if (key === 'search') {
        newFilter = { ...newFilter, [key]: value };
      } else {
        newFilter = { ...newFilter, [key]: value.key };
      }

      setCurrentFilter(0, newFilter);
    }
  };

  const onPageChange = page => {
    const { filter, page: oldPage } = notifications;
    const { search, region, academy } = filter;

    if (oldPage !== page) {
      setCurrentFilter(page, {
        search,
        region: region.key,
        academy: academy.key,
      });
    }
  };

  const handleCheckChanged = id => () => {
    const newNotifSelected = notifSelected.some(item => item === id)
      ? notifSelected.filter(item => !(item === id))
      : notifSelected.concat(id);
    setNotifSelected(newNotifSelected);
    handleMassAction(newNotifSelected);
  };

  const onCreate = () => navigateTo(Routes.NOTIFICATION_CREATION);

  const onDeleteNotifs = ids => {
    deleteNotifs(ids);

    if (listed.length <= ids.length && page > 0) {
      setCurrentFilter(page - 1, {
        search,
        region: region.key,
        academy: academy.key,
      });
    }
  };

  const onDuplicate = id => {
    duplicateNotif(id);
    navigateTo(Routes.NOTIFICATION_CREATION);
  };

  const actions = [
    {
      label: 'notif_delete_action',
      action: id =>
        showPopup(strings('confirm_delete_notif'), () => onDeleteNotifs([id])),
    },
    {
      label: 'notif_duplicate_action',
      action: id => onDuplicate(id),
    },
  ];

  return (
    <ScreenWrapper id={'notifications'}>
      <Filter
        strings={strings}
        label={`${strings('notifications')} (${all.length})`}
        currentSearch={search}
        currentRegion={region}
        currentAcademy={academy}
        regions={regions}
        academies={regionAcademies}
        onSearchChange={onFiltersChange('search')}
        onRegionChange={onFiltersChange('region')}
        onAcademyChange={onFiltersChange('academy')}
      />
      <div className={'select-all'}>
        <CheckBox
          onCheck={onSelectAllChange}
          isChecked={isSelectAllChecked}
          label={strings('select_all')}
        />
      </div>
      <ListWrapper
        strings={strings}
        className={`list-wrapper`}
        currentPage={page}
        pageCount={pageCount}
        onPageChange={onPageChange}
      >
        {all.length > 0 ? (
          listed.map(item => (
            <ListItem
              key={item.id}
              strings={strings}
              item={item}
              isChecked={notifSelected.some(n => n === item.id)}
              handleCheckChanged={handleCheckChanged}
              actions={actions}
            />
          ))
        ) : (
          <p className={'no-list'}>{strings('no_notif')}</p>
        )}
      </ListWrapper>
      <ActionsBar strings={strings} onClickCreate={onCreate} />
    </ScreenWrapper>
  );
};

NotificationList.propTypes = {
  strings: PropTypes.func,
};

NotificationList.defaultProps = {};

export default NotificationList;
