import FileSaver from 'file-saver';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { InscriptionService } from '../../services';
import {
  downloadAttachment,
  schoolStatus,
  userValidationStatus,
} from '../../utils';
import { Routes } from '../../utils/enum';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { default as InscriptionsActions, types } from './actions';

function* getSchoolInscriptionsRequested({ inBackground }) {
  if (!inBackground) {
    yield put(LoaderActions.loading());
  }
  const { id } = yield select(state => state.auth);

  const [error, response] = yield call(
    InscriptionService.getSchoolInscriptions,
    id,
    false
  );

  if (error) {
    yield put(SnackActions.displayError('school_inscription_retrieval_failed'));
  } else {
    yield put(
      InscriptionsActions.getSchoolInscriptionsSucceeded(response.data)
    );
  }

  if (!inBackground) {
    yield put(LoaderActions.loaded());
  }
}

function* getSchoolReinscriptionsRequested({ inBackground }) {
  if (!inBackground) {
    yield put(LoaderActions.loading());
  }
  const { id } = yield select(state => state.auth);

  const [error, response] = yield call(
    InscriptionService.getSchoolInscriptions,
    id,
    true
  );

  if (error) {
    yield put(SnackActions.displayError('school_inscription_retrieval_failed'));
  } else {
    yield put(
      InscriptionsActions.getSchoolReinscriptionsSucceeded(response.data)
    );
  }

  if (!inBackground) {
    yield put(LoaderActions.loaded());
  }
}

function* getSchoolDetailInscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    InscriptionService.getSchoolDetailInscriptions,
    adminId,
    id,
    false
  );
  if (!error) {
    yield put(
      InscriptionsActions.getSchoolDetailInscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('school_detail_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getSchoolDetailReinscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    InscriptionService.getSchoolDetailInscriptions,
    adminId,
    id,
    true
  );
  if (!error) {
    yield put(
      InscriptionsActions.getSchoolDetailReinscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('school_detail_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateSchoolInscriptionsRequested({ school }) {
  yield put(LoaderActions.loading());
  delete school.status;

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.updateSchoolInscriptions,
    adminId,
    school.id,
    false,
    school
  );

  if (!error) {
    yield put(
      InscriptionsActions.updateSchoolInscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('school_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateSchoolReinscriptionsRequested({ school }) {
  yield put(LoaderActions.loading());
  delete school.status;

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.updateSchoolInscriptions,
    adminId,
    school.id,
    true,
    school
  );

  if (!error) {
    yield put(
      InscriptionsActions.updateSchoolReinscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('school_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* editSchoolInscriptionConventionRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    InscriptionService.editSchoolConvention,
    adminId,
    id,
    false,
    { status: schoolStatus.WAITING }
  );

  if (!error && response.data) {
    downloadAttachment(response);
    yield put(InscriptionsActions.editSchoolInscriptionConventionSucceeded(id));
  } else {
    yield put(SnackActions.displayError('school_convention_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* editSchoolReinscriptionConventionRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.editSchoolConvention,
    adminId,
    id,
    true,
    { status: schoolStatus.WAITING }
  );

  if (!error && response.data) {
    downloadAttachment(response);
    yield put(
      InscriptionsActions.editSchoolReinscriptionConventionSucceeded(id)
    );
  } else {
    yield put(SnackActions.displayError('school_convention_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* validateSchoolInscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.validateSchoolInscription,
    adminId,
    id,
    false,
    { status: schoolStatus.VALIDATED }
  );

  if (!error) {
    yield put(
      InscriptionsActions.validateSchoolInscriptionsSucceeded(response.data)
    );

    if (
      window.location.pathname ===
      Routes.INSCRIPTIONS_SCHOOL.replace(':schoolId', id)
    ) {
      window.location = Routes.SCHOOL.replace(':schoolId', id);
    }
  } else {
    yield put(SnackActions.displayError('school_validation_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* validateSchoolReinscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.validateSchoolInscription,
    adminId,
    id,
    true,
    { status: schoolStatus.VALIDATED }
  );

  if (!error) {
    yield put(
      InscriptionsActions.validateSchoolReinscriptionsSucceeded(response.data)
    );

    if (
      window.location.pathname ===
      Routes.REINSCRIPTIONS_SCHOOL.replace(':schoolId', id)
    ) {
      window.location = Routes.SCHOOL.replace(':schoolId', id);
    }
  } else {
    yield put(SnackActions.displayError('school_validation_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* deleteSchoolInscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(
    InscriptionService.deleteSchoolInscriptions,
    adminId,
    id,
    false
  );

  if (!error) {
    yield put(InscriptionsActions.deleteSchoolInscriptionsSucceeded(id));

    if (window.location.pathname !== Routes.INSCRIPTIONS) {
      window.location = Routes.INSCRIPTIONS;
    }
  } else {
    yield put(SnackActions.displayError('school_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* deleteSchoolReinscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(
    InscriptionService.deleteSchoolInscriptions,
    adminId,
    id,
    true
  );

  if (!error) {
    yield put(InscriptionsActions.deleteSchoolReinscriptionsSucceeded(id));

    if (window.location.pathname !== Routes.INSCRIPTIONS) {
      window.location = Routes.INSCRIPTIONS;
    }
  } else {
    yield put(SnackActions.displayError('school_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getMentorInscriptionsRequested({ inBackground }) {
  if (!inBackground) {
    yield put(LoaderActions.loading());
  }
  const { id } = yield select(state => state.auth);

  const [error, response] = yield call(
    InscriptionService.getMentorInscriptions,
    id
  );

  if (error) {
    yield put(SnackActions.displayError('mentor_inscription_retrieval_failed'));
  } else {
    yield put(
      InscriptionsActions.getMentorInscriptionsSucceeded(response.data)
    );
  }

  if (!inBackground) {
    yield put(LoaderActions.loaded());
  }
}

function* validateMentorInscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.updateMentorInscriptions,
    adminId,
    id,
    { status: userValidationStatus.ACCEPTED }
  );

  if (!error) {
    yield put(
      InscriptionsActions.validateMentorInscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('mentor_validation_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* deleteMentorInscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(
    InscriptionService.deleteMentorInscriptions,
    adminId,
    id
  );

  if (!error) {
    yield put(InscriptionsActions.deleteMentorInscriptionsSucceeded(id));
  } else {
    yield put(SnackActions.displayError('mentor_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* getMentorDetailInscriptionsRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  const [error, response] = yield call(
    InscriptionService.getMentorDetailInscriptions,
    adminId,
    id
  );
  if (!error) {
    yield put(
      InscriptionsActions.getMentorDetailInscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('mentor_detail_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* updateMentorInscriptionsRequested({ mentor }) {
  yield put(LoaderActions.loading());
  delete mentor.status;

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    InscriptionService.updateMentorInscriptions,
    adminId,
    mentor.id,
    mentor
  );

  if (!error) {
    yield put(
      InscriptionsActions.updateMentorInscriptionsSucceeded(response.data)
    );
  } else {
    yield put(SnackActions.displayError('mentor_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(
    types.GET_SCHOOL_INSCRIPTIONS_REQUESTED,
    getSchoolInscriptionsRequested
  ),
  takeLatest(
    types.GET_SCHOOL_REINSCRIPTIONS_REQUESTED,
    getSchoolReinscriptionsRequested
  ),
  takeLatest(
    types.GET_SCHOOL_DETAIL_INSCRIPTIONS_REQUESTED,
    getSchoolDetailInscriptionsRequested
  ),
  takeLatest(
    types.GET_SCHOOL_DETAIL_REINSCRIPTIONS_REQUESTED,
    getSchoolDetailReinscriptionsRequested
  ),
  takeLatest(
    types.UPDATE_SCHOOL_INSCRIPTIONS_REQUESTED,
    updateSchoolInscriptionsRequested
  ),
  takeLatest(
    types.UPDATE_SCHOOL_REINSCRIPTIONS_REQUESTED,
    updateSchoolReinscriptionsRequested
  ),
  takeLatest(
    types.EDIT_SCHOOL_INSCRIPTION_CONVENTION_REQUESTED,
    editSchoolInscriptionConventionRequested
  ),
  takeLatest(
    types.EDIT_SCHOOL_REINSCRIPTION_CONVENTION_REQUESTED,
    editSchoolReinscriptionConventionRequested
  ),
  takeLatest(
    types.GET_MENTOR_INSCRIPTIONS_REQUESTED,
    getMentorInscriptionsRequested
  ),
  takeLatest(
    types.VALIDATE_SCHOOL_INSCRIPTION_REQUESTED,
    validateSchoolInscriptionsRequested
  ),
  takeLatest(
    types.VALIDATE_SCHOOL_REINSCRIPTION_REQUESTED,
    validateSchoolReinscriptionsRequested
  ),
  takeLatest(
    types.DELETE_SCHOOL_INSCRIPTION_REQUESTED,
    deleteSchoolInscriptionsRequested
  ),
  takeLatest(
    types.DELETE_SCHOOL_REINSCRIPTION_REQUESTED,
    deleteSchoolReinscriptionsRequested
  ),
  takeLatest(
    types.VALIDATE_MENTOR_INSCRIPTION_REQUESTED,
    validateMentorInscriptionsRequested
  ),
  takeLatest(
    types.DELETE_MENTOR_INSCRIPTION_REQUESTED,
    deleteMentorInscriptionsRequested
  ),
  takeLatest(
    types.GET_MENTOR_DETAIL_INSCRIPTIONS_REQUESTED,
    getMentorDetailInscriptionsRequested
  ),
  takeLatest(
    types.UPDATE_MENTOR_INSCRIPTIONS_REQUESTED,
    updateMentorInscriptionsRequested
  ),
];
