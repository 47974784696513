import React from 'react';
import './panel.scss';

export const Panel = ({id, className, children}) => {
  return (
    <div id={id ? id : ''} className={`panel ${className ? className : ''}`}>
      { children }
    </div>
  ) 
};
