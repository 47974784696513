import {call, put, select, takeLatest} from 'redux-saga/effects';
import { SchoolsServices, SupervisorsServices } from '../../services';
import LoaderActions from '../loader/actions';
import SnackActions from '../snackBar/actions';
import {default as SupervisorsActions, types} from './actions';
import {history} from "../../core/history";
import { downloadAttachment, Routes, ERROR_ALREADY_EXISTS } from '../../utils';
import { CONFLICT } from 'http-status-codes';
import errorService from "../../services/ErrorService";

function* getSupervisorsRequested() {
    yield put(LoaderActions.loading());

    const adminId = yield select(state => state.auth.id);
    const [error, response] = yield call(SupervisorsServices.getSupervisors, adminId);

    if (!error) {
      yield put(SupervisorsActions.getSupervisorsSucceeded(response.data));
    } else {
      yield put(SnackActions.displayError('supervisors_retrieval_failed'));
    }

    yield put(LoaderActions.loaded());
}

function * getSupervisorDetailRequested({supervisorId}) {
  yield put(LoaderActions.loading());
  const {id} = yield select(state => state.auth);
  const [error, response] = yield call(SupervisorsServices.getSupervisorDetail, id, supervisorId);
  if (!error) {
    yield put(SupervisorsActions.getSupervisorDetailSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('supervisor_retrieval_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* linkProjectSupervisorRequested({userId, projectsId}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

  let responseCall = [];
  for(let i = 0; i < projectsId.length ; i++){
    const [error, response] = yield call(SupervisorsServices.linkProject, adminId, userId, projectsId[i]);
    responseCall = responseCall.concat({error, response})
  }

  const error = responseCall.some(item => item.error);

  if (!error) {
    const lastResponse = responseCall[responseCall.length - 1].response;
    yield put(SupervisorsActions.linkProjectSupervisorsSucceeded(lastResponse.data));
  } else {
    yield put(SnackActions.displayError('one_or_more_link_project_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* unlinkProjectSupervisorRequested({userId, projectId}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);

    const [error, response] = yield call(SupervisorsServices.unlinkProject, adminId, userId, projectId);

  if (!error) {
    yield put(SupervisorsActions.unlinkProjectSupervisorsSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('unlink_project_failed'));
  }

  yield put(LoaderActions.loaded());
}

function* deleteSupervisorRequested({id}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(SupervisorsServices.deleteSupervisor, adminId, id);

  if (!error) {
    yield put(SupervisorsActions.deleteSupervisorSucceeded(id));
  } else {
    yield put(SnackActions.displayError('mentor_deletion_failed'));
  }
  yield put(LoaderActions.loaded());
}


function* updateSupervisorRequested({supervisor}) {

  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(SupervisorsServices.updateSupervisor, adminId, supervisor.id, supervisor);

  if (!error) {
    yield put(SupervisorsActions.updateSupervisorSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('surpervisor_update_failed'));
  }
  yield put(LoaderActions.loaded());
}

function* createSupervisorRequested({supervisor}) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(SupervisorsServices.createSupervisor, adminId, supervisor);

  if (!error) {
    yield put(SupervisorsActions.createSupervisorSucceeded(response.data));
    yield call(history.replace, Routes.SUPERVISORS_LIST);
  } else {
    yield  errorService.displayConflictError(error,'supervisor_creation_failed')
  }

  yield put(LoaderActions.loaded());
}

function* exportSupervisorsRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(SupervisorsServices.exportSupervisors, adminId);

  if (response && response.data && !error) {
    downloadAttachment(response)
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}

export default [
  takeLatest(types.GET_SUPERVISORS_REQUESTED, getSupervisorsRequested),
  takeLatest(types.GET_SUPERVISOR_DETAIL_REQUESTED, getSupervisorDetailRequested),
  takeLatest(types.LINK_PROJECT_SUPERVISOR_REQUESTED, linkProjectSupervisorRequested),
  takeLatest(types.UNLINK_PROJECT_SUPERVISOR_REQUESTED, unlinkProjectSupervisorRequested),
  takeLatest(types.DELETE_SUPERVISOR_REQUESTED, deleteSupervisorRequested),
  takeLatest(types.UPDATE_SUPERVISOR_REQUESTED, updateSupervisorRequested),
  takeLatest(types.CREATE_SUPERVISOR_REQUESTED, createSupervisorRequested),
  takeLatest(types.EXPORT_SUPERVISORS_REQUESTED, exportSupervisorsRequested),
];
