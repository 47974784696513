import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack } from 'react-router-redux';
import {FacilitatorsActions, FacilitatorsSelectors} from '../../../../redux/facilitators';
import { FacilitatorDetail } from './FacilitatorDetail';

const mapStateToProps = state => ({
  facilitator: state.facilitators.facilitator,
  strings: getTranslate(state.locale),
  projectsData: (page, search, region, academy) => FacilitatorsSelectors.getProjects(page, search, region, academy)(state),
});

const mapDispatchToProps = dispatch => ({
  linkProjects: (userId, projectsId) => dispatch(FacilitatorsActions.linkProjectFacilitatorRequested(userId, projectsId)),
  unlinkProject: (userId, projectId) => dispatch(FacilitatorsActions.unlinkProjectFacilitatorRequested(userId, projectId)),
  getFacilitator: facilitatorId => dispatch(FacilitatorsActions.getFacilitatorDetailRequested(facilitatorId)),
  updateFacilitator: facilitator => dispatch(FacilitatorsActions.updateFacilitatorRequested(facilitator)),
  resetFacilitatorDetail: () => dispatch(FacilitatorsActions.resetFacilitatorDetail()),
  pop: () => dispatch(goBack()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacilitatorDetail);
