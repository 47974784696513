import React from 'react';
import { formatEnumForSelector, Gender, userRole } from '../../../../utils';

export const User = ({strings, user}) => {
  const civilities = formatEnumForSelector(strings, Gender);

  const userName = `${user.id ? user.firstName : user.firstName.value} ${user.id ? user.lastName : user.lastName.value}`;
  const userCivility = user.id ? user.civility : user.civility.value;
  const civility = civilities.find(c => c.key === userCivility).value.label;

  let role = null;
  switch (user.role) {
    case userRole.MENTOR:
      role = strings('mentor');
      break;
    case userRole.FACILITATOR:
    case userRole.ADMIN:
      role = strings('facilitator');
      break;
    case userRole.SUPERVISOR:
    default:
      role = strings('supervisor');
      break;
  }
  return (
    <>
      <div className={'horizontal-dot-separator'}/>
      <div className={'user'}>
        <div className={'info-container'}>
          <div className={'info-label'}>
            <p>{role}</p>
          </div>
          <div className={'info-value'}>
            <p>{`${civility} ${userName}`}</p>
          </div>
        </div>
        <div className={'info-container'}>
          <div className={'info-label'}>
            <p>{strings('email')}</p>
          </div>
          <div className={'info-value'}>
            <p>{user.id ? user.email : user.email.value}</p>
          </div>
        </div>
        <div className={'info-container'}>
          <div className={'info-label'}>
            <p>{strings('phone')}</p>
          </div>
          <div className={'info-value'}>
            <p>{user.id ? user.phone : user.phone.value}</p>
          </div>
        </div>
      </div>
    </>
  );
};
