import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { history } from '../../core/history';
import {
  FacilitatorsServices,
  MentorsServices,
  MinisServices,
  ParamsService,
  SchoolsServices,
  SupervisorsServices,
} from '../../services';
import { downloadAttachment, Routes } from '../../utils';
import { FacilitatorsActions } from '../facilitators';
import { LoaderActions } from '../loader';
import { MentorsActions } from '../mentors';
import { ParamsActions } from '../params';
import { SchoolsActions } from '../schools';
import { SnackActions } from '../snackBar';
import { SupervisorsActions } from '../supervisors';
import { default as MinisActions, types } from './actions';

function* getMiniListRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MinisServices.getMinis, adminId);

  if (!error) {
    yield put(MinisActions.getMiniListSucceeded(response.data));
  } else {
    yield put(SnackActions.displayError('mini_list_retrieval_error'));
  }

  yield put(LoaderActions.loaded());
}

function* getMiniRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MinisServices.getMini, adminId, id);

  if (!error) {
    yield put(MinisActions.getMiniSucceeded(response.data));
  } else {
    yield put(MinisActions.getMiniSucceeded(null));
    yield put(SnackActions.displayError('mini_detail_retrieval_error'));
  }

  yield put(LoaderActions.loaded());
}

function* createMiniRequested({ mini }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(MinisServices.createMini, adminId, mini);

  if (!error) {
    yield put(MinisActions.createMiniSucceeded(response.data));
    yield call(
      history.replace,
      Routes.MINI_DETAIL.replace(':miniId', response.data.id),
      { fromCreation: true }
    );
  } else {
    yield put(SnackActions.displayError('mini_creation_error'));
  }

  yield put(LoaderActions.loaded());
}

function* updateMiniRequested({ mini }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    MinisServices.updateMini,
    adminId,
    mini.id,
    mini
  );

  if (!error) {
    if (window.location.pathname === Routes.MINI_LIST) {
      yield call(getMiniListRequested);
    } else {
      yield put(MinisActions.updateMiniSucceeded(response.data));
    }
  } else {
    yield put(SnackActions.displayError('mini_update_error'));
  }

  yield put(LoaderActions.loaded());
}

function* getSchoolsFormDataRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [schoolsResp, categoriesResp, levelsResp, sectorsResp] = yield all([
    call(SchoolsServices.getSchools, adminId),
    call(ParamsService.getClassCategories),
    call(ParamsService.getClassLevels),
    call(ParamsService.getClassSectors),
  ]);

  if (
    schoolsResp.error ||
    categoriesResp.error ||
    levelsResp.error ||
    sectorsResp.error
  ) {
    yield put(SnackActions.displayError('generic_error'));
  } else {
    yield all([
      put(SchoolsActions.getSchoolsSucceeded(schoolsResp[1].data)),
      put(ParamsActions.getCategoriesSucceeded(categoriesResp[1].data)),
      put(ParamsActions.getLevelsSucceeded(levelsResp[1].data)),
      put(ParamsActions.getSectorsSucceeded(sectorsResp[1].data)),
    ]);
  }

  yield put(LoaderActions.loaded());
}

function* getTeamFormDataRequested() {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [
    [facilitatorErr, facilitatorResp],
    [supervisorErr, supervisorResp],
    [mentorErr, mentorResp],
  ] = yield all([
    call(FacilitatorsServices.getFacilitators, adminId),
    call(SupervisorsServices.getSupervisors, adminId),
    call(MentorsServices.getMentors, adminId),
  ]);

  if (facilitatorErr || supervisorErr || mentorErr) {
    yield put(SnackActions.displayError('generic_error'));
  } else {
    yield all([
      put(FacilitatorsActions.getFacilitatorsSucceeded(facilitatorResp.data)),
      put(SupervisorsActions.getSupervisorsSucceeded(supervisorResp.data)),
      put(MentorsActions.getMentorsSucceeded(mentorResp.data)),
    ]);
  }

  yield put(LoaderActions.loaded());
}

function* duplicateMiniRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    MinisServices.duplicateMini,
    adminId,
    id
  );

  if (error) {
    yield put(SnackActions.displayError('mini_duplication_error'));
  } else {
    yield put(MinisActions.duplicateMiniSucceeded(response.data));
    yield call(
      history.replace,
      Routes.MINI_DETAIL.replace(':miniId', response.data.id)
    );
  }

  yield put(LoaderActions.loaded());
}

function* deleteMiniRequested({ id }) {
  yield put(LoaderActions.loading());

  const adminId = yield select(state => state.auth.id);
  const [error] = yield call(MinisServices.deleteMini, adminId, id);

  if (!error) {
    if (window.location.pathname !== Routes.MINI_LIST) {
      yield call(history.replace, Routes.MINI_LIST);
    }
    yield put(MinisActions.deleteMiniSucceeded(id));
  } else {
    yield put(SnackActions.displayError('mini_deletion_error'));
  }

  yield put(LoaderActions.loaded());
}
function* exportMiniProjectsRequested() {
  yield put(LoaderActions.loading());
  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    MinisServices.exportMiniProjects,
    adminId
  );

  if (response && response.data && !error) {
    downloadAttachment(response);
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}
function* exportArchivesMiniProjects() {
  yield put(LoaderActions.loading());
  const adminId = yield select(state => state.auth.id);
  const [error, response] = yield call(
    MinisServices.exportArchivesMiniProjects,
    adminId
  );

  if (response && response.data && !error) {
    downloadAttachment(response);
  } else {
    yield put(SnackActions.displayError('export_failed'));
  }
  yield put(LoaderActions.loaded());
}
export default [
  takeLatest(types.GET_MINI_LIST_REQUESTED, getMiniListRequested),
  takeLatest(types.GET_MINI_REQUESTED, getMiniRequested),
  takeLatest(types.CREATE_MINI_REQUESTED, createMiniRequested),
  takeLatest(types.UPDATE_MINI_REQUESTED, updateMiniRequested),
  takeLatest(types.DUPLICATE_MINI_REQUESTED, duplicateMiniRequested),
  takeLatest(types.DELETE_MINI_REQUESTED, deleteMiniRequested),
  takeLatest(
    types.GET_SCHOOLS_FORM_DATA_REQUESTED,
    getSchoolsFormDataRequested
  ),
  takeLatest(types.GET_TEAM_FORM_DATA_REQUESTED, getTeamFormDataRequested),
  takeLatest(types.EXPORT_MINI_PROJECTS_REQUESTED, exportMiniProjectsRequested),
  takeLatest(types.EXPORT_ARCHIVE_MINI_PROJECTS, exportArchivesMiniProjects),
];
