import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';
import { ConfirmPopupActions } from '../../../redux/confirmPopup';
import { SchoolsActions } from '../../../redux/schools';
import { Reinscription } from './Reinscription';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  school: state.schools.school,
});

const mapDispatchToProps = dispatch => ({
  getSchool: id => dispatch(SchoolsActions.getSchoolDetailRequested(id)),
  startReinscription: (schoolId, oldProjects, newProjects, editConvention) => dispatch(SchoolsActions.startReinscriptionRequested(schoolId, oldProjects, newProjects, editConvention)),
  showPopup: (message, popupAction,) => dispatch(ConfirmPopupActions.displayPopup(message, popupAction)),
  pop: () => dispatch(goBack()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reinscription);
