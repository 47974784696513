import React, { useEffect, useState } from 'react';
import { icons } from '../../../../assets';
import { Button, ListWrapper } from '../../../components';
import { Filter } from '../../commons/filter';
import { UserItem } from './UserItem';

export const SelectUserPanel = ({strings, visible, title, options, role, handleClose, handleAddUsers}) => {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('');
  const [optionSelected, setOptionSelected] = useState(null);
  const [itemsSelected, setItemsSelected] = useState([]);

  useEffect(() => {
    if (options && options.length > 0) {
      setOptionSelected(0);
    } else {
      setOptionSelected(null)
    }
  }, [options]);

  const handleOptionSelected = index => () => {
    setOptionSelected(index);
  };

  const handleFilterChanged = () => (value) => {
    setFilter(value)
  };

  const handleValidation = () => {
    if (optionSelected !== null) {
      const {all} = options[optionSelected].list(page, filter);

      handleAddUsers(all.filter(user => itemsSelected.includes(user.id)).map(user => ({...user, role})))
      setItemsSelected([])
    }
  };

  const handleClosePanel = () => {
    handleClose();
    setItemsSelected([]);
  };

  const handlePageChanged = page => {
    setPage(page)
  };

  const handleCheckChanged = id => () => {
    const itemsSelectedCopy = itemsSelected.slice();
    const newItemsSelected = itemsSelectedCopy.some(item => item === id)
      ? itemsSelectedCopy.filter(item => !(item === id))
      : itemsSelectedCopy.concat(id);

    setItemsSelected(newItemsSelected)
  };

  let list = [];
  let totalPage = 0;

  if (optionSelected !== null && options.length > optionSelected) {
    const {all, listed, pageCount} = options[optionSelected].list(page, filter);
    list = listed;
    totalPage = pageCount;
  }

  return (
    <div className={`lateral-panel ${visible ? 'show' : 'hide'}`}>
      <div className={`container ${visible ? 'show' : 'hide'}`}>
        <div className={'header'}>
          <p className={'title'}>{title}</p>
          <i className={`material-icons`} onClick={handleClosePanel}>{icons.CLOSE}</i>
        </div>
        <div className={'filter-bar'}>
          <ul className={'options'}>
            {options.map((item, index) =>
              <li key={item.label} onClick={handleOptionSelected(index)} className={`${optionSelected === index ? 'selected' : ''}`}>
                <p>{item.label}</p>
              </li>,
            )}
          </ul>
          <Filter
            strings={strings}
            currentSearch={filter}
            onSearchChange={handleFilterChanged('search')}
          />
        </div>
        <div className={'user'}>
          <ListWrapper
            strings={strings}
            className={`list-wrapper`}
            currentPage={page}
            pageCount={totalPage}
            onPageChange={handlePageChanged}
          >
            {list.map((user) =>
              <UserItem
                key={user.id}
                isChecked={itemsSelected.some(search => search === user.id)}
                strings={strings}
                user={user}
                role={role}
                handleCheck={handleCheckChanged}/>,
            )
            }
          </ListWrapper>
        </div>
        <div className={'add'}>
          <Button
            label={strings('add')}
            className={'add-button'}
            action={handleValidation}
            enabled={itemsSelected.length > 0}/>
        </div>
      </div>
    </div>
  );
};
