import React, { useEffect, useState } from 'react';
import { icons } from '../../../../../assets';
import { InputTypes, userRole } from '../../../../../utils';
import { Button, ButtonColors, Input, LateralMenu, Panel } from '../../../../components';
import School from './School';
import User from './User';

const Team = ({ strings, mini, usersData, facilitators, supervisors, mentors, getData, handleMiniInfoChanged, saveMini }) => {
  const [showLateralPanel, setShowLateralPanel] = useState(false);
  const [schoolForUsers, setSchoolForUsers] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUsersRole, setSelectedUsersRole] = useState(userRole.FACILITATOR);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const handleSave = () => {
    saveMini();
  };

  const handleAddSupervisor = (index) => () => {
    setSchoolForUsers(index);
    handleAddUser(userRole.SUPERVISOR)();
  };

  const handleAddUser = (role) => () => {
    setSelectedUsersRole(role);
    setSelectedUsers(
      role === userRole.FACILITATOR
        ? mini.facilitators.map((usr) => usr.id)
        : role === userRole.SUPERVISOR
        ? mini.supervisors.map((usr) => usr.id)
        : role === userRole.MENTOR
        ? mini.mentors.map((usr) => usr.id)
        : []
    );
    setShowLateralPanel(true);
  };

  const handleAddSelectedUser = (id) => () => {
    if (!selectedUsers.includes(id)) {
      setSelectedUsers([...selectedUsers, id]);
    } else {
      const newSelectedUsers = selectedUsers.slice();
      newSelectedUsers.splice(selectedUsers.indexOf(id), 1);
      setSelectedUsers(newSelectedUsers);
    }
  };

  const handleValidateSelectedUsers = () => {
    const tag =
      selectedUsersRole === userRole.FACILITATOR
        ? 'facilitators'
        : selectedUsersRole === userRole.SUPERVISOR
        ? 'supervisors'
        : selectedUsersRole === userRole.MENTOR
        ? 'mentors'
        : '';

    let list = [];
    if (selectedUsersRole === userRole.SUPERVISOR) {
      list = mini[tag]
        ? [
            ...mini[tag].slice().filter((user) => selectedUsers.includes(user.id)),
            ...selectedUsers.filter((id) => !mini[tag].some((user) => user.id === id)).map((id) => ({ id, schoolId: schoolForUsers })),
          ]
        : selectedUsers.map((id) => ({ id, schoolId: schoolForUsers }));
    } else {
      list = mini[tag]
        ? [
            ...mini[tag].slice().filter((user) => selectedUsers.includes(user.id)),
            ...selectedUsers.filter((id) => !mini[tag].some((user) => user.id === id)).map((id) => ({ id })),
          ]
        : selectedUsers.map((id) => ({ id }));
    }

    handleMiniInfoChanged(tag, list);
    setShowLateralPanel(false);
  };

  const handleCloseLateralPanel = () => {
    setShowLateralPanel(false);
    setSelectedUsers([]);
  };

  const handleDeleteUser = (tag, index) => () => {
    const users = mini[tag].slice();
    users.splice(index, 1);
    handleMiniInfoChanged(tag, users);
  };

  const handleFieldChanged = (tag, value) => {
    handleMiniInfoChanged(tag, value);
  };

  const handleSchoolChanged = (index) => (tag, value) => {
    const schoolsCopy = mini.schools.slice();
    schoolsCopy[index][tag] = value;
    handleMiniInfoChanged("schools", schoolsCopy);
  };

  const getPanelLabel = () => {
    return `${strings('link_one_more')} ${getFilterLabel().toLowerCase()}`;
  };

  const getFilterLabel = () => {
    return selectedUsersRole === userRole.FACILITATOR
      ? strings('facilitators')
      : selectedUsersRole === userRole.SUPERVISOR
      ? strings('supervisors')
      : selectedUsersRole === userRole.MENTOR
      ? strings('mentors')
      : '';
  };

 

  return (
    <Panel className={'team'}>
      <div className={'header'}>
        <p className={'name'}>{mini.name}</p>
        <div className={'title-wrapper'}>
          <p>{strings('teams')}</p>
        </div>
      </div>
      <LateralMenu
        strings={strings}
        visible={showLateralPanel}
        filterLabel={getFilterLabel()}
        displayAcademiesFilter={selectedUsersRole === userRole.SUPERVISOR}
        label={getPanelLabel()}
        onClose={handleCloseLateralPanel}
        data={usersData}
        itemsChecked={selectedUsers}
        onItemSelected={handleAddSelectedUser}
        onAdd={handleValidateSelectedUsers}
        canAdd={selectedUsers.length > 0}
        role={selectedUsersRole}
        isProject={false}
      />
      <div className={'separator'} />
      <div className={'section'}>
        <div className={'line'}>
          <p className={'label'}>{strings('facilitators')}</p>
          <Button
            color={ButtonColors.orange}
            label={strings('button_text_add')}
            icon={icons.ADD}
            className={'add'}
            action={handleAddUser(userRole.FACILITATOR)}
          />
        </div>
        {mini.facilitators && mini.facilitators.length > 0 && (
          <div className={'facilitators'}>
            <div className={'dashed-separator'} />
            {mini.facilitators.map((user, index) => (
              <User
                key={user.id}
                strings={strings}
                user={user.civility ? user : facilitators.find((usr) => usr.id === user.id)}
                userRole={userRole.FACILITATOR}
                isLast={index === mini.facilitators.length - 1}
                handleDeleteUser={handleDeleteUser('facilitators', index)}
              />
            ))}
          </div>
        )}
      </div>
      <div className={'separator'} />
      <div className={'section'}>
        <div className={'line'}>
          <p className={'label'}>{strings('supervisors')}</p>
        </div>
        {mini.schools && mini.schools.length > 0 && (
          <div className={'supervisors-schools'}>
            <div className={'dashed-separator'} />
            {mini.schools.map((school, index) => (
              <School
                key={school.id}
                strings={strings}
                school={school}
                schoolSupervisors={mini.supervisors.filter((sup) => sup.schoolId === school.id)}
                supervisors={supervisors}
                distance={mini.distance}
                isLast={index === mini.schools.length - 1}
                handleAddSupervisor={handleAddSupervisor}
                handleDeleteUser={handleDeleteUser}
                handleSchoolChanged={handleSchoolChanged(index)}
              />
            ))}
          </div>
        )}
      </div>
      <div className={'separator'} />
      <div className={'section'}>
        <div className={'line'}>
          <p className={'label'}>{strings('mentors')}</p>
          {mini.distance && (
            <Button color={ButtonColors.orange} label={strings('button_text_add')} icon={icons.ADD} className={'add'} action={handleAddUser(userRole.MENTOR)} />
          )}
        </div>
        {mini.distance ? (
          <>
            {mini.mentors && mini.mentors.length > 0 && (
              <div className={'mentors'}>
                <div className={'dashed-separator'} />
                {mini.mentors.map((user, index) => (
                  <User
                    key={user.id}
                    strings={strings}
                    user={user.civility ? user : mentors.find((usr) => usr.id === user.id)}
                    userRole={userRole.MENTOR}
                    isLast={index === mini.mentors.length - 1}
                    handleDeleteUser={handleDeleteUser('mentors', index)}
                  />
                ))}
              </div>
            )}
          </>
        ) : (
          <>
            <Input
              type={InputTypes.NUMBER}
              tag={'mentorsCount'}
              label={strings('mentors_count')}
              className={'mentors'}
              defaultValue={mini.mentorsCount || ''}
              handleValueChanged={handleFieldChanged}
            />
          </>
        )}
      </div>
      <div className={'separator'} />
      <div className={'actions'}>
        <Button color={ButtonColors.green} label={strings('button_text_save')} className={'save'} action={handleSave} />
      </div>
    </Panel>
  );
};

export default Team;
