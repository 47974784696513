import React, { PureComponent } from 'react';
import {
  ButtonColors,
  LateralMenu,
  Panel,
  SelectorButton,
} from '../../../../components';
import { icons } from '../../../../../assets';
import { linkUserTypes, userRole } from '../../../../../utils';
import './projectUsers.scss';
import { UserItem } from './UserItem';

export class ProjectUsers extends PureComponent {
  state = {
    lateralMenu: false,
    usersSelected: [],
    roleSelected: undefined,
  };

  onUserSelected = role => id => () => {
    const { usersSelected } = this.state;

    this.setState({
      usersSelected:
        usersSelected.findIndex(
          item => item.userId === id && item.role === role
        ) !== -1
          ? usersSelected.filter(
              item => !(item.userId === id && item.role === role)
            )
          : usersSelected.concat({ userId: id, role }),
    });
  };

  getFilterLabel = () => {
    const { strings } = this.props;
    const { roleSelected } = this.state;

    return roleSelected === userRole.FACILITATOR
      ? strings('facilitators')
      : roleSelected === userRole.SUPERVISOR
      ? strings('supervisors')
      : roleSelected === userRole.MENTOR
      ? strings('mentors')
      : '';
  };

  getUsersIdSelected = () => {
    const { usersSelected, roleSelected } = this.state;

    return usersSelected
      .filter(item => item.role === roleSelected)
      .map(item => item.userId);
  };

  onAdd = item => {
    const { getFacilitators, getMentors, getSupervisors } = this.props;

    switch (item.key) {
      case userRole.FACILITATOR: {
        getFacilitators();
        break;
      }
      case userRole.MENTOR: {
        getMentors();
        break;
      }
      case userRole.SUPERVISOR: {
        getSupervisors();
        break;
      }
      default: {
        break;
      }
    }

    this.setState({
      lateralMenu: true,
      roleSelected: item.key,
    });
  };

  validateAdd = () => {
    const { linkUser, projectId } = this.props;
    const { usersSelected } = this.state;

    this.setState({ lateralMenu: false, usersSelected: [] });
    linkUser(usersSelected, projectId);
  };

  render() {
    const {
      strings,
      projectId,
      projectUsers,
      usersData,
      navigateTo,
      unlinkUser,
    } = this.props;
    const { roleSelected, lateralMenu } = this.state;
    return (
      <Panel id={'projectUsers'} className={'project-users'}>
        {roleSelected && (
          <LateralMenu
            role={roleSelected}
            filterLabel={this.getFilterLabel()}
            strings={strings}
            visible={lateralMenu}
            label={`${strings(
              'link_one_more'
            )} ${this.getFilterLabel().toLowerCase()}`}
            onClose={() =>
              this.setState({ lateralMenu: false, usersSelected: [] })
            }
            displayAcademiesFilter={roleSelected === userRole.SUPERVISOR}
            data={usersData}
            itemsChecked={this.getUsersIdSelected()}
            onItemSelected={this.onUserSelected(roleSelected)}
            onAdd={this.validateAdd}
            canAdd={this.state.usersSelected.length > 0}
            isProject={false}
          />
        )}
        <div className={'header'}>
          <p className={`medium title`}>{strings('users')}</p>
          <SelectorButton
            color={ButtonColors.orange}
            label={strings('button_text_add')}
            icon={icons.ADD}
            className={`add-button`}
            action={this.onAdd}
            enabled={projectId}
            data={Object.entries(linkUserTypes).map(([key, value]) => ({
              key,
              value: strings(value),
            }))}
          />
        </div>
        <div className={'horizontal-dot-separator'} />
        {projectUsers && projectUsers.length === 0 ? (
          <p className={'no-users'}>{strings('no_users')}</p>
        ) : (
          projectUsers.map(user => (
            <UserItem
              user={user}
              key={user.id}
              navigateTo={navigateTo}
              unlinkUser={() => unlinkUser(user.id, user.role, projectId)}
              strings={strings}
            />
          ))
        )}
      </Panel>
    );
  }
}
