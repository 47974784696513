import React from 'react';
import { icons } from '../../../../assets';
import { getProjectImage, projectStatus, Routes } from '../../../../utils';
import UserItem from '../../usersList/userItem';
import './project-item.scss';

export const ProjectItem = ({ strings, project, allowDetailNavigation, navigateTo, unlinkProject }) => {
  const { name, type, season, users, status } = project;

  const handleUnlink = (event) => {
    event.stopPropagation();
    unlinkProject(project.id);
  };

  const handleNavigation = () => {
    if (allowDetailNavigation) {
      navigateTo(Routes.PROJECT.replace(':projectId', project.id));
    }
  };

  return (
    <div id={'projectItem'} onClick={handleNavigation}>
      <div className={`project-item-title ${allowDetailNavigation ? 'allowed' : ''}`}>
        <div className={'name'}>{name || strings('no_name_project')}</div>
        {status && (
          <div className={"status"}>
            <p>{strings(`project_status_${status.toLowerCase()}`)}</p>
            <div className={`state ${status.toLowerCase()}`} />
          </div>
        )}
        <div className={'type'}>{getProjectImage(strings, type)}</div>
        {unlinkProject && (
          <i className={'material-icons'} onClick={handleUnlink}>
            {icons.CANCEL}
          </i>
        )}
      </div>
      <div className='project-item-title'>
        {strings("season") + " : "}
        {season}
      </div>
      <hr className={'separator'} />
      <div className='users-list'>
        {users &&
          users.map((user) => (
            <div key={user.id}>
              <UserItem user={user} navigateTo={navigateTo} strings={strings} />
            </div>
          ))}
      </div>
    </div>
  );
};
