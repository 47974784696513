import { connect } from "react-redux";
import { FacilitatorsSelectors } from '../../../../../redux/facilitators';
import { MentorsSelectors } from '../../../../../redux/mentors';
import { MinisActions } from '../../../../../redux/minis';
import { ProjectsSelectors } from '../../../../../redux/projects';
import { SupervisorsSelectors } from '../../../../../redux/supervisors';
import { default as Team } from "./Team";

const mapStateToProps = (state) => ({
  usersData: (page, search, region, academy, status, role) => ProjectsSelectors.getUsers(page, search, region, academy, status, role)(state),
  facilitators: state.facilitators.list || [],
  supervisors: state.supervisors.list || [],
  mentors: state.mentors.list || [],
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(MinisActions.getTeamFormDataRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Team);
