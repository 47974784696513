import { Pie } from '@nivo/pie';
import React, { useState } from 'react';
import { icons, images } from '../../../../assets';
import { getProjectImage, type } from '../../../../utils';
import { ActionPopup } from '../../../components';
import './projectList.scss';
import { CheckBox } from '../../../components/checkbox';

export const ProjectListItem = ({
  strings,
  onClick,
  onCheck,
  isChecked,
  item,
  onOptions,
  actions,
}) => {
  const pieStyle = {
    width: 60,
    height: 60,
    colors: v => v.color,
    innerRadius: 0.6,
    enableRadialLabels: false,
    enableSlicesLabels: false,
    animate: true,
    isInteractive: false,
  };
  const [displayActions, setDisplayActions] = useState(false);

  const onToggleActions = e => {
    e && e.stopPropagation();
    setDisplayActions(!displayActions);
  };
  return (
    <div className={'project-list-item'}>
      {onCheck && (
        <div className={'check'}>
          <CheckBox onCheck={onCheck(item.id)} isChecked={isChecked} />
        </div>
      )}
      <div className={'content'} onClick={onClick(item.id)}>
        <div className={'header'}>
          <p className={`label bold`}>{item.title}</p>
          <div className={'info'}>
            <div className={'place'}>
              <p>{item.details.place}</p>
            </div>
            <div className={'time'}>
              {getProjectImage(strings, item.details.time)}
            </div>
          </div>
        </div>
        <div className={'footer'}>
          <div className={'members'}>
            {Object.entries(item.members).map(([key, value]) => (
              <div key={key} className={'item'}>
                <div className={`pins ${key}`}>{value}</div>
                <p className={'member-poste'}>{strings(key)}</p>
              </div>
            ))}
          </div>
          <div className={'other-details'}>
            <div className={'code'}>
              <i className={'material-icons'}>{icons.LINK}</i>
              <p>{item.otherDetails.code}</p>
            </div>
            <div className={'password'}>
              <i className={'material-icons'}>{icons.LOCK}</i>
              <p>{item.otherDetails.password}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={'pie'}>
          <Pie
            data={[
              { id: 'curr', value: item.progress, color: '#8CC73F' },
              {
                id: 'tot',
                value: 100 - item.progress,
                color: '#EFF2F4',
              },
            ]}
            {...pieStyle}
          />
          <p className={'total'}>{`${item.progress}%`}</p>
          {actions && (
            <div className={'options'} onClick={onToggleActions}>
              <i className={'material-icons'}>{icons.THREE_DOT}</i>
            </div>
          )}
        </div>
        {item.status && (
          <div className={"status"}>
            <p>{strings(`project_status_${item.status.toLowerCase()}`)}</p>
            <div className={`state ${item.status.toLowerCase()}`} />
          </div>
        )}
      </div>
      {displayActions && (
        <ActionPopup
          display={true}
          userConcerned={item.id}
          tag={''}
          onClose={onToggleActions}
          items={actions}
        />
      )}
    </div>
  );
};
