import React from 'react';
import { images } from '../assets';
import { iconList } from './enum';

const ImageIcon = ({image, label, className}) => <img src={image} alt={label}
                                                      style={{
                                                        width: '24px',
                                                        position: 'relative',
                                                      }}
                                                      className={`social-icon ${className && className}`}
/>;

export const getIcon = linkIcon => {
  switch (linkIcon) {
    case iconList.FACEBOOK:
      return <i className={'fa fa-facebook-f'}/>;
    case iconList.MAILCHIMP:
      return <i className={'fab fa-mailchimp'}/>;
    case iconList.SLACK:
      return <i className={'fa fa-slack'}/>;
    case iconList.TRELLO:
      return <i className={'fa fa-trello'}/>;
    case iconList.TWITTER:
      return <i className="fa fa-twitter"/>;
    case iconList.CALENDAR:
      return <i className="fa fa-calendar-check"/>;
    case iconList.MAIL:
      return <i className="fa fa-envelope"/>;
    case iconList.G_DRIVE:
      return <i className="fab fa-google-drive"/>;
    case iconList.DROPBOX:
      return <i className="fab fa-dropbox"/>;
    case iconList.FOLDER:
      return <i className="fa fa-folder"/>;
    case iconList.SNAPCHAT:
      return <i className="fa fa-snapchat-ghost"/>;
    case iconList.INSTAGRAM:
      return <i className="fa fa-instagram"/>;
    case iconList.LINKEDIN:
      return <i className="fa fa-linkedin-in"/>;
    case iconList.PINTEREST:
      return <i className={'fa fa-pinterest-p'}/>;
    case iconList.YOUTUBE:
      return <i className={'fab fa-youtube'}/>;
    case iconList.VIMEO:
      return <i className={'fa fa-vimeo-v'}/>;
    case iconList.TUMBLR:
      return <i className={'fa fa-tumblr'}/>;
    case iconList.AT:
      return <i className={'fa fa-at'}/>;
    case iconList.LINK:
      return <i className={'fa fa-link'}/>;
    case iconList.FRIEND:
      return <i className={'fas fa-user-friends'}/>;
    case iconList.ARROW:
      return <i className={'fa fa-arrow-right'}/>;
    case iconList.HOME:
      return <i className={'fa fa-home'}/>;
    case iconList.G_PLUS:
      return <i className="fab fa-google-plus-g"/>;
    case iconList.VIDEO:
      return <i class="fas fa-play-circle"></i>;
    case iconList.ONE_DRIVE:
      return <ImageIcon image={images.onedriveIcon} label={'one-drive'}/>;
    case iconList.TUZZIT:
      return <ImageIcon image={images.tuzzitIcon} label={'tuzzit'}/>;
    case iconList.ASANA:
      return <ImageIcon image={images.asanaIcon} label={'asana'}/>;
    case iconList.WWEEDDOO:
      return <ImageIcon image={images.wweeddooIcon} label={'weedoo'}/>;
    case iconList.WRIKE:
      return <ImageIcon image={images.wrikeIcon} label={'wrike'}/>;
    case iconList.EPA:
      return <ImageIcon image={images.epaLogoTurquoise} label={'epa'}/>;
    case iconList.GLOBAL:
      return <i className="fas fa-globe-europe"/>;
    case iconList.ACHIEVEMENT:
      return <ImageIcon image={images.jaLogo} label={'junior achivement'} className={'junior-achievement'}/>;
    default:
      return '';
  }
};

