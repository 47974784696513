import React, { useEffect, useState } from 'react';
import ShortId from 'shortid';
import { icons, images } from '../../../../../assets';
import { InputTypes, miniStatus, presenceType } from '../../../../../utils';
import { Button, ButtonColors, DateInput, Input, Panel, RadioButton, TextArea } from '../../../../components';
import { validateDraft } from './FormValidator';
import { default as Partner } from './Partner';

const Details = ({ strings, mini, handleMiniInfoChanged, saveMini }) => {
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      const elementsInError = document.querySelectorAll('.invalid');
      if (elementsInError && elementsInError.length > 0) {
        const root = document.querySelector(`#root`);
        root.scrollTo({
          top: elementsInError[0].offsetParent.offsetTop - 20 - 72,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }, [errors]);

  const handleFieldChanged = (tag, value) => {
    setErrors({});
    handleMiniInfoChanged(tag, value);
  };

  const handleDateChanged = (tag) => (date) => handleFieldChanged(tag, date);

  const handleTypeChange = (distance) => () => handleFieldChanged('distance', distance);

  const handleAddPartner = () => handleFieldChanged('partners', [...mini.partners, { key: ShortId.generate() }]);

  const handlePartnerInfoChanged = (index) => (tag, value) => {
    const partners = mini.partners.slice();
    partners[index][tag] = value;

    handleFieldChanged('partners', partners);
  };

  const handleRemovePartner = (index) => () => {
    const partners = mini.partners.slice();
    partners.splice(index, 1);

    handleFieldChanged('partners', partners);
  };

  const handleSave = () => {
    const errors = validateDraft(mini);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      saveMini();
    }
  };

  const getStatus = (strings, status) => {
    switch (status) {
      case miniStatus.DRAFT:
        return strings('mini_draft');
      case miniStatus.RUNNING:
        return strings('mini_running');
      case miniStatus.FINISHED:
        return strings('mini_finished');
      case miniStatus.ARCHIVED:
        return strings('mini_archived');
      default:
        return '';
    }
  };

  return (
    <>
      <Panel>
        <div className={'base-info'}>
          <Input
            type={InputTypes.TEXT}
            placeholder={strings('project_name')}
            tag={'name'}
            className={'name'}
            defaultValue={mini.name || ''}
            isHighlighted={false}
            inputClassName={`${errors.name ? 'invalid' : ''}`}
            error={errors.name ? strings(errors.name) : null}
            handleValueChanged={handleFieldChanged}
          />
          <div className={'line'} alt={'Logo Mini S'}>
            <img src={images.epaLogoMiniS} />
            <Input
              type={InputTypes.TEXT}
              tag={'status'}
              label={strings('status')}
              className={'status'}
              defaultValue={getStatus(strings, mini?.status)}
              disabled={true}
              isHighlighted={false}
            />
          </div>
        </div>
      </Panel>
      <Panel>
        <div className={'advanced-info'}>
          <div className={'section types'}>
            <p className={'label'}>{strings('type')}</p>
            <RadioButton
              className={'medium'}
              value={presenceType.DISTANCE}
              label={strings('distance')}
              isChecked={mini.distance}
              onChange={handleTypeChange(true)}
            />
            <RadioButton className={'medium'} label={strings('presence')} isChecked={!mini.distance} onChange={handleTypeChange(false)} />
          </div>
          <div className={'separator'} />
          <div className={'section dates'}>
            <p className={'label'}>{strings('date_duration')}</p>
            <div className={'wrapper'}>
              <div className={'dates'}>
                <DateInput
                  className={'date'}
                  label={strings('start_date')}
                  placeholder={strings('date_placeholder')}
                  dateFormat={'dd/MM/yyyy'}
                  selectedDate={mini.startDate}
                  onChange={handleDateChanged('startDate')}
                />
                <DateInput
                  className={'date'}
                  label={strings('end_date')}
                  placeholder={strings('date_placeholder')}
                  dateFormat={'dd/MM/yyyy'}
                  selectedDate={mini.endDate}
                  onChange={handleDateChanged('endDate')}
                />
              </div>
              <div className={'duration-wrapper'}>
                <Input
                  type={InputTypes.NUMBER}
                  tag={'duration'}
                  label={strings('duration_hours')}
                  className={'duration'}
                  defaultValue={mini.duration || ''}
                  icon={icons.SCHEDULE}
                  inputClassName={`${errors.duration ? 'invalid' : ''}`}
                  error={errors.duration ? strings(errors.duration) : null}
                  handleValueChanged={handleFieldChanged}
                />
              </div>
            </div>
          </div>
          <div className={'separator'} />
          <div className={'section partners'}>
            <div className={'header'}>
              <p className={'label'}>{strings('partners')}</p>
              <Button color={ButtonColors.orange} label={strings('button_text_add')} icon={icons.ADD} className={'add'} action={handleAddPartner} />
            </div>
            <div className={'list'}>
              {mini.partners.map((partner, index) => (
                <Partner
                  index={index}
                  strings={strings}
                  key={partner.id || partner.key}
                  partner={partner}
                  isLast={index === mini.partners.length - 1}
                  errors={errors.partners}
                  handlePartnerInfoChanged={handlePartnerInfoChanged}
                  handleRemovePartner={handleRemovePartner}
                />
              ))}
            </div>
          </div>
          <div className={'separator'} />
          <div className={'section subject'}>
            <p className={'label'}>{strings('subject')}</p>
            <TextArea
              type={InputTypes.TEXT}
              tag={'subject'}
              label={strings('subject')}
              maxLength={500}
              defaultValue={mini.subject}
              handleValueChanged={handleFieldChanged}
            />
          </div>
          <div className={'separator'} />
          {!mini.distance && (
            <>
              <div className={'section location'}>
                <p className={'label'}>{strings('location')}</p>
                <div className={'line'}>
                  <Input
                    type={InputTypes.TEXT}
                    tag={'placeName'}
                    label={strings('place_name')}
                    className={'place-name'}
                    defaultValue={mini.placeName || ''}
                    handleValueChanged={handleFieldChanged}
                  />
                  <Input
                    type={InputTypes.TEXT}
                    tag={'placeAddress'}
                    label={strings('address')}
                    className={'place-address'}
                    defaultValue={mini.placeAddress || ''}
                    handleValueChanged={handleFieldChanged}
                  />
                </div>
                <div className={'line'}>
                  <Input
                    type={InputTypes.TEXT}
                    tag={'placeCity'}
                    label={strings('city')}
                    className={'place-city'}
                    defaultValue={mini.placeCity || ''}
                    handleValueChanged={handleFieldChanged}
                  />
                  <Input
                    type={InputTypes.TEXT}
                    tag={'placePostalCode'}
                    label={strings('postalCode')}
                    className={'place-postal'}
                    defaultValue={mini.placePostalCode || ''}
                    handleValueChanged={handleFieldChanged}
                  />
                </div>
              </div>
              <div className={'separator'} />
            </>
          )}
          <div className={'actions'}>
            <Button color={ButtonColors.green} label={strings('button_text_save')} className={'save-button'} action={handleSave} />
          </div>
        </div>
      </Panel>
    </>
  );
};

export default Details;
