import {createReducer} from 'reduxsauce';
import { userRole } from '../../utils';
import {types} from './actions';

const initialState = {
  list: [],
  currentFilter: null,
  freemium: {
    id: null,
    civility: null,
    firstName: null,
    lastName: null,
    email: null,
    region: null,
    role: userRole.FREEMIUM,
  }
};

const getFreemiumsSucceeded = (state = initialState, action) => {
  const {list} = action;
  return {...state, list}
};

const setFreemiumsCurrentFilter = (state = initialState, action) => {
  const {page, filter} = action;
  return {...state, currentFilter: {page, filter}};
};

const getFreemiumDetailSucceeded = (state = initialState, action) => {
  const { freemium } = action;
  return { ...state, freemium}
};

const deleteFreemiumSucceeded = (state = initialState, action) => {
  const {id} = action;
  const copyList = JSON.parse(JSON.stringify(state.list));
  const freemiumIndex = copyList.findIndex(s => s.id === id);
  copyList.splice(freemiumIndex, 1);
  return {...state, list: copyList, freemium: state.freemium && state.freemium.id === id ? initialState.freemium : state.freemium};
};

const updateFreemiumSucceeded = (state = initialState, action) => {
  const {freemium} = action;

  const copyList = JSON.parse(JSON.stringify(state.list));
  const freemiumIndex = copyList.findIndex(s => s.id === freemium.id);
  copyList[freemiumIndex] = freemium;
  return {...state, list: copyList, freemium: state.freemium.id === freemium.id ? freemium: state.freemium};
};

const createFreemiumSucceeded = (state = initialState, action) => {
  const {freemium}= action;
  return {...state, freemium};
};

const upgradeFreemiumSucceeded = (state = initialState) => {
  return {...state, freemium: initialState.freemium};
};

const resetFreemiumDetail = (state = initialState) => {
  return {...state, freemium: initialState.freemium};
};

export default createReducer(initialState, {
  [types.GET_FREEMIUMS_SUCCEEDED] : getFreemiumsSucceeded,
  [types.SET_FREEMIUMS_CURRENT_FILTER] : setFreemiumsCurrentFilter,
  [types.GET_FREEMIUM_DETAIL_SUCCEEDED]: getFreemiumDetailSucceeded,
  [types.DELETE_FREEMIUMS_SUCCEEDED] : deleteFreemiumSucceeded,
  [types.UPDATE_FREEMIUM_SUCCEEDED] : updateFreemiumSucceeded,
  [types.CREATE_FREEMIUM_SUCCEEDED] : createFreemiumSucceeded,
  [types.UPGRADE_FREEMIUM_SUCCEEDED] : upgradeFreemiumSucceeded,
  [types.RESET_FREEMIUM_DETAIL] : resetFreemiumDetail,
});
