import { REGEX } from '../../../../utils/index';

export const validateUserForm = (user, errors) => {
  // Validate user

  const {civility, firstName, lastName, email, phone} = user;

  errors.civility.onError = !civility;
  errors.civility.errorMsg = 'input_require';
  errors.firstName.onError = !firstName || firstName.length < 2;
  errors.firstName.errorMsg = 'input_require';
  errors.lastName.onError = !lastName || lastName.length < 2;
  errors.lastName.errorMsg = 'input_require';
  errors.email.onError = !REGEX.EMAIL.test(email);
  errors.email.errorMsg = !email ? 'input_require' : 'wrong_email';
  errors.phone.onError = !phone || !REGEX.PHONE.test(phone);
  errors.phone.errorMsg = !phone ? 'input_require' : 'wrong_phone';

  return errors;
};



