import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { AuthSelectors } from '../../../../redux/auth';
import { ConfirmPopupActions } from '../../../../redux/confirmPopup';
import { MinisActions, MinisSelectors } from '../../../../redux/minis';
import { ProjectsActions } from '../../../../redux/projects';
import {
  formatEnumForSelector,
  formatRegionsSelector,
} from '../../../../utils';
import { miniStatusDropDown } from '../../../../utils/enum';
import { default as MiniList } from './MiniList';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
  isBoUserAdmin: AuthSelectors.isUserAdmin(state),
  regions: formatRegionsSelector(
    getTranslate(state.locale),
    state.commons.regions
  ),
  minis: MinisSelectors.getMinis(state),
  statusFilter: formatEnumForSelector(
    getTranslate(state.locale),
    miniStatusDropDown
  ),
});

const mapDispatchToProps = dispatch => ({
  getMinis: () => dispatch(MinisActions.getMiniListRequested()),
  setCurrentFilter: (page, filter) =>
    dispatch(MinisActions.setMinisCurrentFilter(page, filter)),
  updateMini: mini => dispatch(MinisActions.updateMiniRequested(mini)),
  duplicateMini: id => dispatch(MinisActions.duplicateMiniRequested(id)),
  deleteMini: id => dispatch(MinisActions.deleteMiniRequested(id)),
  showPopup: (message, popupAction, title = null) =>
    dispatch(ConfirmPopupActions.displayPopup(message, popupAction, title)),
  navigateTo: path => dispatch(push(path)),
  exportList: () => dispatch(MinisActions.exportMiniProjectsRequested()),
  exportListArchive: () => dispatch(MinisActions.exportArchivesMiniProjects()),
  // exportList: () => dispatch(ProjectsActions.exportProjectsRequested()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiniList);
