import { createAction } from '../../utils';

// Types
export const types = {
  RESET_REDUCERS: 'RESET_REDUCERS',
  GET_REGIONS_REQUESTED: 'GET_REGIONS_REQUESTED',
  GET_REGIONS_SUCCEEDED: 'GET_REGIONS_SUCCEEDED',
};

// Actions
export default {
  resetReducers: () => createAction(types.RESET_REDUCERS),
  getRegionsRequested: () => createAction(types.GET_REGIONS_REQUESTED),
  getRegionsSucceeded: (regions) => createAction(types.GET_REGIONS_SUCCEEDED, {regions}),
};
